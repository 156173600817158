import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService, ConfigService } from '..';
import { FileUploader } from '@ea-services/file-uploader';
import { SafetyMeetingImageAttribute, SafetyMeetingPostData, Staff } from '@ea-models';

@Injectable({
  providedIn: 'root'
})
export class HealthAndSafetyAPI {
  get safetyMeetingUrl() {
    return `${this.configService.apiURL()}safety_meetings`;
  }

  constructor(
    private apiService: ApiService,
    private configService: ConfigService,
    private fileUploader: FileUploader
  ) {}

  public getStaff(): Observable<Staff[]> {
    return this.apiService.get(`${this.configService.apiURL()}active_staffs`, null);
  }

  public getDepartments(): Observable<any> {
    return this.apiService.get(`${this.configService.apiURL()}departments`, null);
  }

  public submitSafetyMeeting(params: any): Observable<any> {
    return this.apiService.post(this.safetyMeetingUrl, params);
  }

  public submitFormalTraining(params: any): Observable<any> {
    return this.apiService.post(`${this.configService.apiURL()}staff_trainings`, params);
  }

  submitAllImages(safety_meeting: SafetyMeetingImageAttribute | SafetyMeetingPostData) {
    return this.fileUploader.submitAllImages(
      `${this.safetyMeetingUrl}`,
      safety_meeting,
      'safety_meeting',
      'safety_meeting_files_attributes'
    );
  }
}
