import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { StaffSummaryFilters, HealthAndSafetyFilters } from '../../constants/health-safety.constants';
import _ from 'lodash';
import { GlobalService, PopUpService } from '@ea-services';

@Component({
  selector: 'health-safety-staff-filter',
  templateUrl: 'health-safety-staff-filter.html',
  styleUrls: ['health-safety-staff-filter.scss'],
  providers: []
})
export class HealthSafetyStaffFilterModal implements OnInit {
  userChoice: Array<any> = [];

  @Input()
  filtersSource: any;

  @Input()
  selectedFilters: any;

  @Input()
  filterType: string;

  filters: any;
  private selectedFilter: any;

  constructor(
    private modalCtrl: ModalController,
    // private hns: HealthAndSafety,
    private popUpService: PopUpService
  ) {}

  async ngOnInit() {
    this.filtersSource = this.filterType == 'StaffSummary' ? StaffSummaryFilters : HealthAndSafetyFilters;
    this.filters = _.cloneDeep(this.selectedFilters || this.filtersSource);
    const selectedKey = this.getKeys(this.filters).find((i) => this.filters[i].selected);
    if (selectedKey) this.selectedFilter = this.filters[selectedKey];
  }

  save() {
    this.modalCtrl.dismiss(_.cloneDeep(this.filters));
  }

  reset() {
    this.filters = _.cloneDeep(this.filtersSource);
    console.log('reset this.filters', this.filters, this.filtersSource);
  }

  cancel() {
    this.popUpService.popupCustomConfirm(
      'Alert',
      'Are you sure you want to close? If you close this screen any changes made to the filters will not be saved.',
      'Yes',
      'Cancel',
      null,
      () => {
        this.filters = _.cloneDeep(this.selectedFilters || this.filtersSource);
        this.modalCtrl.dismiss();
      },
      () => {}
    );
  }

  onChangeFilter($event, filter) {
    if (this.selectedFilter && this.selectedFilter.filterId === this.filters[filter].filterId) return;
    if (this.selectedFilter) this.selectedFilter.selected = false;
    this.selectedFilter = this.filters[filter];
  }

  getKeys(object: any) {
    return Object.keys(object);
  }
}
