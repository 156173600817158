<ng-container>
  <div
    class="location-map-canvas"
    id="curr_location_map_canvas"
    #locationMapCanvas
  ></div>
  <ion-item lines="none" color="light">
    <ion-label>Map</ion-label>
  </ion-item>
  <ion-button color="success" expand="full" (click)="saveLocation()"
    >Capture Current Location</ion-button
  >
</ng-container>
