<ion-grid class="dynamic-template">
  <!--  show vitals button here-->
  <ion-row *ngIf="vitalsEnabled">
    <ion-col size="12">
      <div class="margin-l-16 mt-16">
        <ion-button (click)="showVitalsClicked()" color="danger">
          <ion-icon class="mr-8" size="large" name="pulse"></ion-icon>Vitals
        </ion-button>
      </div>
    </ion-col>
  </ion-row>

  <ion-row *ngIf="supportScanning"
           class="scanning-codes"
           [class.single]="isSingleScanning">
    <ion-col size-xs="12" size-sm="6" *ngIf="driverLicenseScanningSupport">
      <div class="scanning">
        <div class="scanning-item"
             (click)="onScanning(scanningTypes.driverLicense)">
          <ion-icon name="scan-outline"></ion-icon>
          Scan a valid driver's license
        </div>
        <div>(Only available for Enhanced Driver's License)</div>
      </div>
    </ion-col>
    <ion-col size-xs="12" size-sm="6" *ngIf="supportRTP">
      <div class="scanning">
        <div class="scanning-item"
             (click)="onScanning(scanningTypes.liftAccessPass)">
          <ion-icon name="scan-outline"></ion-icon>Scan a valid lift access pass
        </div>
      </div>
    </ion-col>
  </ion-row>

  <ion-row *ngFor="let row of template" [ngClass]="row.customCss || null">
    <ng-container *ngIf="model[row.if.key] === row.if.value || row.if.or?.includes(model[row.if.key])">
      <ng-container *ngFor="let col of row.cols">
        <ion-col *ngIf="(model[col.if.key] === col.if.value || col.if.or?.includes(model[col.if.key]))  
        && (!col.dynamicKey || !dynamicFields || col.expect === !!dynamicFields[col.dynamicKey])"
                 [sizeXs]="col.xs"
                 [sizeSm]="col.sm"
                 [class.required-q]="col.required">
          <!--Input/textarea-->
          <ng-container *ngIf="col.type === 'input' || col.type === 'textarea'">
            <ion-item lines="none">
              <ion-label>{{ col.label }} <span class="required">*</span></ion-label>
            </ion-item>
            <ion-item lines="none">
              <ion-input *ngIf="col.type === 'input'"
                         debounce="400"
                         [(ngModel)]="model[col.field]"
                         [class.in-valid]="!model[col.field]"
                         (ionChange)="onChangeEventValue($event, col.field)"
                         autocapitalize="sentences"
                         [attr.data-ci]="col.field"></ion-input>
              <ion-textarea *ngIf="col.type === 'textarea' && (!col.lockable || !flags.lock_accident_report_statement)"
                            debounce="400"
                            rows="5"
                            [(ngModel)]="model[col.field]"
                            autocapitalize="sentences"
                            [class.in-valid]="!model[col.field]"
                            (ionChange)="onChangeEventValue($event, col.field)">
              </ion-textarea>
              <!-- A specific case: locked field if Signature is set -->
              <ng-container *ngIf="col.type === 'textarea' && col.lockable && flags.lock_accident_report_statement">
                <ion-grid>
                  <ion-row>
                    <ion-col>
                      <ion-textarea debounce="400"
                                    rows="5"
                                    [(ngModel)]="model[col.field]"
                                    [class.in-valid]="!model[col.field]"
                                    [disabled]="model[col.lockIfProp]?.length > 0"
                                    autocapitalize="sentences"
                                    (ionChange)="onChangeEventValue($event, col.field)">
                      </ion-textarea>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ng-container>
            </ion-item>
          </ng-container>
          <!--Date/time-->
          <ng-container *ngIf="col.type === 'datetime'">
            <ion-item lines="none">
              <ion-label>{{ col.label }} <span class="required">*</span></ion-label>
            </ion-item>
            <ion-item lines="none">
              <input readonly
                     class="standard-input"
                     [owlDateTime]="dt1"
                     [max]="maxDate"
                     [owlDateTimeTrigger]="dt1"
                     [(ngModel)]="model[col.field]"
                     [class.in-valid]="!model[col.field]"
                     (dateTimeChange)="onChange($event.value, col.field, true)" />
              <owl-date-time [pickerType]="col.displayFormat === 'H:mm' ? 'timer' : 'calendar'"
                             #dt1></owl-date-time>
            </ion-item>
          </ng-container>
          <!--Address custom component-->
          <ng-container *ngIf="!resetDataAfterScanning && col.type === 'custom' && col.custom === 'address'">
            <app-address [modelData]="customData"
                         [requiredObjects]="requiredQuestions"
                         (changed)="onCustomDataChange($event)">
            </app-address>
          </ng-container>
          <!--Birthdate custom component-->
          <ng-container *ngIf="!resetDataAfterScanning && col.type === 'custom' && col.custom === 'birthdate'">
            <app-birthdate [modelData]="customData"
                           (changed)="onChange($event, col.field)"
                           [requiredObjects]="requiredQuestions"></app-birthdate>
          </ng-container>
          <!--Select/Dropdown dynamic fields-->
          <ng-container *ngIf="col.type === 'select' && !!col.dynamicKey 
          && dynamicFields && !!dynamicFields[col.dynamicKey]">
            <ion-item lines="none">
              <ion-label>{{ col.label }} <span class="required">*</span></ion-label>
            </ion-item>
            <div class="ion-padding-start ion-padding-end">
              <ng-select [items]="dynamicFields[col.dynamicKey]"
                         [selectOnTab]="true"
                         [bindLabel]="col.data.value"
                         [bindValue]="col.data.key"
                         placeholder=""
                         [dropdownPosition]="!isMobilePlatform ? 'bottom' : 'top'"
                         class="standard-select"
                         [class.in-valid]="!model[col.field]"
                         (focus)="incidentProvider.scrollToElement($event)"
                         (change)="onSelectChange($event, col.data.key, col.field)"
                         [(ngModel)]="model[col.field]">
              </ng-select>
            </div>
            <ion-item lines="none"
                      *ngIf="selects[col._ + col.field]?.length === 0">
              <ion-input debounce="400"
                         [(ngModel)]="model[col.field]"
                         autocapitalize="sentences"
                         [class.in-valid]="!model[col.field]"
                         (ionChange)="onChangeEventValue($event, col.field)">
              </ion-input>
            </ion-item>
          </ng-container>
          <!--Select/Dropdown-->
          <ng-container *ngIf="col.type === 'select' && !col.dynamicKey">
            <ion-item lines="none">
              <ion-label>{{ col.label }} <span class="required">*</span></ion-label>
            </ion-item>
            <div *ngIf="selects[col._ + col.field]?.length > 0"
                 class="ion-padding-start ion-padding-end">
              <ng-select [selectOnTab]="true"
                         placeholder=""
                         dropdownPosition="bottom"
                         class="standard-select"
                         [class.in-valid]="!model[col.field]"
                         [dropdownPosition]="!isMobilePlatform ? 'bottom' : 'top'"
                         (change)="onSelectChange($event, col.data.key, col.field)"
                         (focus)="incidentProvider.scrollToElement($event)"
                         [(ngModel)]="model[col.field]">
                <ng-option *ngFor="let item of selects[col._ + col.field] | dynamicSelectCustom:currentUser?.org"
                           [value]="item[col.data.key]"
                           [disabled]="item.disabled">{{ item[col.data.value] }}</ng-option>
              </ng-select>
            </div>
            <ion-item *ngIf="selects[col._ + col.field]?.length === 0"
                      lines="none">
              <ion-input debounce="400"
                         [(ngModel)]="model[col.field]"
                         autocapitalize="sentences"
                         [class.in-valid]="!model[col.field]"
                         (ionChange)="onChangeEventValue($event, col.field)">
              </ion-input>
            </ion-item>
          </ng-container>
          <!--Switch-->
          <ng-container *ngIf="col.type === 'checkbox'">
            <img *ngIf="model[col.field] === '0'"
                 src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                 (load)="onCbxHasValue(col.field, model[col.field])" />
            <ion-item lines="none" *ngIf="model[col.field] !== '0'">
              <ion-label>{{ col.label }} <span class="required">*</span></ion-label>
              <ion-toggle slot="start"
                          [(ngModel)]="model[col.field]"
                          mode="ios"
                          (ionChange)="onChangeEventChecked($event, col.field)">
              </ion-toggle>
            </ion-item>
          </ng-container>
          <!--Label/Header-->
          <ng-container *ngIf="col.type === 'label'">
            <div class="icon-header-container">
              <ion-icon [name]="col.icon" *ngIf="col.icon"></ion-icon>
              <ion-label class="bold">{{ col.label }} <span class="required">*</span></ion-label>
            </div>
          </ng-container>
          <!--Signature custom component -->
          <ng-container *ngIf="col.type === 'custom' && col.custom === 'signature'">
            <div *ngIf="col.lockable && col.unlockText && flags.lock_accident_report_statement"
                 class="notice">
              {{ col.unlockText }}
            </div>
            <app-report-completion [signatureOnly]="col.signatureOnly"
                                   [requiredObjects]="requiredQuestions"
                                   [hideHeader]="true"
                                   (nameChanged)="onChange($event, 'completed_by')"
                                   (dateChanged)="onChange($event, 'completed_date')"
                                   [nameEvent]="nameSubject.asObservable()"
                                   [dateEvent]="dateSubject.asObservable()"
                                   [signatureEvent]="signSubject.asObservable()"
                                   [parentChangeDetectorRef]="parentChangeDetectorRef"
                                   (signatureChanged)="onSignatureChange($event, col.field)">
            </app-report-completion>
          </ng-container>
          <!--Complaint(s) custom component -->
          <ng-container *ngIf="col.type === 'custom' && col.custom === 'complaint'">
            <app-complaint [selects]="selects"
                           [label]="col.label"
                           [labels]="col.labels"
                           [userEvent]="userSubject.asObservable()"
                           [modelData]="customData"
                           (changed)="onCustomDataChange($event)"></app-complaint>
          </ng-container>
          <!--Patrollers custom component -->
          <ng-container *ngIf="col.type === 'custom' && col.custom === 'patrollers'">
            <app-patrollers [selects]="selects"
                            [modelData]="customData"
                            (changed)="onCustomDataChange($event)">
            </app-patrollers>
          </ng-container>
          <!-- Location custom component -->
          <ng-container *ngIf="col.type === 'custom' && col.custom === 'location' && currentUser">
            <app-incident-location [locationModelEvent]="locationModelSubject.asObservable()"
                                   [currentUser]="currentUser"
                                   [mapObject]="mapObject"
                                   [newPoint]="mapObject.point"
                                   [requiredObjects]="requiredQuestions"
                                   (locationChanged)="onChange($event, 'incident_location')"
                                   (featureChanged)="onChange($event.featureId, $event.field)"
                                   (buildFeatureChanged)="onChange($event?.id, $event?.name)"
                                   (markerChanged)="onChange($event, 'incidentMarker')"
                                   [selectionAppendDOM]="incidentReportMatTabGroup">
            </app-incident-location>
          </ng-container>
        </ion-col>
      </ng-container>
    </ng-container>
  </ion-row>
</ion-grid>
