import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicSelectCustomPipe } from './dynamic-select-custom.pipe';

@NgModule({
  declarations: [DynamicSelectCustomPipe],
  imports: [CommonModule],
  exports: [DynamicSelectCustomPipe]
})
export class DynamicSelectCustomPipeModule {}
