import {
  DEF_AUTO_COL,
  DEF_COL_12,
  DEF_COL_3,
  DEF_COL_4,
  DEF_COL_6,
  DEF_KEY,
  DEF_KEY_VAL,
  DEF_KEY_VAL_POS_TOP,
  WITNESSES_KEY
} from './constants';
import { ITemplate } from './types';
import { EquipmentOption } from '@ea-models/templates/selects';

export const NSAA_PATIENTS_TEMPLATE: ITemplate = [
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_6,
        label: 'First Name',
        field: 'first_name',
        type: 'input',
        required: true
      },
      {
        ...DEF_COL_6,
        label: 'Last Name',
        field: 'last_name',
        type: 'input',
        required: true
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Address',
        field: 'address',
        type: 'custom',
        custom: 'address',
        defaultCountry: 'US'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Occupation',
        field: 'occupation',
        type: 'input'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_6,
        label: 'If Minor, Released to',
        field: 'minor_released_to',
        type: 'input'
      },
      {
        ...DEF_COL_6,
        label: 'Relationship to Minor',
        field: 'minor_released_to_relationship',
        type: 'input'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Date of Birth',
        field: 'birthday',
        type: 'custom',
        custom: 'birthdate'
      }
    ]
  },
  {
    ...{ ...DEF_KEY, customCss: 'no-break' },
    cols: [
      {
        ...{ ...DEF_AUTO_COL, xs: '' },
        label: 'Gender',
        field: 'gender',
        type: 'select',
        ...DEF_KEY_VAL
      },
      {
        ...{ ...DEF_AUTO_COL, xs: '' },
        label: 'Gender - Other',
        field: 'gender_notes',
        type: 'input',
        if: { key: 'gender', value: 'Other' }
      },
      {
        ...{ ...DEF_AUTO_COL, xs: '' },
        label: 'Height',
        field: 'height',
        type: 'input'
      },
      {
        ...{ ...DEF_AUTO_COL, xs: '' },
        label: 'Weight',
        field: 'weight',
        type: 'input'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      { ...DEF_COL_6, label: 'Phone', field: 'phone', type: 'input' },
      { ...DEF_COL_6, label: 'Email', field: 'email', type: 'input' }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_6,
        label: 'Ticket Type',
        field: 'ticket_type',
        type: 'select',
        ...DEF_KEY_VAL_POS_TOP
      },
      {
        ...DEF_COL_6,
        label: 'Ticket Number #',
        field: 'ticket_number',
        type: 'input'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_6,
        label: 'Injured is an Employee',
        field: 'is_staff',
        type: 'checkbox',
        val: false
      },
      // eslint-disable-next-line max-len
      {
        ...DEF_COL_6,
        label: 'Group Name',
        field: 'group_name',
        type: 'input',
        if: { key: 'is_staff', value: false, or: [null, undefined] }
      }
    ]
  },
  {
    if: { key: 'is_staff', value: true },
    cols: [
      {
        ...DEF_COL_6,
        label: 'Injured Employee was on the Clock',
        field: 'is_staff_on_clock',
        type: 'checkbox'
      },
      {
        ...DEF_COL_6,
        label: "Employee's Department",
        field: 'staff_department',
        type: 'input'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Injured wears glasses/contact lenses',
        field: 'wear_glasses',
        type: 'checkbox',
        val: false
      }
    ]
  },
  {
    if: { key: 'wear_glasses', value: true },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Glasses/contact lenses were worn at the time the incident occurred',
        field: 'used_glasses',
        type: 'checkbox',
        val: false
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Activity History',
        field: '',
        type: 'label'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_4,
        label: 'Were you in lesson when this happened?',
        field: 'was_in_lesson',
        type: 'checkbox'
      },
      {
        ...DEF_COL_4,
        label: 'Have you taken a lesson previously?',
        field: 'lesson',
        type: 'checkbox'
      },
      {
        ...DEF_COL_4,
        label: 'Was accident the result of a collision?',
        field: 'is_collision',
        type: 'checkbox'
      }
    ]
  },
  {
    if: { key: 'was_in_lesson', value: true },
    cols: [
      {
        ...DEF_COL_6,
        label: 'Instructors Name at Time of Incident',
        field: 'instructor',
        type: 'input'
      },
      {
        ...DEF_COL_6,
        label: 'Current Lesson Program',
        field: 'in_lesson',
        type: 'select',
        ...DEF_KEY_VAL
      }
    ]
  },
  {
    if: { key: 'is_collision', value: true },
    cols: [
      {
        ...DEF_COL_6,
        label: 'Name of Other Party',
        field: 'collision_party_name',
        type: 'input'
      },
      // eslint-disable-next-line max-len
      {
        ...DEF_COL_6,
        label: 'Relation to Other Party',
        field: 'collision_party_relationship',
        type: 'input',
        dynamicKey: 'cpr',
        expect: false
      },
      {
        ...DEF_COL_6,
        label: 'Relation to Other Party',
        field: 'collision_party_relationship',
        type: 'select',
        dynamicKey: 'cpr',
        expect: true,
        ...DEF_KEY_VAL
      },
      {
        ...DEF_COL_6,
        label: 'Equipment of Other Party',
        field: 'collision_party_equipment',
        type: 'select',
        ...DEF_KEY_VAL
      },
      // eslint-disable-next-line max-len
      {
        ...DEF_COL_6,
        label: 'Party Equipment - Other',
        field: 'collision_party_equipment_other',
        type: 'input',
        if: { key: 'collision_party_equipment', value: 'Other' }
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_6,
        label: 'Ability',
        field: 'ability',
        type: 'select',
        ...DEF_KEY_VAL
      },
      {
        ...DEF_COL_6,
        label: 'Equipment Removed by',
        field: 'equipment_removed_by',
        type: 'select',
        ...DEF_KEY_VAL
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_6,
        label: 'How Many Times Did Patient Use Lift, Trail or Attraction Today?',
        field: 'usage_today',
        type: 'input'
      },
      {
        ...DEF_COL_6,
        label: 'How Many Times Did Patient Use Lift, Trail or Attraction in the Past?',
        field: 'usage_past',
        type: 'input'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Patient History',
        field: '',
        type: 'label'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Injury is a re-occurrence of a previous one',
        field: 'is_reccurence',
        type: 'checkbox'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Allergies',
        field: 'has_allergies',
        type: 'checkbox'
      }
    ]
  },
  {
    if: { key: 'has_allergies', value: true },
    cols: [
      {
        ...DEF_COL_12,
        label: 'List Allergies',
        field: 'allergies',
        type: 'textarea'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Describe Prior Injury or Illness',
        field: 'history',
        type: 'input'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_4,
        label: 'Year Injured',
        field: 'previous_year_injured',
        type: 'input'
      },
      {
        ...DEF_COL_4,
        label: 'Medical insurance',
        field: 'medical_insurance',
        type: 'checkbox'
      },
      {
        ...DEF_COL_4,
        label: 'Medication Taken',
        field: 'medications',
        type: 'input'
      }
    ]
  }
];

export const NSAA_FIRST_AID_TEMPLATE: ITemplate = [
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_6,
        label: 'First Aid on Scene',
        field: 'first_aid_scene',
        type: 'textarea'
      },
      {
        ...DEF_COL_6,
        label: 'First Aid at Base',
        field: 'first_aid_base',
        type: 'textarea'
      }
    ]
  }
];

export const NSAA_WITNESSES_TEMPLATE: ITemplate = [
  {
    ...DEF_KEY,
    cols: []
  },
  {
    ...WITNESSES_KEY,
    cols: [
      { ...DEF_COL_6, label: 'First Name', field: 'first_name', type: 'input' },
      { ...DEF_COL_6, label: 'Last Name', field: 'last_name', type: 'input' }
    ]
  },
  {
    ...WITNESSES_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Address',
        field: 'address',
        type: 'custom',
        custom: 'address',
        defaultCountry: ''
      }
    ]
  },
  {
    ...WITNESSES_KEY,
    cols: [
      { ...DEF_COL_6, label: 'Phone', field: 'phone', type: 'input' },
      { ...DEF_COL_6, label: 'Email', field: 'email', type: 'input' }
    ]
  },
  {
    ...WITNESSES_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Statement',
        field: 'statement',
        type: 'textarea'
      }
    ]
  },
  {
    ...WITNESSES_KEY,
    cols: [
      {
        ...DEF_COL_4,
        label: 'Witnessed by?',
        field: 'is_witnessed_by',
        type: 'checkbox'
      },
      {
        ...DEF_COL_4,
        label: 'Accompanied by?',
        field: 'is_accompanied_by',
        type: 'checkbox'
      },
      {
        ...DEF_COL_4,
        label: 'Collided with?',
        field: 'is_collided_with',
        type: 'checkbox'
      }
    ]
  },
  {
    ...WITNESSES_KEY,
    cols: [{ ...DEF_COL_12, label: 'Other', field: 'other', type: 'textarea' }]
  },
  {
    ...WITNESSES_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Signature',
        field: 'signature',
        type: 'custom',
        custom: 'signature',
        signatureOnly: true
      }
    ]
  }
];

export const NSAA_DESCRIPTION_TEMPLATE: ITemplate = [
  {
    ...DEF_KEY,
    cols: [
      // eslint-disable-next-line max-len
      {
        ...DEF_COL_6,
        label: "How Did The Incident Occur? (Patient's words)",
        field: 'description',
        type: 'textarea',
        lockable: true,
        lockIfProp: 'signature'
      },
      // eslint-disable-next-line max-len
      {
        ...DEF_COL_6,
        label: "How Could This Incident Have Been Prevented? (Patient's words)",
        field: 'prevention_description',
        type: 'textarea',
        lockable: true,
        lockIfProp: 'signature',
        unlockText: ''
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Against The Advice Given, The Patient Has Refused Care',
        field: 'refused_care',
        type: 'checkbox'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_6,
        label: 'Signature Type',
        field: 'signature_type',
        type: 'select',
        ...DEF_KEY_VAL
      },
      // eslint-disable-next-line max-len
      {
        ...DEF_COL_6,
        label: 'Name of Person Signing',
        field: 'other_signature_name',
        type: 'input',
        if: {
          key: 'signature_type',
          value: 'Parent',
          or: ['Guardian', 'Spouse']
        }
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Signature',
        field: 'signature',
        type: 'custom',
        custom: 'signature',
        signatureOnly: true,
        lockable: true,
        lockIfProp: 'signature',
        // eslint-disable-next-line max-len
        unlockText:
          'Once a signature is added, the patient’s statements will be locked. In order to edit the patient’s statements, clear the signature and sign once the statement has been updated.'
      }
    ]
  }
];

export const NSAA_CONDITIONS_TEMPLATE: ITemplate = [
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_6,
        label: 'Visibility',
        field: 'weather',
        type: 'select',
        ...DEF_KEY_VAL
      },
      {
        ...DEF_COL_6,
        label: 'Temperature',
        field: 'temperature',
        type: 'select',
        ...DEF_KEY_VAL,
        _: 'nsaa_'
      },
      {
        ...DEF_COL_6,
        label: 'Wind',
        field: 'wind',
        type: 'select',
        ...DEF_KEY_VAL
      },
      {
        ...DEF_COL_6,
        label: 'Surface',
        field: 'surface',
        type: 'select',
        ...DEF_KEY_VAL
      }
    ]
  }
];

export const NSAA_ADDITIONAL_QUESTIONS_TEMPLATE: ITemplate = [
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Incident Type',
        field: 'accident_category',
        type: 'select',
        ...DEF_KEY_VAL
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Lift' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Location at lift',
        field: 'lift_location_at_lift',
        type: 'input'
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Lift' },
    cols: [
      {
        ...DEF_COL_12,
        label: '# Passengers on chair',
        field: 'lift_total_passengers_on_chair',
        type: 'input'
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Lift' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Were any other riders related or acquainted to you?',
        field: 'lift_related_to_other_riders',
        type: 'select',
        ...DEF_KEY_VAL
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Lift' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Seat position',
        field: 'lift_seat_position',
        type: 'input'
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Lift' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Previous rides on lift',
        field: 'lift_total_previous_rides',
        type: 'input'
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Lift' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Rides on lift today',
        field: 'lift_total_rides_today',
        type: 'input'
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Lift' },
    cols: [
      // eslint-disable-next-line max-len
      {
        ...DEF_COL_12,
        label: 'Did you notice warning information?',
        field: 'lift_did_you_notice_warning_information',
        type: 'select',
        ...DEF_KEY_VAL
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Lift' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Did you ask for assistance?',
        field: 'lift_did_you_ask_for_assistance',
        type: 'select',
        ...DEF_KEY_VAL
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Lift' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Did you ask the operator to slow or stop the lift?',
        field: 'lift_ask_operator_to_stop_lift',
        type: 'select',
        ...DEF_KEY_VAL
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Lift' },
    cols: [
      // eslint-disable-next-line max-len
      {
        ...DEF_COL_12,
        label: 'Are you familiar with the lift operations?',
        field: 'lift_are_you_familiar_with_its_operation',
        type: 'select',
        ...DEF_KEY_VAL_POS_TOP
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Collision' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Do you wear glasses?',
        field: 'corrective_lenses_needed',
        type: 'select',
        ...DEF_KEY_VAL
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Collision' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Were they worn?',
        field: 'corrective_lenses_worn',
        type: 'select',
        ...DEF_KEY_VAL
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Collision' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Have you had any alcohol or drugs today?',
        field: 'drugs_alcohol',
        type: 'select',
        ...DEF_KEY_VAL
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Collision' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'What and when?',
        field: 'alcohol_drugs_description',
        type: 'input'
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Tubing' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Tubing lane',
        field: 'tubing_lane',
        type: 'input'
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Tubing' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Tubing lift',
        field: 'tubing_lift',
        type: 'input'
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Tubing' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Location',
        field: 'tubing_accident_location',
        type: 'input'
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Tubing' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Footwear',
        field: 'tubing_footwear',
        type: 'input'
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Tubing' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Alone or in a group?',
        field: 'tubing_alone_or_group',
        type: 'select',
        ...DEF_KEY_VAL
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Tubing' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Number of people',
        field: 'tubing_number_of_people',
        type: 'input'
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Tubing' },
    cols: [{ ...DEF_COL_12, label: 'Who', field: 'tubing_who', type: 'input' }]
  },
  {
    if: { key: 'accident_category', value: 'Tubing' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Previous experience',
        field: 'tubing_experience_previous',
        type: 'select',
        ...DEF_KEY_VAL
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Tubing' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Runs today',
        field: 'tubing_experience_runs_today',
        type: 'select',
        ...DEF_KEY_VAL
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Tubing' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Falls today',
        field: 'tubing_experience_falls_today',
        type: 'select',
        ...DEF_KEY_VAL
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Tubing' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Start method',
        field: 'tubing_start_method',
        type: 'select',
        ...DEF_KEY_VAL_POS_TOP
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Terrain Park' },
    cols: [
      // eslint-disable-next-line max-len
      {
        ...DEF_COL_12,
        label: 'Did you enter the park through an entrance gate?',
        field: 'terrain_park_did_you_enter_through_entrance_gate',
        type: 'select',
        ...DEF_KEY_VAL
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Terrain Park' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'How and where did you enter the park?',
        field: 'terrain_park_alternate_entrance_details',
        type: 'input'
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Terrain Park' },
    cols: [
      // eslint-disable-next-line max-len
      {
        ...DEF_COL_12,
        label: 'Did you read the informational and warning signs posted at the entrance?',
        field: 'terrain_park_did_you_notice_warning_information_signs',
        type: 'select',
        ...DEF_KEY_VAL
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Terrain Park' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Have you been in this park before? If yes, how often?',
        field: 'terrain_park_previous_usage',
        type: 'input'
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Terrain Park' },
    cols: [
      // eslint-disable-next-line max-len
      {
        ...DEF_COL_12,
        label: 'Have you ridden this feature before?',
        field: 'terrain_park_previous_attempts',
        type: 'select',
        ...DEF_KEY_VAL
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Terrain Park' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'If yes, how many times today?',
        field: 'terrain_park_previous_attempts_today',
        type: 'input'
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Terrain Park' },
    cols: [
      // eslint-disable-next-line max-len
      {
        ...DEF_COL_12,
        label: 'Did you scope out the feature before using it today?',
        field: 'terrain_park_scope_feature',
        type: 'select',
        ...DEF_KEY_VAL
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Terrain Park' },
    cols: [
      // eslint-disable-next-line max-len
      {
        ...DEF_COL_12,
        label: 'Do you believe the incident was due to action/inaction on your part?',
        field: 'terrain_park_do_you_believe_this_was_from_your_action',
        type: 'select',
        ...DEF_KEY_VAL
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Terrain Park' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Please explain',
        field: 'terrain_park_explain_action',
        type: 'textarea'
      }
    ]
  }
];

export const NSAA_COMPLAINTS_TEMPLATE: ITemplate = [
  {
    ...DEF_KEY,
    cols: [{ ...DEF_COL_12, label: ' ', field: '', type: 'label' }]
  },
  {
    ...DEF_KEY,
    cols: [{ ...DEF_COL_12, label: ' ', field: '', type: 'label' }]
  },
  {
    ...DEF_KEY,
    cols: [{ ...DEF_COL_12, label: ' ', field: '', type: 'label' }]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: '',
        labels: { part: 'Part/Type', probable: 'Probable Injury/Illness' },
        field: 'complaint',
        type: 'custom',
        custom: 'complaint'
      }
    ]
  }
];

export const NSAA_TRANSPORT_TEMPLATE: ITemplate = [
  {
    ...DEF_KEY,
    cols: [
      // eslint-disable-next-line max-len
      {
        ...DEF_COL_4,
        label: 'Arrived at Resort Aid/Transfer Location by',
        field: 'transport_to_base',
        type: 'select',
        ...DEF_KEY_VAL,
        _: 'nsaa_'
      },
      // eslint-disable-next-line max-len
      {
        ...DEF_COL_4,
        label: 'Left Resort Aid/Transfer Location by',
        field: 'transport_to_aid',
        type: 'select',
        ...DEF_KEY_VAL,
        _: 'nsaa_'
      },
      {
        ...DEF_COL_4,
        label: 'Destination',
        field: 'destination',
        type: 'select',
        ...DEF_KEY_VAL
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      // eslint-disable-next-line max-len
      {
        ...DEF_COL_4,
        label: 'Arrived at Resort Aid/Transfer Location by Other?',
        field: 'other_to_base',
        type: 'input',
        if: { key: 'transport_to_base', value: 'Other' }
      },
      // eslint-disable-next-line max-len
      {
        ...DEF_COL_4,
        label: 'Ambulance/Helicopter #',
        field: 'ambulance',
        type: 'input',
        if: { key: 'transport_to_aid', value: 'Ambulance', or: ['Helicopter'] }
      },
      // eslint-disable-next-line max-len
      {
        ...DEF_COL_4,
        label: 'Left Resort Aid/Transfer Location by Other?',
        field: 'other_to_aid',
        type: 'input',
        if: { key: 'transport_to_aid', value: 'Other' }
      },
      {
        ...DEF_COL_4,
        label: 'Other Destination',
        field: 'destination_other',
        type: 'input',
        if: { key: 'destination', value: 'Other' }
      },
      // eslint-disable-next-line max-len
      {
        ...DEF_COL_4,
        label: 'Visitor Local Accommodation',
        field: 'local_accomodation_info',
        type: 'input',
        if: { key: 'destination', value: 'Hotel' }
      },
      {
        ...DEF_COL_4,
        label: 'Hospital',
        field: 'hospital',
        type: 'input',
        if: { key: 'destination', value: 'Hospital' }
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Transport & Patrollers',
        field: 'patrollers',
        type: 'custom',
        custom: 'patrollers'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Additional Personnel Involved (Name & ID)',
        field: 'patrol',
        type: 'textarea'
      }
    ]
  }
];

export const NSAA_EQUIPMENT_TEMPLATE: ITemplate = [
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_AUTO_COL,
        label: 'Activity',
        field: 'activity',
        type: 'select',
        dynamicKey: 'default_activity',
        expect: true,
        ...DEF_KEY_VAL
      },
      {
        ...DEF_AUTO_COL,
        label: 'Activity',
        field: 'activity',
        type: 'select',
        dynamicKey: 'activity',
        expect: true,
        ...DEF_KEY_VAL
      },
      {
        ...DEF_AUTO_COL,
        label: 'Other Activity',
        field: 'activity_other',
        type: 'input',
        if: { key: 'activity', value: 'Other' }
      },
      {
        ...DEF_AUTO_COL,
        label: 'Equipment',
        field: 'equipment',
        type: 'select',
        ...DEF_KEY_VAL
      },
      {
        ...DEF_AUTO_COL,
        label: 'Helmet',
        field: 'helmet',
        type: 'select',
        ...DEF_KEY_VAL
      }
    ]
  },
  {
    if: { key: 'activity', value: 'Alpine Skiing' },
    cols: [
      {
        ...DEF_COL_3,
        label: 'Left Din Toe',
        field: 'equipment_left_din_toe',
        type: 'input'
      },
      {
        ...DEF_COL_3,
        label: 'Left Din Heel',
        field: 'equipment_left_din_heel',
        type: 'input'
      },
      {
        ...DEF_COL_3,
        label: 'Right Din Toe',
        field: 'equipment_right_din_toe',
        type: 'input'
      },
      {
        ...DEF_COL_3,
        label: 'Right Din Heel',
        field: 'equipment_right_din_heel',
        type: 'input'
      }
    ]
  },
  {
    if: { key: 'activity', value: 'Biking' },
    cols: [
      {
        ...DEF_COL_4,
        label: 'Bike Make/Model',
        field: 'equipment_make_model',
        type: 'input'
      },
      // eslint-disable-next-line max-len
      {
        ...DEF_COL_4,
        label: 'Bike #',
        field: 'rental_bike',
        type: 'input',
        if: { key: 'equipment', value: EquipmentOption.ResortRental, or: [EquipmentOption.NonResortRental] }
      },
      // eslint-disable-next-line max-len
      {
        ...DEF_COL_4,
        label: 'Shoe #',
        field: 'rental_shoe',
        type: 'input',
        if: { key: 'equipment', value: EquipmentOption.ResortRental, or: [EquipmentOption.NonResortRental] }
      }
    ]
  },
  {
    if: { key: 'activity', value: 'Alpine Skiing' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Binding Make/Model',
        field: 'equipment_make_model',
        type: 'input'
      }
    ]
  },
  {
    if: {
      key: 'equipment',
      value: EquipmentOption.ResortRental,
      or: [EquipmentOption.NonResortRental, EquipmentOption.ResortDemos]
    },
    cols: [
      // eslint-disable-next-line max-len
      {
        ...DEF_COL_6,
        label: 'Rental Boot #',
        field: 'rental_boot',
        type: 'input',
        if: { key: 'activity', value: 'Alpine Skiing', or: ['Snowboard'] }
      },
      // eslint-disable-next-line max-len
      {
        ...DEF_COL_6,
        label: 'Rental Ski/Board #',
        field: 'rental_ski',
        type: 'input',
        if: { key: 'activity', value: 'Alpine Skiing', or: ['Snowboard'] }
      }
    ]
  },
  {
    if: { key: 'helmet', value: EquipmentOption.ResortRental, or: [EquipmentOption.ResortDemos] },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Rental Helmet #',
        field: 'rental_helmet_number',
        type: 'input'
      }
    ]
  },
  {
    if: { key: 'equipment', value: EquipmentOption.ResortRental, or: [EquipmentOption.ResortDemos] },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Rental Location',
        field: 'equipment_rented_from',
        type: 'select',
        ...DEF_KEY_VAL_POS_TOP
      }
    ]
  },
  {
    if: { key: 'equipment', value: EquipmentOption.NonResortRental },
    cols: [
      {
        ...DEF_COL_6,
        label: 'Shop Name',
        field: 'equipment_rented_from',
        type: 'input'
      },
      {
        ...DEF_COL_6,
        label: 'Shop Address',
        field: 'equipment_rented_address',
        type: 'input'
      }
    ]
  }
];
