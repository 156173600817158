<ng-container>
  <app-image-gallery [attachments]="attachments"
                     [hideNote]="hideNote"
                     [hidePreview]="hidePreview"
                     (attachmentsChanged)="imageSelected.emit({ currentImage: $event[0], images: $event })"></app-image-gallery>
  <div [class.invalid]="isRequired && attachments?.length === 0">
    <ng-container *ngIf="isMobilePlatform">
      <ion-button size="default"
                  expand="full"
                  color="primary"
                  [disabled]="disabled"
                  (click)="addFromCamera()">Add Image from Camera</ion-button>
      <ion-button size="default"
                  [disabled]="disabled"
                  expand="full"
                  color="primary"
                  (click)="addFromLibrary()">Add Image from Photo Library</ion-button>
    </ng-container>
    <ng-container *ngIf="!isMobilePlatform">
      <ion-button size="default"
                  [disabled]="disabled"
                  expand="full"
                  color="primary"
                  (click)="addFile()">Upload Photo</ion-button>
    </ng-container>
  </div>

</ng-container>
<input id="custom-input"
       type="file"
       #fileButton
       hidden
       accept=".jpeg,.jpg,.png"
       (change)="fileChangeListener($event)" />