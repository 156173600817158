import { Component, OnInit, Input } from '@angular/core';
import { GlobalService, SpinnerService, PopUpService, GroomersService } from '@ea-services';
import { ModalController, AlertController } from '@ionic/angular';
import { tap, take, catchError, delay } from 'rxjs/operators';
import { of } from 'rxjs';
import _ from 'lodash';
import { LogRocketProvider } from '@ea-services/provider/logrocket';
import { Utility } from '@edgeauditor/utilities/utility';

@Component({
  selector: 'moal-log-fuel',
  templateUrl: './log-fuel.component.html',
  styleUrls: ['./log-fuel.component.scss']
})
export class LogFuelModalComponent implements OnInit {
  @Input() params: { vehicle_id: number; groomer_shift_id: number };

  fuelLocations = [];
  departments = [];
  currentUser: any;
  model: {
    fuel_location_id: number;
    department_id: number;
    fuel_used: number;
    hour_meter: number;
    notes: string;
    vehicle_id: number;
    shift_id: number;
  };
  readonly utility = Utility;

  get allowSave() {
    return (
      !!this.model.department_id &&
      !!this.model.fuel_location_id &&
      !_.isNil(this.model.hour_meter) &&
      !_.isNil(this.model.fuel_used) &&
      this.model.hour_meter >= 0 &&
      this.model.fuel_used >= 0
    );
  }

  constructor(
    public globalService: GlobalService,
    private groomersService: GroomersService,
    private spinnerService: SpinnerService,
    private popUpService: PopUpService,
    private logRocketProvider: LogRocketProvider,
    private alertController: AlertController,
    public modalController: ModalController
  ) {}

  ngOnInit() {
    this.initModel();
  }

  ionViewDidEnter() {
    if (this.globalService._currentUser) {
      this.currentUser = this.globalService._currentUser;
      this.fuelLocations = _.cloneDeep(this.currentUser.fuel_locations);
      this.departments = _.cloneDeep(this.currentUser.departments);
    }
    if (this.params) {
      this.model.shift_id = this.params.groomer_shift_id;
      this.model.vehicle_id = this.params.vehicle_id;
    }
  }

  onChange(item, name, list) {
    if (item.selected) {
      return;
    }
    this.model[name] = item.id;
    of(null)
      .pipe(
        delay(100),
        take(1),
        tap(() => {
          list.forEach((element) => {
            element.selected = false;
          });
          item.selected = true;
        })
      )
      .subscribe();
  }

  close() {
    this.modalController.dismiss();
  }

  reset() {
    this.initModel();
  }

  save() {
    if (!this.allowSave) return;
    this.spinnerService.show('processing...');
    this.groomersService
      .createFuelLog(this.currentUser.token, this.model)
      .pipe(
        take(1),
        tap((result) => {
          this.spinnerService.hide();
          if (!result || !result.success) {
            this.popUpService.popupError('Log fuel failed', this.alertController);
            return;
          }
          this.modalController.dismiss(true);
        }),
        catchError(() => {
          this.popUpService.popupError('Log fuel failed', this.alertController);
          return of(null);
        })
      )
      .subscribe();
    this.logRocketProvider.logEvent('log fuel');
    this.logRocketProvider.logInfo('log fuel', this.model);
  }

  private initModel() {
    this.model = {
      fuel_location_id: null,
      department_id: null,
      fuel_used: null,
      hour_meter: null,
      notes: null,
      vehicle_id: null,
      shift_id: null
    };
  }
}
