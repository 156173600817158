import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import moment from 'moment';
import _ from 'lodash';

import { GlobalService } from '../global.service';
import { Department, Staff, TrainingType, SafetyMeeting, FormalTraining } from '@ea-models';
import { HealthSafetyStaffFilterModal } from '../../modals/health-safety-staff-filter/health-safety-staff-filter';
import { HealthSafetyOfflineDataUtility } from './health-safety-offline-data-utility';
import { SignaturePadModal } from '../../modals/signature-pad/signature-pad';
import {
  StaffSummaryFilters,
  HealthAndSafetyFilters,
  CompliantStaffFilter,
  unlimited
} from '../../constants/health-safety.constants';
import { LogRocketProvider } from '../provider/logrocket';

@Injectable({
  providedIn: 'root'
})
export class HealthAndSafety {
  public loading = false;
  public staffList: Array<Staff> = [];
  public filteredStaffList: Array<Staff> = [];
  public departments: Array<Department> = [];
  public trainingTypes: Array<TrainingType> = [];

  public selectedFilters: any;
  private selectedFiltersArray: Array<CompliantStaffFilter> = [];
  public isSortStaffByNameOnly = false;

  private selectedDepartmentId: number;
  private selectedTrainingId: number;
  public filterType: string;
  constructor(
    private globalService: GlobalService,
    private modalController: ModalController,
    private hnsOfflineDataUtility: HealthSafetyOfflineDataUtility,
    private logRocketProvider: LogRocketProvider
  ) {}

  filtersSelected() {
    this.selectedFiltersArray = [];
    for (const filter in this.selectedFilters) {
      if (this.selectedFilters.hasOwnProperty(filter)) {
        const value = this.selectedFilters[filter];
        if (value.selected) {
          this.selectedFiltersArray.push(value);
        }
      }
    }
    this.loadStaff();
  }

  async selectDepartmentId(_departmentId: number) {
    const filterTrainingTypes = () => {
      return this.globalService._currentUser.training_types.filter(
        (_trainingType: TrainingType) => _trainingType.department_ids.indexOf(_departmentId) >= 0
      );
    };
    this.selectedDepartmentId = _departmentId;

    this.trainingTypes = _.sortBy(filterTrainingTypes(), [
      (_trainingType: TrainingType) => (_trainingType.title || '').toLowerCase()
    ]);
    console.log('select department trainings', this.trainingTypes);
  }
  async selectTrainingId(_trainingId: number) {
    this.selectedTrainingId = _trainingId;

    this.loadStaff();
  }

  async loadStaff() {
    // console.log("all staff", this.staffList);
    const showAllStaff: boolean =
      this.selectedFilters &&
      this.selectedFilters.all_staff_all_departments &&
      this.selectedFilters.all_staff_all_departments.selected;
    let staff: Array<Staff> = this.staffWithDepartmentAndTraining(
      this.selectedDepartmentId,
      showAllStaff ? 0 : this.selectedTrainingId
    );
    staff = _.cloneDeep(staff);
    // console.log("Staff with training", "is showAllStaff", showAllStaff, "staff List", staff);
    let validTrainingStaff: Array<Staff> = [];
    let compliantFilterStaff: Array<Staff> = [];
    if (this.selectedFiltersArray.length && !_.isNil(this.selectedTrainingId)) {
      const group = _.groupBy(this.selectedFiltersArray, 'type');
      const boolFilter = _.maxBy(group.bool, 'compareValue');
      const rangeFilter = _.maxBy(group.range, 'compareValue');
      if (boolFilter && rangeFilter) {
        validTrainingStaff = this.staffWithValidTraining(staff, this.selectedTrainingId, rangeFilter);

        compliantFilterStaff = this.filterCompliantStaff(staff, this.selectedTrainingId, boolFilter);

        staff = _.intersectionWith<Staff>(validTrainingStaff, compliantFilterStaff);
      } else if (rangeFilter && !boolFilter)
        staff = validTrainingStaff = this.staffWithValidTraining(staff, this.selectedTrainingId, rangeFilter);
      else if (boolFilter && !rangeFilter) {
        staff = compliantFilterStaff = this.filterCompliantStaff(staff, this.selectedTrainingId, boolFilter);
      }
      // console.log("Filtered staff", "validTrainingStaff", validTrainingStaff, "compliantFilterStaff", compliantFilterStaff, "staff union", staff);
    }
    if (this.isSortStaffByNameOnly) {
      this.filteredStaffList = _.sortBy(staff, ['full_name']);
    } else {
      this.filteredStaffList = _.sortBy(staff, ['is_compliant', 'full_name']);
    }
  }
  staffWithDepartmentAndTraining(_departmentId: number, _trainingType?: number) {
    return this.staffList
      .filter((staff: Staff) => {
        staff.has_training = this.hasTraining(staff.training_types, _trainingType);
        return staff.department_id == +_departmentId &&
          !_.isNil(_trainingType) &&
          (_trainingType <= 0 || staff.has_training)
          ? staff
          : null;
      })
      .map((staff: Staff) => {
        staff.is_compliant =
          !!staff.training_types &&
          staff.training_types.some(
            (training: TrainingType) => training.id == _trainingType && training.expiryInDays > 0
          );
        return staff;
      });
  }

  hasTraining(_training_types: Array<TrainingType>, trainingType: number) {
    return _.some(_training_types, { id: +trainingType });
  }

  staffWithValidTraining(staffList: Array<Staff>, trainingId: number, rangeFilter) {
    const validTrainigs = (staff) => {
      return staff.training_types.filter(
        (training: TrainingType) =>
          trainingId == training.id &&
          rangeFilter.compareValue &&
          training.expiryInDays > 0 &&
          training.expiryInDays < rangeFilter.compareValue
      );
    };
    return staffList.filter((staff: Staff) => validTrainigs(staff).length > 0);
  }

  filterCompliantStaff(staffList: Array<Staff>, trainingType: number, boolFilter: CompliantStaffFilter) {
    // console.log("filterCompliantStaff staffList", staffList);
    const compliantStaff = (_training_types: Array<TrainingType>) =>
      _training_types.some((training: TrainingType) => trainingType == training.id && training.expiryInDays > 0);
    const nonCompliantStaff = (_training_types: Array<TrainingType>) =>
      // not assigned to that training type before
      !_training_types.some((training: TrainingType) => trainingType == training.id) ||
      // or expired
      _training_types.some((training: TrainingType) => trainingType == training.id && training.expiryInDays <= 0);

    // all staff
    if (StaffSummaryFilters.all_staff.compareValue == boolFilter.compareValue) return staffList;
    if (StaffSummaryFilters.non_compliant.compareValue == boolFilter.compareValue)
      // non-compliant only
      return staffList.filter(
        (staff: Staff) =>
          !staff.training_types ||
          !staff.training_types.some((t) => t.id == trainingType) ||
          (nonCompliantStaff(staff.training_types) && !compliantStaff(staff.training_types))
      );
  }

  async openFilters(filterType: string) {
    this.filterType = filterType;
    const modal = await this.modalController.create({
      component: HealthSafetyStaffFilterModal,
      cssClass: 'staff-filter-modal',
      componentProps: {
        filtersSource: StaffSummaryFilters,
        selectedFilters: this.selectedFilters,
        filterType: filterType
      }
    });

    modal.onDidDismiss().then((res: any) => {
      if (res.data) {
        this.selectedFilters = res.data;
        this.filtersSelected();
      }
    });
    await modal.present();
  }

  initFilters(filterType: string) {
    this.filteredStaffList = [];
    this.selectedFiltersArray = [];
    this.selectedFilters =
      filterType == 'HealthAndSafety' ? _.cloneDeep(HealthAndSafetyFilters) : _.cloneDeep(StaffSummaryFilters);
  }

  async getStaff(): Promise<Staff[]> {
    const now = moment(new Date());
    const expireInDays = (training_type: TrainingType) => {
      const training = _.find(this.globalService._currentUser.training_types);
      training_type.expiry = training ? training.expiry : -1;

      try {
        training_type.expiryInDays = 0;
        if (training_type.valid_until) {
          if (training_type.valid_until == 'Does not expire') {
            training_type.expiryInDays = unlimited;
          } else {
            const valid_until = moment(training_type.valid_until);
            training_type.expiryInDays = valid_until.diff(now, 'days') + 1;
          }
        }
      } catch (e) {
        this.logRocketProvider.captureException(e, 'error');
      }
    };
    const response = await this.hnsOfflineDataUtility.getStaff();
    response?.forEach((s) => {
      s.full_name = s.first_name + ' ' + s.last_name;
      s.training_types?.forEach((training: TrainingType) => expireInDays(training));
    });
    this.staffList = response;
    return response;
  }

  getDepartments(): Promise<any> {
    return this.hnsOfflineDataUtility.getDepartments().then((response) => {
      this.departments = _.sortBy(response.departments || [], [
        (_department: Department) => (_department.name || '').toLowerCase()
      ]);
      // this.departments = response.departments || [];
      return this.departments;
    });
  }

  async submitSafetyMeeting(meeting: SafetyMeeting, idx: number = null) {
    return await this.hnsOfflineDataUtility.submitSafetyMeeting(meeting, idx);
  }
  async submitFormalTraining(training: FormalTraining, idx: number = null) {
    return await this.hnsOfflineDataUtility.submitFormalTraining(training, idx);
  }

  async signaturePad(signature: string) {
    const modal = await this.modalController.create({
      component: SignaturePadModal,
      cssClass: 'signature-pad-modal',
      componentProps: { signature: signature }
    });

    setTimeout(async () => {
      await modal.present();
    });
    return modal.onDidDismiss().then((res: any) => {
      return res.data ? res.data.signature : signature;
    });
  }

  async getPendingFormalTrainigByIndex(index: number) {
    const data = await this.hnsOfflineDataUtility.getPendingFormalTrainings();
    return _.cloneDeep(data.length > index ? data[index] : null);
  }
  async getPendingSafetyMeetingByIndex(index: number) {
    const data = await this.hnsOfflineDataUtility.getPendingSafetyMeetings();
    return _.cloneDeep(data.length > index ? data[index] : null);
  }
}
