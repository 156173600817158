import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { from, of } from 'rxjs';
import { map, take, tap, catchError } from 'rxjs/operators';
import { User } from '@ea-models';
import { RECENT_USERS_KEY } from './services.constants';
import { PendingIncidentReport } from '@ea-models-v4';

type ReportsListName = 'pendingIncidentReports' | 'pendingReports';

@Injectable({
  providedIn: 'root'
})
export class LocalIncidentReportService {
  private incidentReportKey = 'ir_';
  private org: string;
  private username: string;

  constructor(private storage: StorageService) {}

  public setUpKey(org, username) {
    this.incidentReportKey = `ir_${org}_${username}`;
    [this.org, this.username] = [org, username];
  }

  public quickSave(data: PendingIncidentReport) {
    return from(this.storage.setObject(this.incidentReportKey, data));
  }

  public async quickRestore(): Promise<PendingIncidentReport> {
    try {
      const data: PendingIncidentReport = await this.storage.getObject(this.incidentReportKey);
      return data;
    } catch (error) {
      return null;
    }
  }

  clearTemporaryReport(): void {
    try {
      this.storage.clearObject(this.incidentReportKey);
    } catch (err) {}
  }

  public remove(reportIndex: number) {
    return this.saveOrRemoveReport(null, reportIndex, 'remove');
  }

  public save(data, reportIndex = null) {
    return this.saveOrRemoveReport(data, reportIndex, 'save');
  }

  public removeReport(reportIndex) {
    return this.saveOrRemoveReport(null, reportIndex, 'remove', 'pendingReports');
  }

  public removeReportByReportNumber(reportNumber, listName: ReportsListName = 'pendingIncidentReports') {
    let savedUsers = [];
    const opMapping = {
      remove: (user) => {
        if (reportNumber !== null && user[listName].length > 0) {
          const _reportIndex = user[listName].findIndex((r) => r.mainSection.report_number !== reportNumber);
          if (_reportIndex >= 0) {
            user[listName].splice(_reportIndex, 1);
          }
        }
      }
    };
    return from(this.storage.getObject(RECENT_USERS_KEY)).pipe(
      take(1),
      tap((users) => (savedUsers = users)),
      map((users) => users.find((obj) => obj.org === this.org && obj.username === this.username)),
      tap((user: User) => {
        opMapping.remove(user);
        this.saveUsers(savedUsers);
      })
    );
  }

  public saveReport(data, reportIndex: number, number = null) {
    return this.saveOrRemoveReport(data, reportIndex, 'save', 'pendingReports');
  }

  private saveOrRemoveReport(
    data,
    reportIndex: number = null,
    op: 'save' | 'remove',
    listName: ReportsListName = 'pendingIncidentReports'
  ) {
    let savedUsers = [];
    const opMapping = {
      save: (user) => {
        if (reportIndex !== null) user[listName][reportIndex] = data;
        else user[listName].push(data);
      },
      remove: (user) => {
        if (reportIndex !== null && user[listName].length > 0) user[listName].splice(reportIndex, 1);
      }
    };
    return from(this.storage.getObject(RECENT_USERS_KEY)).pipe(
      take(1),
      tap((users) => (savedUsers = users)),
      map((users) => users.find((obj) => obj.org === this.org && obj.username === this.username)),
      tap((user: User) => {
        opMapping[op](user);
        this.saveUsers(savedUsers);
      })
    );
  }

  private saveUsers(users) {
    from(this.storage.setObject(RECENT_USERS_KEY, users)).pipe(take(1)).subscribe();
  }
}
