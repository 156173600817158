import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { GlobalService, SpinnerService, PopUpService, ReportService, AttachmentsService } from '@ea-services';
import { ModalController, AlertController } from '@ionic/angular';
import { tap, take, catchError, concatMap, delay } from 'rxjs/operators';
import { of, from } from 'rxjs';
import _ from 'lodash';
import { IAttachment, UploadAttachedPhoto } from '@ea-models';
import {
  priorities,
  CREATE_TASK_ACTIONS,
  ACTION_COMPLETE_REPORT_ID,
  ACTION_INSPECT_FEATURE_ID
} from '../../../constants/tasks.constants';
import { LinkReportComponent } from '../../link-report/link-report.component';
import { LogRocketProvider } from '@ea-services/provider/logrocket';
import { TasksService } from '@ea-services/api/tasks.service';
import { TasksProviderService } from '@ea-services/provider/tasks-provider.service';
import moment from 'moment';

@Component({
  selector: 'modal-create-task',
  templateUrl: './create-task.component.html',
  styleUrls: ['./create-task.component.scss']
})
export class CreateTaskModalComponent implements OnInit {
  @Input() params: { vehicle_id: number; groomer_shift_id: number };
  @Input() departments = [];
  @ViewChild('scrollerCreateTask') scrollerCreateTask;

  types = [];
  areas = [];
  features = [];
  priorities = [];
  actions = [];
  reports = [];
  currentUser: any;
  readonly minDate = moment().startOf('day').toDate();
  model: {
    name: string;
    action_id: number;
    department_id?: number;
    area_id?: number;
    action: string;
    feature_id?: number;
    task_type_id: number;
    priority_id?: number;
    priority?: string;
    description: string;
    due_date?: string;
    created?: string;
    report_name?: string;
    report_id?: number;
    accident_report_number?: number;
    accident_report_id?: number;
    status: string;
  };
  attachments: IAttachment[] = [];
  linkedIncident: any;
  allowInspectFeature: boolean;
  readonly ACTION_COMPLETE_REPORT_ID = ACTION_COMPLETE_REPORT_ID;
  readonly ACTION_INSPECT_FEATURE_ID = ACTION_INSPECT_FEATURE_ID;
  isOpenTimePanel: boolean;
  contentScrollTop: any;

  get allowSave() {
    return (
      !!this.model.name &&
      !!this.model.name.trim() &&
      this.model.action_id &&
      (this.model.action_id != ACTION_COMPLETE_REPORT_ID || !!this.model.report_id)
    );
  }

  constructor(
    public globalService: GlobalService,
    private reportService: ReportService,
    private tasksService: TasksService,
    private tasksProService: TasksProviderService,
    private attachmentsService: AttachmentsService,
    private spinnerService: SpinnerService,
    private popUpService: PopUpService,
    private alertController: AlertController,
    public modalController: ModalController,
    private logRocketProvider: LogRocketProvider
  ) {}

  ngOnInit() {
    if (this.globalService._currentUser) {
      this.currentUser = this.globalService._currentUser;
      this.initData();
    }
  }

  ionViewDidEnter() {
    this.getFeatures();
  }

  onChangeAction(item: any, name: string, list: any) {
    if (item.id !== ACTION_COMPLETE_REPORT_ID) {
      this.model.report_name = null;
      this.model.report_id = null;
    }
    this.onChange(item, name, list, true);
  }

  onChange(item: any, name: string, list: any, notEmpty?: boolean) {
    if (notEmpty && item.selected) {
      return;
    }
    of(null)
      .pipe(
        delay(100),
        take(1),
        tap(() => {
          const _selected = item.selected;
          list.forEach((element) => {
            element.selected = false;
          });
          item.selected = !_selected;
          if (item.selected) {
            this.model[name] = item.id;
          }
        })
      )
      .subscribe();
  }

  onImageChange(imagesData: { currentImage: IAttachment; images: IAttachment[] }) {
    this.attachments = imagesData.images;
  }

  close(data?: any) {
    this.modalController.dismiss(data);
  }

  save() {
    if (!this.allowSave) return;
    this.spinnerService.show('Create Task in progress...');
    this.model.accident_report_id = this.linkedIncident?.id;
    this.model.accident_report_number = this.linkedIncident?.report_number;
    this.model.report_name = this.reports.find((r) => r.id === this.model.report_id)?.name;
    this.model.action = this.actions.find((r) => r.id === this.model.action_id).name;
    this.model.priority = this.priorities.find((r) => r.id === this.model.priority_id)?.name;
    this.createTask();
  }

  removeLinkToIncident() {
    this.linkedIncident = null;
  }

  async presentLinkIncidentModal() {
    const modal = await this.modalController.create({
      component: LinkReportComponent,
      cssClass: 'link-incident-modal',
      componentProps: { token: this.currentUser.token },
      backdropDismiss: true
    });
    from(modal.onDidDismiss())
      .pipe(
        take(1),
        tap((data) => {
          if (data.data && data.data.success) {
            this.linkedIncident = { ...data.data.linkedIncident };
          }
        })
      )
      .subscribe();
    return await modal.present();
  }

  focusOnInputTime() {
    this.isOpenTimePanel = true;
  }

  scrollEnd($event) {
    const top = $event.currentTarget.scrollTop;
    if (this.isOpenTimePanel && top <= 0) {
      $event.currentTarget.scrollTop = this.contentScrollTop;
      this.isOpenTimePanel = false;
      return;
    }
    this.isOpenTimePanel = false;
    this.contentScrollTop = $event.currentTarget.scrollTop;
  }

  private initData() {
    this.model = {
      name: null,
      department_id: null,
      task_type_id: null,
      area_id: null,
      description: null,
      action_id: null,
      action: null,
      feature_id: null,
      due_date: null,
      created: null,
      priority: null,
      priority_id: null,
      report_id: null,
      report_name: null,
      accident_report_id: null,
      accident_report_number: null,
      status: 'outstanding'
    };
    this.attachments = [];
    this.areas = _.cloneDeep(this.tasksService.areasData);
    this.types = _.cloneDeep(this.tasksService.typesData);
    this.priorities = _.cloneDeep(priorities);
    this.actions = _.cloneDeep(CREATE_TASK_ACTIONS);
    this.reports = _.cloneDeep(this.reportService.reports);
    //---TODO: this is temp config to support internal accounts, remove when it is ready
    this.allowInspectFeature = false; //this.currentUser.permissions.features > 1

    if (!this.allowInspectFeature) {
      this.actions = this.actions.filter((a) => a.id !== ACTION_INSPECT_FEATURE_ID);
    }
  }

  private getFeatures() {
    this.tasksService
      .getFeatures(this.currentUser.token)
      .pipe(
        take(1),
        tap((res) => {
          const types = {
            building: true,
            lift: true,
            park: true,
            trail: true,
            sign: true,
            skirun: true
          };
          this.features = _.cloneDeep(res)
            .filter((f) => types[(f.type || '').toLowerCase()])
            .sort((a, b) => {
              return a.name.localeCompare(b.name);
            });
        })
      )
      .subscribe();
  }

  private createTask() {
    let totalAttachments = 0;
    let _result = null;
    const uploadAttachedPhotos = (data, result) => {
      if (!data || !data.length || !result) return of(null);
      const _data = _.cloneDeep(data).reverse();
      return from(_data).pipe(
        concatMap((attachment: any) => this.uploadAttachedPhotos({ ...attachment, id: result.data.id })),
        concatMap(() => {
          totalAttachments--;
          return of(null);
        }),
        catchError((e) => {
          totalAttachments--;
          this.logRocketProvider.captureException(e, 'upload photo failed', 'create task');
          return of(null);
        })
      );
    };
    const submission = (data) => {
      const _submitData = _.cloneDeep(data);
      _submitData.due_date = moment(_submitData.due_date).format();
      return this.tasksService.createTask(_submitData, this.currentUser.token).pipe(
        concatMap((result: any) => {
          if (!result || !result.success) {
            this.popUpService.popupError('Create Task failed.', this.alertController);
            this.logRocketProvider.captureMessage('Create Task failed', 'Create Task failed', 'create task');
            totalAttachments = 1;
            this.spinnerService.hide();
            return of(null);
          }
          _result = result;
          if (_result.data.accident_report_id === _submitData.accident_report_id) {
            _result.data.accident_report_number = _submitData.accident_report_number;
          }
          totalAttachments = this.attachments.length;
          return of(result);
        }),
        concatMap((res) => uploadAttachedPhotos(this.attachments, res))
      );
    };
    from([this.model])
      .pipe(
        concatMap((data) => submission(data)),
        catchError((e) => {
          this.spinnerService.hide();
          this.popUpService.popupError('Create Task failed.', this.alertController);
          totalAttachments = 1;
          this.logRocketProvider.captureException(e, 'error', 'create task');
          return of(null);
        })
      )
      .subscribe(() => {
        if (totalAttachments <= 0 && _result) {
          this.spinnerService.hide();
          this.close(_result.data);
        }
      });
  }

  private uploadAttachedPhotos(data) {
    const uploadData: UploadAttachedPhoto = {
      feature_id: data.id,
      object_attachment: data.object_attachment,
      feature_type: 'Task'
    };
    return this.attachmentsService.uploadAttachment(this.currentUser.token, uploadData);
  }
}
