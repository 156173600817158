import {
  INCIDENT_TICKET_TYPE,
  INCIDENT_TICKET_TYPE_1,
  INCIDENT_TICKET_TYPE_CWSAA,
  TICKET_TYPE_CUSTOM_ORGS
} from '@ea-pages/pages.constants';
import { ISelectOption } from './types';
import * as _ from 'lodash';

const yes_no: ISelectOption[] = [{ name: 'Yes' }, { name: 'No' }];

export const gender: ISelectOption[] = [{ name: 'Male' }, { name: 'Female' }];
// export const gender: ISelectOption[] = [{name: 'Male'}, {name: 'Female'}];

export const patient_type = [
  { name: 'Guest' },
  { name: 'Member' },
  { name: 'Staff-Off' },
  { name: 'Staff-Work' },
  { name: 'Unknown' }
];

export const ticket_type: (org: string) => ISelectOption[] = (org) =>
  (TICKET_TYPE_CUSTOM_ORGS.includes(org) ? INCIDENT_TICKET_TYPE_1 : INCIDENT_TICKET_TYPE).map((name) => ({ name }));

export const cwsaa_ticket_type: ISelectOption[] = INCIDENT_TICKET_TYPE_CWSAA.map((name) => ({ name }));

export const collision_party_equipment: ISelectOption[] = [
  { name: 'Alpine Skiing' },
  { name: 'Snowboard' },
  { name: 'Telemark' },
  { name: 'Other' }
];

export const ability: ISelectOption[] = [
  { name: 'Beginner' },
  { name: 'Intermediate' },
  { name: 'Advanced/Expert' },
  { name: 'Unknown' },
  { name: 'N/A' }
];

export const cwsaa_ability: ISelectOption[] = [
  { name: 'Beginner' },
  { name: 'Intermediate' },
  { name: 'Advanced' },
  { name: 'Expert' },
  { name: 'Unknown' },
  { name: 'N/A' }
];

export const equipment_removed_by: ISelectOption[] = [
  { name: 'Fall' },
  { name: 'Fall/Patrol' },
  { name: 'Fall/Injured Party' },
  { name: 'Injured' },
  { name: 'Patrol' },
  { name: 'Bystander' },
  { name: 'Other' },
  { name: 'N/A' }
];

export const copper_lesson: ISelectOption[] = [{ name: 'Woodward' }, { name: 'Ski and Ride' }];

export const copper_activity: ISelectOption[] = [
  { name: 'Ski' },
  { name: 'Snowboard' },
  { name: 'Telemark' },
  { name: 'Bumper Boats' },
  { name: 'Bungee Trampoline' },
  { name: 'Biking' },
  { name: 'Cheer' },
  { name: 'Climbing Wall' },
  { name: 'Go Karts' },
  { name: 'Gymnastics' },
  { name: 'Hiking' },
  { name: 'Parkour' },
  { name: 'Ropes Course' },
  { name: 'Scooter' },
  { name: 'Skateboard' },
  { name: 'Swimming' },
  { name: 'Wake Board' },
  { name: 'Zip Line' },
  { name: 'Other' }
];

export const copper_equipment: ISelectOption[] = [
  { name: 'Owned' },
  { name: 'Copper Rented' },
  { name: 'Other Rented' },
  { name: 'Borrowed' },
  { name: 'Unknown/NA' }
];

export const copper_helmet: ISelectOption[] = [...yes_no];

export const copper_binding_release: ISelectOption[] = [
  { name: 'None' },
  { name: 'Left only' },
  { name: 'Right only' },
  { name: 'Both' }
];

export const signature_type: ISelectOption[] = [
  { name: 'Patient' },
  { name: 'Parent' },
  { name: 'Guardian' },
  { name: 'Spouse' },
  { name: 'Signed Paper Form' }
];

export const weather: ISelectOption[] = [
  { name: 'Clear' },
  { name: 'Overcast' },
  { name: 'Snowing' },
  { name: 'Raining' },
  { name: 'Fog' },
  { name: 'Snow Being Made at Scene' },
  { name: 'Unknown' },
  { name: 'N/A' }
];

export const nsaa_temperature: ISelectOption[] = [
  { name: 'Below 0' },
  { name: '0 to 32' },
  { name: '32 to 50' },
  { name: '50 to 60' },
  { name: '60 to 80' },
  { name: 'Above 80' },
  { name: 'Unknown/Walk In' },
  { name: 'N/A' }
];

export const wind: ISelectOption[] = [
  { name: 'Light' },
  { name: 'Moderate' },
  { name: 'High' },
  { name: 'Unknown/Walk In' },
  { name: 'N/A' }
];

export const surface: ISelectOption[] = [
  { name: 'Packed Powder' },
  { name: 'Packed' },
  { name: 'Groomed' },
  { name: 'Moguls' },
  { name: 'Powder' },
  { name: 'Variable' },
  { name: 'Granular' },
  { name: 'Hard' },
  { name: 'Unknown' },
  { name: 'Padded' },
  { name: 'Other' },
  { name: 'Unknown/Walk In' },
  { name: 'Concrete' },
  { name: 'Dirt/Gravel - Wet' },
  { name: 'Dirt/Gravel - Dry' },
  { name: 'Grass' },
  { name: 'Sand' },
  { name: 'Rock' },
  { name: 'N/A' }
];

export const accident_category: ISelectOption[] = [
  { name: 'Collision' },
  { name: 'Tubing' },
  { name: 'Lift' },
  { name: 'Terrain Park' }
];

export const lift_related_to_other_riders: ISelectOption[] = [{ name: 'Yes' }, { name: 'No' }];

export const lift_ask_operator_to_stop_lift: ISelectOption[] = [{ name: 'Yes' }, { name: 'No' }];

export const lift_did_you_notice_warning_information: ISelectOption[] = [...yes_no];

export const lift_did_you_ask_for_assistance: ISelectOption[] = [...yes_no];

export const lift_are_you_familiar_with_its_operation: ISelectOption[] = [...yes_no];

export const corrective_lenses_needed: ISelectOption[] = [...yes_no];

export const corrective_lenses_worn: ISelectOption[] = [...yes_no];

export const drugs_alcohol: ISelectOption[] = [...yes_no];

export const tubing_alone_or_group: ISelectOption[] = [{ name: 'Alone' }, { name: 'Group' }];

export const tubing_start_method: ISelectOption[] = [
  { name: 'Sitting Down' },
  { name: 'Running' },
  { name: 'Laying Down' },
  { name: 'Group Tied Together' }
];

export const tubing_experience_falls_today: ISelectOption[] = [
  { name: 'First' },
  { name: '2-9' },
  { name: '10 Or More' }
];

export const tubing_experience_runs_today: ISelectOption[] = [...tubing_experience_falls_today];

export const tubing_experience_previous: ISelectOption[] = [
  { name: 'Once' },
  { name: '1-5 Times' },
  { name: '5+ Times' }
];

export const terrain_park_did_you_enter_through_entrance_gate: ISelectOption[] = [...yes_no];

export const terrain_park_did_you_notice_warning_information_signs: ISelectOption[] = [...yes_no];

export const terrain_park_previous_attempts: ISelectOption[] = [...yes_no];

export const terrain_park_scope_feature: ISelectOption[] = [...yes_no];

export const terrain_park_do_you_believe_this_was_from_your_action: ISelectOption[] = [...yes_no];

export const incident_part = [
  { name: 'Ankle' },
  { name: 'Calf' },
  { name: 'Chest' },
  { name: 'Clavicle' },
  { name: 'Elbow' },
  { name: 'Eyes' },
  { name: 'Face' },
  { name: 'Finger' },
  { name: 'Foot' },
  { name: 'Hand' },
  { name: 'Head' },
  { name: 'Hip' },
  { name: 'Knee' },
  { name: 'Lower Ab' },
  { name: 'Lower Arm' },
  { name: 'Lower Back' },
  { name: 'Lower Leg' },
  { name: 'Medical' },
  { name: 'Neck' },
  { name: 'Nose' },
  { name: 'No Injury' },
  { name: 'N/A' },
  { name: 'Other' },
  { name: 'Pelvis' },
  { name: 'Rib' },
  { name: 'Shoulder' },
  { name: 'Tailbone' },
  { name: 'Thigh' },
  { name: 'Thumb' },
  { name: 'Upper Ab' },
  { name: 'Upper Arm' },
  { name: 'Upper Back' },
  { name: 'Upper Leg' },
  { name: 'Wrist' }
];

export const incident_side: ISelectOption[] = [{ name: 'Left' }, { name: 'Right' }, { name: 'Both' }, { name: 'N/A' }];

export const nsaa_incident_side: ISelectOption[] = [
  { name: 'Left' },
  { name: 'Right' },
  { name: 'Both' },
  { name: 'Center' },
  { name: 'N/A' }
];

export const cwsaa_incident_side: ISelectOption[] = nsaa_incident_side;

export const incident_treatment_protocol: ISelectOption[] = [
  { name: 'Abrasion' },
  { name: 'Avulsion' },
  { name: 'Backboard/C-Spine' },
  { name: 'Bruise' },
  { name: 'Burn' },
  { name: 'Cardiac' },
  { name: 'Concussion' },
  { name: 'Contusion' },
  { name: 'Deceased' },
  { name: 'Deformity' },
  { name: 'Dislocation' },
  { name: 'Fracture' },
  { name: 'Frostbite' },
  { name: 'Hypothermia' },
  { name: 'Illness' },
  { name: 'Insect Bite/Sting' },
  { name: 'Internal' },
  { name: 'Laceration' },
  { name: 'Medical' },
  { name: 'N/A' },
  { name: 'Other' },
  { name: 'Puncture' },
  { name: 'Sprain' },
  { name: 'Strain' },
  { name: 'Stroke' },
  { name: 'Swelling' },
  { name: 'Separation' },
  { name: 'Unknown' }
];

export const transport_to_base: ISelectOption[] = [
  { name: 'Walk/Ski/Ride' },
  { name: 'Toboggan' },
  { name: 'Snowmobile' },
  { name: 'Motor Vehicle' },
  { name: 'Helicopter' },
  { name: 'Download' },
  { name: 'On-hill' },
  { name: 'Other' },
  { name: 'Unknown' },
  { name: 'N/A' },
  { name: 'ETV' }
];

export const cwsaa_transport_to_base: ISelectOption[] = [
  { name: 'Walk/Ski/Ride' },
  { name: 'Toboggan' },
  { name: 'Snowmobile' },
  { name: 'Motor Vehicle' },
  { name: 'Helicopter' },
  { name: 'Download' },
  { name: 'On-hill' },
  { name: 'ETV' },
  { name: 'Walk/Bike' },
  { name: 'Other' },
  { name: 'Unknown' },
  { name: 'N/A' }
];

export const nsaa_transport_to_base: ISelectOption[] = [...transport_to_base, { name: 'Patrol Vehicle' }];

export const transport_to_aid: ISelectOption[] = [
  { name: 'Private Car' },
  { name: 'Taxi' },
  { name: 'Company' },
  { name: 'Ambulance' },
  { name: 'Bus' },
  { name: 'Helicopter' },
  { name: 'Walk/Ski/Ride' },
  { name: 'Other' },
  { name: 'Unknown' },
  { name: 'N/A' }
];

export const nsaa_transport_to_aid: ISelectOption[] = [...transport_to_aid, { name: 'Patrol Vehicle' }];

export const cwsaa_transport_to_aid: ISelectOption[] = [
  { name: 'Private Car' },
  { name: 'Taxi' },
  { name: 'Company' },
  { name: 'Ambulance' },
  { name: 'Bus' },
  { name: 'Helicopter' },
  { name: 'Walk/Bike' },
  { name: 'Walk/Ski/Ride' },
  { name: 'Other' },
  { name: 'Unknown' },
  { name: 'N/A' }
];

//nsaa_collision_party_relationship
export const nsaa_cpr: ISelectOption[] = [
  { name: 'No Relation' },
  { name: 'Unknown' },
  { name: 'Family' },
  { name: 'Friend' }
];
//nsaa_collision_party_relationship
export const nsaa_mammoth_cpr: ISelectOption[] = nsaa_cpr;
//nsaa_collision_party_relationship
export const nsaa_june_cpr: ISelectOption[] = nsaa_cpr;

export const destination: ISelectOption[] = [
  { name: 'Home' },
  { name: 'Doctor' },
  { name: 'Hospital' },
  { name: 'Clinic' },
  { name: 'Hotel' },
  { name: 'Return to Activity' },
  { name: 'Other' },
  { name: 'Unknown' },
  { name: 'N/A' }
];

export const activity: ISelectOption[] = [
  { name: 'Alpine Skiing' },
  { name: 'Snowboard' },
  { name: 'Telemark' },
  { name: 'Nordic' },
  { name: 'Touring' },
  { name: 'Tubing' },
  { name: 'Zip Line' },
  { name: 'Hiking' },
  { name: 'Biking' },
  { name: 'Euro-Bungee' },
  { name: 'Climbing' },
  { name: 'Golfing' },
  { name: 'Other' }
];

export const activity_v2: ISelectOption[] = [
  { name: 'Alpine Skiing' },
  { name: 'Snowboard' },
  { name: 'Telemark' },
  { name: 'Nordic' },
  { name: 'Touring' },
  { name: 'Tubing' },
  { name: 'Euro-Bungee' },
  { name: 'Climbing' },
  { name: 'Golfing' },
  { name: 'Bumper Boats' },
  { name: 'Bungee Trampoline' },
  { name: 'Biking' },
  { name: 'Cheer' },
  { name: 'Climbing Wall' },
  { name: 'Go Karts' },
  { name: 'Gymnastics' },
  { name: 'Hiking' },
  { name: 'Parkour' },
  { name: 'Ropes Course' },
  { name: 'Scooter' },
  { name: 'Skateboard' },
  { name: 'Swimming' },
  { name: 'Wake Board' },
  { name: 'Zip Line' },
  { name: 'Other' }
];

export const nsaa_default_activity: ISelectOption[] = activity;

const WWPA_AND_WWWEST_ACTIVITIES = [
  'Bumper Boats',
  'Bungee Trampoline',
  'Cheer',
  'Climbing Wall',
  'Go Karts',
  'Gymnastics',
  'Parkour',
  'Ropes Course',
  'Skating',
  'Scooter',
  'Skateboard',
  'Swimming',
  'Wake Board'
];

const wwpaAndWwwestActivities = _.concat(
  nsaa_default_activity,
  WWPA_AND_WWWEST_ACTIVITIES.map((name) => ({ name }))
);

export const nsaa_wwpa_activity: ISelectOption[] = wwpaAndWwwestActivities;
export const nsaa_wwwest_activity: ISelectOption[] = wwpaAndWwwestActivities;

export const nsaa_snowbird_activity: ISelectOption[] = activity_v2;
export const nsaa_soda_activity: ISelectOption[] = activity_v2;
export const nsaa_bach_activity: ISelectOption[] = activity_v2;
export const nsaa_killington_activity: ISelectOption[] = activity_v2;
export const nsaa_wwpc_activity: ISelectOption[] = activity_v2.map((i) =>
  // * EA-705
  i.name === 'Gymnastics'
    ? {
        ...i,
        name: 'Acrobatics'
      }
    : i
);
export const nsaa_boreal_activity: ISelectOption[] = [
  { name: 'Alpine Skiing' },
  { name: 'Snowboard' },
  { name: 'Telemark' },
  { name: 'Nordic' },
  { name: 'Touring' },
  { name: 'Tubing' },
  { name: 'Euro-Bungee' },
  { name: 'Climbing' },
  { name: 'Golfing' },

  { name: 'Bumper Boats' },
  { name: 'Bungee Trampoline' },
  { name: 'Biking' },
  { name: 'Cheer' },
  { name: 'Climbing Wall' },
  { name: 'Go Karts' },
  { name: 'Gymnastics' },
  { name: 'Hiking' },
  { name: 'Parkour' },
  { name: 'Ropes Course' },
  { name: 'Scooter' },
  { name: 'Skateboard' },
  { name: 'Swimming' },
  { name: 'Wake Board' },
  { name: 'Zip Line' },
  { name: 'Other' }
];

export enum EquipmentOption {
  Owned = 'Owned',
  None = 'None',
  ResortRental = 'Resort Rental',
  NonResortRental = 'Non-Resort Rental',
  ResortDemos = 'Resort Demos',
  NonResortDemos = 'Non-Resort Demos',
  Borrowed = 'Borrowed',
  Unknown = 'Unknown',
  NA = 'N/A'
}

export const equipment: ISelectOption[] = [
  { name: EquipmentOption.Owned },
  { name: EquipmentOption.None },
  { name: EquipmentOption.ResortRental },
  { name: EquipmentOption.NonResortRental },
  { name: EquipmentOption.ResortDemos },
  { name: EquipmentOption.NonResortDemos },
  { name: EquipmentOption.Borrowed },
  { name: EquipmentOption.Unknown },
  { name: EquipmentOption.NA }
];

export const helmet: ISelectOption[] = [
  { name: EquipmentOption.None },
  { name: EquipmentOption.Owned },
  { name: EquipmentOption.ResortRental },
  { name: EquipmentOption.NonResortRental },
  { name: EquipmentOption.ResortDemos },
  { name: EquipmentOption.NonResortDemos },
  { name: EquipmentOption.Borrowed },
  { name: EquipmentOption.Unknown },
  { name: EquipmentOption.NA }
];

export const involvement: ISelectOption[] = [
  { name: 'Recreation' },
  { name: 'Recreation jumping' },
  { name: 'Competition' },
  { name: 'Training' },
  { name: 'Lesson - Snowboard' },
  { name: 'Lesson - Ski' },
  { name: 'Tube slide' },
  { name: 'Terrain Park Feature' },
  { name: 'Unknown' },
  { name: 'Other' }
];

export const light: ISelectOption[] = [
  { name: 'Sharp' },
  { name: 'Flat' },
  { name: 'Whiteout' },
  { name: 'Lights' },
  { name: 'Overcast' },
  { name: 'Dark' },
  { name: 'Unknown/Walk In' },
  { name: 'N/A' }
];

export const temperature: ISelectOption[] = [
  { name: 'Over 30' },
  { name: '21 - 30' },
  { name: '10 - 20' },
  { name: '0 to 10' },
  { name: '-10 to 0' },
  { name: '-20 to -11' },
  { name: 'Below 20' },
  { name: 'Unknown/Walk In' },
  { name: 'N/A' }
];

export const snow: ISelectOption[] = [
  { name: 'No new' },
  { name: '0 to 5' },
  { name: '5 to 10' },
  { name: '10 to 15' },
  { name: 'Over 15' },
  { name: 'Unknown/Walk In' },
  { name: 'N/A' }
];

export const binding_release: ISelectOption[] = [
  { name: 'None' },
  { name: 'Left only' },
  { name: 'Right only' },
  { name: 'Both' },
  { name: 'Pre-released' },
  { name: 'Unknown' },
  { name: 'N/A' }
];

export const lesson: ISelectOption[] = [{ name: 'Never' }, { name: 'This year' }, { name: 'Other' }, { name: 'N/A' }];

export const copper_transport_to_aid: ISelectOption[] = [
  { name: 'EMV' },
  { name: 'ROC' },
  { name: 'UTV' },
  { name: 'Walk' },
  { name: 'Ambulance' },
  { name: 'Helicopter' }
];

export const copper_destination: ISelectOption[] = [
  { name: 'CMC' },
  { name: 'Flights' },
  { name: 'Ambulance' },
  { name: 'ROC' }
];

export const copper_weather: ISelectOption[] = [
  { name: 'Clear' },
  { name: 'Cloudy' },
  { name: 'Heavy Rain' },
  { name: 'Heavy Snow' },
  { name: 'Light Rain' },
  { name: 'Light Snow' },
  { name: 'Partly Cloudy' },
  { name: 'Thunderstorms' },
  { name: 'Unknown/Walk In' }
];

export const copper_surface: ISelectOption[] = [
  { name: 'Powder' },
  { name: 'Packed Powder' },
  { name: 'Powder/Packed Powder' },
  { name: 'Spring Conditions' },
  { name: 'Manmade' },
  { name: 'Hard Pack' },
  { name: 'Groomed' },
  { name: 'Unknown/Walk In' }
];

export const copper_light: ISelectOption[] = [
  { name: 'Fair' },
  { name: 'Good' },
  { name: 'Reduced Snow' },
  { name: 'Reduced Fog' },
  { name: 'Unknown/Walk In' },
  { name: 'Unlimited' }
];

export const primary_cause: ISelectOption[] = [
  { name: 'Fall - skier lost control', group: 'Non-collision' },
  { name: 'Fall - caught an edge', group: 'Non-collision' },
  { name: 'Fall & struck by own equipment', group: 'Non-collision' },
  { name: 'Fall - jumping', group: 'Non-collision' },
  { name: 'Fall - change of conditions', group: 'Non-collision' },
  { name: 'Fall - change of terrain', group: 'Non-collision' },
  {
    name: 'Fall - caused by natural element (trees, branches, etc.)',
    group: 'Non-collision'
  },
  { name: 'Near Collision', group: 'Non-collision' },
  { name: 'Prior medical condition', group: 'Non-collision' },
  { name: 'Skied off trail', group: 'Non-collision' },
  { name: 'Equipment failure (not binding)', group: 'Non-collision' },
  { name: 'Binding pre-released', group: 'Non-collision' },
  { name: "Hit by other's equipment", group: 'Non-collision' },

  { name: 'Clothing caught on lift', group: 'Lift related' },
  { name: 'Fall while loading', group: 'Lift related' },
  { name: 'Fall after unload', group: 'Lift related' },
  { name: 'Fall from lift', group: 'Lift related' },
  { name: 'Jump chair lift', group: 'Lift related' },
  { name: 'Injured by restraining bar', group: 'Lift related' },
  { name: 'Struck by chair', group: 'Lift related' },

  { name: 'Slip & fall (non-skiing)', group: 'Non-skiing related' },
  { name: 'Cold/weather related', group: 'Non-skiing related' },
  { name: 'Not otherwise classified', group: 'Non-skiing related' },
  { name: 'Other', group: 'Non-skiing related' }
];
