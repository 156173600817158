import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { ConfigService } from '../config.service';
import { SpecialEventFeatue, SpecialEvent } from '@ea-models';

@Injectable({
  providedIn: 'root'
})
export class SpecialEventsService {
  public allEvents: SpecialEvent[];
  constructor(private httpClient: HttpClient, public configService: ConfigService) {}

  public getEvents(token: string): Observable<SpecialEventFeatue[]> {
    return this.httpClient.get<any>(`${this.configService.apiURL()}special_events/get_events?token=${token}`).pipe(
      tap((result) => {
        this.allEvents = result ? result.events : [];
      }),
      catchError(() => {
        if (this.allEvents) {
          return of({ events: this.allEvents });
        }
        return of(null);
      })
    );
  }

  public getExistedFeatures(eventId: number, token: string): Observable<any> {
    return this.httpClient.get<any>(
      `${this.configService.apiURL()}special_events/get_existing_features?id=${eventId}&token=${token}`
    );
  }

  public addFeature(token: string, data: SpecialEventFeatue): Observable<any> {
    return this.httpClient.post<any>(`${this.configService.apiURL()}special_events/add_feature?token=${token}`, data);
  }

  public updateFeature(token: string, data: SpecialEventFeatue): Observable<any> {
    return this.httpClient.post<any>(
      `${this.configService.apiURL()}special_events/update_feature?token=${token}`,
      data
    );
  }
}
