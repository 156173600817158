import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import * as _ from 'lodash';

import { User } from '@ea-models';
import { take, tap } from 'rxjs/operators';
import { GlobalService } from '@ea-services';
import { IncidentReportProviderService } from '@ea-services/provider/incident-report-provider.service';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { AppState } from '@edgeauditor/app/store/app/app.state';
import { INCIDENT_REPORT_MAT_TAB_GROUP } from '@ea-constants';

type SelectType = _.Dictionary<{ name: string }[]>;

@Component({
  selector: 'app-complaint',
  templateUrl: './complaint.component.html',
  styleUrls: ['./complaint.component.scss']
})
export class ComplaintComponent implements OnInit, OnChanges {
  @Output() changed = new EventEmitter<Map<string, string>>();
  @Input() userEvent: Observable<User>;
  @Input() modelData: { [key: string]: any };
  @Input() label: string;
  @Input() labels: { part: string; probable: string };
  @Input()
  set selects(val: SelectType) {
    this._selects = val;
    if (this.selects && this.currentUser) {
      this.incidentSide = (this.selects[`${this.currentUser.accident_template}_incident_side`] ||
        this.selects.incident_side) as any;

      this.incidentSideItems = Object.values(this.incidentSide);
    }
  }
  get selects() {
    return this._selects;
  }
  @Input() requiredObjects: { [key: string]: boolean };

  @ViewSelectSnapshot(AppState.getIsMobilePlatform) isMobilePlatform: boolean;

  private _selects: SelectType = {};
  readonly incidentReportMatTabGroup = INCIDENT_REPORT_MAT_TAB_GROUP;
  currentUser: User;
  isCopper: boolean;
  incidentSide: _.Dictionary<string>;
  incidentSideItems: string[];
  model: {
    injuries: {
      id?: number;
      part?: string;
      side?: string;
      treatment_protocol?: string;
      is_primary?: boolean;
      other_injury?: string;
      other_treatment?: string;
      _destroy?: boolean;
    }[];
  } = {
    injuries: [{}]
  };

  constructor(
    public globalService: GlobalService,
    public incidentProvider: IncidentReportProviderService,
    public refEle: ElementRef
  ) {}

  ngOnInit() {
    this.userEvent
      .pipe(
        take(1),
        tap((user) => {
          this.currentUser = user;
          this.isCopper = user.accident_template === 'copper';
          if (this.selects && this.currentUser) {
            this.incidentSide = (this.selects[`${this.currentUser.accident_template}_incident_side`] ||
              this.selects.incident_side) as any;
            this.incidentSideItems = Object.values(this.incidentSide);
          }
        })
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      'modelData' in changes &&
      changes.modelData.currentValue &&
      changes.modelData.currentValue.injuries &&
      changes.modelData.currentValue.injuries.length > 0
    ) {
      this.model.injuries = [...changes.modelData.currentValue.injuries];
    }
  }

  addInjury() {
    this.model.injuries.push({});
  }

  removeInjury($event, index) {
    if (this.model.injuries[index].id) {
      this.model.injuries[index]._destroy = true;
    } else {
      this.model.injuries.splice(index, 1);
    }
    this.onChange(JSON.stringify(this.model.injuries), 'injuries');
  }

  onChange(newVal: string, fieldName: string) {
    const newData = new Map<string, string>();
    newData.set(fieldName, newVal);
    this.changed.emit(newData);
  }

  rowChange(index: number) {
    const inj = this.model.injuries[index];
    this.adjustCheckboxes(index);
    this.onChange(JSON.stringify(this.model.injuries) || null, 'injuries');
  }

  adjustCheckboxes(index: number) {
    if (this.model.injuries[index].is_primary) {
      this.model.injuries.forEach((inj, i) => {
        if (i !== index) {
          inj.is_primary = false;
        }
      });
    }
  }
}
