import { LoadingController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalService } from '@ea-services/global.service';
import { ApiService } from '@ea-services/api.service';
import { User, UserCredentials, UserLoginError } from '@ea-models';
import { ConfigService } from '@ea-services/config.service';
import { HttpClient } from '@angular/common/http';
import { Credentials, LoginSuccessResponse } from '@edgeauditor/app/models/auth';

export const AUTH_SESSION = 'auth_session';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  constructor(
    public globalService: GlobalService,
    public apiService: ApiService,
    public loadingController: LoadingController,
    public configService: ConfigService,
    private http: HttpClient
  ) {}

  public login(data: UserCredentials): Observable<User | Error> {
    return this.http.post(this.configService.getBaseUrl(1) + 'sessions.json', data).pipe(
      map((response: User | UserLoginError) => {
        if (response && response.success) {
          return response;
        } else {
          throw new Error('Invalid credentials');
        }
      })
    );
  }

  // v4 API Login - Temporarily just using the JWT attribute on it
  public loginV4(data: Credentials): Observable<string> {
    return this.http
      .post(this.configService.getBaseUrl(4) + 'auth', data)
      .pipe(map((response: LoginSuccessResponse) => response.jwt));
  }

  public resetPassword(data): Observable<any> {
    return this.apiService.post(this.configService.apiURL() + 'change_password', data, '', true);
  }

  refreshToken(oldToken?: string): Observable<string> {
    const token = oldToken || this.globalService._currentUser.token;
    const location_id = this.globalService._currentUser.location.id;

    return this.http
      .get<{ status: boolean; token: string }>(this.configService.apiURL() + 'refresh_token', {
        params: { token, location_id }
      })
      .pipe(map((res) => res.token));
  }
}
