import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { CachedImageComponent } from './cached-image.component';

@NgModule({
  declarations: [CachedImageComponent],
  imports: [CommonModule, IonicModule, RouterModule, FormsModule],
  exports: [CachedImageComponent]
})
export class CachedImageModule {}
