import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { DeviceInfo } from '@capacitor/device';
import { NetworkStatus } from '@capacitor/network';
import _ from 'lodash';

import { PendingAndRejectedTotals } from '@ea-models-v4/location';
import { IncidentReportService, LocationService } from '@ea-services-v4';
import { PendingLocalReportsService } from '@ea-services/pending-local-reports.service';
import {
  GetPendingAndRejectedTotals,
  NetworkStatusChange,
  SetDeviceInfo,
  GetPendingLocalReports,
  GetIncidentObjects
} from './app.action';
import { IncidentObjects } from '@ea-models';

interface AppStateModel {
  networkStatus: NetworkStatus;
  deviceInfo: DeviceInfo;
  pendingAndRejectedTotals: PendingAndRejectedTotals;
  localReportTotal: number;
  incidentsObjects: IncidentObjects;
}

const defaults: AppStateModel = {
  networkStatus: null,
  deviceInfo: null,
  pendingAndRejectedTotals: null,
  localReportTotal: null,
  incidentsObjects: null
};

@State<AppStateModel>({
  name: 'app',
  defaults
})
@Injectable({
  providedIn: 'root'
})
export class AppState {
  constructor(
    private locationService: LocationService,
    private pendingLocalReportsService: PendingLocalReportsService,
    private incidentReportService: IncidentReportService
  ) {}

  //#region @Selector
  @Selector()
  static getNetworkStatus(state: AppStateModel) {
    return state.networkStatus;
  }

  @Selector()
  static getDeviceInfo(state: AppStateModel) {
    return state.deviceInfo;
  }

  @Selector()
  static getDevicePlatform(state: AppStateModel) {
    return state.deviceInfo?.platform;
  }

  @Selector()
  static isAndroid(state: AppStateModel) {
    return state.deviceInfo?.platform === 'android';
  }

  @Selector()
  static getIsMobilePlatform(state: AppStateModel): boolean {
    return (
      state.deviceInfo &&
      state.deviceInfo.platform &&
      (state.deviceInfo.platform === 'android' || state.deviceInfo.platform === 'ios')
    );
  }

  @Selector()
  static getPendingAndRejectedTotals(state: AppStateModel) {
    return state.pendingAndRejectedTotals;
  }

  @Selector()
  static getPendingTotals(state: AppStateModel) {
    const pendingAndRejectedTotals = state.pendingAndRejectedTotals;
    const total = _.isNil(pendingAndRejectedTotals)
      ? 0
      : _.sum([
          pendingAndRejectedTotals.in_progress_accident_reports,
          pendingAndRejectedTotals.in_progress_nemsis_submissions,
          pendingAndRejectedTotals.in_progress_report_submissions
        ]);
    return total;
  }

  @Selector()
  static getRejectedTotals(state: AppStateModel) {
    const pendingAndRejectedTotals = state.pendingAndRejectedTotals;
    const total = pendingAndRejectedTotals
      ? _.sumBy(
          [
            pendingAndRejectedTotals.rejected_accident_reports,
            pendingAndRejectedTotals.rejected_nemsis_reports,
            pendingAndRejectedTotals.rejected_park_builds,
            pendingAndRejectedTotals.rejected_report_submissions
          ],
          (i) => {
            if (_.isNumber(i)) return i;
            else return 0;
          }
        )
      : 0;
    return total;
  }

  @Selector()
  static getLocalReportTotal(s: AppStateModel) {
    return s.localReportTotal;
  }

  @Selector()
  static getIncidentObjects(s: AppStateModel) {
    return s.incidentsObjects;
  }

  @Selector()
  static getInProgressAccidentReportNumber({ pendingAndRejectedTotals }: AppStateModel) {
    return pendingAndRejectedTotals?.in_progress_accident_reports;
  }

  @Selector()
  static getInProgressOtherReportNumber({ pendingAndRejectedTotals }: AppStateModel) {
    return pendingAndRejectedTotals?.in_progress_report_submissions;
  }

  @Selector()
  static getInProgressNemsisReportNumber({ pendingAndRejectedTotals }: AppStateModel) {
    return pendingAndRejectedTotals?.in_progress_nemsis_submissions;
  }
  //#endregion

  //#region
  @Action(NetworkStatusChange)
  networkStatusChange(ctx: StateContext<AppStateModel>, { status }: NetworkStatusChange) {
    ctx.patchState({ networkStatus: status });
  }

  @Action(SetDeviceInfo)
  setDeviceInfo(ctx: StateContext<AppStateModel>, { deviceInfo }: SetDeviceInfo) {
    ctx.patchState({ deviceInfo });
  }

  @Action(GetPendingAndRejectedTotals)
  getPendingAndRejectedTotals(ctx: StateContext<AppStateModel>) {
    return this.locationService.getPendingAndRejectedTotals().pipe(
      tap((response) => ctx.patchState({ pendingAndRejectedTotals: response }))
      // catchError((err) => {
      //   let message = 'There was an error calculating the pending and rejected report totals.';
      //   return ctx.dispatch(new GeneralError(message));
      // })
    );
  }

  @Action(GetPendingLocalReports)
  getPendingLocalReports(ctx: StateContext<AppStateModel>) {
    const localReportTotal = this.pendingLocalReportsService.getPendingReportsCount();
    ctx.patchState({ localReportTotal });
  }

  @Action(GetIncidentObjects)
  getIncidentObjects(ctx: StateContext<AppStateModel>) {
    return this.incidentReportService.getIncidentObjects().pipe(
      tap((res) => {
        ctx.patchState({ incidentsObjects: res });
      })
    );
  }
  //#endregion
}
