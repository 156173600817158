import { RejectedReportStateModel } from './rejected-report.state';

const STATE_KEY = '[Rejected Report State]';

const TYPE = (text: string) => STATE_KEY + ' ' + text;

export class GetRejectedReports {
  static readonly type = TYPE('Get Rejected Reports');
  constructor(public reports: RejectedReportStateModel) {}
}

export class UpdateRejectReports {
  static readonly type = TYPE('Update Rejected Reports');
  constructor(public reports: Partial<RejectedReportStateModel>) {}
}
