import { Component, OnInit, Output, EventEmitter, Input, ChangeDetectorRef, OnDestroy, ViewChild } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { GlobalService, SpinnerService, PopUpService } from '@ea-services';
import { WARNING_HEADER } from '../../pages/pages.constants';
import { of } from 'rxjs';
import { tap, take, delay } from 'rxjs/operators';
import { LogRocketProvider } from '@ea-services/provider/logrocket';
import { AppState } from '@edgeauditor/app/store/app/app.state';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Geolocation } from '@capacitor/geolocation';
import { GeolocationCoords } from '@ea-models';

const API_KEY = 'AIzaSyC-O4VNt3VYCcvnlqIaAYXdLTPSu_Bq5MA';
const MAP_CANVAS = '#curr_location_map_canvas';

@Component({
  selector: 'app-current-location-map',
  templateUrl: './current-location-map.component.html',
  styleUrls: ['./current-location-map.component.scss']
})
export class CurrentLocationMapComponent implements OnInit, OnDestroy {
  @ViewChild('locationMapCanvas') locationMapCanvas: any;
  @Input() parentChangeDetectorRef: ChangeDetectorRef;
  @Input() set loadCurrentLocationInBG(is: boolean) {
    this._loadCurrentLocationInBG = is;
    if (!is && this.ionViewLoaded) {
      this.isManualSetLocation = false;
      if (!this.currentCoords) {
        this.spinnerService.show('getting location...');
      }
    }
  }
  @Input() set refreshCurrentLocation(is: boolean) {
    if (is && this.ionViewLoaded) {
      if (this.watchPositionId) {
        Geolocation.clearWatch({ id: this.watchPositionId });
        this.watchPositionId = null;
      }
      this.getCurrentLocation();
    }
  }
  @Input() isResetLocationOnClose: boolean;
  @Input() supportWatchLocation: boolean = true;
  @Output() closeMap = new EventEmitter<any>();
  @Output() emitMapObject = new EventEmitter<google.maps.Map>();

  @SelectSnapshot(AppState.getIsMobilePlatform) private readonly isMobilePlatform: boolean;

  private map: google.maps.Map;
  private coords: Partial<GeolocationCoords>;
  private marker: google.maps.Marker;
  private ionViewLoaded = false;
  private watchPositionId: string;
  private currentCoords: any;
  private _loadCurrentLocationInBG: boolean;
  private isManualSetLocation = false;

  constructor(
    private modalController: ModalController,
    public spinnerService: SpinnerService,
    public popUpService: PopUpService,
    public alertController: AlertController,
    private globalService: GlobalService,
    private logRocket: LogRocketProvider
  ) {}

  ngOnInit() {
    if (!this.ionViewLoaded) {
      if (this.parentChangeDetectorRef && this.isMobilePlatform) {
        this.parentChangeDetectorRef.detach();
      }
      this.ionViewLoaded = true;
      this.ionViewDidLoad();
    }
  }

  ngOnDestroy(): void {
    if (this.parentChangeDetectorRef && this.isMobilePlatform) {
      this.parentChangeDetectorRef.reattach();
    }

    if (this.watchPositionId) {
      Geolocation.clearWatch({ id: this.watchPositionId });
    }
  }

  ionViewDidEnter() {
    if (!this.ionViewLoaded) {
      this.ionViewLoaded = true;
      this.ionViewDidLoad();
    }
  }

  async ionViewDidLoad() {
    if (!this.globalService._currentUser) return;
    const location = this.globalService._currentUser.location;

    const coords = new google.maps.LatLng(location.lat, location.lng);
    this.loadMap(coords);
    this.getCurrentLocation(this._loadCurrentLocationInBG);
  }

  saveLocation() {
    if (this.parentChangeDetectorRef && this.isMobilePlatform) {
      this.parentChangeDetectorRef.reattach();
    }
    try {
      const coords = this.marker.getPosition();
      this.coords = { latitude: coords.lat(), longitude: coords.lng() };
      this.modalController.dismiss(this.coords).catch(() => {});
      if (this.isResetLocationOnClose) {
        of(null)
          .pipe(
            delay(1000),
            take(1),
            tap(() => this.setPosition(this.currentCoords))
          )
          .subscribe();
      }
    } catch {
      this.coords = null;
    }
    this.closeMap.emit(this.coords);
  }

  private loadMap(center: google.maps.LatLng | google.maps.LatLngLiteral) {
    const mapOptions: google.maps.MapOptions = {
      zoom: 16,
      center,
      mapTypeId: google.maps.MapTypeId.SATELLITE,
      zoomControl: true,
      mapTypeControl: true,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: true
    };

    this.map = new google.maps.Map(document.querySelector(MAP_CANVAS), mapOptions);
    of(null)
      .pipe(
        delay(1000),
        take(1),
        tap(() => {
          if (this.locationMapCanvas) {
            this.locationMapCanvas.nativeElement.setAttribute(
              'style',
              `height: ${this.locationMapCanvas.nativeElement.clientHeight || 500}px`
            );
          }
        })
      )
      .subscribe();
    this.emitMapObject.emit(this.map);
  }

  private getCurrentLocation(isInBG?: boolean, noShowSpinner?: boolean, loopTimes?: number, maximumAge?: number) {
    loopTimes = loopTimes || 3;
    if (!isInBG && !noShowSpinner) this.spinnerService.show('getting location...', 75000);
    Geolocation.getCurrentPosition({
      maximumAge: maximumAge || 1000,
      enableHighAccuracy: true,
      timeout: 25000
    })
      .then((resp) => {
        const currentCoords = this.currentCoords;
        this.currentCoords = resp.coords;
        if (this.currentCoords?.accuracy > 100 && loopTimes > 1) {
          if (currentCoords && currentCoords.accuracy < this.currentCoords.accuracy) {
            this.currentCoords = currentCoords;
          } else {
            this.currentCoords = currentCoords || this.currentCoords;
          }
          loopTimes--;
          setTimeout(() => {
            this.getCurrentLocation(isInBG, true, loopTimes, 100);
          }, 1000);
          return;
        }
        this.setPosition(this.currentCoords, null, true);
        if (this.watchPositionId) {
          Geolocation.clearWatch({ id: this.watchPositionId });
        }
        if (this.supportWatchLocation) {
          Geolocation.watchPosition(
            {
              maximumAge: maximumAge || 1000,
              enableHighAccuracy: true,
              timeout: 15000
            },
            (_resp, error) => {
              if (this.isManualSetLocation || !_resp) return;
              this.currentCoords = _resp.coords;
              this.setPosition(_resp.coords, true);
            }
          ).then((value) => (this.watchPositionId = value));
        }
        this.spinnerService.hide();
      })
      .catch((error) => {
        if (!isInBG) {
          this.spinnerService.hide();
          this.popUpService.popupConfirm(
            WARNING_HEADER,
            'Cannot get your current location. Do you want to retry?',
            null,
            () => this.getCurrentLocation(isInBG)
          );
        }
        this.logRocket.logEvent('location by GPS');
        this.logRocket.captureException(error);
      });
  }

  setPosition(coords: GeolocationCoords, notReloadMap?: boolean, forceReload?: boolean) {
    if (
      !forceReload &&
      (!coords ||
        (this.coords && coords.latitude === this.coords.latitude && coords.longitude === this.coords.longitude))
    )
      return;
    const position: google.maps.LatLngLiteral = { lat: coords.latitude, lng: coords.longitude };
    if (!notReloadMap) this.loadMap(position);
    this.coords = coords;
    const icon = {
      url: 'assets/image/red-map-marker.svg',
      scaledSize: new google.maps.Size(30, 45)
    };
    if (this.marker) {
      this.marker.setMap(null);
    }

    this.marker = new google.maps.Marker({
      animation: google.maps.Animation.DROP,
      position: position,
      icon: icon,
      map: this.map
    });

    google.maps.event.addListener(this.map, 'click', (e: { latLng }) => {
      this.marker.setPosition(e.latLng);
      this.coords = {
        latitude: e.latLng.lat(),
        longitude: e.latLng.lng()
      } as any;
      this.isManualSetLocation = true;
      this.logRocket.logEvent('location by manual');
      this.logRocket.logInfo(
        'current location',
        `lat: ${coords.latitude}, lng: ${coords.longitude}, accuracy: ${coords.accuracy}`
      );
    });
    this.map.setCenter(new google.maps.LatLng(coords.latitude, coords.longitude));
    this.logRocket.logEvent('location by GPS');
    this.logRocket.logInfo(
      'current location',
      `lat: ${coords.latitude}, lng: ${coords.longitude}, accuracy: ${coords.accuracy}`
    );
  }
}
