import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { InProgressOtherReport, InProgressOtherReportsResponse } from '@ea-models-v4/other-report';
import { GlobalService } from '@ea-services';
import { ConfigService } from '../../services/config.service';

@Injectable({
  providedIn: 'root'
})
export class OtherReportService {
  constructor(private http: HttpClient, private configService: ConfigService, private globalService: GlobalService) {}

  getInProgressOtherReports(start_index: number, limit: number): Observable<InProgressOtherReport[]> {
    const token = this.globalService._currentUser.token;

    return this.http
      .get<InProgressOtherReportsResponse | { success: boolean; msg: string }>(
        `${this.configService.apiURL()}submissions/available`,
        { params: { token, start_index, limit } }
      )
      .pipe(map((response) => ('pending_reports' in response ? response.pending_reports : [])));
  }
}
