import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ElementRef } from '@angular/core';
import { IncidentReportProviderService } from '@ea-services/provider/incident-report-provider.service';
import { AppState } from '@edgeauditor/app/store/app/app.state';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import moment, { Moment } from 'moment';

@Component({
  selector: 'app-birthdate',
  templateUrl: './birthdate.component.html',
  styleUrls: ['./birthdate.component.scss']
})
export class BirthdateComponent implements OnInit, OnChanges {
  @Output() changed = new EventEmitter<string>();
  @Input() modelData: { [key: string]: any };
  @Input() requiredObjects: { [key: string]: boolean };
  monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  months = [];
  age: number;
  years: number[];
  days: number[];
  model = {
    year: null,
    month: null,
    day: null
  };
  searchingModel = {
    year: null,
    month: null,
    day: null
  };
  selectedDate: Moment;

  @ViewSelectSnapshot(AppState.getIsMobilePlatform) isMobilePlatform: boolean;

  constructor(public incidentProvider: IncidentReportProviderService, public refEle: ElementRef) {}

  ngOnInit() {
    const maxAge = 110;
    const year = moment().year();
    this.years = [...Array(maxAge).keys()].map((i) => year - i);
    this.days = [...Array(31).keys()].map((i) => i + 1);
    this.months = this.monthNames.map((m, i) => ({ number: i + 1, name: m }));
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('modelData' in changes && changes.modelData.currentValue && changes.modelData.currentValue.birthday) {
      this.model = { ...changes.modelData.currentValue.birthday };
      this.calcAge();
    }
  }

  onChange() {
    this.calcAge();
    if (this.selectedDate) {
      this.changed.emit(this.selectedDate.format('YYYY-MM-DD'));
      return;
    }
    this.changed.emit(null);
  }

  onSearching($event, name) {
    this.searchingModel[name] = $event.term;
  }

  onClose(name, list, bindLabel?: string, bindValue?: string) {
    if (!this.searchingModel[name]) return;
    ''.toLowerCase;
    const item = list.find(
      (v) => `${v[bindLabel] || v}`.toLowerCase().trim() === `${this.searchingModel[name]}`.toLowerCase().trim()
    );
    if ((item[bindValue] || item) === this.model[name]) return;
    this.model[name] = item[bindValue] || item;
    this.onChange();
  }

  private calcAge() {
    if (this.model.year && this.model.month && this.model.day) {
      try {
        this.selectedDate = moment()
          .year(this.model.year)
          .month(this.model.month - 1)
          .date(this.model.day);
        this.age = moment().diff(this.selectedDate, 'years') || 0;
      } catch {
        this.age = 0;
      }
      return;
    }
    this.selectedDate = null;
    this.age = 0;
  }
}
