export const GROOMER_STATUS_INPROGRESS = 'In Progress';
export const STATUS_COMPLETED_NORDIC = 'Nordic Groomed and Track Set';
export const STATUS_COMPLETED_NORMAL = 'Complete - Groomed for Public';
export const GROOMER_SET_INPROGRESS_NOTE = 'Set in progress';
export const GROOMER_SET_COMPLETE_NOTE = 'Marked as completed';
export const filter_types = [
  { id: 1, name: 'Ski Runs', value: 1, selected: true, key: 'ski_run_id' },
  { id: 2, name: 'Lifts', value: 2, key: 'lift_id' },
  { id: 3, name: 'Other Areas', value: 3 }
];
