import { take, map } from 'rxjs/operators';
import * as _ from 'lodash';
import { Observable, forkJoin, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { GlobalService } from '../global.service';
import { RecentUsers } from '../recent-users.service';
import { EmergencyOperationalPlansAPI } from './emergency-operational-plans.api';
import { EmergencyOperationPlan } from '@ea-models/emergency-operation-plans';
import { PopUpService } from '../popup.service';
import { WARNING_HEADER, NO_CACHED_DATA_FOR_OFFLINE } from '../../pages/pages.constants';
import { AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import { User } from '@ea-models';
import { AppState } from '@edgeauditor/app/store/app/app.state';
import { RouterConstant } from '@edgeauditor/app/constants/router.constant';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root'
})
export class OfflineDataUtility {
  observables: Array<Observable<any>> = [];

  constructor(
    private globalService: GlobalService,
    private recentUsers: RecentUsers,
    private popUpService: PopUpService,
    private alertControler: AlertController,
    private router: Router,
    private eopServie: EmergencyOperationalPlansAPI,
    private store: Store
  ) {}

  getPlans(): Promise<any> {
    const networkStatus = this.store.selectSnapshot(AppState.getNetworkStatus);

    return (
      networkStatus.connected
        ? this.eopServie
            .getAllEmergencyOperationalPlans()
            .pipe(map((data: any) => (data = { ...data, source: 'api' })))
            .pipe(
              take(1),
              map(async (rsData) => {
                var response = rsData['emergency_operation_plan_categories'] || [];
                response = _.sortBy(response, [(category) => category['title'].toLowerCase()]);
                await this.storeSavedPlans({
                  _timeStamp: new Date(),
                  data: response
                });
                return response;
              })
            )
        : of(this.getSavedPlans()).pipe(
            take(1),
            map((res: any) => {
              if (!res?.length) {
                const handler = () => {
                  this.router.navigateByUrl(`/${RouterConstant.DASHBOARD_PAGE}`);
                };
                this.popUpService.popupAlert(
                  WARNING_HEADER,
                  NO_CACHED_DATA_FOR_OFFLINE,
                  this.alertControler,
                  handler,
                  false
                );
              }
              return res;
            })
          )
    ).toPromise();
  }
  getAllPlanDocs(emergencyPlans): Promise<any> {
    const networkStatus = this.store.selectSnapshot(AppState.getNetworkStatus);

    return new Promise(async (resolve, reject) => {
      if (!networkStatus.connected || !emergencyPlans || !emergencyPlans.length) return;
      this.observables = [];
      emergencyPlans.forEach((emergencyPlan: EmergencyOperationPlan) => {
        //generating observable array
        this.observables.push(this.getDocs(emergencyPlan));
      });
      forkJoin(this.observables)
        .toPromise()
        .then(async (allDocsArray: Array<any>) => {
          if (allDocsArray) {
            emergencyPlans.forEach((emergencyPlan: EmergencyOperationPlan, index: number) => {
              emergencyPlan.emergency_operation_plan_documents = allDocsArray[index];
            });
            await this.storeSavedPlans({
              _timeStamp: new Date(),
              data: emergencyPlans
            });
          }
          resolve(emergencyPlans);
        });
    });
  }

  getDocs(emergencyPlan: EmergencyOperationPlan): Observable<any> {
    return this.eopServie
      .getEmergencyOperationalPlanDocument(emergencyPlan.id)
      .pipe(map((data: any) => (data = { ...data, source: 'api' })))
      .pipe(
        take(1),
        map((rsData) => {
          var response = rsData['emergency_operation_plan_documents'] || [];
          return _.sortBy(response, [(doc) => doc['title'].toLowerCase()]);
        })
      );
  }

  getSavedPlans() {
    if (!this.globalService._currentUser) return [];
    return this.globalService._currentUser.emergency_operation_plans
      ? this.globalService._currentUser.emergency_operation_plans.data
      : [];
  }

  async storeSavedPlans(data) {
    if (!this.globalService._currentUser) return;
    const user: User = this.recentUsers.get(
      this.globalService._currentUser.org,
      this.globalService._currentUser.username
    );
    if (!user) return;
    user.emergency_operation_plans = data;
    this.recentUsers.update(user);
  }
}
