import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { ConfigService, ApiService, SpinnerService, GlobalService } from '@ea-services';
import { ISign } from '@ea-models/object-module';
import { IAttachment } from '@ea-models';
import { Select } from '@ngxs/store';
import { AppState } from '@edgeauditor/app/store/app/app.state';
import { NetworkStatus } from '@capacitor/network';
import { Observable } from 'rxjs';

@Component({
  selector: 'modal-add-object',
  templateUrl: 'add-object.component.html',
  styleUrls: ['add-object.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddObjectModalComponent {
  @Input() sign: ISign;
  @Input() coords: any;

  @Select(AppState.getNetworkStatus) networkStatus$: Observable<NetworkStatus>;

  sign_types = [];
  get isReadyToSave() {
    return (
      this.sign.category_id &&
      this.sign.name &&
      this.sign.object_attachments_attributes &&
      this.sign.object_attachments_attributes.length
    );
  }
  constructor(
    public configService: ConfigService,
    public apiService: ApiService,
    public globalService: GlobalService,
    public spinnerService: SpinnerService,
    public modalController: ModalController,
    public alertController: AlertController
  ) {}

  ionViewWillEnter() {
    if (this.globalService._currentUser) {
      this.sign_types = this.sortSignTypesByName(this.globalService._currentUser.sign_types || []);
    }
  }

  onImageChange(imagesData: { currentImage: IAttachment; images: IAttachment[] }) {
    this.sign.object_attachments_attributes = [{ file: imagesData.images[0].object_attachment.file }];
  }

  sortSignTypesByName(sign_types) {
    if (!sign_types) return [];
    return sign_types.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  }

  customSearchFn(term: string, item) {
    return item.name.toLocaleLowerCase().indexOf(term) > -1;
  }

  async save() {
    if (this.isReadyToSave) await this.modalController.dismiss(this.sign);
  }

  async close() {
    await this.modalController.dismiss();
  }
}
