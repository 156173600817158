<ng-container>
  <ng-container *ngIf="!signatureOnly">
    <div class="icon-header-container" [hidden]="hideHeader">
      <ion-icon
                size="small"
                class="incident-icon"
                src="/assets/image/document-file.svg"></ion-icon>
      <ion-label class="bold">Report Completion</ion-label>
    </div>
    <ion-grid>
      <ion-row>
        <ion-col
                 size-xs="12"
                 size-sm="6"
                 class="report-completion__name"
                 [class.required-add]="requiredObjects?.completed_by">
          <ion-item lines="none" class="transparent-item">
            <ion-label [hidden]="hideHeader">Name <span class="required">*</span></ion-label>
            <ion-label [hidden]="!hideHeader">Form Completed By <span class="required">*</span></ion-label>
          </ion-item>
          <ion-item lines="none" class="transparent-item">
            <ion-input
                       debounce="400"
                       class="standard-input"
                       [(ngModel)]="model.completed_by"
                       [class.in-valid]="!model.completed_by"
                       (ionChange)="onChangeEvent($event);"
                       autocapitalize="sentences"></ion-input>
          </ion-item>
        </ion-col>
        <ion-col
                 size-xs="12"
                 size-sm="6"
                 [class.required-add]="requiredObjects?.completed_date">
          <ion-item lines="none" class="transparent-item">
            <ion-icon
                      size="small"
                      class="incident-icon"
                      src="/assets/image/calendar-outline.svg"></ion-icon>
            <ion-label>Date <span class="required">*</span></ion-label>
          </ion-item>
          <ion-item lines="none" class="transparent-item">
            <input
                   readonly
                   class="standard-input"
                   [owlDateTime]="completeDt"
                   [max]="maxDate"
                   [owlDateTimeTrigger]="completeDt"
                   [(ngModel)]="model.completed_date"
                   (dateTimeChange)="onDateChange($event.value)"
                   [class.in-valid]="!model.completed_date" />
            <owl-date-time pickerType="calendar" #completeDt></owl-date-time>
          </ion-item>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ng-container>
  <ion-item
            lines="none"
            class="transparent-item"
            [ngClass]="{ 'report-completion signature-only': signatureOnly }"
            [class.required-add]="requiredObjects?.url">
    <ion-label>Signature <span class="required">*</span></ion-label>
  </ion-item>
  <ion-item
            lines="none"
            class="transparent-item"
            [ngClass]="{ 'report-completion signature-only': signatureOnly }"
            [class.required-add]="requiredObjects?.url">
    <div class="signature-container" [class.existing]="!!s3signature">
      <img
           [src]="s3signature"
           alt=""
           *ngIf="s3signature"
           [ngStyle]="imgStyle"
           class="img-signature" />
      <img
           [src]="signature"
           alt=""
           *ngIf="signature"
           [ngStyle]="imgStyle"
           class="img-signature"
           [hidden]="true" />
      <signature-pad
                     class="report-completion__signature-pad"
                     [options]="signaturePadOptions"
                     [hidden]="s3signature"
                     [class.in-valid]="!signature"
                     (onBeginEvent)="drawStart()"
                     (onEndEvent)="drawComplete()"
                     #_signaturePad>
      </signature-pad>
      <div class="bottom-control">
        <ion-button (click)="clearSignature()" fill="clear" size="small" color="medium">
          <ion-icon slot="icon-only" name="close"></ion-icon>
        </ion-button>
      </div>
      <div class="bottom-control btn">
        <div (click)="clearSignature()">Clear</div>
      </div>
    </div>
  </ion-item>
</ng-container>