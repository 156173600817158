import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { createGesture, Gesture } from '@ionic/core';
import { GlobalService, SpinnerService, PopUpService, GroomersService } from '@ea-services';
import { catchError, take, tap, delay } from 'rxjs/operators';
import { ModalController, AlertController } from '@ionic/angular';
import { of } from 'rxjs';
import _ from 'lodash';
import { LogRocketProvider } from '@ea-services/provider/logrocket';
import {
  GROOMER_SET_COMPLETE_NOTE,
  GROOMER_SET_INPROGRESS_NOTE,
  GROOMER_STATUS_INPROGRESS,
  STATUS_COMPLETED_NORDIC,
  STATUS_COMPLETED_NORMAL
} from '@edgeauditor/constants/groomer.constants';
import { AppState } from '@edgeauditor/app/store/app/app.state';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';

@Component({
  selector: 'modal-groomer-assignment',
  templateUrl: './groomer-assignment.component.html',
  styleUrls: ['./groomer-assignment.component.scss']
})
export class GroomerAssignmentModalComponent implements OnInit, OnDestroy {
  @Input() params: {
    vehicle_id: number;
    groomer_shift_id: number;
    assignment: any;
    logs: any;
    activity_type: string;
  };

  model: {
    vehicle_id: number;
    status: string;
    comments: string;
    amount_completed: number;
    time_completed: string;
    time_in_hours: number;
    time_in_minutes: number;
    total_time: string;
    overridden: boolean;
  } = {} as any;
  tabId = 2;
  currentUser: any;
  status = [];
  isSuccessful: boolean;
  assignment: any;
  percentageCompletes = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
  amountComplete: number = 0;
  currentAmountComplete: number = 0;
  totalFinalComplete: number = 0; // sum of all complete
  totalOtherComplete: number = 0; // sum of all complete excluding from current user
  limitComplete: number = 100;
  time = { h_complete: null, m_complete: null, h_spent: null, m_spent: null };
  dragEvent: { x: number; y: number; end?: boolean } = {
    x: 0,
    y: 0,
    end: true
  };
  setOverridden: boolean;
  logged: any;
  updatedStatus: any;
  get alreadySetOverridden() {
    return this.assignment && this.assignment.log ? this.assignment.log.overridden : false;
  }

  get shouldLimitComplete() {
    return !this.alreadySetOverridden && !this.setOverridden && this.limitComplete > 0;
  }

  get allowSave() {
    return !!this.model.vehicle_id && !!this.model.status && this.time.h_spent + this.time.m_spent > 0;
  }
  get limitWidth() {
    return {
      width: `calc(${100 - (this.limitComplete || 0)}% - ${this.spaceObject[100 - (this.limitComplete || 0)]})`
    };
  }

  isShowWarning: boolean;
  askBeforeLeaving = false;
  dragGesture: Gesture;
  readonly spaceObject = {
    10: '1px',
    20: '5px',
    30: '9px',
    40: '13px',
    50: '20px',
    60: '25px',
    70: '27px',
    80: '35px',
    90: '39px'
  };
  readonly GROOMER_STATUS_INPROGRESS = GROOMER_STATUS_INPROGRESS;
  readonly STATUS_COMPLETED_NORDIC = STATUS_COMPLETED_NORDIC;
  readonly STATUS_COMPLETED_NORMAL = STATUS_COMPLETED_NORMAL;
  readonly GROOMER_SET_INPROGRESS_NOTE = GROOMER_SET_INPROGRESS_NOTE;
  readonly GROOMER_SET_COMPLETE_NOTE = GROOMER_SET_COMPLETE_NOTE;

  @ViewChild('sliderControl') sliderControl: ElementRef;
  @ViewChild('sliderElement') set sliderElement(ele: ElementRef) {
    if (ele && this.isMobilePlatform) {
      console.log('sliderElement - create draggable event');
      this.dragGesture = createGesture({
        el: ele.nativeElement,
        gestureName: 'draggable',
        threshold: 0,
        canStart: () => true,
        onStart: (ev) => {
          const event = { x: ev.currentX, y: ev.currentY };
          this.logDrag(event);
          this.changeDetectorRef.detectChanges();
        },
        onMove: (ev) => {
          const event = { x: ev.currentX, y: ev.currentY };
          this.logDrag(event);
          this.changeDetectorRef.detectChanges();
        },
        onEnd: (ev) => {
          const event = { x: ev.currentX, y: ev.currentY };
          this.logDrag(event, true);
          this.changeDetectorRef.detectChanges();
        }
      });
      this.dragGesture.enable(true);
      console.dir(this.dragGesture);
    }
  }

  isSetProgressCase(status, log) {
    return (
      (status === GROOMER_STATUS_INPROGRESS || log.total_time === '0.0') && log.comments === GROOMER_SET_INPROGRESS_NOTE
    );
  }

  isSetCompletedCase(status, log) {
    return (
      (status === STATUS_COMPLETED_NORDIC || status === STATUS_COMPLETED_NORMAL) &&
      log.comments === GROOMER_SET_COMPLETE_NOTE
    );
  }

  @ViewSelectSnapshot(AppState.getIsMobilePlatform) isMobilePlatform: boolean;

  constructor(
    public globalService: GlobalService,
    private spinnerService: SpinnerService,
    private groomerService: GroomersService,
    private popUpService: PopUpService,
    private alertController: AlertController,
    private logRocketProvider: LogRocketProvider,
    private changeDetectorRef: ChangeDetectorRef,
    public modalController: ModalController
  ) {}

  ngOnInit() {
    if (this.globalService._currentUser) {
      this.currentUser = this.globalService._currentUser;
    }
    this.isSuccessful = false;
    this.assignment = this.params.assignment;
    this.initReasons();
    this.initModel();
    this.getGroomerAssignment();
  }

  ngOnDestroy(): void {
    if (this.dragGesture) {
      this.dragGesture.destroy();
    }
  }

  logDrag($event, end?: boolean) {
    console.log('logDrag');
    console.dir($event);
    let newX = Math.max($event.x - 70, 0);
    const containerW = this.sliderControl.nativeElement.offsetWidth;
    if (containerW) newX = Math.min(containerW - 26, newX);
    if (this.dragEvent && newX <= 0 && this.dragEvent.x - newX > 50) return;
    const percent = Math.round((newX / containerW) * 100);
    this.currentAmountComplete = percent;
    if (end) {
      let n = parseInt((percent / 10) as any);
      if (percent % 10 > 5) {
        n++;
      }
      this.model.amount_completed = n * 10;
      // for un-overridden, prevent over 100% in final total complete
      if (!(this.alreadySetOverridden || this.setOverridden) && this.limitComplete > 0) {
        this.model.amount_completed = Math.min(this.limitComplete, this.model.amount_completed);
      }
      this.amountComplete = this.model.amount_completed;
    }
    this.dragEvent = { x: newX, y: $event.y, end: end };
  }

  setCompleted(value) {
    this.model.amount_completed = value;
    // for un-overridden, prevent over 100% in final total complete
    if (!(this.alreadySetOverridden || this.setOverridden)) {
      this.model.amount_completed = Math.min(this.limitComplete, this.model.amount_completed);
    }
    this.amountComplete = this.model.amount_completed;
    this.dragEvent = { x: 0, y: 0, end: true };
  }

  onChangeTimeEvent(e: Event, e2: Event, name: string, max?: number) {
    const e2Value = (<HTMLTextAreaElement>e2.target).value;
    this.onChangeTime(e, e2Value, name, max);
  }

  onChangeTime($event, value, name, max?: number, min?: number) {
    value = parseInt(value || '0');
    if (!_.isNil(max) && value > max) {
      value = max;
    }
    if (!_.isNil(min) && value < min) {
      value = min;
    }
    this.time[name] = value;
    try {
      if (!value && $event && $event.target && $event.target.childNodes.length) {
        $event.target.childNodes[0].value = '00';
      }
    } catch {}
  }

  setNowTime() {
    const _date = new Date();
    this.time.h_complete = _date.getHours() + '';
    this.time.m_complete = _date.getMinutes() + '';
  }

  addTimeSpent(mins: number) {
    const value = +this.time.m_spent + mins;
    this.time.m_spent = value;
    if (value >= 60) {
      this.time.m_spent = value % 60;
      this.time.h_spent += (value - this.time.m_spent) / 60;
    }
  }

  changeStatus(item) {
    if (item.selected) {
      return;
    }
    this.model.status = item.value;
    of(null)
      .pipe(
        delay(100),
        take(1),
        tap(() => {
          this.status.forEach((element) => {
            element.selected = false;
          });
          item.selected = true;
        })
      )
      .subscribe();
  }

  onSetOverridden(is: boolean) {
    this.setOverridden = is;
    this.assignment.amount_completed = 0;
    this.amountComplete = 0;
    this.time.h_spent = 0;
    this.time.m_spent = 0;
  }

  async close(result?: any, force?: boolean) {
    if (this.allowSave && !result && !force) {
      this.askBeforeLeaving = true;
      this.isShowWarning = true;
      return;
    }
    this.askBeforeLeaving = false;
    await this.modalController.dismiss(result);
  }

  reset() {
    this.initModel();
    this.initReasons();
  }

  save() {
    if (!this.allowSave) return;
    this.askBeforeLeaving = false;
    this.spinnerService.show('processing...');
    const model = { ...this.assignment, ...this.model };
    model.overridden = this.setOverridden || this.alreadySetOverridden;
    model.time_completed = `${this.time.h_complete}:${this.time.m_complete}`;
    model.time_in_hours = this.time.h_spent;
    model.time_in_minutes = this.time.m_spent;
    model.total_time = `${this.time.m_spent} ${this.time.h_spent}`;
    delete model.log;
    this.processStatus(model.id, model.status);
    this.groomerService
      .logActivity(this.currentUser.token, model)
      .pipe(
        take(1),
        tap((res) => {
          if (!res || !res.success) {
            this.spinnerService.hide();
            this.popUpService.popupError('Log Progress failed.', this.alertController);
            this.logRocketProvider.captureMessage(
              'save groomer assignment failed',
              'groomer assignment error',
              'groomer assignment'
            );
            return;
          }
          this.logged = true;
          this.afterSubmittedAll();
        }),
        catchError((e) => {
          this.popUpService.popupError('Log Progress failed.', this.alertController);
          this.logRocketProvider.captureMessage(e, 'groomer assignment error', 'groomer assignment');
          return of(null);
        })
      )
      .subscribe();
    this.logRocketProvider.logEvent('save groomer assignment');
    this.logRocketProvider.logInfo('save groomer assignment', model);
  }

  private processStatus(id: number, status: string) {
    this.groomerService
      .updateGroomerStatus(id, encodeURIComponent(status), this.currentUser.token)
      .pipe(
        take(1),
        tap((res: any) => {
          if (!res || !res.success) {
            this.spinnerService.hide();
            this.popUpService.popupError('Update status failed.', this.alertController);
            this.logRocketProvider.captureMessage(
              'save assignment status failed',
              'groomer assignment error',
              'groomer assignment'
            );
            return;
          }
          this.updatedStatus = true;
          this.afterSubmittedAll();
        }),
        catchError((e) => {
          this.popUpService.popupError('Update status failed.', this.alertController);
          this.logRocketProvider.captureMessage(e, 'groomer assignment error', 'groomer assignment');
          return of(null);
        })
      )
      .subscribe();
    this.logRocketProvider.logEvent('save assignment status');
    this.logRocketProvider.logInfo('save assignment status', status);
  }

  private afterSubmittedAll() {
    if (!this.logged || !this.updatedStatus) return;
    this.groomerService
      .getGroomerAssignment(this.params.assignment.id, this.currentUser.token)
      .pipe(
        take(1),
        tap((res: any) => {
          this.spinnerService.hide();
          if (!res || !res.success || !res.data) {
            this.close({ assignment: this.model }, true);
            return;
          }
          const data = res.data;
          const logs = res.data.groomer_activity_logs;
          delete data.groomer_activity_logs;
          this.close({ assignment: data, logs: logs }, true);
        })
      )
      .subscribe();
  }

  private getGroomerAssignment() {
    this.groomerService
      .getGroomerAssignment(this.params.assignment.id, this.currentUser.token)
      .pipe(
        take(1),
        tap((res: any) => {
          if (!res || !res.success || !res.data) return;
          this.params.logs = res.data.groomer_activity_logs.filter(
            (l) => l.groomer_activity_assignment_id === this.assignment.id
          );
          // do nothing for overridden assignment
          if (this.alreadySetOverridden || this.setOverridden) {
            return;
          }
          this.initModel();
          this.assignment.log = _.last(this.params.logs);
          this.assignment.log_by_user = this.params.logs
            .filter((l) => l.groomer_activity_assignment_id === this.assignment.id && l.user_id === this.currentUser.id)
            .pop();
          this.totalFinalComplete = _.sumBy(this.params.logs, 'amount_completed');
          this.totalOtherComplete = this.totalFinalComplete;
          if (this.assignment.log_by_user) {
            this.totalOtherComplete = this.totalFinalComplete - (this.assignment.log_by_user.amount_completed || 0);
          }
          if (this.totalFinalComplete >= 100) this.amountComplete = 100;
          if (this.totalOtherComplete > 0) this.limitComplete = 100 - this.totalOtherComplete;
        })
      )
      .subscribe();
  }

  private initModel() {
    this.model = {
      vehicle_id: this.params.vehicle_id,
      status: null,
      comments: null,
      amount_completed: this.params.assignment.log ? this.params.assignment.log.amount_completed : 0,
      overridden: this.params.assignment.log ? this.params.assignment.log.overridden : false,
      time_completed: null,
      time_in_hours: 0,
      time_in_minutes: 0,
      total_time: null
    };
    if (this.assignment && this.assignment.log_by_user) {
      this.model.time_in_hours = !this.alreadySetOverridden ? this.assignment.log_by_user.time_in_hours || 0 : 0;
      this.model.time_in_minutes = !this.alreadySetOverridden ? this.assignment.log_by_user.time_in_minutes || 0 : 0;
      this.model.amount_completed = this.assignment.log_by_user.amount_completed || 0;
      this.amountComplete = this.model.amount_completed;
    }
    const _date = new Date();
    this.time = {
      h_complete: _date.getHours(),
      m_complete: _date.getMinutes(),
      h_spent: this.model.time_in_hours,
      m_spent: this.model.time_in_minutes
    };
  }

  private initReasons() {
    this.status = [
      { value: GROOMER_STATUS_INPROGRESS, selected: false },
      { value: 'Complete - Groomed for Public', selected: false },
      { value: 'Complete - Groomed for Public - Fresh Snow', selected: false },
      { value: 'Complete - Run Open', selected: false },
      { value: 'Complete - Closed- Track Packed Only', selected: false },
      { value: 'Nordic Groomed & Track Set', selected: false },
      { value: 'Nordic Groomed', selected: false },
      { value: 'Task Completed', selected: false }
    ];
  }
}
