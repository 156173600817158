<ion-header class="report-history-header">
  <ion-toolbar color="main">
    <ng-container *ngIf="historyData && !historyDetail">
      <ion-icon
        size="large"
        slot="start"
        name="calendar-outline"
        class="calendar"
      ></ion-icon>
      <div class="filter-time" [class.has-filter]="filterTime">
        <input
          readonly
          [owlDateTime]="incT"
          [max]="maxDate"
          [ngModel]="filterTime"
          [owlDateTimeTrigger]="incT"
          (dateTimeChange)="onChangeFilter($event.value)"
        />
        <owl-date-time pickerType="calendar" #incT></owl-date-time>
        <div class="clear-filter" (click)="onChangeFilter(null)">
          <div></div>
        </div>
      </div>
    </ng-container>
    <ion-title class="ion-text-center" [class.has-filter]="filterTime"
      >View Previous Reports</ion-title
    >
    <ion-icon
      size="large"
      slot="start"
      name="arrow-back-outline"
      class="arrow-back"
      [hidden]="!historyDetail"
      (click)="historyDetail = ''"
    ></ion-icon>
    <ion-icon
      size="large"
      slot="end"
      name="close"
      *ngIf="!historyDetail"
      (click)="myDismiss()"
      class="icon-close"
    ></ion-icon>
  </ion-toolbar>
</ion-header>
<ion-content scrollY="true">
  <ion-list *ngIf="historyData" [hidden]="!!historyDetail" #compHistoryData>
  </ion-list>
  <div
    class="view-history-detail"
    [innerHTML]="historyDetail"
    [class.full]="!!historyDetail"
  ></div>
</ion-content>
