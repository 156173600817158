import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_BASE_URL } from './constants';

@Injectable({
  providedIn: 'root'
})
export class GeoDataService {
  constructor(private httpClient: HttpClient) {}

  public getGeoData(featureId: number, token: string): Observable<any> {
    return this.httpClient.get<any>(`${API_BASE_URL}/get_geo_data?id=${featureId}&token=${token}`);
  }

  public getRecentParkBuild(id: number, token: string): Observable<any> {
    return this.httpClient.get<any>(`${API_BASE_URL}/recent_park_build/${id}?token=${token}`);
  }
}
