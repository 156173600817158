import { FormControl, FormGroup, Validators } from '@angular/forms';
import _ from 'lodash';
import moment from 'moment';

import {
  NemsisQuestion,
  NemsisQuestionAnswer,
  NemsisQuestionType,
  NemsisReportCreation,
  NemsisReportStatus,
  NemsisReportSubmitData,
  NemsisTemplateSection
} from '@ea-models-v4';

interface PayloadItem {
  accident_field_name: string;
  value: string | number;
}

export class NemsisHelper {
  static linkIncidentToNemsisReport(formGroup: FormGroup, questions: NemsisQuestion[], items: PayloadItem[]) {
    items.forEach((i) => this.mapIncidentToNemsisReport(formGroup, questions, i));
  }

  private static mapIncidentToNemsisReport(formGroup: FormGroup, questions: NemsisQuestion[], item: PayloadItem) {
    if (item.value) {
      questions
        .filter(
          (i) =>
            i.accident_field_name === item.accident_field_name &&
            formGroup.controls[i.id] &&
            !questions.find((q) => q.accident_field_name === item.accident_field_name).default_text_value
        )
        .forEach((i) => {
          formGroup.controls[i.id].patchValue(item.value);
          formGroup.controls[i.id].disable();
        });
    }
  }

  static transformFormToRequest(
    nemsis_template_id: number,
    data: NemsisReportSubmitData,
    status: NemsisReportStatus,
    templateSections: Partial<NemsisTemplateSection>[],
    nemsisAnswers?: NemsisQuestionAnswer[]
  ): NemsisReportCreation {
    //Get raw value from formGroup
    const formRawValue = data.form.getRawValue();
    const questions = _.flatten<NemsisQuestion>(_.merge(templateSections.map((i) => i.questions)));
    //Build request object
    const createReportRequest: NemsisReportCreation = {
      nemsis_template_id,
      nemsis_report_answers_attributes: [],
      status
    };

    if (data.incidentReport) {
      createReportRequest.accident_report_id = data.incidentReport.id;
    }
    //Transform form values to create report request format
    createReportRequest.nemsis_report_answers_attributes = Object.values(formRawValue).reduce<
      Partial<NemsisQuestionAnswer>[]
    >((agg, currentValue) => {
      const nemsisAnswerArray: Partial<NemsisQuestionAnswer>[] = [];
      Object.entries(currentValue).forEach(([key, value]) => {
        const answer: Partial<NemsisQuestionAnswer> = {
          nemsis_template_question_id: +key
        };
        const questionType = questions.find((q) => q.id === +key).question_type;
        switch (questionType) {
          case NemsisQuestionType.MultiSelect:
            const answers: Partial<NemsisQuestionAnswer>[] = (<number[]>value).map((id) => ({
              nemsis_template_question_id: +key,
              nemsis_question_option_id: id
            }));
            nemsisAnswerArray.push(...answers);
            break;
          case NemsisQuestionType.Selectable:
            answer.nemsis_question_option_id = value;
            nemsisAnswerArray.push(answer);
            break;
          case NemsisQuestionType.Date:
            answer.date = value ? moment(value).format(moment.HTML5_FMT.DATE) : value;
            nemsisAnswerArray.push(answer);
            break;
          case NemsisQuestionType.Time:
            if (value) {
              const momentValue = moment(value);
              answer.time = `${momentValue.hours()}:${momentValue.minutes()}`;
            } else {
              answer.time = value;
            }
            nemsisAnswerArray.push(answer);
            break;
          default:
            answer.text_answer = value;
            nemsisAnswerArray.push(answer);
            break;
        }
      });
      return agg.concat(nemsisAnswerArray);
    }, []);

    let deletePreviousAnswers: NemsisQuestionAnswer[] = [];
    /** Clone previous answers list */
    if (nemsisAnswers) {
      _.forEach(_.cloneDeep(nemsisAnswers), (o) => {
        Object.entries(o).forEach(([k, v]) => {
          if (_.isNil(v)) delete o[k];
        });
        o._destroy = true;
        deletePreviousAnswers.push(o);
      });
    }

    createReportRequest.nemsis_report_answers_attributes.forEach((attr) => {
      let itemIdHaveBeenEdited: number[] = [];
      const previousAnswer = deletePreviousAnswers.find(
        (i, idx) => i.nemsis_template_question_id === attr.nemsis_template_question_id
      );
      if (previousAnswer) {
        itemIdHaveBeenEdited.push(previousAnswer.id);
        attr.id = previousAnswer.id;
      }
      /** Remove edited item from the removing list */
      deletePreviousAnswers = deletePreviousAnswers.filter((i) => {
        const idx = itemIdHaveBeenEdited.findIndex((item) => i.id === item);
        if (idx > -1) {
          itemIdHaveBeenEdited = itemIdHaveBeenEdited.slice(idx + 1);
          return false;
        }
        return true;
      });
    });
    createReportRequest.nemsis_report_answers_attributes.push(...deletePreviousAnswers);
    return createReportRequest;
  }

  //Returns a form group based on the selected template
  static toFormGroup(template_sections: NemsisTemplateSection[] | Partial<NemsisTemplateSection>[]): FormGroup {
    const group = {};

    template_sections.forEach((ts) => {
      let templateGroup = {};
      ts.questions.forEach((q) => {
        let value: string | number | number[] = '';
        const isMultiSelect = q.question_type === NemsisQuestionType.MultiSelect;
        const isSelectable = q.question_type === NemsisQuestionType.Selectable;
        const isSelectType = isMultiSelect || isSelectable;
        if (isMultiSelect) {
          value = [];
        } else if (isSelectable) {
          value = null;
        }

        //If the template section has an answers array, find if the question has a value.

        if (ts.answers) {
          const answerIndex = ts.answers.findIndex((a) => a.nemsis_template_question_id === q.id);
          if (answerIndex !== -1) {
            value = ts.answers[answerIndex].text_answer
              ? ts.answers[answerIndex].text_answer
              : ts.answers[answerIndex].nemsis_question_option_id;
          }
        } else {
          //Check if a question has a default text value or a default option
          if (q.default_text_value && !isSelectType) {
            value = q.default_text_value;
          } else if (q.question_options?.length) {
            if (q.question_type === NemsisQuestionType.MultiSelect) {
              value = q.question_options.filter((i) => i.default).map((i) => i.id);
            } else if (q.question_type === NemsisQuestionType.Selectable) {
              const defaultOption = q.question_options.find((qo) => qo.default === true);
              if (defaultOption) {
                value = defaultOption.id;
              }
            }
          }
        }

        templateGroup[q.id] = q.can_be_null ? new FormControl(value) : new FormControl(value, Validators.required);
      });

      group[ts.name] = new FormGroup(templateGroup);
    });

    return new FormGroup(group);
  }
}
