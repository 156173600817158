import {
  Component,
  EventEmitter,
  Input,
  Output,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  ComponentFactoryResolver,
  ViewChild,
  ViewContainerRef,
  AfterViewInit
} from '@angular/core';
import { IDynamicComponentModelWrapper, IReportSection, IReportSectionObject } from '@ea-models/reports';
import { Observable, of } from 'rxjs';
import { User } from '@ea-models';
import { take, tap } from 'rxjs/operators';
import { ReportItemBase } from '../report-item.base';
import { ReportService, GlobalService } from '@ea-services';

@Component({
  selector: 'app-single-page-report',
  template: ` <div class="section-component__title">{{ section?.heading }}</div>
    <ng-container #viewContainerRef></ng-container>`
})
export class SinglePageReportComponent {
  @Input() section: IReportSection;
  @Input() userEvent: Observable<User>;
  @Input() restoreSectionEvent: Observable<any>;
  @Output() questionChanged = new EventEmitter<IDynamicComponentModelWrapper>();
  @Input() parentChangeDetectorRef: ChangeDetectorRef;
  @Input() isSupportResizePhoto: boolean;
  @Input() showWarningResizePhoto: boolean;

  @ViewChild('viewContainerRef', { read: ViewContainerRef, static: true })
  private readonly viewContainerRef: Readonly<ViewContainerRef>;

  constructor(private factoryResolver: ComponentFactoryResolver, protected ref: ChangeDetectorRef) {}

  ngAfterContentInit() {
    if (this.section && this.section.objects && this.section.questions && this.viewContainerRef) {
      this.section.objects.forEach((obj, index) => {
        obj.models = [];
        this.addDynamicComponent(obj, index);
      });
    }
  }

  private addDynamicComponent(object: IReportSectionObject, objectIndex: number) {
    const viewContainerRef = this.viewContainerRef;
    const factory = this.factoryResolver.resolveComponentFactory(SinglePageReportItemComponent);
    const component = factory.create(viewContainerRef.parentInjector);
    component.instance.section = this.section;
    component.instance.parentChangeDetectorRef = this.parentChangeDetectorRef;
    component.instance.parentRef = this.ref;
    component.instance.restoreSectionEvent = this.restoreSectionEvent;
    component.instance.userEvent = this.userEvent;
    component.instance.isSupportResizePhoto = this.isSupportResizePhoto;
    component.instance.showWarningResizePhoto = this.showWarningResizePhoto;
    component.instance.object = object;
    component.instance.objectIndex = objectIndex;
    component.instance.sectionIndex = this.section['index'];
    component.instance.initModel();
    of(null)
      .pipe(
        take(1),
        tap(() => viewContainerRef.insert(component.hostView))
      )
      .subscribe();
  }
}

@Component({
  selector: 'single-report-item-component',
  templateUrl: './single-page-report.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SinglePageReportItemComponent extends ReportItemBase implements AfterViewInit {
  constructor(
    protected factoryResolver: ComponentFactoryResolver,
    protected reportService: ReportService,
    protected globalService: GlobalService
  ) {
    super(factoryResolver, reportService);
  }

  ngAfterViewInit() {
    this.renderControls();
  }
}
