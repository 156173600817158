import { Component, Input, OnInit } from '@angular/core';
import { IReportSection } from '@ea-models/reports';

@Component({
  selector: 'app-no-object-report',
  templateUrl: './no-object-report.component.html',
  styleUrls: ['./no-object-report.component.scss']
})
export class NoObjectReportComponent implements OnInit {
  @Input() section: IReportSection;

  constructor() {}

  ngOnInit() {}
}
