import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ChangeDetectorRef,
  OnDestroy
} from '@angular/core';
import { Observable, Subscription, of } from 'rxjs';
import { take, tap, catchError } from 'rxjs/operators';
import { NetworkStatus } from '@capacitor/network';
import { Select, Store } from '@ngxs/store';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import _ from 'lodash';

import { ILatLng, User } from '@ea-models';
import { GeoDataService, GlobalService, ParkFeaturesService } from '@ea-services';
import { FEATURE_BUILD_TYPE, INCIDENT_LOCATION } from '@edgeauditor/pages/pages.constants';
import { LOCATION_MAPPING } from '@edgeauditor/constants/incident-report.constants';
import { IncidentReportProviderService } from '@ea-services/provider/incident-report-provider.service';
import { AppState } from '@edgeauditor/app/store/app/app.state';
import { MapObject } from '@ea-models-v4';

@Component({
  selector: 'app-incident-location',
  templateUrl: './incident-location.component.html',
  styleUrls: ['./incident-location.component.scss']
})
export class IncidentLocationComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() currentUser: User;
  @Input() selectionAppendDOM: string;
  @Input() mapObject: MapObject;
  @Input() locationModelEvent: Observable<{ [key: string]: any }>;
  @Input() requiredObjects: { [key: string]: boolean };
  @Input() parentChangeDetectorRef: ChangeDetectorRef;
  @Input() set newPoint(point: ILatLng) {
    if (point) {
      this.setMarkerPosition(point);
    }
  }

  @Output() locationChanged = new EventEmitter<string>();
  @Output() markerChanged = new EventEmitter<ILatLng>();
  @Output() featureChanged = new EventEmitter<{
    featureId: number;
    field: string;
  }>();
  @Output() buildFeatureChanged = new EventEmitter<any>();

  @Select(AppState.getNetworkStatus) readonly networkStatus$: Observable<NetworkStatus>;
  @ViewSelectSnapshot(AppState.getIsMobilePlatform) readonly isMobilePlatform: boolean;

  readonly mapLocationFeatures = {
    [LOCATION_MAPPING.freestyleTerrain.name]: 'Terrain Park Feature',
    [LOCATION_MAPPING.bikingHiking.name]: 'Trail Feature'
  };
  private readonly mapLocationsWithFeatures = [
    LOCATION_MAPPING.freestyleTerrain.name,
    LOCATION_MAPPING.bikingHiking.name
  ];
  private readonly mapFeatureIdNames = {
    [LOCATION_MAPPING.freestyleTerrain.name]: 'park_feature_id',
    [LOCATION_MAPPING.bikingHiking.name]: 'trail_feature_id'
  };
  private readonly mapBuildTypes = {
    [LOCATION_MAPPING.freestyleTerrain.name]: FEATURE_BUILD_TYPE.park,
    [LOCATION_MAPPING.bikingHiking.name]: FEATURE_BUILD_TYPE.trail
  };

  locations: string[];
  recentBuildFeatures = {};
  model: { [key: string]: any } = {
    selectedLocation: '',
    selectedSubLocation: null,
    buildFeature: null,
    feature: null,
    selectedPosition: null,
    selectedMarker: '',
    lift_id: null,
    ski_run_id: null,
    trail_id: null,
    park_id: null,
    park_feature_id: null,
    trail_feature_id: null
  };
  private trailsData = [];
  private parksData = [];
  locationMapping = {
    [LOCATION_MAPPING.liftIncident.name]: {
      title: LOCATION_MAPPING.liftIncident.title,
      field: LOCATION_MAPPING.liftIncident.field,
      aliasField: LOCATION_MAPPING.liftIncident.aliasField,
      data: () => this.currentUser?.incident_objects?.lifts || []
    },
    [LOCATION_MAPPING.markedSkiRun.name]: {
      title: LOCATION_MAPPING.markedSkiRun.title,
      field: LOCATION_MAPPING.markedSkiRun.field,
      aliasField: LOCATION_MAPPING.markedSkiRun.aliasField,
      data: () => this.currentUser?.incident_objects?.skiruns || []
    },
    [LOCATION_MAPPING.bikingHiking.name]: {
      title: LOCATION_MAPPING.bikingHiking.title,
      field: LOCATION_MAPPING.bikingHiking.field,
      aliasField: LOCATION_MAPPING.bikingHiking.aliasField,
      data: () => this.trailsData || []
    },
    [LOCATION_MAPPING.freestyleTerrain.name]: {
      title: LOCATION_MAPPING.freestyleTerrain.title,
      field: LOCATION_MAPPING.freestyleTerrain.field,
      aliasField: LOCATION_MAPPING.freestyleTerrain.aliasField,
      data: () => this.parksData || []
    }
  };
  buildFeatures = [];
  loadingParkFeatures = false;
  restoringModel = false;
  dataNeedToLoadAsNetworkIsBack: any;

  sortFn = (list) => {
    try {
      if (_.isEmpty(list)) {
        return list;
      }
      list = list.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    } catch {}
    return list;
  };
  subscription: Subscription;

  get shouldRenderDDLFeatures() {
    return (
      this.mapLocationsWithFeatures.includes(this.model.selectedLocation) &&
      this.model.selectedSubLocation !== null &&
      this.locationMapping[this.model.selectedLocation]
        .data()
        ?.some((c) => c.id === this.model.selectedSubLocation && c.has_feature)
    );
  }

  constructor(
    public globalService: GlobalService,
    public cdr: ChangeDetectorRef,
    public incidentProvider: IncidentReportProviderService,
    private parkFeaturesService: ParkFeaturesService,
    private geoData: GeoDataService,
    private store: Store
  ) {}

  ngOnInit() {
    this.locations = INCIDENT_LOCATION;
  }

  ngAfterViewInit() {
    if (this.currentUser) {
      if (this.currentUser.incident_objects) {
        try {
          this.currentUser.incident_objects.lifts = this.sortFn(this.currentUser.incident_objects.lifts);
        } catch (error) {}
        this.currentUser.incident_objects.skiruns = this.sortFn(this.currentUser.incident_objects.skiruns);
      }

      if (_.isEmpty(this.currentUser.parks)) {
        delete this.locationMapping[LOCATION_MAPPING.freestyleTerrain.name];
      } else {
        this.parksData = this.sortFn(this.currentUser.parks);
      }

      if (this.currentUser.trails?.length) {
        this.trailsData = this.sortFn(this.currentUser.trails);
      } else {
        delete this.locationMapping[LOCATION_MAPPING.bikingHiking.name];
      }
    }
    if (this.locationModelEvent) {
      this.locationModelEvent
        .pipe(
          take(1),
          tap((locationModel) => this.initLocationModel(locationModel))
        )
        .subscribe();
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }

  async presentModal() {
    this.mapObject.isShow = true;
    this.mapObject.data = {
      selectedPosition: this.model.selectedPosition,
      locationModel: { ...this.model },
      buildFeatures: [...this.buildFeatures],
      customFeatures: Object.keys(this.locationMapping)
    };
  }

  locationChange() {
    this.locationChanged.emit(this.model.selectedLocation);
    // reset all child fields
    this.resetAllIDs();
    // reset feature to reset previous location points
    this.model.feature = null;
    this.model.selectedSubLocation = null;
    this.resetModelBuildFeatureId();
    this.cdr.markForCheck();
  }

  subLocationChange() {
    if (Object.keys(this.locationMapping).includes(this.model.selectedLocation) && this.model.selectedSubLocation) {
      const obs = this.willCheckRecentFeatures() ? this.getRecentBuildFeatures() : this.getGeoData();
      obs.subscribe();
      if (this.model.selectedLocation === LOCATION_MAPPING.bikingHiking.name) {
        this.getGeoData().subscribe();
      }
    }
    const field = this.locationMapping[this.model.selectedLocation].field;
    const featureId = this.model.selectedSubLocation;
    this.featureChanged.emit({ featureId, field });
    const resetCoords = this.mapLocationsWithFeatures.includes(this.model.selectedLocation);
    if (!featureId || resetCoords) {
      this.resetModelBuildFeatureId();
    }

    this.cdr.markForCheck();
  }

  buildFeatureChange(selectedPosition: ILatLng = null, selectedMarker: string = null) {
    if (!this.model.buildFeature) {
      this.resetModelBuildFeatureId(selectedPosition, selectedMarker);
    } else {
      this.setBuildFeature(selectedPosition, selectedMarker);
    }
    this.cdr.markForCheck();
  }

  private initLocationModel(locationModel) {
    // this.restoringModel = true;
    this.model = { ...locationModel };
    // reset "selectedLocation" to empty
    // to prevent error "ExpressionChangedAfterItHasBeenCheckedError" from Angular
    if (!this.model.selectedLocation) {
      this.model.selectedLocation = '';
    }
    const loc = this.model.selectedLocation;
    const locationMapping = this.locationMapping[loc];
    const featureIdName = this.mapFeatureIdNames[loc];
    if (loc && locationMapping) {
      const featureId = this.model[featureIdName];
      const hasIncludeLoc: boolean = this.mapLocationsWithFeatures.includes(loc);
      this.model.selectedSubLocation = this.model[locationMapping.field];
      if (hasIncludeLoc) {
        this.getRecentBuildFeatures()
          .pipe(
            tap(() => {
              if (featureId) {
                this.model.buildFeature = this.buildFeatures?.find((p) => p.id === featureId);
                this.buildFeatureChange(locationModel.selectedPosition, locationModel.selectedMarker);
              }
            })
          )
          .subscribe();
        if (featureId) {
          this.buildFeatureChanged.emit({
            id: featureId,
            name: featureIdName
          });
        }
      }
      if (!hasIncludeLoc || !featureId) {
        this.getGeoData().subscribe(() => {
          this.buildFeatureChange(locationModel.selectedPosition, locationModel.selectedMarker);
        });
      }
    }
    this.locationChanged.emit(this.model.selectedLocation);
    this.markerChanged.emit(this.model.selectedPosition);
  }

  private setMarkerPosition(data: ILatLng) {
    const position = data as ILatLng;
    this.setBuildFeatureByPosition(position);
    this.model.selectedPosition = { ...position };
    this.model.selectedMarker = `${position.lat} ${position.lng}`;
    this.markerChanged.emit(position);
    if (this.parentChangeDetectorRef) this.parentChangeDetectorRef.detectChanges();
  }

  private willCheckRecentFeatures() {
    return this.mapLocationsWithFeatures.includes(this.model.selectedLocation);
  }

  private setBuildFeatureByPosition(position: ILatLng) {
    if (this.willCheckRecentFeatures()) {
      this.buildFeatures.forEach((buildFeature) => {
        if (buildFeature.location[1] === position.lat && buildFeature.location[0] === position.lng) {
          this.model.buildFeature = { ...buildFeature };
          this.resetModelBuildFeatureId();
          this.setModelBuildFeatureId();
        }
      });
    }
  }

  private setModelBuildFeatureId() {
    const buildFeatureId = this.model.buildFeature?.id;
    const name = this.mapFeatureIdNames[this.model.selectedLocation];
    this.model[name] = this.model.buildFeature?.id;
    this.buildFeatureChanged.emit({ id: buildFeatureId, name: name });
  }

  private resetModelBuildFeatureId(selectedPosition: ILatLng = null, selectedMarker: string = null) {
    Object.keys(this.mapFeatureIdNames).forEach((k) => {
      const _name = this.mapFeatureIdNames[k];
      this.model[_name] = null;
      this.buildFeatureChanged.emit({ id: null, name: _name });
    });
    this.resetCoordinates(selectedPosition, selectedMarker);
  }

  private getGeoData() {
    return this.geoData.getGeoData(this.model.selectedSubLocation, this.currentUser.token).pipe(
      take(1),
      tap((res) => (this.model.feature = res.feature)),
      catchError(() => {
        this.dataNeedToLoadAsNetworkIsBack = () => this.getGeoData();
        return of(null);
      })
    );
  }

  private getRecentBuildFeatures() {
    const type = this.model.selectedSubLocation;
    if (!type) return of(null);

    this.buildFeatures = _.cloneDeep(this.recentBuildFeatures[type]) || null;
    this.model.buildFeature = null;
    if (this.buildFeatures) {
      return of(null);
    }
    this.loadingParkFeatures = true;
    return this.parkFeaturesService
      .getRecentBuildFeatures(
        this.currentUser.token,
        this.model.selectedSubLocation,
        this.mapBuildTypes[this.model.selectedLocation]
      )
      .pipe(
        take(1),
        tap((res) => {
          this.buildFeatures = _.cloneDeep(res.data);
          this.recentBuildFeatures[type] = res.data;
        }),
        tap(() => (this.loadingParkFeatures = false)),
        catchError(() => {
          this.dataNeedToLoadAsNetworkIsBack = () => this.getRecentBuildFeatures();
          return of(null);
        })
      );
  }

  private setBuildFeature(selectedPosition: ILatLng = null, selectedMarker: string = null) {
    this.resetModelBuildFeatureId(selectedPosition, selectedMarker);
    const [lng, lat] = this.model.buildFeature.location;
    const position = { lat, lng };
    this.model.selectedPosition = position;
    this.model.selectedMarker = `${position.lat} ${position.lng}`;
    this.markerChanged.emit(position);
    this.setModelBuildFeatureId();
  }

  private resetCoordinates(selectedPosition: ILatLng = null, selectedMarker: string = null) {
    this.model.selectedMarker = selectedMarker;
    this.model.selectedPosition = _.isEmpty(selectedPosition)
      ? selectedPosition
      : Object.values(selectedPosition).some((v) => _.isNil(v))
      ? null
      : selectedPosition;
    const position = selectedPosition === null ? { lat: null, lng: null } : _.clone(selectedPosition);
    this.markerChanged.emit(position);
  }

  private resetAllIDs() {
    if (this.restoringModel) {
      this.restoringModel = false;
      return;
    }

    Object.values(this.locationMapping).forEach((settings) => {
      const [field, featureId] = [settings.field, null];
      this.model[field] = featureId;
      this.featureChanged.emit({ featureId, field });
    });
    this.resetModelBuildFeatureId();
  }
}
