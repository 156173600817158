/**
 * * Inspired by https://github.com/RenovoSolutions/TypeScript-Angular-Utilities/tree/master/source/services/timezone
 */
import { ITimezone, ITimezones } from '@ea-models-v4';
import _ from 'lodash';

export class Timezones implements ITimezones {
  AST: ITimezone = new Timezone({
    offset: '-04:00',
    display: 'AST',
    momentName: 'Canada/Atlantic',
    offsetMinutes: -240,
    eaTimezone: '-4'
  });
  EST: ITimezone = new Timezone({
    offset: '-05:00',
    display: 'EST',
    momentName: 'US/Eastern',
    offsetMinutes: -300,
    eaTimezone: '-5'
  });
  CST: ITimezone = new Timezone({
    offset: '-06:00',
    display: 'CST',
    momentName: 'US/Central',
    offsetMinutes: -360,
    eaTimezone: '-6'
  });
  MST: ITimezone = new Timezone({
    offset: '-07:00',
    display: 'MST',
    momentName: 'US/Mountain',
    offsetMinutes: -420,
    eaTimezone: '-7'
  });
  PST: ITimezone = new Timezone({
    offset: '-08:00',
    display: 'PST',
    momentName: 'US/Pacific',
    offsetMinutes: -480,
    eaTimezone: '-8'
  });
  AKST: ITimezone = new Timezone({
    offset: '-09:00',
    display: 'AKST',
    momentName: 'US/Alaska',
    offsetMinutes: -540,
    eaTimezone: '-9'
  });
  HAST: ITimezone = new Timezone({
    offset: '-10:00',
    display: 'HAST',
    momentName: 'US/Hawaii',
    offsetMinutes: -600,
    eaTimezone: '-10'
  });

  items: ITimezone[];

  constructor() {
    this.items = [this.AST, this.EST, this.CST, this.MST, this.PST, this.AKST, this.HAST];
  }

  get(offsetOrMomentName: string): ITimezone {
    return _.find(this.items, (item: ITimezone): boolean => {
      return (
        item.offset === offsetOrMomentName ||
        item.momentName === offsetOrMomentName ||
        item.eaTimezone === offsetOrMomentName
      );
    });
  }

  all(): ITimezone[] {
    return this.items;
  }
}

class Timezone implements ITimezone {
  offset: string;
  display: string;
  momentName: string;
  offsetMinutes: number;
  eaTimezone: string;

  constructor(data: ITimezone) {
    this.offset = data.offset;
    this.display = data.display;
    this.momentName = data.momentName;
    this.offsetMinutes = data.offsetMinutes;
    this.eaTimezone = data.eaTimezone;
  }
}

export let timezones: ITimezones = new Timezones();
