<ion-content scrollY="true" class="img-gallery-preview">
  <!--<ion-icon class="close-button" name="close" (click)="close()"></ion-icon>-->
  <ion-grid class="ion-no-padding">
    <ion-row>
      <ion-col class="ion-no-padding img-content" size-xs="12">
        <img
          [src]="attachment?.object_attachment.file"
          class="image-gallery-preview-img"
          alt=""
        />
      </ion-col>
    </ion-row>
    <ion-row [hidden]="hideNote">
      <ion-col class="ion-no-padding" size-xs="12">
        <ion-input
          [(ngModel)]="attachment?.object_attachment.note"
          placeholder="Enter description"
          debounce="700"
          class="standard-input"
          (ionChange)="noteChanged()"
          autocapitalize="sentences"
        ></ion-input>
      </ion-col>
    </ion-row>
    <ion-row class="btn-container">
      <ion-col class="ion-no-padding" size-xs="6" [hidden]="hideRemove">
        <ion-button expand="full" color="danger" (click)="confirmRemove()"
          >Remove</ion-button
        >
      </ion-col>
      <ion-col class="ion-no-padding" size-xs="6">
        <ion-button expand="full" color="warning" (click)="close()"
          >Close</ion-button
        >
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
