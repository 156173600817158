import { map, delay } from 'rxjs/operators';
import * as _ from 'lodash';
import { Observable, from, forkJoin } from 'rxjs';
import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';

import { SafetyMeeting, FormalTraining, User } from '@ea-models';
import { StorageService } from './storage.service';
import { PendingReportType, PendingReportKey as ReportKey } from '@ea-models/misc';

@Injectable({
  providedIn: 'root'
})
export class PendingLocalReportsService {
  private readonly usersKey = 'RecentUsers';

  reportKeys: Array<any> = [
    ReportKey.incidentReports.value,
    ReportKey.reports.value,
    ReportKey.safetyMeetings.value,
    ReportKey.formalTrainings.value,
    ReportKey.parkBuilds.value
  ];

  observables: Array<Observable<any>> = [];

  constructor(public globalService: GlobalService, public storageService: StorageService) {}

  private async saveAndReturn(PendingReportKey: PendingReportType, data: any) {
    const org: string = this.globalService._currentUser?.org;
    const username: string = this.globalService._currentUser?.username;

    let savedUsers: any = await this.storageService.getObject(this.usersKey);

    savedUsers.forEach((user: User) => {
      if (user.org === org && user.username === username) {
        user[PendingReportKey] = data;
      }
    });
    await this.storageService.setObject(this.usersKey, savedUsers);
    this.globalService._currentUser[PendingReportKey] = data;
    return data;
  }

  async saveData(PendingReportKey: PendingReportType, data: any) {
    return await this.saveAndReturn(PendingReportKey, data);
  }

  async readData(PendingReportKey: PendingReportType) {
    const org: string = this.globalService._currentUser?.org;
    const username: string = this.globalService._currentUser.username;

    let users: Array<User> = await this.storageService.getObject(this.usersKey);
    let user: any = users.find((user: User) => user.org === org && user.username === username) || {};
    return user[PendingReportKey] || [];
  }

  async loadAllPendingReports() {
    const keys: Array<PendingReportType> = [ReportKey.safetyMeetings.value, ReportKey.formalTrainings.value];
    from(keys)
      .pipe(
        map((PRK: PendingReportType) => {
          this.readData(PRK).then((data) => (this.globalService._currentUser[PRK] = data || []));
        })
      )
      .subscribe();
  }

  async deleteItem(
    PendingReportKey: PendingReportType,
    itemList: Array<SafetyMeeting | FormalTraining>,
    index: number
  ) {
    if (itemList.length > index) {
      itemList.splice(index, 1);
    }
    return await this.saveAndReturn(PendingReportKey, itemList);
  }

  getPendingReportsCount(): number {
    if (!this.globalService._currentUser) return 0;
    let reportsCnt = 0;
    this.reportKeys.forEach((key) => {
      reportsCnt += (this.globalService._currentUser[key] || []).length;
    });

    if (this.globalService._currentUser.pendingObjects) {
      const pendingObjects = this.globalService._currentUser.pendingObjects;
      pendingObjects.forEach(
        (d) => (reportsCnt += (d.add || d.inspect) && (d.add || d.inspect).data ? (d.add || d.inspect).data.length : 0)
      );
    }

    return reportsCnt;
  }
}
