import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { from } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { User } from '@ea-models';
import { RECENT_USERS_KEY } from './services.constants';

export interface LocalParkBuild {
  park: any;
  features: any;
  buildId?: any;
  date?: any;
  type?: any;
}

export const LOCAL_PARK_BUILDS = 'pendingParkBuilds';

@Injectable({
  providedIn: 'root'
})
export class LocalParkBuildService {
  private readonly parkBuildListName = LOCAL_PARK_BUILDS;
  private org: string;
  private username: string;

  constructor(private storage: StorageService) {}

  public setUpKey(org, username) {
    [this.org, this.username] = [org, username];
  }

  public remove(index) {
    return this.saveOrRemove(null, index, 'remove');
  }

  public save(data, index = null) {
    return this.saveOrRemove(data, index, 'save');
  }

  private saveOrRemove(data, index: number = null, op: 'save' | 'remove') {
    let savedUsers = [];
    const opMapping = {
      save: (user) => {
        if (!user[this.parkBuildListName]) user[this.parkBuildListName] = [];
        if (index !== null) {
          user[this.parkBuildListName][index] = data;
        } else {
          user[this.parkBuildListName].push(data);
        }
      },
      remove: (user) => {
        if (index !== null && user[this.parkBuildListName].length > 0) {
          user[this.parkBuildListName].splice(index, 1);
        }
      }
    };
    return from(this.storage.getObject(RECENT_USERS_KEY)).pipe(
      take(1),
      tap((users) => (savedUsers = users)),
      map((users) => users.find((obj) => obj.org === this.org && obj.username === this.username)),
      tap((user: User) => {
        opMapping[op](user);
        this.saveUsers(savedUsers);
      })
    );
  }

  private saveUsers(users) {
    from(this.storage.setObject(RECENT_USERS_KEY, users)).pipe(take(1)).subscribe();
  }
}
