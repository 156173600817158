import { NgModule, ModuleWithProviders } from '@angular/core';
import { LiftStop } from './liftStop';
import { LiftService } from './lift.service';

const providers = [LiftStop, LiftService];

@NgModule({})
export class LiftModule {
  static forRoot(): ModuleWithProviders<LiftModule> {
    return {
      ngModule: LiftModule,
      providers
    };
  }
}
