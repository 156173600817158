import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { BarcodeScanner } from '@capacitor-mlkit/barcode-scanning';
import { IonContent, Platform } from '@ionic/angular';
import { Store } from '@ngxs/store';
import _ from 'lodash';

import { LogRocketProvider } from './logrocket';
import { DLAbbrDesMap } from '@edgeauditor/constants/incident-report.constants';
import { AppState } from '@edgeauditor/app/store/app/app.state';

@Injectable({
  providedIn: 'root'
})
export class IncidentReportProviderService {
  barcode: string = '';
  barcode$: Subject<string> = new Subject();
  scrollerContext: IonContent;
  private _scanActive = new BehaviorSubject<boolean>(false);
  scanActive$ = this._scanActive.asObservable();
  torchEnabled = false;
  isTorchAvailable = false;

  isCwsaa: boolean;
  isNsaa: boolean;

  constructor(
    private readonly logRocket: LogRocketProvider,
    private readonly platform: Platform,
    private readonly store: Store
  ) {}

  async openCameraScanning(isPDF417 = false) {
    // this plugin no work on browser
    const isMobilePlatform = this.store.select(AppState.getIsMobilePlatform);

    if (!isMobilePlatform) return;

    const checkPermissions = await BarcodeScanner.checkPermissions();

    if (checkPermissions.camera !== 'granted') {
      const permission = await BarcodeScanner.requestPermissions();

      if (permission.camera !== 'granted') {
        return;
      }
    }

    const _subscription = this.platform.backButton.subscribeWithPriority(9999, () => {
      document.addEventListener('backbutton', () => this.stopScanner(), false);
    });

    this.scanActive = true;

    document.querySelector('body').classList.add('scanner-active');

    // Add the `barcodeScanned` listener
    const listener = await BarcodeScanner.addListener('barcodeScanned', async (result) => {
      setTimeout(() => {
        document.removeEventListener('backbutton', () => {});
        if (_subscription) _subscription.unsubscribe();
      }, 500);
      if (!result.barcode) return;

      this.barcode$.next(result.barcode.rawValue);

      listener.remove();
    });

    // Start the barcode scanner
    try {
      await BarcodeScanner.startScan();
      this.isTorchAvailable = (await BarcodeScanner.isTorchAvailable()).available;
    } catch (err) {
      document.removeEventListener('backbutton', () => {});
      if (_subscription) _subscription.unsubscribe();
      this.logRocket.captureException(err, 'incident report - camera scaning');
    }
  }

  async stopScanner() {
    this.scanActive = false;
    //TODO: Check if this is needed
    // BarcodeScanner.showBackground();
    document.querySelector('body').classList.remove('scanner-active');
    await BarcodeScanner.removeAllListeners();
    await BarcodeScanner.stopScan();
  }

  async toggleFlash() {
    if (!this.isTorchAvailable) {
      return;
    }

    try {
      await BarcodeScanner.toggleTorch();
      this.torchEnabled = !this.torchEnabled;
    } catch (err) {}
  }

  parseScanningLicense(txt: string) {
    let lines = txt.split('\n');
    let abbrs = Object.keys(DLAbbrDesMap);
    let map = {};
    lines.forEach((line, i) => {
      let abbr;
      let content;
      if (i === 1) {
        abbr = 'DAQ';
        content = line.substring(line.indexOf(abbr) + 3);
      } else {
        abbr = line.substring(0, 3);
        content = line.substring(3).trim();
      }
      if (abbrs.includes(abbr)) {
        map = {
          ...map,
          [DLAbbrDesMap[abbr]]: ((map[abbr] ? map[abbr][DLAbbrDesMap[abbr]] : '') || '') + content
        };
      }
    });
    return map;
  }

  scrollToElement(eleDOM: any, addSpace: number = 0) {
    try {
      const devicePlatform = this.store.selectSnapshot(AppState.getDevicePlatform);

      if (devicePlatform !== 'ios') return;
      const colEle = (eleDOM?.target || eleDOM)?.closest('ion-col');
      const rootEle = (eleDOM?.target || eleDOM)?.closest('ion-row.row-mat-tab-group');
      if (!this.scrollerContext || !colEle) return;
      const rootTop = rootEle?.offsetTop || 0;
      const _addSpace = (this.isCwsaa ? 0 : 80 + addSpace) + (!this.isCwsaa ? rootTop / 2 : 0);
      this.scrollerContext.scrollToPoint(0, (colEle.offsetTop || 0) + _addSpace, 0);
    } catch {}
  }

  scrollToBottom(eleDOM: any, bottom: number) {
    try {
      const devicePlatform = this.store.selectSnapshot(AppState.getDevicePlatform);

      if (devicePlatform !== 'ios' || !this.scrollerContext) return;
      const colEle = (eleDOM?.target || eleDOM)?.closest('ion-col');
      if (!this.scrollerContext || !colEle) return;
      this.scrollerContext.scrollToPoint(0, colEle.offsetTop - (this.scrollerContext as any).el.offsetHeight + bottom);
    } catch {}
  }

  private get scanActive(): boolean {
    return this._scanActive.value;
  }

  private set scanActive(value: boolean) {
    this._scanActive.next(value);
  }
}
