<ng-container>
  <ion-row
           class="address-component"
           [class.required-add]="requiredObjects?.address">
    <ion-col size-xs="12">
      <ion-item lines="none">
        <ion-label>Address <span class="required">*</span></ion-label>
      </ion-item>
      <ng-container *ngIf="(networkStatus$ | async) as networkStatus">
        <ion-item lines="none" *ngIf="networkStatus.connected">
          <input
                 ngx-google-places-autocomplete
                 [(ngModel)]="model.address"
                 (ngModelChange)="onChange($event, 'address')"
                 (onAddressChange)="handleAddressChange($event)"
                 [class.in-valid]="!model.address" />
        </ion-item>
        <ion-item lines="none" *ngIf="!networkStatus.connected">
          <ion-input
                     debounce="400"
                     [(ngModel)]="model.address"
                     (ionChange)="onChangeEventValue($event, 'address')"
                     placeholder="Enter a location"
                     autocapitalize="sentences"
                     [class.in-valid]="!model.address"></ion-input>
        </ion-item>
      </ng-container>
    </ion-col>
  </ion-row>
  <ion-row class="address-component">
    <ion-col
             size-xs="12"
             size-sm="6"
             [class.required-add]="requiredObjects?.province">
      <ion-item lines="none">
        <ion-label>City <span class="required">*</span></ion-label>
      </ion-item>
      <ion-item lines="none">
        <ion-input
                   debounce="400"
                   [(ngModel)]="model.city"
                   (ionChange)="onChangeEventValue($event, 'city')"
                   autocapitalize="sentences"
                   [class.in-valid]="!model.city"></ion-input>
      </ion-item>
    </ion-col>
    <ion-col
             size-xs="12"
             size-sm="6"
             [class.required-add]="requiredObjects?.province">
      <ion-item lines="none">
        <ion-label *ngIf="model.country === 'US'">State <span class="required">*</span></ion-label>
        <ion-label *ngIf="model.country !== 'US'">Province <span class="required">*</span></ion-label>
      </ion-item>
      <div class="ion-padding-start ion-padding-end">
        <ion-input
                   debounce="400"
                   *ngIf="!states || !states[model.country]"
                   [(ngModel)]="model.province"
                   [class.in-valid]="!model.province"
                   (ionChange)="onChangeEventValue($event, 'province')"
                   autocapitalize="sentences"></ion-input>
        <ng-select
                   *ngIf="states && states[model.country]"
                   [items]="states[model.country]"
                   [dropdownPosition]="isMobilePlatform ? 'top' : 'auto'"
                   [selectOnTab]="true"
                   bindLabel="prov"
                   bindValue="code"
                   placeholder=""
                   class="standard-select"
                   [class.in-valid]="!model.province"
                   (focus)="incidentProvider.scrollToElement(refEle?.nativeElement)"
                   (change)="onSelectChange($event, 'province')"
                   [(ngModel)]="model.province">
        </ng-select>
      </div>
    </ion-col>
  </ion-row>
  <ion-row class="address-component">
    <ion-col
             size-xs="12"
             size-sm="6"
             [class.required-add]="requiredObjects?.country">
      <ion-item lines="none">
        <ion-label>Country <span class="required">*</span></ion-label>
      </ion-item>
      <div class="ion-padding-start ion-padding-end">
        <ng-select
                   [items]="countries"
                   [dropdownPosition]="isMobilePlatform ? 'top' : 'auto'"
                   [virtualScroll]="true"
                   [selectOnTab]="true"
                   bindLabel="name"
                   bindValue="code"
                   placeholder=""
                   class="standard-select"
                   [class.in-valid]="!model.country"
                   (focus)="incidentProvider.scrollToElement(refEle?.nativeElement, 50)"
                   (change)="onSelectChange($event, 'country')"
                   [(ngModel)]="model.country">
        </ng-select>
      </div>
    </ion-col>
    <ion-col
             size-xs="12"
             size-sm="6"
             [class.required-add]="requiredObjects?.postal_code">
      <ion-item lines="none">
        <ion-label>Zip/Postal Code <span class="required">*</span></ion-label>
      </ion-item>
      <ion-item lines="none">
        <ion-input
                   debounce="400"
                   [(ngModel)]="model.postal_code"
                   (ionChange)="onChangeEventValue($event, 'postal_code')"
                   autocapitalize="sentences"
                   [class.in-valid]="!model.postal_code"></ion-input>
      </ion-item>
    </ion-col>
  </ion-row>
</ng-container>