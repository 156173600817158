import _ from 'lodash';

export type CompliantStaffFilter = {
  filterId: number;
  name: string;
  compareValue: number;
  type: string;
  selected?: boolean;
};

export const unlimited = 1000000;

export const StaffSummaryFilters: {
  [CompliantFilter: string]: CompliantStaffFilter;
} = {
  non_compliant: {
    filterId: 1,
    name: 'Non-Compliant Staff',
    compareValue: -1,
    type: 'bool'
  },
  expires_30_days: {
    filterId: 2,
    name: 'Staff With Training Expiring Within 30 days',
    compareValue: 30,
    type: 'range'
  },
  expires_14_days: {
    filterId: 3,
    name: 'Staff With Training Expiring Within 14 days',
    compareValue: 14,
    type: 'range'
  },
  expires_7_days: {
    filterId: 4,
    name: 'Staff With Training Expiring Within 7 days',
    compareValue: 7,
    type: 'range'
  },
  all_staff: {
    filterId: 5,
    name: 'All Staff',
    compareValue: 0,
    type: 'bool',
    selected: true
  }
};

export const HealthAndSafetyFilters: {
  [CompliantFilter: string]: CompliantStaffFilter;
} = {
  ...(() => {
    let filters = _.cloneDeep(StaffSummaryFilters);
    filters.all_staff.name = 'All Staff from the Selected Department and Training Type';
    filters.all_staff.selected = false;
    return filters;
  })(),
  all_staff_all_departments: {
    filterId: 6,
    name: 'All Staff from Selected Department',
    compareValue: 0,
    type: 'bool',
    selected: true
  }
};
