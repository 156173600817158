import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import moment from 'moment';
import { DatetimeUtilsService } from '@ea-services/datetime-utils.service';
import { of } from 'rxjs';
import { take, tap, catchError, delay } from 'rxjs/operators';

@Component({
  selector: 'app-vitals',
  templateUrl: './vitals.component.html',
  styleUrls: ['./vitals.component.scss']
})
export class VitalsComponent implements OnInit {
  @ViewChild('vitalsScroller') vitalsScroller;
  @Input() vitalsData: { [key: string]: any }[];
  maxDate = moment().endOf('day').toDate();
  vitals: { [key: string]: any }[] = [{ isNew: true }]; //_destroy
  deletedVitals: { [key: string]: any }[] = [];
  isOpenTimePanel: boolean;
  contentScrollTop = 0;

  constructor(private modalController: ModalController, private dateTimeUtils: DatetimeUtilsService) {}

  ngOnInit() {
    if (this.vitalsData && this.vitalsData.length > 0) {
      this.vitals = this.convertTimeFields(this.vitalsData.filter((i) => !i._destroy));
      this.deletedVitals = this.vitalsData.filter((i) => i._destroy);
    }
  }

  focusOnInputTime() {
    this.isOpenTimePanel = true;
  }

  logScrollStart($event) {
    const top = $event.currentTarget.scrollTop;
    if (this.isOpenTimePanel && top <= 0) {
      $event.currentTarget.scrollTop = this.contentScrollTop;
      this.isOpenTimePanel = false;
      return;
    }
    this.isOpenTimePanel = false;
    this.contentScrollTop = $event.currentTarget.scrollTop;
  }

  removeVitals(idx: number) {
    const removeItem = this.vitals[idx];
    if (!removeItem.isNew) {
      removeItem._destroy = true;
      this.deletedVitals.push(removeItem);
    }
    this.vitals.splice(idx, 1);
    this.scroll();
  }

  addVitals() {
    this.vitals.push({ isNew: true });
    this.scroll(true);
  }

  close() {
    this.modalController.dismiss({ vitals: null, success: false });
  }

  save() {
    this.modalController.dismiss({
      vitals: [...this.vitals, ...this.deletedVitals],
      success: true
    });
  }

  scroll(toBottom?: boolean) {
    if (this.vitalsScroller && this.vitalsScroller.nativeElement) {
      of(null)
        .pipe(
          delay(100),
          take(1),
          tap(() => {
            this.vitalsScroller.nativeElement.scrollTop = !toBottom
              ? 0
              : this.vitalsScroller.nativeElement.scrollHeight || 0;
          }),
          catchError(() => of(null))
        )
        .subscribe();
    }
  }

  onTimeChange(value, model) {
    model.vitals_time = moment(value as unknown as Date).format();
  }

  convertTimeFields(vitalsData: { [key: string]: any }[]) {
    const data = [...vitalsData];
    data.forEach((row) => (row.vitals_time = this.dateTimeUtils.fromUTC(row.vitals_time)));
    return data;
  }
}
