<ion-grid class="patrollers-component">
  <ion-row>
    <ion-col size-xs="12" size-sm="4">
      <ion-item lines="none">
        <ion-label>Select Patroller</ion-label>
      </ion-item>
      <div class="ion-padding-start ion-padding-end">
        <ng-select
                   [items]="selects.patrollers"
                   [selectOnTab]="true"
                   class="standard-select"
                   bindLabel="first_name"
                   [dropdownPosition]="!isMobilePlatform ? 'bottom' : 'top'"
                   [searchFn]="customSearchFn"
                   [(ngModel)]="patroller.staff"
                   (focus)="incidentProvider.scrollToElement(ref.nativeElement)">
          <ng-template ng-label-tmp let-item="item">
            <span>{{ item.first_name }} {{ item.last_name }} ({{
              item.staff_number
              }})</span>
          </ng-template>
          <ng-template
                       ng-option-tmp
                       let-item="item"
                       let-search="searchTerm"
                       let-index="index">
            <span>{{ item.first_name }} {{ item.last_name }} ({{
              item.staff_number
              }})</span>
          </ng-template>
        </ng-select>
      </div>
    </ion-col>
    <ion-col size-xs="12" size-sm="4">
      <ion-item lines="none">
        <ion-label>Patroller's Role</ion-label>
      </ion-item>
      <div class="ion-padding-start ion-padding-end">
        <ng-select
                   [items]="selects.patroller_roles"
                   [selectOnTab]="true"
                   class="standard-select"
                   [dropdownPosition]="!isMobilePlatform ? 'bottom' : 'top'"
                   bindLabel="title"
                   [(ngModel)]="patroller.role"
                   (focus)="incidentProvider.scrollToElement(ref.nativeElement)">
        </ng-select>
      </div>
    </ion-col>
    <ion-col size-xs="12" size-sm="4">
      <ion-item lines="none">
        <ion-label></ion-label>
      </ion-item>
      <ion-item lines="none">
        <ion-button (click)="addPatroller()">Add Patroller</ion-button>
      </ion-item>
    </ion-col>
  </ion-row>
  <ion-row
           *ngIf="model.patrollers?.length > 0"
           class="patrollers-component__on-scene">
    <ion-col size-xs="12">
      <ion-item lines="none">
        <ion-label>Patrollers On Scene</ion-label>
      </ion-item>
      <ion-item lines="none">
        <ion-grid>
          <ion-row>
            <ion-col
                     size-xs="12"
                     size-sm="6"
                     *ngFor="let patroller of model.patrollers; let idx = index"
                     [hidden]="patroller._destroy">
              <ion-item lines="none" class="patrollers-component__item">
                <ion-label class="patrollers-component__label">
                  {{ patroller.staff?.first_name }}
                  {{ patroller.staff?.last_name }} ({{
                  patroller.staff?.staff_number
                  }})<br />
                  {{ patroller.role?.title }}
                </ion-label>
                <ion-button
                            slot="end"
                            color="danger"
                            (click)="removePatroller(idx)"
                            class="patrollers-component__icon">
                  <ion-icon slot="icon-only" name="close"></ion-icon>
                </ion-button>
              </ion-item>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>
    </ion-col>
  </ion-row>
</ion-grid>