import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { Wkt } from 'wicket';
import {
  GlobalService,
  ObjectsProviderService,
  ObjectsService,
  SpinnerService,
  RecentUsers,
  PopUpService
} from '@ea-services';
import { IInspection } from '@ea-models/object-module';
import { IAttachment, ILatLng } from '@ea-models';
import { take, tap, delay } from 'rxjs/operators';
import moment from 'moment';
import { ModalController, AlertController } from '@ionic/angular';
import { of } from 'rxjs';
import { OBJECTS_TEMPLATE_NAMES } from '../../../pages/pages.constants';
import { Geolocation } from '@capacitor/geolocation';

const API_KEY = 'AIzaSyC-O4VNt3VYCcvnlqIaAYXdLTPSu_Bq5MA';
const MAP_CANVAS = 'inspection_map_canvas';

@Component({
  selector: 'modal-inspect-object',
  templateUrl: './inspect-object.component.html',
  styleUrls: ['./inspect-object.component.scss']
})
export class InspectObjectModalComponent implements OnInit, OnDestroy {
  @Input() type: string;
  @Input() featureId: number;
  @Input() sign: any;
  @ViewChild('locationMapCanvas') locationMapCanvas: any;
  readonly OBJECTS_TEMPLATE_NAMES = OBJECTS_TEMPLATE_NAMES;
  currentUser: any;
  defaultPosition: ILatLng;
  map: any;
  location: any;
  signs: any;
  isShowQuestion = true;
  hasUnNameForImage: boolean = true;
  isIpad = false;
  model: IInspection = {
    feature_id: null,
    feature_name: null,
    inspection_condition_id: null,
    comments: null,
    object_attachments_attributes: []
  };
  get isReadyToSave() {
    return this.model.feature_id && this.model.comments && !this.hasUnNameForImage;
  }
  private currentObjMap: any;
  private selectedSignMarker: any;
  private isDestroyed: boolean;

  constructor(
    public globalService: GlobalService,
    public objProviderService: ObjectsProviderService,
    private recentUsersService: RecentUsers,
    private spinnerService: SpinnerService,
    private objService: ObjectsService,
    private popUpService: PopUpService,
    private alertController: AlertController,
    public modalController: ModalController
  ) {}

  ngOnInit() {
    this.currentUser = this.globalService._currentUser;
    this.defaultPosition = {
      lat: parseFloat(this.currentUser.location.lat as unknown as string),
      lng: parseFloat(this.currentUser.location.lng as unknown as string)
    };
    this.isShowQuestion = this.type !== 'Sign';
    this.location = {
      lat: this.globalService._currentUser.location.lat,
      lng: this.globalService._currentUser.location.lng
    };
  }

  async ngAfterViewInit() {
    try {
      const currentPosition = await Geolocation.getCurrentPosition();
      this.location = { lat: currentPosition.coords.latitude, lng: currentPosition.coords.longitude };
    } catch (err) {
      this.popUpService.popupConfirm('Location', 'Cannot get your current location.', this.alertController);
    }
  }

  ngOnDestroy(): void {
    this.isDestroyed = true;
  }

  ionViewDidEnter() {
    this.loadMap();
    if (this.type === 'Sign') {
      this.isShowQuestion = false;
      this.spinnerService.show('loading...');
      let delayTime = 0;
      this.objService
        .getSigns(this.currentUser.token)
        .pipe(
          take(1),
          tap((res: any) => {
            if (res) {
              this.signs = res.signs as any;
              if (!this.sign && this.featureId) {
                this.sign = this.signs.find((i) => i.id === this.featureId);
              }
              this.loadSigns(this.signs);
              if (this.signs && this.signs.length > 400) {
                delayTime = this.signs.length * 4;
              }
            }
          }),
          tap(() => {
            of(null)
              .pipe(
                delay(delayTime),
                take(1),
                tap(() => this.spinnerService.hide(true))
              )
              .subscribe();
          })
        )
        .subscribe();
    } else if (this.objProviderService.currentFeatures) {
      this.objProviderService.currentFeatures = this.sortByName(this.objProviderService.currentFeatures);
      this.model.feature_id = this.featureId;
      const feature = this.objProviderService.currentFeatures.find((i) => i.id === this.featureId);
      this.onChangeFeature(feature);
    }
  }

  onChangeFeature(feature) {
    if (!feature) return;
    this.model.feature_name = feature.name;
    if (feature.coordinates) {
      this.defineMapLayout(feature);
      return;
    }
    this.objService
      .getGEOData(feature.id, this.currentUser.token)
      .pipe(
        take(1),
        tap((res: any) => {
          const index = this.objProviderService.currentFeatures.findIndex((i) => i.id === feature.id);
          feature = { ...feature, ...res.feature };
          this.objProviderService.currentFeatures[index] = feature;
        }),
        tap(() => this.defineMapLayout(feature))
      )
      .subscribe();
  }

  onImageChange(imagesData: { currentImage: IAttachment; images: IAttachment[] }) {
    this.model.object_attachments_attributes.push({
      file: imagesData.images[0].object_attachment.file,
      geo_point: this.location,
      note: null
    });
  }

  changeFeatureName($event, item) {
    this.hasUnNameForImage = this.model.object_attachments_attributes.some((i) => !i.note || !i.note.trim());
  }

  removeAttachment(index) {
    this.model.object_attachments_attributes.splice(index, 1);
    this.hasUnNameForImage =
      !this.model.object_attachments_attributes.length ||
      this.model.object_attachments_attributes.some((i) => !i.note || !i.note.trim());
  }

  private loadMap() {
    const mapOptions = {
      zoom: 16,
      center: this.defaultPosition,

      mapTypeId: google.maps.MapTypeId.SATELLITE,
      zoomControl: true,
      mapTypeControl: true,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: true
    };

    this.map = new google.maps.Map(document.querySelector(`#${MAP_CANVAS}`), mapOptions);
    of(null)
      .pipe(
        delay(1000),
        take(1),
        tap(() => {
          if (this.locationMapCanvas) {
            this.locationMapCanvas.nativeElement.setAttribute(
              'style',
              `height: ${this.locationMapCanvas.nativeElement.clientHeight || 500}px`
            );
          }
        })
      )
      .subscribe();
  }

  private defineMapLayout(feature) {
    if (this.currentObjMap) this.currentObjMap.setMap(null);
    const wkt = new Wkt();
    wkt.read(feature.coordinates);
    const components = feature.coordinates.startsWith('POLYGON') ? wkt.components[0] : wkt.components;
    const points = components.map((c) => ({ lat: c.y, lng: c.x }));

    const latLngBounds = new google.maps.LatLngBounds();
    for (let i = 0; i < points.length; i++) {
      latLngBounds.extend(points[i]);
    }
    const defaultPosition = latLngBounds.getCenter();
    this.map.setCenter(defaultPosition);
    if (feature.coordinates.startsWith('POLYGON')) {
      const polygon = new google.maps.Polygon({
        paths: points,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35
      });
      polygon.setMap(this.map);
      this.currentObjMap = polygon;
    } else if (feature.coordinates.startsWith('LINESTRING')) {
      const polyline = new google.maps.Polyline({
        path: points,
        geodesic: true,
        strokeColor: '#FF0000',
        strokeOpacity: 1.0,
        strokeWeight: 2
      });
      polyline.setMap(this.map);
      this.currentObjMap = polyline;
    }
  }

  private loadSigns(p_previousSigns) {
    if (p_previousSigns) {
      const markers = [];
      const blueIcon = {
        url: 'assets/image/light-blue-marker.svg',
        scaledSize: new google.maps.Size(30, 45)
      };
      const yelowIcon = {
        url: 'assets/image/light-yellow-marker.svg',
        scaledSize: new google.maps.Size(30, 45)
      };
      p_previousSigns.forEach((sign, index) => {
        if (p_previousSigns.length > 300) {
          setTimeout(() => {
            if (this.isDestroyed) return;
            this.buildMarkerForPreviousSigns(markers, sign, blueIcon, yelowIcon);
          }, index * 20);
        } else {
          this.buildMarkerForPreviousSigns(markers, sign, blueIcon, yelowIcon);
        }
      });
    }
  }

  private buildMarkerForPreviousSigns(markers, sign, blueIcon, yelowIcon) {
    const isInspected = !!(this.sign && this.sign.id === sign.id);

    const latLng = new google.maps.LatLng(sign.lon, sign.lat);

    const marker = new google.maps.Marker({
      title: 'Incident Location',
      icon: isInspected ? yelowIcon : blueIcon,
      // image: sign.image,
      position: latLng,
      map: this.map
    });

    const onclick = () => {
      if (this.selectedSignMarker) {
        this.selectedSignMarker.setIcon(blueIcon);
      }
      this.selectedSignMarker = marker;
      // markers.forEach(mrk => {
      //   mrk.setIcon(blueIcon);
      // });
      marker.setIcon(yelowIcon);
      this.model.feature_id = sign.id;
      this.model.feature_name = sign.name;
      this.isShowQuestion = true;
    };

    // const safeContent = this._sanitizer.bypassSecurityTrustHtml(content);

    const infowindow = new google.maps.InfoWindow();

    google.maps.event.addListener(
      marker,
      'click',
      ((marker, safeContent, infowindow) => {
        return () => {
          const contentEle = this.buildWindoInfoContent(sign, onclick);
          infowindow.setContent(contentEle);
          infowindow.open(this.map, marker);
        };
      })(marker, null, infowindow)
    );
    markers.push(marker);
    if (isInspected) {
      of(null)
        .pipe(
          delay(1000),
          take(1),
          tap(() => {
            const contentEle = this.buildWindoInfoContent(sign, onclick);
            infowindow.setContent(contentEle);
            infowindow.open(this.map, marker);
            onclick();
          })
        )
        .subscribe();
    }
  }

  private buildWindoInfoContent(sign, onclick) {
    const contentEle = document.createElement('div');
    contentEle.setAttribute('style', 'position: relative;width: 300px;height: 170px;overflow: auto;display:flex;');
    const button = document.createElement('button');
    button.textContent = 'Inspect';
    button.setAttribute(
      'style',
      'min-width:100px;height:30px;background-color: #efefef !important; color: #000!important;outline: none;'
    );

    button.onclick = onclick;
    contentEle.innerHTML += `<style>
    .gm-style-iw-d{overflow: hidden !important; max-height: 161px !important;} 
    .gm-style-iw.gm-style-iw-c{padding: 0; max-height: 161px !important;}
    .gm-style-iw.gm-style-iw-c button {top: -10px !important; right: 14px !important;}
    .gm-style-iw.gm-style-iw-c button img{height: 30px !important; width: 30px !important;}
    </style>`;
    contentEle.innerHTML += sign.image
      ? '<img src="' + sign.image.url + '" style="height: 161px;max-width:50%;" />'
      : '';
    let content = '';
    content += `<strong>${sign.name}</strong><br><br>`;
    const contentMidEle = document.createElement('div');
    contentMidEle.setAttribute(
      'style',
      'margin: 15px 0 0 15px;position: relative;overflow: auto;padding: 0 27px 15px 0;'
    );
    contentMidEle.innerHTML = content;
    contentMidEle.appendChild(button);
    contentEle.appendChild(contentMidEle);
    return contentEle;
  }

  async cancel() {
    await this.modalController.dismiss();
  }

  async save() {
    if (!this.isReadyToSave) return;
    if (this.currentUser) {
      const data = {
        ...this.model,
        created_at: moment().format('YYYY-MM-DDTHH:mm:ss.sssZ'),
        type: this.type,
        local_id: new Date().getTime()
      };
      const _user = this.recentUsersService.get(this.currentUser.org, this.currentUser.username);
      if (_user) {
        const currentUser = this.globalService._currentUser;
        currentUser.pendingObjects = _user.pendingObjects || [];
        let filtered = currentUser.pendingObjects.find((i) => i['inspect']);
        if (!filtered) {
          filtered = { inspect: { data: [], name: 'Inspections' } };
          currentUser.pendingObjects.push(filtered);
        }
        filtered.inspect.data.push(data);
        _user.pendingObjects = currentUser.pendingObjects;
        this.recentUsersService.update(_user);
        await this.modalController.dismiss(data);
        this.popUpService.popupAlert(
          'Inspection has been saved',
          'Please go to Pending Local Reports to submit the inspection. Otherwise, proceed with your next inspection.',
          this.alertController
        );
        return;
      }
      await this.modalController.dismiss();
    }
  }

  private sortByName(data) {
    return data.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  }

  filterFeatures(term: string, item) {
    return item.name.toLocaleLowerCase().indexOf(term) > -1;
  }

  filterConditions(term: string, item) {
    return item.title.toLocaleLowerCase().indexOf(term) > -1;
  }
}
