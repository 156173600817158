import { Component } from '@angular/core';
import { AppState } from '@edgeauditor/app/store/app/app.state';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';

@Component({
  selector: 'app-preload-dynamic-component',
  templateUrl: './dynamic.component.html',
  styleUrls: []
})
export class PreloadDynamicComponent {
  @ViewSelectSnapshot(AppState.getIsMobilePlatform) readonly isMobilePlatform: boolean;

  constructor() {}
}
