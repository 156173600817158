import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_BASE_URL } from './constants';

@Injectable({
  providedIn: 'root'
})
export class ObjectsService {
  constructor(private httpClient: HttpClient) {}

  public getSigns(token: string): Observable<any> {
    return this.httpClient.get<any>(`${API_BASE_URL}/signs?token=${token}`);
  }

  public getGEOData(id: number, token: string): Observable<any> {
    return this.httpClient.get<any>(`${API_BASE_URL}/get_geo_data?id=${id}&token=${token}`);
  }

  public submitSigns(token: string, data: any): Observable<any> {
    return this.httpClient.post<any>(`${API_BASE_URL}/signs?token=${token}`, data);
  }

  public submitInjections(token: string, data: any): Observable<any> {
    return this.httpClient.post<any>(`${API_BASE_URL}/post_inspection?token=${token}`, data);
  }
}
