<ea-header title="Add an Item"></ea-header>
<ion-content scrollY="true">
  <ion-grid class="section-component__body" style="width: 100%">
    <ion-row>
      <ion-col size-xs="12" size-sm="6">Name</ion-col>
      <ion-col size-xs="12" size-sm="6">
        <ion-input
          class="standard-input"
          [(ngModel)]="feature.name"
        ></ion-input>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size-xs="12" size-sm="6">Feature Category</ion-col>
      <ion-col size-xs="12" size-sm="6">
        <ng-select
          [items]="event_feature_categories"
          [selectOnTab]="true"
          class="standard-select"
          bindValue="id"
          [searchFn]="customSearchFn"
          notFoundText="No item found"
          placeholder="Select Feature Category"
          bindLabel="name"
          [(ngModel)]="feature.category_id"
          [searchable]="true"
        >
        </ng-select>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size-xs="12">
        <ion-textarea
          rows="5"
          class="standard-input"
          [(ngModel)]="feature.notes"
        ></ion-textarea>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size-xs="12">
        <app-image-picker
          (imageSelected)="onImageChange($event)"
          [hideNote]="true"
          [restoreImagesEvent]="imgSubject.asObservable()"
          [isSupportResizePhoto]="true"
        >
        </app-image-picker>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size-xs="6">
        <ion-button expand="full" color="danger" (click)="close()"
          >Cancel</ion-button
        >
      </ion-col>
      <ion-col size-xs="6">
        <ion-button expand="full" color="success" (click)="save()"
          >Save</ion-button
        >
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
