import { NgModule } from '@angular/core';
import { HealthAndSafetyAPI } from './health-and-safety.api';
import { HealthAndSafety } from './health-and-safety';
import { SignaturePadModalModule } from '@ea-modals/signature-pad/signature-pad.module';
import { HealthSafetyStaffFilterModule } from '@ea-modals/health-safety-staff-filter/health-safety-staff-filter.module';
import { FileUploader } from '@ea-services/file-uploader';
import { HealthSafetyOfflineDataUtility } from './health-safety-offline-data-utility';
import { HealthSafetySaveToCloudUtility } from './health-safety-save-to-cloud-utility';

const providers = [
  HealthAndSafety,
  HealthAndSafetyAPI,
  HealthSafetyOfflineDataUtility,
  HealthSafetySaveToCloudUtility,
  FileUploader
];

@NgModule({
  imports: [SignaturePadModalModule, HealthSafetyStaffFilterModule],
  declarations: [],
  entryComponents: [],
  providers: providers
})
export class HealthAndSafetyModule {}
