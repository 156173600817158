import { Injectable } from '@angular/core';
import { LiftService } from './lift.service';
import { Lift, StopReason, ActiveLift, LiftLog, LiftLogEntriesAttribute } from '@ea-models';
import _ from 'lodash';
import moment from 'moment';
import { interval, Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { NavController, AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import { GlobalService } from '../global.service';

@Injectable({
  providedIn: 'root'
})
export class LiftStop {
  public lifts: Array<Lift> = [];
  public stopReasons: Array<StopReason> = [];

  public selectedLiftId: number;
  public selectedLift: ActiveLift;
  public loading = false;
  public liftLog: LiftLog;
  public timer$: Subscription;
  constructor(
    private liftService: LiftService,
    private router: Router,
    private globalService: GlobalService,
    private alertCtrl: AlertController
  ) {}

  getLifts() {
    this.loading = true;
    this.liftService.getLifts().subscribe((res) => {
      this.lifts = _.sortBy(res.lifts, [(lift: Lift) => (lift.name || '').toLowerCase()]);
      this.stopReasons = _.sortBy(res.stop_reasons, [
        (stopReason: StopReason) => (stopReason.reason || '').toLowerCase()
      ]);
      this.loading = false;
    });
  }

  selectLift(id: number) {
    this.selectedLiftId = id;
    const lift: Lift = _.find(this.lifts, { id: this.selectedLiftId }, 0);
    this.selectedLift = {
      lift,
      start_time: '',
      timer_state: 'off',
      current_time: ''
    };
  }

  initLiftTimer() {
    this.turnLiftTimerOn();
    this.initLiftLog();
  }

  private initLiftLog() {
    const { lift, start_time } = this.selectedLift;
    this.liftLog = {
      ...this.liftLog,
      lift_id: lift.id,
      start_time: start_time,
      start_date: moment().format('MMMM Do YYYY, H:mm:ss'),
      lift_log_entries_attributes: []
    };
  }

  turnLiftTimerOn() {
    this.selectedLift = {
      ...this.selectedLift,
      current_time: moment().format('H:mm:ss'),
      timer_state: 'on',
      start_time: moment().format('H:mm:ss')
    };

    this.timer$ = interval(1000).subscribe(() => {
      this.updateLiftTimer();
    });
  }

  updateLiftTimer() {
    this.selectedLift = {
      ...this.selectedLift,
      current_time: moment().format('H:mm:ss'),
      timer_state: 'on'
    };
  }

  turnLiftTimerOff() {
    this.timer$ && this.timer$.unsubscribe();
  }
  restartLiftTimer(stopReasonId: number) {
    const { current_time } = this.selectedLift; //stopped time after turnoff timer
    this.turnLiftTimerOn();
    const { start_time } = this.selectedLift; //new start time
    const liftLogEntry: LiftLogEntriesAttribute = {
      lift_stop_reason_id: stopReasonId,
      stop_time: current_time,
      restart_time: start_time
    };
    console.log('updateLogEvent', liftLogEntry);
    this.updateLogEvent(liftLogEntry);
  }
  updateLogEvent(logEvent: LiftLogEntriesAttribute) {
    this.liftLog.lift_log_entries_attributes.push(logEvent);
    console.log(this.liftLog);
  }
  submitLog() {
    let submit: Function = () => {
      this.turnLiftTimerOff();
      const { current_time } = this.selectedLift; //stopped time after turnoff timer
      this.liftLog = { ...this.liftLog, end_time: current_time };

      this.liftService
        .submitLiftLOg(this.liftLog)
        .pipe(
          take(1),
          tap((res) => {
            if (res.success) {
              this.clearLog(true);
              this.router.navigate(['/dashboard']);
            }
          })
        )
        .subscribe();
    };
    this.globalService.popupCustomConfirm(
      'Finish Lift Log',
      'Closing this will end the lift log for the shift, this should only be exited when the lift is no longer in use for the remainder of the day',
      'Save and Submit Log',
      'Cancel',
      this.alertCtrl,
      () => {
        //yes
        submit();
      },
      () => {
        //no
      }
    );
  }

  clearLog(force: boolean = false) {
    let clear: Function = () => {
      this.turnLiftTimerOff();
      this.liftLog = null;
      this.selectLift(null); //reset lift
      this.router.navigate(['/dashboard']);
    };
    if (!force) {
      this.globalService.popupCustomConfirm(
        'Leave Lift Log',
        'Closing this will end the lift log and all data will be lost',
        'Exit Report',
        'Cancel',
        this.alertCtrl,
        () => {
          //yes
          clear();
        },
        () => {
          //no
        }
      );
    } else {
      clear();
    }
  }
}
