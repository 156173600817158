<ion-grid *ngIf="object" class="panel"
          [class.done]="object.done"
          [attr.data-ci]="sectionIndex + '' + objectIndex">
  <ion-row class="header" (click)="handleExpandObject()" #header>
    <ion-col size-xs="12" size-sm="10">
      <span class="bold">{{ object.object_name }}</span>
    </ion-col>
    <ion-col size-xs="12" size-sm="2">
      <ng-container *ngIf="section && section.save_status">
        <ion-checkbox [(ngModel)]="object.is_enabled">
          {{ section?.save_label }}
        </ion-checkbox>
      </ng-container>
      <span class="arrow-up-down" [class.up]="!!object.expanded"></span>
    </ion-col>
  </ion-row>
  <ion-row class="question mark-done ion-hide"
           [class.ion-hide]="!object.expanded">
    <ion-col size-xs="12" size-sm="12">
      <ng-container #viewContainerRef></ng-container>
    </ion-col>
    <ion-col size-xs="12" size-sm="12">
      <ion-button expand="full" color="success" (click)="onDone()">Mark Done</ion-button>
    </ion-col>
  </ion-row>
</ion-grid>