import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { API_BASE_URL, API_BASE_URL_V3 } from './constants';
import { tap } from 'rxjs/operators';
import { Department } from '@ea-models';

@Injectable({
  providedIn: 'root'
})
export class TasksService {
  public featuresData: any;
  public areasData: any;
  public departmentsData: any;
  public typesData: any;
  public areasObject: any;
  public departmentsObject: any;
  public typesObject: any;

  constructor(private httpClient: HttpClient) {
    this.clearTempData();
  }

  public getTasks(token: string): Observable<any> {
    return this.httpClient.get<any>(`${API_BASE_URL_V3}/tasks?token=${token}`);
  }

  public getTaskDetail(id: number, token: string): Observable<any> {
    return this.httpClient.get<any>(`${API_BASE_URL_V3}/tasks/detail?id=${id}&token=${token}`);
  }

  public getDepartments(token: string, existedData?: any): Observable<any> {
    if (this.departmentsData && this.departmentsData.length) {
      return of(this.departmentsData);
    }
    if (existedData && existedData.length) {
      this.departmentsData = existedData;
      this.departmentsData = (this.departmentsData || []).sort((a, b) => {
        return a.name?.localeCompare(b.name);
      });
      this.departmentsObject = {};
      existedData.forEach((i) => {
        this.departmentsObject[i.id] = i.name;
      });
      return of(existedData);
    }
    return this.httpClient
      .get<{ departments: Department[]; success: boolean }>(`${API_BASE_URL}/departments?token=${token}`)
      .pipe(
        tap((res) => {
          if (!res || !res.departments) return res;
          this.departmentsData = res.departments;
          this.departmentsObject = {};
          this.departmentsData = (this.departmentsData || []).sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
          res.departments.forEach((i) => {
            this.departmentsObject[i.id] = i.name;
          });
        })
      );
  }

  public getFeatures(token: string): Observable<any> {
    if (this.featuresData && this.featuresData.length) {
      return of(this.featuresData);
    }
    return this.httpClient.get<any>(`${API_BASE_URL}/get_features?token=${token}`).pipe(
      tap((res) => {
        if (!res || !res.features) return res;
        this.featuresData = res.features; //.filter(f => !!OBJECTS_TEMPLATE_NAMES[f.type]);
      })
    );
  }

  public createTask(data: any, token: string): Observable<any> {
    return this.httpClient.post<any>(`${API_BASE_URL_V3}/tasks?token=${token}`, data);
  }

  public completeTask(data: any, token: string): Observable<any> {
    return this.httpClient.post<any>(`${API_BASE_URL_V3}/tasks/complete?token=${token}`, data);
  }

  public clearTempData() {
    this.departmentsData = [];
    this.departmentsObject = {};
    this.areasData = [];
    this.areasObject = {};
    this.typesData = [];
    this.typesObject = {};
  }
}
