import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ChangeDetectorRef,
  ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver,
  ChangeDetectionStrategy,
  OnDestroy,
  AfterViewInit
} from '@angular/core';
import { Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import _ from 'lodash';

import {
  IDynamicComponentModelWrapper,
  IReportSection,
  IReportSectionObject,
  ReportQuestionType
} from '@ea-models/reports';
import { User } from '@ea-models';
import { ReportItemBase } from '../report-item.base';
import { ReportService, GlobalService } from '@ea-services';

@Component({
  selector: 'app-grid-report',
  templateUrl: './grid-report.component.html',
  styleUrls: ['./grid-report.component.scss']
})
export class GridReportComponent implements OnInit, OnDestroy {
  @Input() section: IReportSection;
  @Input() userEvent: Observable<User>;
  @Input() restoreSectionEvent: Observable<any>;
  @Input() parentChangeDetectorRef: ChangeDetectorRef;
  @Input() isSupportResizePhoto: boolean;
  @Input() showWarningResizePhoto: boolean;

  @Output() questionChanged = new EventEmitter<IDynamicComponentModelWrapper>();
  @ViewChild('viewContainerRef', { read: ViewContainerRef, static: true })
  viewContainerRef: ViewContainerRef;
  minWidthForEachRow = 0;
  readonly ReportQuestionType = ReportQuestionType;
  readonly SelectTypes = {
    [ReportQuestionType.patroller]: true,
    [ReportQuestionType.lift_operator]: true,
    [ReportQuestionType.lift_maintenance_staff]: true,
    [ReportQuestionType.park_staff]: true,
    [ReportQuestionType.ski_instructor]: true
  };

  private currentDDLOpen: any;
  private subscription: Subscription;
  constructor(
    protected reportService: ReportService,
    protected ref: ChangeDetectorRef,
    private factoryResolver: ComponentFactoryResolver
  ) {}

  ngOnInit() {
    // this.cssClass = {};
    // this.cssClass[`cls-${this.section.questions.length}`] = true;
    if (this.section && this.section.objects && this.section.questions) {
      let minWidthForEachRow = 200;
      this.section.questions.forEach((q) => {
        if (q.type === ReportQuestionType.checkbox) minWidthForEachRow += 70;
        else minWidthForEachRow += 170;
      });
      if (minWidthForEachRow) this.minWidthForEachRow = minWidthForEachRow;
      this.subscription = new Subscription();
      this.section.objects.forEach((object, objIndex) => {
        object.models = [];
        this.addDynamicComponent(object, objIndex);
      });
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  scrollHandler($event) {
    if (this.currentDDLOpen) {
      try {
        this.currentDDLOpen.close();
      } catch {}
    }
  }

  private addDynamicComponent(object: IReportSectionObject, index: number) {
    const viewContainerRef = this.viewContainerRef;
    const factory = this.factoryResolver.resolveComponentFactory(GridReportItemComponent);
    const component = factory.create(viewContainerRef.parentInjector);
    component.instance.section = this.section;
    component.instance.parentChangeDetectorRef = this.parentChangeDetectorRef;
    component.instance.parentRef = this.ref;
    component.instance.restoreSectionEvent = this.restoreSectionEvent;
    component.instance.userEvent = this.userEvent;
    component.instance.isSupportResizePhoto = this.isSupportResizePhoto;
    component.instance.showWarningResizePhoto = this.showWarningResizePhoto;
    component.instance.object = object;
    component.instance.objectIndex = index;
    component.instance.sectionIndex = this.section['index'];
    component.instance.minWidthForEachRow = this.minWidthForEachRow;
    component.instance.isDDLOpen$ = new ReplaySubject<any>();
    this.subscription.add(
      component.instance.isDDLOpen$
        .pipe(
          tap((res) => {
            try {
              this.currentDDLOpen = null;
              if (res.is) this.currentDDLOpen = res.event;
            } catch {}
          })
        )
        .subscribe()
    );
    component.instance.initModel();
    of(null)
      .pipe(
        take(1),
        tap(() => viewContainerRef.insert(component.hostView))
      )
      .subscribe();
  }
}

@Component({
  selector: 'grid-report-item',
  templateUrl: './grid-report-item.component.html',
  styleUrls: ['./grid-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridReportItemComponent extends ReportItemBase implements AfterViewInit {
  @Input() minWidthForEachRow: number;
  @Input() isDDLOpen$: ReplaySubject<any>;

  constructor(
    protected factoryResolver: ComponentFactoryResolver,
    protected reportService: ReportService,
    protected globalService: GlobalService
  ) {
    super(factoryResolver, reportService);
  }

  ngAfterViewInit() {
    this.renderControls();
  }
}
