import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AbstractControl, FormGroup } from '@angular/forms';
import _ from 'lodash';

import {
  NemsisLocalReport,
  NemsisQuestionAnswer,
  NemsisReport,
  NemsisReportDetail,
  NemsisReportStatus,
  NemsisTemplate
} from '@ea-models-v4/nemsis';
import {
  CancelReport,
  CreateCloudNemsisReport,
  CreateLocalReport,
  DeleteLocalReport,
  DeleteLocalReportSuccess,
  GetCloudReport,
  GetCloudReports,
  GetTemplates,
  UpdateNemsisCloudReport,
  SelectTemplate,
  UpdateLocalReport,
  GetNemsisLocalReport,
  GetNemsisLocalReportSuccess,
  LeaveNemsisPage
} from './nemsis.action';
import { LoginComplete } from '@ea-store-v4/auth';
import { NEMSIS_STORAGE_KEY } from '@edgeauditor/app/constants';
import { ClearReports, GeneralError } from '@ea-store-v4/app/app.action';
import { NemsisService } from '@ea-services-v4';
import { StorageProvider } from '@ea-providers-v4';
import { NemsisHelper } from '@edgeauditor/app/helpers';

export interface NemsisStateModel {
  selectedTemplateId: number;
  selectedNemsisReport: NemsisReportDetail;
  nemsisTemplates: NemsisTemplate[];
  localReports: NemsisLocalReport[];
  selectedLocalReport: NemsisLocalReport;
  inProgressReports: NemsisReport[];
  declinedReports: NemsisReport[];
  answers: NemsisQuestionAnswer[];
}

export const NEMSIS_DEFAULT_STATE: NemsisStateModel = {
  nemsisTemplates: null,
  selectedNemsisReport: null,
  selectedTemplateId: null,
  localReports: null,
  selectedLocalReport: null,
  inProgressReports: [],
  declinedReports: [],
  answers: null
};

export const NEMSIS_REPORTS_STATE_TOKEN = new StateToken<NemsisStateModel>('nemsis');

@State({
  name: NEMSIS_REPORTS_STATE_TOKEN,
  defaults: NEMSIS_DEFAULT_STATE
})
@Injectable()
export class NemsisState {
  constructor(private nemsisService: NemsisService, private storageService: StorageProvider) {}

  //#region @Selector
  @Selector()
  static getTemplates(state: NemsisStateModel) {
    return state.nemsisTemplates || [];
  }

  @Selector()
  static getSelectedTemplateId(state: NemsisStateModel) {
    return state.selectedTemplateId;
  }

  @Selector()
  static getSelectedTemplate(state: NemsisStateModel) {
    return state.nemsisTemplates.find((t) => t.id === state.selectedTemplateId);
  }

  @Selector()
  static getLocalReports(state: NemsisStateModel) {
    return state.localReports && state.localReports;
  }

  @Selector()
  static getLocalReportsAmount(state: NemsisStateModel) {
    return (state.localReports && state.localReports.length) || 0;
  }

  @Selector()
  static getInProgressReports(state: NemsisStateModel) {
    return state.inProgressReports || [];
  }

  @Selector()
  static getDeclinedReports(state: NemsisStateModel) {
    return state.declinedReports || [];
  }

  @Selector()
  static getNemsisReportDetail(state: NemsisStateModel): NemsisReportDetail {
    return state.selectedNemsisReport;
  }

  @Selector()
  static getNemsisLocalReport(state: NemsisStateModel) {
    return state.selectedLocalReport;
  }

  @Selector()
  static getSelectedNemsisAnswers(s: NemsisStateModel) {
    return s.answers;
  }
  //#endregion

  //#region @Action
  @Action([LoginComplete, GetTemplates])
  networkStatusChange(ctx: StateContext<NemsisStateModel>) {
    if (navigator.onLine) {
      return this.nemsisService.getTemplates().pipe(
        tap((nemsisTemplates) => ctx.patchState({ nemsisTemplates })),
        catchError((err) => {
          const message = 'There was an error fetching NEMSIS templates.';
          ctx.dispatch(new GeneralError(message));
          const errMsg = err instanceof Error ? err.message : JSON.stringify(err);
          return throwError(errMsg);
        })
      );
    }
  }

  @Action(SelectTemplate)
  selectTemplate(ctx: StateContext<NemsisStateModel>, { id }: SelectTemplate) {
    const nemsisTemplates = ctx.getState().nemsisTemplates;
    const invalid = _.some(nemsisTemplates, (o) => o.id === id);
    if (invalid) {
      ctx.patchState({ selectedTemplateId: id });
    } else {
      const error = new Error('Template Not Found');
      throw error;
    }
  }

  @Action(CreateCloudNemsisReport)
  createCloudReport(ctx: StateContext<NemsisStateModel>, { data, closeReport }: CreateCloudNemsisReport) {
    const state = ctx.getState();
    const selectedTemplate = state.nemsisTemplates.find((i) => i.id === state.selectedTemplateId);
    const createReportRequest = NemsisHelper.transformFormToRequest(
      ctx.getState().selectedTemplateId,
      data,
      closeReport ? NemsisReportStatus.PendingApproval : NemsisReportStatus.Draft,
      selectedTemplate.template_sections
    );

    //TODO: BE to specify how to close a report
    return this.nemsisService.createReport(createReportRequest).pipe(
      tap(() => {
        ctx.patchState({ selectedTemplateId: null, selectedLocalReport: null });
        //If saving to cloud from a local report, delete the local report.
        if (data.reportId) {
          ctx.dispatch(new DeleteLocalReport(data.reportId as string));
        }
      }),
      catchError(() => ctx.dispatch(new GeneralError('There was an error submitting the NEMSIS report.')))
    );
  }

  @Action([LoginComplete, DeleteLocalReportSuccess])
  async getLocalReports(ctx: StateContext<NemsisStateModel>) {
    await this.resetLocalReports(ctx);
  }

  @Action(CreateLocalReport)
  async createLocalReport(ctx: StateContext<NemsisStateModel>, payload: CreateLocalReport) {
    const state = ctx.getState();

    //Pull local reports
    let localReports: NemsisLocalReport[] = await this.storageService.get(NEMSIS_STORAGE_KEY);

    const date = new Date();
    const linkedIncidentFields: string[] = [];
    _.forEach(payload.localReport.form.controls, (formGroup: FormGroup) => {
      const keys = Object.keys(formGroup.controls).filter((key) => formGroup.controls[key].disabled);
      linkedIncidentFields.push(...keys);
    });
    const localReport: NemsisLocalReport = {
      uuid: uuidv4(),
      templateId: state.selectedTemplateId,
      templateName: state.nemsisTemplates.find((t) => t.id === state.selectedTemplateId)?.name,
      timestamp: date.toISOString(),
      formGroup: payload.localReport.form.getRawValue(),
      incidentReport: payload.localReport.incidentReport,
      linkedIncidentFields
    };

    //Check if stored value is an array. If it is, add new report to array. Otherwise, initialize new Array.
    if (localReports instanceof Array) {
      localReports.push(localReport);
    } else {
      localReports = [localReport];
    }

    //Store local reports
    await this.storageService.set(NEMSIS_STORAGE_KEY, localReports);

    this.resetLocalReports(ctx);
    this.cancelEditReportHandler(ctx);
  }

  @Action(UpdateLocalReport)
  async updateLocalReport(ctx: StateContext<NemsisStateModel>, payload: UpdateLocalReport) {
    //Pull local reports
    let localReports: NemsisLocalReport[] = await this.storageService.get(NEMSIS_STORAGE_KEY);
    const selectedLocalReport = _.find(localReports, (r) => r.uuid === payload.localReport.reportId);
    const date = new Date();
    selectedLocalReport.formGroup = payload.localReport.form.getRawValue();
    selectedLocalReport.timestamp = date.toISOString();
    selectedLocalReport.incidentReport = payload.localReport.incidentReport;
    selectedLocalReport.linkedIncidentFields = payload.localReport.incidentReport
      ? this.linkedIncidentFields(payload.localReport.form.controls)
      : [];
    await this.storageService.set(NEMSIS_STORAGE_KEY, localReports);
    this.resetLocalReports(ctx);
    this.cancelEditReportHandler(ctx);
  }

  @Action([CancelReport])
  cancelEditReport(ctx: StateContext<NemsisStateModel>) {
    this.cancelEditReportHandler(ctx);
  }

  @Action(DeleteLocalReport)
  async deleteLocalReport(ctx: StateContext<NemsisStateModel>, { uuid }: DeleteLocalReport) {
    let localReports: NemsisLocalReport[] = await this.storageService.get(NEMSIS_STORAGE_KEY);
    const idx = localReports.findIndex((r) => r.uuid === uuid);
    localReports.splice(idx, 1);

    await this.storageService.set(NEMSIS_STORAGE_KEY, localReports);

    ctx.dispatch(new DeleteLocalReportSuccess());
  }

  @Action(GetCloudReports)
  getCloudReports({ getState, patchState, dispatch }: StateContext<NemsisStateModel>, { status }: GetCloudReports) {
    const { inProgressReports, declinedReports } = getState(),
      sortedReportsByDateDesc = (reports: NemsisReport[]) =>
        reports.sort((a, b) => (a.created_at < b.created_at ? 1 : a.created_at > b.created_at ? -1 : 0));

    return this.nemsisService.getReports(status).pipe(
      tap((reports) => {
        if (status === 'draft') {
          patchState({ inProgressReports: sortedReportsByDateDesc(_.concat(inProgressReports, reports)) });
        } else if (status === 'rejected') {
          patchState({ declinedReports: sortedReportsByDateDesc(_.concat(declinedReports, reports)) });
        }
      }),
      catchError((error) => {
        dispatch(new GeneralError('There was an error fetching the NEMSIS reports.'));
        const errorMsg = error instanceof Error ? error.message : JSON.stringify(error);
        return throwError(errorMsg);
      })
    );
  }

  @Action(GetCloudReport)
  getCloudReport(ctx: StateContext<NemsisStateModel>, { id }: GetCloudReport) {
    return this.nemsisService.getReport(id).pipe(
      tap((report) => {
        const answers = _.first(report.template_sections)?.answers || [];
        ctx.patchState({ selectedNemsisReport: report, answers });
      }),
      catchError(() => {
        let message = 'There was an error fetching the NEMSIS reports.';
        return ctx.dispatch(new GeneralError(message));
      })
    );
  }

  @Action(ClearReports)
  clearReports(ctx: StateContext<NemsisStateModel>) {
    ctx.patchState({ inProgressReports: [], declinedReports: [] });
  }

  @Action(UpdateNemsisCloudReport)
  saveIncompleteNemsisReportToCloud(ctx: StateContext<NemsisStateModel>, payload: UpdateNemsisCloudReport) {
    const selectedNemsisReport = ctx.getState().selectedNemsisReport;
    const reportId = selectedNemsisReport.id;
    return this.nemsisService.updateNemsisReport(reportId, payload.data);
  }

  @Action(GetNemsisLocalReport)
  getNemsisLocalReport({ getState, dispatch }: StateContext<NemsisStateModel>, payload: GetNemsisLocalReport) {
    const localReport = getState().localReports.find((i) => i.uuid === payload.uuid);
    if (localReport) {
      dispatch(new GetNemsisLocalReportSuccess(localReport));
    }
  }

  @Action(GetNemsisLocalReportSuccess)
  getNemsisLocalReportSuccess(ctx: StateContext<NemsisStateModel>, payload: GetNemsisLocalReportSuccess) {
    ctx.patchState({ selectedLocalReport: payload.report });
  }

  @Action(LeaveNemsisPage)
  leaveNemsisPage(ctx: StateContext<NemsisStateModel>) {
    ctx.patchState({ selectedNemsisReport: null, selectedLocalReport: null, selectedTemplateId: null });
  }
  //#endregion

  //#region @Helper
  private linkedIncidentFields(controls: { [key: string]: AbstractControl }) {
    const linkedIncidentFields: string[] = [];
    _.forEach(controls, (formGroup: FormGroup) => {
      const keys = Object.keys(formGroup.controls).filter((key) => formGroup.controls[key].disabled);
      linkedIncidentFields.push(...keys);
    });
    return linkedIncidentFields;
  }

  private async resetLocalReports(ctx: StateContext<NemsisStateModel>) {
    let localReports: NemsisLocalReport[] = await this.storageService.get(NEMSIS_STORAGE_KEY);

    //Return empty array
    if (!(localReports instanceof Array)) {
      localReports = [];
    }

    ctx.patchState({ localReports });
  }

  private cancelEditReportHandler(ctx: StateContext<NemsisStateModel>) {
    ctx.patchState({ selectedTemplateId: null, selectedLocalReport: null });
  }
  //#endregion
}
