import { Component, OnInit, ViewEncapsulation, Input, ViewChild, ElementRef } from '@angular/core';
import { NavParams, ModalController, AlertController } from '@ionic/angular';
import { ConfigService, ApiService, SpinnerService, GlobalService, ReportService } from '@ea-services';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { tap, take, catchError } from 'rxjs/operators';
import moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'modal-report-history',
  templateUrl: 'report-history.component.html',
  styleUrls: ['report-history.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReportHistoryModalComponent implements OnInit {
  @Input()
  historyData: any;

  @ViewChild('compHistoryData') compHistoryData: ElementRef;

  viewData: any = [];
  historyDetail: SafeHtml;
  maxDate = moment().endOf('day').toDate();

  readonly addtionalStyle = `
    <style>

      /*** Table Styles **/

      .table {
        background: white;
        border-radius:3px;
        border-collapse: collapse;
        height: 320px;
        margin: auto;
        padding:5px;
        width: 100%;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
      }
       
      th {
        color:#fff;;
        background:#586785;
        border-bottom:4px solid #9ea7af;
        border-right: 1px solid #343a45;
        font-size:23px;
        padding:18px;
        text-align:left;
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
        vertical-align:middle;
      }

      th:first-child {
        border-top-left-radius:3px;
      }
       
      th:last-child {
        border-top-right-radius:3px;
        border-right:none;
      }
        
      tr {
        border-top: 1px solid #C1C3D1;
        border-bottom-: 1px solid #C1C3D1;
        color:#3b3d4a;
        font-size:16px;
        font-weight:normal;
        text-shadow: 0 1px 1px rgba(256, 256, 256, 0.1);
      }
       
      tr:hover td {
        background:#4E5066;
        color:#FFFFFF;
        border-top: 1px solid #22262e;
        border-bottom: 1px solid #22262e;
      }
       
      tr:first-child {
        border-top:none;
      }

      tr:last-child {
        border-bottom:none;
      }
       
      tr:nth-child(odd) td {
        background:#EBEBEB;
      }
       
      tr:nth-child(odd):hover td {
        background:#4E5066;
      }

      tr:last-child td:first-child {
        border-bottom-left-radius:3px;
      }
       
      tr:last-child td:last-child {
        border-bottom-right-radius:3px;
      }
       
      td {
        background:#FFFFFF;
        padding:18px;
        text-align:left;
        vertical-align:middle;
      †  font-size:18px;
        text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
        border-right: 1px solid #C1C3D1;
      }

      td:last-child {
        border-right: 0px;
      }

      th.text-left {
        text-align: left;
      }

      th.text-center {
        text-align: center;
      }

      th.text-right {
        text-align: right;
      }

      td.text-left {
        text-align: left;
      }

      td.text-center {
        text-align: center;
      }

      td.text-right {
        text-align: right;
      }


      </style>
    `;
  filterTime: any;

  constructor(
    public configService: ConfigService,
    public apiService: ApiService,
    public globalService: GlobalService,
    public spinnerService: SpinnerService,
    public modalController: ModalController,
    public alertController: AlertController,
    private reportService: ReportService,
    public navParams: NavParams,
    public router: Router,
    private _sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    if (this.historyData) {
      this.spinnerService.show('loading...', 1000);
    }
  }

  ionViewDidEnter() {
    this.renderDataList(this.compHistoryData, this.historyData);
    // this.viewData = this.historyData;
  }

  ionViewWillLeave() {
    this.modalController.dismiss().catch(() => {});
  }

  async myDismiss() {
    await this.modalController.dismiss();
  }

  onChangeFilter(value) {
    if (this.filterTime === value) return;

    this.filterTime = value;
    if (!value) {
      this.renderDataList(this.compHistoryData, this.historyData);
    } else {
      value = moment(value).format('YYYY-MM-DD');
      this.getSubmissionsList(value);
    }
  }

  private getSubmissionsList(time) {
    this.spinnerService.show('searching...', 15000);
    const reportId = this.historyData[0].report_id;
    this.reportService
      .getSubmissionsList(this.globalService._currentUser.token, reportId, time)
      .pipe(
        take(1),
        tap((result) => {
          this.spinnerService.hide();
          this.renderDataList(this.compHistoryData, result?.submission_history);
        }),
        catchError(() => this.spinnerService.hide())
      )
      .subscribe();
  }

  getSubmissionData(submissionId) {
    if (!submissionId) return;
    this.spinnerService.show('loading...');
    this.reportService
      .getSubmissionData(this.globalService._currentUser.token, submissionId)
      .pipe(
        take(1),
        tap((result) => {
          this.spinnerService.hide();
          if (result && result.result && result.report_html) {
            this.historyDetail = this._sanitizer.bypassSecurityTrustHtml(this.addtionalStyle + result.report_html);
          }
        })
      )
      .subscribe();
  }

  private renderDataList(rootEle: any, historyData: any) {
    rootEle.el.innerHTML = '';
    if (!historyData || !historyData.length) {
      if (rootEle) {
        const itemEle = document.createElement('ion-item');
        itemEle.lines = 'none';
        const labelEle = document.createElement('ion-label');
        labelEle.textContent = `No results to display`;
        itemEle.appendChild(labelEle);
        rootEle.el.appendChild(itemEle);
      }
      return;
    }

    _.cloneDeep(historyData).forEach((item) => {
      if (item.created_at) {
        item.created_at = item.created_at.split('.')[0].replace('T', ' ');
        item.created_at = item.created_at.substr(0, item.created_at.lastIndexOf(':'));
      }
      if (rootEle) {
        const itemEle = document.createElement('ion-item');
        itemEle.setAttribute('style', 'cursor: pointer');
        itemEle.lines = 'full';
        itemEle.onclick = () => this.getSubmissionData(item.id);
        const labelEle = document.createElement('ion-label');
        labelEle.textContent = `Created At: ${item.created_at} - Status: ${item.status}`;
        itemEle.appendChild(labelEle);
        rootEle.el.appendChild(itemEle);
      }
    });
  }
}
