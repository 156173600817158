import { DispatchConsoleEvent } from '@ea-models';

export class GetDispatchEvents {
  static readonly type = '[Dispatch Console] Get Dispatch Events';
}

export class GetDispatchEventsSuccess {
  static readonly type = '[Dispatch Console] Get Dispatch Events Success';
  constructor(public dispatchEvents: DispatchConsoleEvent[]) {}
}

export class CreateDispatchEvent {
  static readonly type = '[Dispatch Console] Create Dispatch Event';
}

export class LockDispatchEvent {
  static readonly type = '[Dispatch Console] Lock Dispatch Event';
  constructor(public id: number) {}
}

export class UnlockDispatchEvent {
  static readonly type = '[Dispatch Console] Unlock Dispatch Event';
  constructor(public id: number) {}
}
