<ng-container>
  <ion-item class="signature-component__item" lines="none">
    <ion-label>Signature</ion-label>
    <ion-button *ngIf="showClear"
                class="signature-component__signature-clear-button"
                color="main"
                (click)="clearSignature()">Clear
    </ion-button>
  </ion-item>
  <ion-item class="signature-component__item" lines="none">
    <div class="signature-component__signature-pad"
         [class.invalid]="isRequired && !signature && !s3signature">
      <ng-container *ngIf="!isRefreshSignaturePad">
        <signature-pad #_signaturePad
                       [options]="signaturePadOptions"
                       (onEndEvent)="drawComplete()"
                       (onBeginEvent)="drawStart()">
        </signature-pad>
        <ng-container *ngIf="!!s3signature || !!signature">
          <img [src]="s3signature || signature"
               [hidden]="!s3FromServer"
               class="img-for-lgRK" />
        </ng-container>
      </ng-container>
    </div>
  </ion-item>
</ng-container>