import { NetworkStatus } from '@capacitor/network';
import { DeviceInfo } from '@capacitor/device';

const STATE_KEY = '[App State]';

const TYPE = (text: string) => STATE_KEY + ' ' + text;

export class NetworkStatusChange {
  static readonly type = TYPE('Network Status Change');
  constructor(public status: NetworkStatus) {}
}

export class SetDeviceInfo {
  static readonly type = TYPE('Setting Device Info');
  constructor(public deviceInfo: DeviceInfo) {}
}

export class GeneralError {
  static readonly type = TYPE('General Error');
  constructor(public errorMessage: string) {}
}

export class GetPendingAndRejectedTotals {
  static readonly type = TYPE('Get Pending and Rejected Totals');
}

export class GetPendingLocalReports {
  static readonly type = TYPE('Get Pending Local Reports');
}

export class ClearReports {
  static readonly type = TYPE('Clear Reports');
}

export class GetIncidentObjects {
  static readonly type = TYPE('Get Incident Objects');
}
