export const WEBSOCKET_CHANNELS = {
  DISPATCH: 'DispatchChannel'
};

export namespace WSActionCable {
  export interface Channel {
    unsubscribe(): void;
    perform(action: string, data: {}): void;
    send(data: Object): boolean;
  }

  export interface Subscriptions {
    create(channel: string | ChannelNameWithParams, obj: CreateMixin): Channel;
  }

  export interface Cable {
    subscriptions: Subscriptions;
    send(data: any): void;
    connect(): void;
    disconnect(): void;
    ensureActiveConnection(): void;
  }

  export interface CreateMixin {
    connected(): void;
    disconnected(): void;
    received(obj: Object): void;
    [key: string]: Function;
  }

  export interface ChannelNameWithParams {
    channel: string;
    [key: string]: any;
  }
}
