<ion-grid>
  <ion-row>
    <ion-col size="4">
      <ion-label class="bold">Name</ion-label>
      <input type="text" [(ngModel)]="filter.name" />
    </ion-col>
    <ion-col size="4">
      <ion-label class="bold">Phone #</ion-label>
      <input
        type="number"
        inputmode="numeric"
        [(ngModel)]="filter.phone"
        numbersOnly
        onKeyPress="if(this.value.length==16) return false;"
      />
    </ion-col>
    <ion-col size="4">
      <ion-label class="bold">Email</ion-label>
      <input type="email" [(ngModel)]="filter.email" />
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <ion-button
        [disabled]="filter.name == '' && filter.phone == '' && filter.email == ''"
        expand="full"
        color="dark"
        (click)="findCustomers()"
        >Search</ion-button
      >
    </ion-col>
  </ion-row>
</ion-grid>

<ion-grid class="customer-grid">
  <ion-row class="c-header">
    <ion-col size="4">
      <ion-label class="bold">Name</ion-label>
    </ion-col>
    <ion-col size="4">
      <ion-label class="bold">Phone #</ion-label>
    </ion-col>
    <ion-col size="4">
      <ion-label class="bold">Email</ion-label>
    </ion-col>
  </ion-row>
  <ion-row
    *ngFor="let customer of customers; index as i"
    (click)="showDetails(customer.id)"
    class="c-data"
  >
    <ion-col size="4">
      <ion-label>{{ customer.first_name }} {{ customer.last_name }}</ion-label>
    </ion-col>
    <ion-col size="4">
      <ion-label>{{ customer.phone }}</ion-label>
    </ion-col>
    <ion-col size="4">
      <ion-label>{{ customer.email }}</ion-label>
    </ion-col>
  </ion-row>
  <ion-row class="ion-text-center" *ngIf="customers.length <= 0">
    <ion-col>
      <ion-label>Find a customer to continue</ion-label>
    </ion-col>
  </ion-row>
</ion-grid>
