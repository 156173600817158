import { Directory, Filesystem, ReadFileResult, WriteFileResult } from '@capacitor/filesystem';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { IMAGE_FOLDER } from '@edgeauditor/app/app.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { CapacitorHttp } from '@capacitor/core';
import { Network } from '@capacitor/network';

@Component({
  selector: 'app-cached-image',
  templateUrl: './cached-image.component.html',
  styleUrls: ['./cached-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CachedImageComponent {
  @Input()
  set src(image: string) {
    const imageUrl = location.origin + '/' + image;
    const imageName = imageUrl.split('/').pop();
    let fileType = imageName.split('.').pop();
    if (fileType === 'svg') {
      fileType += '+xml';
    }
    const path = `${IMAGE_FOLDER}/${imageName}`;
    Filesystem.readFile({
      directory: Directory.Cache,
      path
    })
      .then((readFile) => {
        this.readAndProcessFile(readFile, fileType);
      })
      .catch(async (e) => {
        const networkStatus = await Network.getStatus();
        if (networkStatus.connected) {
          const file = await this.storeImage(imageUrl, path);
          Filesystem.readFile({ path: file.uri }).then((readFile) => {
            this.readAndProcessFile(readFile, fileType);
          });
        }
      });
  }

  _src: string | SafeResourceUrl = '';
  destroyed$ = new Subject<void>();

  constructor(private cdr: ChangeDetectorRef, private sanitizer: DomSanitizer) {}

  private transformUrl(data: string, fileType: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/${fileType};base64,${data}`);
  }

  private async storeImage(url: string, path: string): Promise<WriteFileResult> {
    const response = await CapacitorHttp.get({ url, responseType: 'blob' });
    const data: string = response.data;
    return await Filesystem.writeFile({ path, data, directory: Directory.Cache, recursive: true });
  }

  /**
   * Read and process the file data to display the image
   * @param fileResult result of the file read operation
   * @param fileType file type of the image
   */
  private readAndProcessFile(fileResult: ReadFileResult, fileType: string): void {
    if (fileResult.data instanceof Blob) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result as string;
        // Extract base64 data from result
        const base64Data = base64String.split(',')[1];
        this._src = this.transformUrl(base64Data, fileType);
        this.cdr.markForCheck();
      };
      reader.readAsDataURL(fileResult.data);
    } else {
      this._src = this.transformUrl(fileResult.data, fileType);
      this.cdr.markForCheck();
    }
  }
}
