<ion-item
  lines="none"
  *ngFor="let _ of section?.questions"
  class="ion-text-center"
>
  This report section does not have any valid features to connect to.
  EdgeAuditor requires all report sections to be connected to a single feature,
  or a category of features. Please contact your administrator or
  support@geoauditinc.com for assistance.
</ion-item>
