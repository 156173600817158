import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ChangeDetectorRef,
  ElementRef,
  OnDestroy
} from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { File as NgxFile } from '@awesome-cordova-plugins/file/ngx';
import { NgxImageCompressService } from 'ngx-image-compress';
import imageCompression from 'browser-image-compression';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Camera, CameraResultType, CameraSource, ImageOptions, Photo } from '@capacitor/camera';
import { Filesystem } from '@capacitor/filesystem';
import { StatusBar } from '@capacitor/status-bar';
import _ from 'lodash';

import { AppState } from '@edgeauditor/app/store/app/app.state';
import { DevicePlatform } from '@ea-models-v4/common';
import { WARNING_HEADER } from '../../pages/pages.constants';
import { IAttachment } from '@ea-models';
import { ConfigService, SpinnerService, PopUpService } from '@ea-services';

@Component({
  selector: 'app-image-picker',
  templateUrl: './image-picker.component.html',
  styleUrls: ['./image-picker.component.scss']
})
export class ImagePickerComponent implements OnInit, OnDestroy {
  @Input() disabled: boolean;
  @Input() hidePreview: boolean;
  @Input() hideNote: boolean;
  @Input() hideRemove: boolean;
  @Input() askWhenPickup: boolean;
  @Input() restoreImagesEvent?: Observable<IAttachment[]>;
  @Input() singleImageOnly: boolean = false;
  @Input() isSupportResizePhoto: boolean;
  @Input() showWarningResizePhoto: boolean;
  @Input() newToRight: boolean;
  @Input() isRequired = false;

  @Output() imageSelected = new EventEmitter<{
    currentImage: IAttachment;
    images: IAttachment[];
  }>();
  @Output() onPickup = new EventEmitter<any>();

  @Input()
  destinationType = CameraResultType.Base64;
  attachments: IAttachment[] = [];
  private get cameraOptions(): ImageOptions {
    const op: ImageOptions = {
      quality: this.isSupportResizePhoto ? 100 : 60,
      // destinationType: this.destinationType,
      // encodingType: this.camera.EncodingType.JPEG,
      // mediaType: this.camera.MediaType.PICTURE,
      // targetWidth: this.configService.photoResolutionsLimitation.width,
      // targetHeight: this.configService.photoResolutionsLimitation.height,
      // saveToPhotoAlbum: false,
      correctOrientation: true,
      resultType: this.destinationType || CameraResultType.Base64,
      source: CameraSource.Camera,
      width: this.configService.photoResolutionsLimitation.width,
      height: this.configService.photoResolutionsLimitation.height
    };
    console.dir(op);
    return op;
  }
  get cameraImageOptions(): ImageOptions {
    return {
      ...this.cameraOptions,
      source: CameraSource.Photos
    };
  }

  @ViewSelectSnapshot(AppState.getIsMobilePlatform) readonly isMobilePlatform: boolean;
  @ViewSelectSnapshot(AppState.getDevicePlatform) readonly devicePlatform: Readonly<DevicePlatform>;
  @ViewChild('fileButton') readonly fileButtonRef: ElementRef;

  private readonly destroyed$ = new Subject<void>();

  constructor(
    private configService: ConfigService,
    private file: NgxFile,
    private imageCompress: NgxImageCompressService,
    private spinnerService: SpinnerService,
    private popupService: PopUpService,
    private alertController: AlertController,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.restoreImagesEvent?.pipe(takeUntil(this.destroyed$)).subscribe((images) => (this.attachments = images));
    if (this.isMobilePlatform && this.devicePlatform === 'android') {
      this.askAndroidPermissions();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  addFromCamera = () => this.getImage(this.cameraOptions);

  addFromLibrary = () => this.getImage(this.cameraImageOptions);

  addFile = () => (this.fileButtonRef.nativeElement as HTMLInputElement).click();

  fileChangeListener($event) {
    const file = $event.target.files[0];
    const myReader: FileReader = new FileReader();
    myReader.onloadend = (event: any) => {
      const img = event.target.result;
      if (this.isSupportResizePhoto) this.compressImage(img, '');
      else this.dispatchData(img, '');
    };

    if (file) {
      myReader.readAsDataURL(file);
    }

    $event.target.files = null;
  }

  private async askAndroidPermissions() {
    // const ap = this.androidPermissions;
    // ap.checkPermission(ap.PERMISSION.CAMERA).catch(() => ap.requestPermission(ap.PERMISSION.CAMERA));
    try {
      const fsPermissions = await Filesystem.checkPermissions();
      const cameraPermissions = await Camera.checkPermissions();
      const allowRequestStatus = ['granted', 'prompt', 'prompt-with-rationale'];
      fsPermissions.publicStorage === 'prompt-with-rationale';
      if (allowRequestStatus.includes(fsPermissions.publicStorage)) {
        await Filesystem.requestPermissions();
      }
      if (
        allowRequestStatus.includes(cameraPermissions.camera) ||
        allowRequestStatus.includes(cameraPermissions.photos)
      ) {
        await Camera.requestPermissions();
      }
    } catch (err) {
      console.log('devDebug Filesystem.requestPermissions error: ', err);
    }

    // ap.checkPermission(ap.PERMISSION.READ_EXTERNAL_STORAGE).catch(() =>
    //   ap.requestPermission(ap.PERMISSION.READ_EXTERNAL_STORAGE)
    // );
  }

  private getImage(options: ImageOptions, isForce?: boolean) {
    if (!isForce && this.askWhenPickup) {
      return this.onPickup.emit(() => this.getImage(options, true));
    }
    this.spinnerService.show('processing...');
    this.displayStatusBar(false);
    Camera.getPhoto(options).then(
      (imageData: Photo) => {
        const _data =
          !this.destinationType || this.destinationType == CameraResultType.Base64
            ? imageData?.base64String
            : imageData?.path;
        this.processImage(_data);
      },
      (err) => {
        this.spinnerService.hide();
        this.displayStatusBar(true);
        console.error(`addFromCamera error (src=${options.source}): `, err);
      }
    );
  }

  private dispatchData(file: string, note: string, filePath: string = null, fileName: string = null) {
    this.spinnerService.hide();
    this.displayStatusBar(true);
    const imgObj: IAttachment = {
      object_attachment: { file, note, filePath, fileName }
    };
    this.singleImageOnly
      ? (this.attachments = [imgObj])
      : this.newToRight
      ? this.attachments.push(imgObj)
      : this.attachments.unshift(imgObj);
    this.imageSelected.emit({ currentImage: imgObj, images: this.attachments });
  }

  private async readFileAsDataURL(path: string) {
    return this.file.resolveLocalFilesystemUrl(path).then((fileEntry) => {
      return Filesystem.readFile({ path }).then((fileData) => {
        return {
          dataUrl: `data:image/jpeg;base64,${fileData.data}`,
          fileName: fileEntry.name
        };
      });
    });
  }

  private processImage(imageData: string) {
    if (this.destinationType == CameraResultType.Base64) {
      const [file, note] = [`data:image/jpeg;base64,${imageData}`, ''];
      if (this.isSupportResizePhoto) this.compressImage(file, note);
      else this.dispatchData(file, note);
    } else {
      const [fileUrl, note] = [imageData, ''];
      this.readFileAsDataURL(fileUrl).then((fileData) => {
        this.dispatchData(fileData.dataUrl, note, fileUrl, fileData.fileName);
      });
    }
  }

  private async compressImage(file: string, note: string, filePath: string = null, fileName: string = null) {
    const fetchRes = await fetch(file);
    const buf = await fetchRes.arrayBuffer();
    const size = buf.byteLength;
    let maxSize: number = this.configService.photoSizeLimitation;
    if (!this.isMobilePlatform) {
      maxSize = Math.min(maxSize, 700000);
      const newQuality = size > maxSize ? maxSize / size : 1;
      if (newQuality < 1) {
        const handler = async () => {
          this.spinnerService.show('processing...');
          const imageFile = new File([buf], fileName, { type: 'image/jpeg' });
          const options = {
            maxSizeMB: maxSize / 1000000,
            maxWidthOrHeight: 10000,
            useWebWorker: false
          };
          try {
            const compressedFile = await imageCompression(imageFile, options);
            const myReader: FileReader = new FileReader();
            myReader.onloadend = (event) => {
              const result = event.target.result;
              if (typeof result === 'string') {
                this.dispatchData(result, note, filePath, fileName);
                this.spinnerService.hide();
                this.cd.detectChanges();
              }
            };
            myReader.readAsDataURL(compressedFile);
          } catch (error) {
            console.dir(error);
            this.dispatchData(file, note, filePath, fileName);
            this.spinnerService.hide();
            this.cd.detectChanges();
          }
        };
        const msg =
          'The image you have chosen is either over 1600px x 1600px in resolution or over 30KB in size. It will be resized automatically. To continue with this image, choose “Yes”. Otherwise, choose “Cancel”.';
        if (this.showWarningResizePhoto)
          this.popupService.popupConfirm(WARNING_HEADER, msg, this.alertController, handler);
        else handler();
        return;
      }
      this.dispatchData(file, note, filePath, fileName);
      return;
    }
    //--app
    const img = document.createElement('img');
    img.src = file;
    let newQuality = 100;
    if (size < 1000000 && size > 500000) {
      maxSize = 500000;
    }
    newQuality = size > maxSize ? maxSize / size : 1;
    newQuality = newQuality * 100;
    if (newQuality < 100) {
      const handler = () => {
        this.imageCompress
          .compressFile(file, null, 100, newQuality)
          .then((result) => {
            this.dispatchData(result, note, filePath, fileName);
            this.cd.detectChanges();
          })
          .catch(() => {
            this.dispatchData(file, note, filePath, fileName);
            this.cd.detectChanges();
          });
      };
      const msg =
        'The image you have chosen is either over 1600px x 1600px in resolution or over 30KB in size. It will be resized automatically. To continue with this image, choose “Yes”. Otherwise, choose “Cancel”.';
      if (this.showWarningResizePhoto)
        this.popupService.popupConfirm(WARNING_HEADER, msg, this.alertController, handler);
      else handler();
      return;
    }
    this.dispatchData(file, note, filePath, fileName);
  }

  private displayStatusBar(is: boolean) {
    try {
      if (this.isMobilePlatform && this.devicePlatform === 'ios' && StatusBar) {
        if (is) StatusBar.show();
        else StatusBar.hide();
      }
    } catch {}
  }
}
