export interface GetDispatchCategoriesResponse {
  categories: DispatchCategory[];
}

export interface DispatchCategory {
  category_event_type: DispatchEventType;
  color: string;
  created_at: string;
  description: string;
  id: number;
  location_id: number;
  name: string;
  text_color: string;
  updated_at: string;
}

export type DispatchWSMessageType = 'lock_change' | 'status_changed';

export interface DispatchWSMessage {
  event_id: number;
  event_type: DispatchWSMessageType;
  locked?: boolean;
  status?: string;
}

export enum DispatchEventType {
  Medical = 'MedicalEvent',
  SearchAndRescue = 'SearchAndRescue',
  GuestContact = 'GuestContact',
  Lift = 'LiftMaintenance',
  WorkMission = 'WorkMission'
}
