import { Injectable } from '@angular/core';
import moment from 'moment';
import _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class DatetimeUtilsService {
  constructor() {}

  fromUTC(utcString: string) {
    if (utcString) {
      const parsedDt = moment.parseZone(utcString).format('YYYY-MM-DDTHH:mm');
      const outputDt = moment(parsedDt).format('YYYY-MM-DDTHH:mm:ss.000Z');
      return outputDt;
    } else {
      return utcString;
    }
    // return utcString;
    // const offset = moment().utcOffset() / 60;
    // const absOffset = Math.abs(offset);
    // const pref = offset > 0 ? '+' : '-';
    // const leading = absOffset > 9 ? '' : '0';
    // const tz = `${pref}${leading}${absOffset}:00`;
    // return utcString && utcString.replace('Z', tz);
  }

  timeStringToFullZone(time: string) {
    if (time) {
      console.log('input date/time', time);
      let outputDt = moment().format('YYYY-MM-DDT[TIMESTRING]:00.000Z');
      time = (time + '').trim();
      if (time.indexOf(' ') >= 0) {
        time = time.split(' ')[0];
      }
      outputDt = outputDt.replace('TIMESTRING', time);
      return outputDt;
    } else {
      return time;
    }
  }

  dateStringToFullZone(date: string) {
    if (date) {
      console.log('input date/time', date);
      let outputDt = moment().format('[TIMESTRING]T00:00:00Z');
      date = (date + '').trim();
      outputDt = outputDt.replace('TIMESTRING', date);
      return new Date(outputDt);
    }
    return date;
  }

  convertTimeToNumber(value: string) {
    try {
      if (value) {
        const time = _.last(value.split('T')).split('.')[0];
        const splits = time.split(':');
        return +(splits[0] + splits[1]);
      }
    } catch {}
    return 0;
  }
}
