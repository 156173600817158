import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Action, State } from '@ngxs/store';
import { GeneralError } from '../app/app.action';
import { LoginError, SetupUserError } from '../auth/auth.action';

interface StateModel {}
@State<StateModel>({
  name: 'error'
})
@Injectable({
  providedIn: 'root'
})
export class ErrorState {
  constructor(private toastController: ToastController) {}

  //Sets host and domain data
  @Action([LoginError, SetupUserError, GeneralError])
  async handleErrors(ctx, { errorMessage }: LoginError) {
    const toast = await this.toastController.create({
      color: 'danger',
      message: errorMessage,
      duration: 4000
    });
    toast.present();
  }
}
