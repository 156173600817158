<ng-container>
  <ion-item
            lines="none"
            class="location-wrapper-item"
            [class.required-add]="requiredObjects?.incident_location">
    <ion-icon
              name="pin"
              size="small"
              class="incident-icon"
              src="/assets/image/location-outline.svg"></ion-icon>
    <ion-label>Incident Location <span class="required">*</span></ion-label>
  </ion-item>
  <div
       class="ion-padding-start ion-padding-end"
       [class.required-add]="requiredObjects?.incident_location">
    <ng-select
               [items]="locations"
               [selectOnTab]="true"
               class="standard-select"
               [(ngModel)]="model.selectedLocation"
               (change)="locationChange()"
               [class.in-valid]="!model.selectedLocation"
               [dropdownPosition]="isMobilePlatform && !!selectionAppendDOM ? 'top' : 'auto'"
               (focus)="incidentProvider.scrollToElement(!!selectionAppendDOM && $event)">
    </ng-select>
  </div>
  <div class="ion-margin-start ion-margin-end location-list">
    <ng-container *ngFor="let item of locationMapping | keyvalue">
      <div *ngIf="item.key === model.selectedLocation"
           [class.required-add]="requiredObjects ? requiredObjects[item.value.aliasField] : false">
        <ion-item lines="none" color="medium">
          <ion-label>{{ item.value.title }}<span class="required">*</span></ion-label>
        </ion-item>
        <div class="ion-padding-start ion-padding-end"
             style="background-color: var(--ion-color-medium)">
          <ng-select class="standard-select"
                     bindLabel="name"
                     bindValue="id"
                     [items]="item.value.data()"
                     [selectOnTab]="true"
                     [class.in-valid]="!model.selectedSubLocation"
                     [virtualScroll]="true"
                     [dropdownPosition]="!isMobilePlatform ? 'bottom' : 'auto'"
                     [(ngModel)]="model.selectedSubLocation"
                     [dropdownPosition]="isMobilePlatform && !!selectionAppendDOM ? 'top' : 'auto'"
                     (change)="subLocationChange()"
                     (focus)="incidentProvider.scrollToElement(!!selectionAppendDOM && $event)">
          </ng-select>
        </div>
      </div>
    </ng-container>
    <div *ngIf="shouldRenderDDLFeatures"
         [class.required-add]="requiredObjects?.park_feature">
      <ion-item lines="none" color="medium">
        <ion-label>{{ mapLocationFeatures[model.selectedLocation] }}
          <span class="required">*</span>
        </ion-label>
      </ion-item>
      <div class="ion-padding-start ion-padding-end"
           style="background-color: var(--ion-color-medium)">
        <ng-select class="standard-select"
                   bindLabel="name"
                   [items]="buildFeatures"
                   [selectOnTab]="true"
                   [virtualScroll]="true"
                   [class.in-valid]="!model.buildFeature"
                   [loading]="loadingParkFeatures"
                   [(ngModel)]="model.buildFeature"
                   [dropdownPosition]="isMobilePlatform && !!selectionAppendDOM ? 'top' : 'auto'"
                   (change)="buildFeatureChange()"
                   (focus)="incidentProvider.scrollToElement(!!selectionAppendDOM && $event)">
          <ng-template ng-loadingspinner-tmp>
            <ion-spinner
                         class="select-spinner"
                         name="lines"
                         color="light"></ion-spinner>
          </ng-template>
        </ng-select>
      </div>
    </div>
    <ion-item *ngIf="currentUser.location.disable_accident_geo_map === false"
              lines="none"
              color="medium"
              [hidden]="!model.selectedLocation"
              class="btn-conainer">
      <ion-button color="dark"
                  [hidden]="!(networkStatus$ | async)?.connected"
                  (click)="presentModal()">
        <span [hidden]="model.selectedMarker">Pin Location on Map</span>
        <span [hidden]="!model.selectedMarker">Reset Pin Location</span>
      </ion-button>
      <ion-label slot="end" *ngIf="model.selectedMarker">{{model.selectedMarker}}</ion-label>
      <div *ngIf="(requiredObjects?.latitude || requiredObjects?.longitude) && !model.selectedMarker"
           class="required-coordinators">
        Please add incident location coordinates by selecting PIN LOCATION ON MAP.
      </div>
    </ion-item>
  </div>
</ng-container>