import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  ComponentFactoryResolver,
  ViewContainerRef,
  ViewChild,
  OnDestroy,
  AfterContentInit
} from '@angular/core';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';

import { IDynamicComponentModelWrapper, IReportSection, IReportSectionObject } from '@ea-models/reports';
import { User } from '@ea-models';
import { ReportItemBase } from '../report-item.base';
import { ReportService } from '@ea-services';

@Component({
  template: `<div class="section-component__title">{{ section?.heading }}</div>
    <ng-container #viewContainerRef></ng-container>`,
  selector: 'app-multi-page-report'
})
export class MultiPageReportComponent implements AfterContentInit {
  @Input() section: IReportSection;
  @Input() sectionIndex: number;
  @Input() userEvent: Observable<User>;
  @Input() restoreSectionEvent: Observable<any>;
  @Input() parentChangeDetectorRef: ChangeDetectorRef;
  @Input() isSupportResizePhoto: boolean;
  @Input() isReadyToRestore: boolean;
  @Input() showWarningResizePhoto: boolean;
  @Output() questionChanged = new EventEmitter<IDynamicComponentModelWrapper>();
  @ViewChild('viewContainerRef', { read: ViewContainerRef, static: true })
  viewContainerRef: ViewContainerRef;

  readonly object$: Subject<IReportSectionObject> = new Subject();

  constructor(private factoryResolver: ComponentFactoryResolver, protected reportService?: ReportService) {}

  ngAfterContentInit() {
    if (this.section && this.section.objects && this.section.questions && this.viewContainerRef) {
      this.section.objects.forEach((obj, index) => {
        this.addDynamicComponent(obj, index);
      });
    }
  }

  private addDynamicComponent(object: IReportSectionObject, index: number) {
    const viewContainerRef = this.viewContainerRef;
    const factory = this.factoryResolver.resolveComponentFactory(MultiPageReportItemComponent);
    const component = factory.create(viewContainerRef.parentInjector);
    component.instance.section = this.section;
    component.instance.parentChangeDetectorRef = this.parentChangeDetectorRef;
    component.instance.restoreSectionEvent = this.restoreSectionEvent;
    component.instance.userEvent = this.userEvent;
    component.instance.isSupportResizePhoto = this.isSupportResizePhoto;
    component.instance.showWarningResizePhoto = this.showWarningResizePhoto;
    component.instance.object = object;
    component.instance.objectIndex = index;
    component.instance.sectionIndex = this.sectionIndex;
    component.instance.object$ = this.object$.asObservable();
    component.instance.submitParentDetectChange = (object: IReportSectionObject) => {
      this.object$.next(object);
    };
    component.instance.object$ = this.object$;
    if (this.reportService.currAdditionalReportAttributes) {
      const _object =
        this.reportService.currAdditionalReportAttributes[`${this.sectionIndex}_${object.object_id}_${index}`];
      if (_object) component.instance.object.done = _object.done;
    }
    if (component.instance.object.done) {
      if (!this.reportService.currAdditionalReportAttributes) {
        this.reportService.currAdditionalReportAttributes = {};
      }
      this.reportService.currAdditionalReportAttributes[`${this.sectionIndex}_${object.object_id}_${index}`] = {
        done: true
      };
    }
    object['expanded'] = false;
    of(null)
      .pipe(
        take(1),
        tap(() => viewContainerRef.insert(component.hostView))
      )
      .subscribe();
  }
}

@Component({
  templateUrl: './multi-page-report.component.html',
  styleUrls: ['./multi-page-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class MultiPageReportItemComponent extends ReportItemBase implements OnDestroy {
  @Input() submitParentDetectChange: (object: IReportSectionObject) => void;
  @Input() object$: Observable<IReportSectionObject>;
  @ViewChild('header') header: any;
  @ViewChild('viewContainerRef', { read: ViewContainerRef, static: false })
  viewContainerRef: ViewContainerRef;

  constructor(protected factoryResolver: ComponentFactoryResolver, protected reportService?: ReportService) {
    super(factoryResolver);
    this.renderQuestionName = true;
  }

  private subscription: Subscription;

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngAfterContentInit() {
    this.subscription = this.object$.subscribe((_object) => {
      if (!_object) return;
      if (this.object['expanded'] && _object.object_id !== this.object.object_id && this.header) {
        this.header.el.click();
      }
    });
  }

  ngAfterViewInit() {
    super.init(true);
  }

  onDone() {
    this.object.done = true;
    this.object.expanded = false;
    if (!this.reportService.currAdditionalReportAttributes) {
      this.reportService.currAdditionalReportAttributes = {};
    }
    const attributeKey = `${this.sectionIndex}_${this.object.object_id}_${this.objectIndex}`;
    this.reportService.currAdditionalReportAttributes[attributeKey] = { done: true };
  }

  handleExpandObject() {
    const isExpanded = this.object.expanded;
    // this.section.objects.forEach(o => o['expanded'] = false);
    this.object.expanded = !isExpanded;
    if (this.submitParentDetectChange && this.object.expanded) {
      this.submitParentDetectChange(this.object);
    }
  }
}
