<ion-header class="task-filter-header">
  <ion-toolbar color="main">
    <ion-title class="ion-text-center">Filter</ion-title>
    <ion-icon
      size="large"
      src="/assets/image/white-close.svg"
      class="close"
      (click)="close()"
      slot="end"
    ></ion-icon>
  </ion-toolbar>
</ion-header>
<ion-content scrollY="true" class="task-filter-container">
  <div class="main-content">
    <div
      class="section-component__body"
      style="width: 100%"
      *ngIf="selectedOptions"
    >
      <ion-row>
        <div class="title">Department</div>
        <div class="control">
          <ng-select
            [items]="departments"
            [selectOnTab]="true"
            class="standard-select"
            bindLabel="name"
            bindValue="id"
            [(ngModel)]="selectedOptions.department_id"
            [disabled]="!departments?.length"
          >
          </ng-select>
        </div>
      </ion-row>
      <ion-row>
        <div class="title">Area</div>
        <div class="control">
          <ng-select
            [items]="areas"
            [selectOnTab]="true"
            class="standard-select"
            bindLabel="name"
            bindValue="id"
            [(ngModel)]="selectedOptions.area_id"
            [disabled]="!areas?.length"
          >
          </ng-select>
        </div>
      </ion-row>
      <ion-row>
        <div class="title">Priority</div>
        <div class="filter-item" *ngFor="let item of priorities">
          <div>{{ item.name }}</div>
          <ion-checkbox
            [checked]="item.selected"
            (click)="onChange(item, priorities, true)"
          ></ion-checkbox>
        </div>
      </ion-row>
    </div>
  </div>

  <div class="btn-task-filter-container">
    <button class="btn-cancel no-ripple" (click)="applyFilter(true)">
      Remove Filters
    </button>
    <button class="btn-success no-ripple" (click)="applyFilter()">
      Apply Filters
    </button>
  </div>
</ion-content>
