import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService, ConfigService } from '..';
import { LiftLog } from '@ea-models';

@Injectable({
  providedIn: 'root'
})
export class LiftService {
  constructor(private apiService: ApiService, private configService: ConfigService) {}

  public getLifts(): Observable<any> {
    var params = {};
    return this.apiService.get(`${this.configService.apiURL()}lifts`, params);
  }

  public submitLiftLOg(lift_log: LiftLog): Observable<any> {
    var params = { lift_log: lift_log };
    return this.apiService.post(`${this.configService.apiURL()}submit_lift_log`, params);
  }
}
