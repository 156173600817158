import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { take, map, tap } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import _ from 'lodash';

import { User, IAttachment, Coordinates } from '@ea-models';
import { Feature, GlobalService, ParkBuildsService } from '@ea-services';
import { FEATURE_BUILD_TYPE } from '@ea-pages/pages.constants';

@Component({
  selector: 'app-park-build-feature-details',
  templateUrl: './park-build-feature-details.component.html',
  styleUrls: ['./park-build-feature-details.component.scss']
})
export class ParkBuildFeatureDetailsComponent implements OnInit {
  @Input() coords: Coordinates;
  @Input() feature: { [key: string]: any } = {};
  @Input() buildType: string;
  imgSubject: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  features: Feature[] = [];
  currentUser: User;
  buildFeaturesCates = [];
  private cloneFeature: any;
  private featureName: string;

  getFeatureById = (featureId) => this.features && featureId && this.features.find((item) => item.id === featureId);
  defineSnowFeature: boolean;
  readonly FEATURE_BUILD_TYPE = FEATURE_BUILD_TYPE;

  constructor(
    private globalService: GlobalService,
    private modalController: ModalController,
    private parkBuildsService: ParkBuildsService
  ) {}

  ngOnInit() {
    if (this.globalService._currentUser && typeof this.feature === 'object') {
      this.currentUser = this.globalService._currentUser;
      this.cloneFeature = _.cloneDeep(this.feature);
      this.buildFeaturesCates = this.currentUser.f_categories[`${this.buildType}s`] || [];
      this.parkBuildsService
        .getBuildsAndFeaturesByType(this.currentUser.token, this.buildType)
        .pipe(
          take(1),
          map((res) => res.features.filter((i) => !i.is_now)),
          tap((features) => {
            this.features = features;
            this.initFeature();
          })
        )
        .subscribe((f) => console.log('features:', f));
    }
  }

  onImageChange(imagesData: { currentImage: IAttachment; images: IAttachment[] }) {
    this.feature.object_attachments_attributes = imagesData.images.map((img) => {
      return img.object_attachment;
    });
    if (!this.feature.object_attachments_attributes || !this.feature.object_attachments_attributes.length) {
      delete this.feature.images;
    } else if (this.feature.images) {
      this.feature.images = this.feature.images.filter((i) => {
        return this.feature.object_attachments_attributes.some((f) => f.file === i.url.url);
      });
    }
    this.feature.unchange = false;
    console.log('curr build feature:', this.feature);
  }

  close() {
    this.modalController.dismiss();
  }

  save() {
    let feature = _.cloneDeep(this.feature);
    if (this.feature.id) {
      const f = this.getFeatureById(this.feature.id);
      this.feature.park_feature_id = this.feature.id;
      if (f && f.name) {
        this.feature.name = f.name;
      }
    }

    if (feature.is_snow && this.featureName != feature.name) {
      feature.new = 1;
      this.feature.name = feature.name;
    } else if (!feature.is_snow) {
      feature = _.cloneDeep(this.feature);
    }
    feature.newAdded = true;
    this.modalController.dismiss({
      feature: feature,
      clone: this.cloneFeature
    });
  }

  onFeatureTypeChange(id: number) {
    this.feature.is_snow = id;
    this.defineSnowFeature = true;
    const f = this.getFeatureById(this.feature.id);
    if (!f && !this.feature.is_snow) {
      this.feature.id = null;
    }
  }

  private initFeature() {
    this.setFeatureLocation();
    this.setFeatureNewFlag();
    this.setFeatureSnowFlag();
    this.restoreFeatureImages();
    this.sortFeatureByName();
  }

  private sortFeatureByName() {
    if (!this.features) return;
    this.features = this.features.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  }

  customSearchFn(term: string, item) {
    return item.name.toLocaleLowerCase().indexOf(term) > -1;
  }

  private restoreFeatureImages() {
    let listImages = [];
    if (this.feature.unchange !== false && this.feature.images) {
      listImages = this.feature.images.map((img) => ({
        object_attachment: { file: img && img.url && img.url.url }
      }));
    } else if (this.feature.unchange === false && this.feature.object_attachments_attributes) {
      listImages = [
        ...listImages,
        ...this.feature.object_attachments_attributes.map((img) => ({
          object_attachment: { file: img.file }
        }))
      ];
    }

    this.imgSubject.next(listImages);
  }

  private setFeatureNewFlag() {
    if (!this.feature.id) {
      this.feature.new = 1;
    }
  }

  private setFeatureSnowFlag() {
    const f: any = this.feature.newAdded ? this.feature : this.getFeatureById(this.feature.id);

    if (f && f.is_snow !== undefined) {
      this.feature.is_snow = f.is_snow ? 1 : 0;
      if (f.is_snow) {
        this.featureName = f.name;
      }
    }
  }

  private setFeatureLocation() {
    if (!this.feature.location && this.coords) {
      this.feature.location = [this.coords.longitude, this.coords.latitude];
    }
  }
}
