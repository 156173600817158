import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavParams, ModalController, AlertController } from '@ionic/angular';
import { ConfigService, ApiService, SpinnerService, GlobalService } from '@ea-services';
import { Observable } from 'rxjs';
import { Customer, CustomerWaiver } from '@ea-models';

import moment from 'moment';
import { Router } from '@angular/router';
import { ViewPhotoModal } from '../view-photo/view-photo';
import { RouterConstant } from '@edgeauditor/app/constants/router.constant';

const DATE_FORMAT = 'YYYY-MM-DD';
const TIME_FORMAT = 'HH:mm';

@Component({
  selector: 'modal-customer-details',
  templateUrl: 'customer-details.html',
  styleUrls: ['customer-details.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomerDetailsModal implements OnInit {
  customer_id: number;
  is_rental: boolean;
  customer: Customer;
  age: number;

  hasPhoto: boolean = false;

  constructor(
    public configService: ConfigService,
    public apiService: ApiService,
    public globalService: GlobalService,
    public spinnerService: SpinnerService,
    public modalController: ModalController,
    public alertController: AlertController,
    public navParams: NavParams,
    public router: Router
  ) {}

  ngOnInit() {}

  ionViewWillEnter() {
    this.customer_id = this.navParams.get('customer_id');
    this.is_rental = this.navParams.get('is_rental');

    this.spinnerService.show('Retrieving customer information...');
    this.getCustomer().subscribe((response) => {
      this.customer = response;
      const { waivers } = this.customer;

      if (waivers && waivers.length) {
        waivers.forEach((waiver: CustomerWaiver) => {
          this.hasPhoto = !!waiver.image || this.hasPhoto;
        });
      }
      this.age = moment().diff(this.customer.birthday, 'years');
      this.spinnerService.hide();
    });
  }

  ionViewWillLeave() {
    this.modalController.dismiss().catch(() => {});
  }

  async myDismiss() {
    await this.modalController.dismiss();
  }

  public getCustomer(): Observable<any> {
    return this.apiService.get(
      this.configService.apiURL() +
        'locations/' +
        this.globalService._currentUser.location.id +
        '/customers/' +
        this.customer_id,
      null
    );
  }

  public continueRentals() {
    this.router.navigateByUrl(`/${RouterConstant.EQUIPMENT_ASSIGNMENT_RENTAL_PAGE}/${this.customer_id}`);
  }
  async viewPhoto(_customer: Customer, _waiver: CustomerWaiver) {
    let componentProps = {
      customer_name: `${_customer.first_name} ${_customer.last_name}`,
      waiver: _waiver
    };

    const modal = await this.modalController.create({
      component: ViewPhotoModal,
      cssClass: 'view-photo-modal',
      componentProps: componentProps
    });

    await modal.present();
  }
}
