import { Wkt } from 'wicket';
import { Component, Input, ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';

import { User } from '@ea-models';
import { LOCATION_MAPPING } from '@edgeauditor/constants/incident-report.constants';
import { MapObject } from '@ea-models-v4';
import _ from 'lodash';

const MAP_CANVAS = 'map_canvas';
// const API_KEY = 'AIzaSyC-O4VNt3VYCcvnlqIaAYXdLTPSu_Bq5MA';

@Component({
  selector: 'app-incident-location-map',
  template: `
    <div style="height: 70%;">
      <ion-header>
        <ion-toolbar color="danger">
          <ion-icon size="large" slot="start" name="arrow-back-outline" (click)="close()" class="arrow-back"></ion-icon>
        </ion-toolbar>
      </ion-header>
      <div style="height: 78%; color: black" id="${MAP_CANVAS}"></div>
      <ion-item lines="none" color="light ion-text-center"><ion-label>Tap to Place Pin</ion-label></ion-item>
      <ion-button color="success" expand="full" (click)="savePos()">Capture Pinned Location</ion-button>
    </div>
  `
})
export class IncidentLocationMapComponent implements OnInit, OnDestroy {
  @Input() currentUser: User;
  @Input() mapObject: MapObject;
  @Input() selectedPosition: google.maps.LatLngLiteral;
  @Input() locationModel: { [key: string]: any };
  @Input() buildFeatures: [];
  @Input() customFeatures: string[];
  @Input() parentChangeDetectorRef: ChangeDetectorRef;

  private map: google.maps.Map;
  private marker: google.maps.Marker;
  private ionViewLoaded = false;
  private selectedFeature: google.maps.Marker;
  private defaultPosition: google.maps.LatLngLiteral;

  constructor() {}

  ngOnInit(): void {
    this.parentChangeDetectorRef?.detach();

    if (!this.ionViewLoaded) {
      this.ionViewLoaded = true;
      this.ionViewDidLoad();
    }
  }

  ngOnDestroy(): void {
    this.parentChangeDetectorRef?.reattach();
  }

  ionViewDidLoad() {
    setTimeout(() => this.loadMap(), 500);
  }

  close() {
    if (this.parentChangeDetectorRef) {
      this.parentChangeDetectorRef.reattach();
    }

    this.mapObject.data = null;
    this.mapObject.isShow = false;
    this.mapObject.point = null;
  }

  savePos() {
    if (this.parentChangeDetectorRef) {
      this.parentChangeDetectorRef.reattach();
    }

    try {
      const pos = this.selectedFeature
        ? this.selectedFeature.getPosition()
        : this.marker
        ? this.marker.getPosition()
        : null;

      this.mapObject.data = null;
      this.mapObject.isShow = false;
      this.mapObject.point = null;

      if (!pos) {
        return;
      }
      this.mapObject.point = { lat: pos.lat(), lng: pos.lng() };
      this.parentChangeDetectorRef.detectChanges();
    } catch (ex) {
      console.error(ex);
    }
  }

  private loadMap() {
    this.selectedFeature = null;

    if (this.currentUser.location) {
      this.defaultPosition = {
        lat: parseFloat(this.currentUser.location.lat as any),
        lng: parseFloat(this.currentUser.location.lng as any)
      };
    }

    if (this.selectedPosition) {
      if (this.selectedPosition.lat === undefined || this.selectedPosition.lng === undefined) {
        this.selectedPosition = null;
      } else {
        this.selectedPosition.lng = +this.selectedPosition.lng;
        this.selectedPosition.lat = +this.selectedPosition.lat;
      }
    }

    const mapOptions = {
      zoom: 16,
      center: this.selectedPosition || this.defaultPosition,
      mapTypeId: google.maps.MapTypeId.SATELLITE,
      zoomControl: true,
      mapTypeControl: true,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: true
    };
    this.map = new google.maps.Map(document.querySelector(`#${MAP_CANVAS}`), mapOptions);

    const featuresList = this.customFeatures.slice();
    const parkFeature = [LOCATION_MAPPING.freestyleTerrain.name];
    const trailFeature = [LOCATION_MAPPING.bikingHiking.name];

    if (this.locationModel && trailFeature.includes(this.locationModel.selectedLocation)) {
      let points = [];
      const isZoomTo10 =
        !!this.locationModel.feature && this.buildFeatures.length > 0 && !!this.locationModel.buildFeature;
      if (this.locationModel.feature) {
        points = [...(this.handleFeature() || []), ...points];
      }

      if (this.buildFeatures.length > 0 && this.locationModel.buildFeature) {
        points = [...(this.handleParkFeature() || []), ...points];
      }

      if (points.length) {
        this.setMapCenter(points);
      }

      if (isZoomTo10) {
        this.map.setZoom(10);
      }
    } else if (
      this.locationModel &&
      parkFeature.includes(this.locationModel.selectedLocation) &&
      this.buildFeatures.length > 0
    ) {
      this.handleParkFeature();
    } else if (
      this.locationModel &&
      featuresList.includes(this.locationModel.selectedLocation) &&
      this.locationModel.feature
    ) {
      this.handleFeature();
    } else {
      this.handleCustom();
    }

    this.marker = new google.maps.Marker({
      title: 'Incident Location',
      // icon: 'red',
      animation: google.maps.Animation.DROP,
      position: this.selectedPosition || this.defaultPosition,
      map: this.map
    });
    this.map.setCenter(this.selectedPosition || this.defaultPosition);
    google.maps.event.addListener(this.map, 'click', (e: { latLng: google.maps.LatLng }) =>
      this.marker.setPosition(e.latLng)
    );
  }

  private handleFeature() {
    const wkt = new Wkt();
    wkt.read(this.locationModel.feature.coordinates);
    const components = this.locationModel.feature.coordinates.startsWith('POLYGON')
      ? wkt.components[0]
      : wkt.components;
    const points = components.map((c) => ({ lat: c.y, lng: c.x }));
    this.setMapCenter(points);
    if (this.locationModel.feature.coordinates.startsWith('POLYGON')) {
      const polygon = new google.maps.Polygon({
        paths: points,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35
      });
      polygon.setMap(this.map);

      google.maps.event.addListener(polygon, 'click', (e: { latLng: google.maps.LatLng }) =>
        this.marker.setPosition(e.latLng)
      );
    }
    if (this.locationModel.feature.coordinates.startsWith('LINESTRING')) {
      const polyline = new google.maps.Polyline({
        path: points,
        geodesic: true,
        strokeColor: '#FF0000',
        strokeOpacity: 1.0,
        strokeWeight: 2
      });
      polyline.setMap(this.map);

      google.maps.event.addListener(polyline, 'click', (e: { latLng: google.maps.LatLng }) =>
        this.marker.setPosition(e.latLng)
      );
    }
    return points;
  }

  private handleParkFeature() {
    console.log('handleParkFeature', this.locationModel, this.buildFeatures);
    const coords = this.buildFeatures.map((c: any) => ({
      lat: c.location[1],
      lng: c.location[0]
    }));
    this.setMapCenter(coords);
    this.buildFeatures.forEach((bf: any) => {
      const markerColor =
        this.locationModel.buildFeature && this.locationModel.buildFeature.id === bf.id ? 'yellow' : 'blue';
      this.addInfoWindow(
        new google.maps.Marker({
          icon: `assets/image/${markerColor}-dot.png`,
          position: { lat: bf.location[1], lng: bf.location[0] },
          map: this.map
        }),
        bf
      );
    });
    return coords;
  }

  private setMapCenter(points: any[]) {
    const latLngBounds = new google.maps.LatLngBounds();
    points.forEach((point) => {
      latLngBounds.extend(point);
    });
    const defaultPosition = latLngBounds.getCenter();
    if (defaultPosition) {
      this.defaultPosition = { lat: defaultPosition.lat(), lng: defaultPosition.lng() };
      this.map.setCenter(this.defaultPosition);
    }
  }

  private addInfoWindow(marker: google.maps.Marker, feature) {
    const inlineStyle = 'width: 105px; height: auto; display: block; margin: 0 auto;';
    const { images } = feature;
    const img =
      !_.isEmpty(images) && !_.isEmpty(images[0].url.url)
        ? `<img style="${inlineStyle}" src="${images?.length && images[0].url.url}" /><br/>`
        : '';
    // const src = _.isEmpty(images) ? null : images[0]
    const contentString = [
      '<div id="content">',
      `<h6 class="firstHeading">${feature.name}</h6>`,
      '<div id="bodyContent" text-center>',
      `${!!feature.size ? '<span>Size: ' + feature.size + '</span>' : ''}`,
      feature.notes && feature.notes !== '' ? `<p>Notes: ${feature.notes}</p>` : '',
      img,
      '</div>',
      '</div>'
    ].join('');

    const infoWindow = new google.maps.InfoWindow({ content: contentString });

    google.maps.event.addListener(marker, 'click', () => this.handleMarkerClick(infoWindow, marker));
  }

  private handleMarkerClick(infoWindow, marker: google.maps.Marker) {
    this.selectedFeature = marker;
    infoWindow.open(this.map, marker);
  }

  private handleCustom() {
    console.log('handleCustom', this.locationModel, this.currentUser.location);
  }
}
