import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CustomerWaiver } from '@ea-models';

@Component({
  selector: 'modal-view-photo',
  templateUrl: 'view-photo.html',
  styleUrls: ['view-photo.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ViewPhotoModal {
  @Input()
  customer_name: string;
  @Input()
  waiver: CustomerWaiver;

  constructor(public modalController: ModalController) {}
}
