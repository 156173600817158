export const ACTION_VIEW_TASK_ID = 1;
export const ACTION_COMPLETE_TASK_ID = 2;
export const ACTION_COMPLETE_REPORT_ID = 3;
export const ACTION_INSPECT_FEATURE_ID = 4;
export const actionIdsObject = {
  view: ACTION_VIEW_TASK_ID,
  finish: ACTION_COMPLETE_TASK_ID,
  complete: ACTION_COMPLETE_REPORT_ID,
  inspect: ACTION_INSPECT_FEATURE_ID
};
export const ACTIONS = [
  { id: -1, name: '', value: -1, selected: false, hide: true },
  {
    id: ACTION_VIEW_TASK_ID,
    name: 'View Task',
    value: ACTION_VIEW_TASK_ID,
    selected: false
  },
  {
    id: ACTION_COMPLETE_TASK_ID,
    name: 'Finish Task',
    value: ACTION_COMPLETE_TASK_ID
  },
  {
    id: ACTION_COMPLETE_REPORT_ID,
    name: 'Complete Report',
    value: ACTION_COMPLETE_REPORT_ID
  },
  {
    id: ACTION_INSPECT_FEATURE_ID,
    name: 'Inspect Feature',
    value: ACTION_INSPECT_FEATURE_ID
  }
];
export const CREATE_TASK_ACTIONS = [
  { id: 1, name: 'Complete Task', value: 1, selected: false },
  {
    id: ACTION_COMPLETE_REPORT_ID,
    name: 'Complete Report',
    value: ACTION_COMPLETE_REPORT_ID
  },
  {
    id: ACTION_INSPECT_FEATURE_ID,
    name: 'Inspect Feature',
    value: ACTION_INSPECT_FEATURE_ID
  }
];
export const priority = { high: 3, medium: 2, low: 1, none: 0 };
export const priorities = [
  { id: 1, name: 'Low' },
  { id: 2, name: 'Medium' },
  { id: 3, name: 'High' }
];
