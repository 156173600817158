<ion-app
         [class.on-browser]="isOnBrowser"
         [class.on-device]="!isOnBrowser"
         [class.on-android]="!!isOnAndroid"
         [class.device-ipad]="!!isIpad">
  <ea-user-menu></ea-user-menu>
  <ion-router-outlet id="main"></ion-router-outlet>
</ion-app>
<!-- Preload Components -->
<div style="display: none;" id="ion-preload-components">
  <ion-card></ion-card>
  <ion-toast></ion-toast>
  <ion-toggle></ion-toggle>
  <ion-textarea></ion-textarea>
  <ion-checkbox></ion-checkbox>
  <ion-alert></ion-alert>
  <ion-text></ion-text>
  <ion-action-sheet></ion-action-sheet>
  <ion-loading></ion-loading>
  <ion-modal></ion-modal>
  <app-signature></app-signature>
  <owl-date-time></owl-date-time>
  <ng-select></ng-select>
  <ion-icon *ngFor="let icon of preloadIcons" [name]="icon"></ion-icon>
  <ng-container *ngIf="!isMobilePlatform">
    <app-cached-image *ngFor="let img of preloadImages" [src]="img"></app-cached-image>
  </ng-container>
</div>