import { Component, Input, OnInit } from '@angular/core';
import { ReportService } from '@ea-services/api';
import { take, tap } from 'rxjs/operators';
import { ModalController } from '@ionic/angular';
import { AppState } from '@edgeauditor/app/store/app/app.state';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Keyboard } from '@capacitor/keyboard';

@Component({
  selector: 'app-link-report',
  templateUrl: './link-report.component.html',
  styleUrls: ['./link-report.component.scss']
})
export class LinkReportComponent implements OnInit {
  @Input() token: string;
  model: { searchStr?: string } = {};
  incidents: any[] = [];

  @ViewSelectSnapshot(AppState.getIsMobilePlatform) isMobilePlatform: boolean;

  constructor(private reportService: ReportService, private modalController: ModalController) {}

  ngOnInit() {}

  search(final?: boolean) {
    const searchParams = {};
    const paramName = isNaN(this.model.searchStr as any) ? 'last_name' : 'accident_number';
    searchParams[paramName] = this.model.searchStr;
    try {
      if (!!final && this.isMobilePlatform) Keyboard.hide();
    } catch {}
    this.reportService
      .searchIncident(searchParams)
      .pipe(
        take(1),
        tap((res) => (this.incidents = res.accidents) && console.log('searchIncident', res.accidents))
      )
      .subscribe();
  }

  selectIncident(incident: any) {
    this.modalController.dismiss({ linkedIncident: incident, success: true });
  }

  dismiss() {
    this.modalController.dismiss().catch(() => {});
  }
}
