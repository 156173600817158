import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import _ from 'lodash';
import { GlobalService } from '@ea-services';
import { Observable, ReplaySubject } from 'rxjs';

@Component({
  selector: 'signature-pad',
  templateUrl: 'signature-pad.html',
  styleUrls: ['signature-pad.scss'],
  providers: []
})
export class SignaturePadModal implements OnInit, AfterViewInit {
  @Input()
  signature: string = '';

  canvasWidth: number = 350;
  signatureEvent: ReplaySubject<string> = new ReplaySubject<string>();
  constructor(private modalCtrl: ModalController, private gbService: GlobalService) {
    this.canvasWidth = window.innerWidth - 32;
  }
  ngAfterViewInit(): void {
    this.signatureEvent.next(this.signature || '');
  }

  ngOnInit() {}

  onChange(signature) {
    this.signature = signature;
  }

  save() {
    this.modalCtrl.dismiss({ signature: this.signature });
  }

  reset() {}

  cancel() {
    this.gbService.popupCustomConfirm(
      'Alert',
      'Are you sure you want to close? If you close this screen any changes made will not be saved.',
      'Yes',
      'Cancel',
      null,
      () => {
        this.modalCtrl.dismiss();
      },
      () => {}
    );
  }

  getKeys(object: any) {
    return Object.keys(object);
  }

  // async selectFilter(event: any, filter: CompliantStaffFilter) {
  //   //userChoice.indexOf(filters[filter])>0?
  //   let selectedFilters = [];
  //   for (const filter in this.filters) {
  //     if (this.filters.hasOwnProperty(filter)) {
  //       const value = this.filters[filter];
  //       if (value.selected) {
  //         selectedFilters.push(value);
  //       }
  //     }
  //   }
  //   const group = _.groupBy(selectedFilters, 'type');
  //   const bool = _.maxBy(group.bool, 'compareValue');
  //   const range = _.maxBy(group.range, 'compareValue');

  //   console.log("selectFilter, event,filters", this.filters, 'selectedFilters', selectedFilters, "group", group, "bool", bool, 'renge', range);
  // }

  // changeFilter(event: any, filter: CompliantStaffFilter) {
  //   if (event.detail) {
  //     const detail = event.detail;
  //     if (!detail.checked) {
  //       const index = _.findIndex(this.userChoice, detail.value);
  //       if (index >= 0) {
  //         this.userChoice.splice(index, 1);
  //       }
  //     }
  //     else {
  //       this.userChoice.push(detail.value);
  //     }
  //   }
  //   console.log("changeFilter, event,filter", this.userChoice, event, filter);
  //   //userChoice.indexOf(filters[filter])>0?
  // }
}
