import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { CustomerDetailsModal } from './customer-details';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@edgeauditor/app/components/shared/shared.module';

@NgModule({
  declarations: [CustomerDetailsModal],
  imports: [CommonModule, IonicModule, FormsModule, ReactiveFormsModule, SharedModule],
  entryComponents: [CustomerDetailsModal]
})
export class CustomerDetailsModalModule {}
