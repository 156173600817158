<ng-container *ngIf="!defineSnowFeature">
  <ea-header [hasCloseButton]="true" color="dark" [title]="'What kind of '+ (buildType | titlecase) +' Feature would you like to add?'"></ea-header>
  <ion-content scrollY="true" class="define-snow-feature">
    <ion-row>
      <ion-col size-xs="12" size-sm="6">
        <ion-button
          expand="full"
          color="primary"
          (click)="onFeatureTypeChange(1)"
          >{{
            buildType !== FEATURE_BUILD_TYPE.trail
              ? 'Snow Feature'
              : 'Manmade Feature'
          }}</ion-button
        >
      </ion-col>
      <ion-col size-xs="12" size-sm="6">
        <ion-button
          expand="full"
          color="primary"
          (click)="onFeatureTypeChange(0)"
          >From Inventory</ion-button
        >
      </ion-col>
    </ion-row>
  </ion-content>
</ng-container>
<ng-container *ngIf="defineSnowFeature">
  <ea-header color="dark" title="Add an Item"></ea-header>
  <ion-content scrollY="true">
    <ion-grid class="section-component__body" style="width: 100%">
      <ion-row *ngIf="buildType !== FEATURE_BUILD_TYPE.trail">
        <ion-col size-xs="12" size-sm="6">Is this a snow feature?</ion-col>
        <ion-col size-xs="12" size-sm="6">
          <div>{{ feature.is_snow === 1 ? 'Yes' : 'No' }}</div>
        </ion-col>
      </ion-row>
      <ion-row [hidden]="feature.is_snow !== 1">
        <ion-col size-xs="12" size-sm="6">Feature Name</ion-col>
        <ion-col size-xs="12" size-sm="6">
          <ion-input
            class="standard-input"
            [(ngModel)]="feature.name"
            data-ci="f-name"
          ></ion-input>
        </ion-col>
      </ion-row>
      <ion-row [hidden]="feature.is_snow !== 1">
        <ion-col size-xs="12" size-sm="6">Category</ion-col>
        <ion-col size-xs="12" size-sm="6">
          <ng-select
            [items]="buildFeaturesCates"
            [selectOnTab]="true"
            class="standard-select"
            bindValue="id"
            [searchFn]="customSearchFn"
            notFoundText="No item found"
            data-ci="category_id"
            placeholder="Select Category"
            bindLabel="name"
            [(ngModel)]="feature.category_id"
            [searchable]="true"
          >
          </ng-select>
        </ion-col>
      </ion-row>
      <ion-row [hidden]="feature.is_snow !== 0">
        <ion-col size-xs="12" size-sm="6">Feature</ion-col>
        <ion-col size-xs="12" size-sm="6">
          <ng-select
            [items]="features"
            [selectOnTab]="true"
            class="standard-select"
            bindValue="id"
            [searchFn]="customSearchFn"
            notFoundText="No item found"
            data-ci="id"
            placeholder="Select Feature"
            bindLabel="name"
            [(ngModel)]="feature.id"
            [searchable]="true"
          >
          </ng-select>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size-xs="12" size-sm="6">Size</ion-col>
        <ion-col size-xs="12" size-sm="6">
          <ng-select
            [items]="['XS', 'S', 'M', 'L', 'XL', 'XXL']"
            [selectOnTab]="true"
            class="standard-select"
            placeholder="Select Size"
            [(ngModel)]="feature.size"
            [searchable]="true"
            data-ci="size"
          >
          </ng-select>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size-xs="12">
          <ion-textarea
            rows="5"
            class="standard-input"
            [(ngModel)]="feature.notes"
            data-ci="notes"
          ></ion-textarea>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size-xs="12">
          <app-image-picker
            (imageSelected)="onImageChange($event)"
            [hideNote]="true"
            [isSupportResizePhoto]="true"
            [restoreImagesEvent]="imgSubject.asObservable()"
            [newToRight]="true"
          >
          </app-image-picker>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size-xs="6">
          <ion-button expand="full" color="danger" (click)="close()"
            >Cancel</ion-button
          >
        </ion-col>
        <ion-col size-xs="6">
          <ion-button
            expand="full"
            color="success"
            [disabled]="
              (feature.is_snow === 0 && !feature.id) ||
              (feature.is_snow === 1 && (!feature.category_id || !feature.name))
            "
            (click)="save()"
            >Save</ion-button
          >
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</ng-container>
