import { FormGroup } from '@angular/forms';

// NEMSIS Templates
export enum NemsisQuestionType {
  Text = 'text',
  Selectable = 'selectable',
  AccidentField = 'accident_field',
  DispatchField = 'dispatch_field',
  Time = 'time',
  Date = 'date',
  MultiSelect = 'multi_select'
}

export interface NemsisTemplatesResponse {
  nemsis_templates: NemsisTemplate[];
}

export interface NemsisTemplate {
  id: number;
  name: string;
  template_sections: NemsisTemplateSection[];
}

export interface NemsisTemplateSection {
  name: string;
  nemsis_code: string;
  sort_order: number;
  questions: NemsisQuestion[];
  answers?: NemsisQuestionAnswer[];
}

export interface NemsisQuestion {
  id: number;
  question_label: string;
  accident_field_name: string;
  can_be_null: boolean;
  default_text_value: string;
  dispatch_field_name: string;
  nemsis_code: string;
  null_value: string;
  question_type: NemsisQuestionType;
  sort_order: number;
  question_options: NemsisQuestionOption[];
  required: boolean;
}

export interface NemsisQuestionOption {
  id: number;
  default: boolean;
  label: string;
  nemsis_code: string;
}

export interface NemsisQuestionAnswer {
  id: number;
  nemsis_template_question_id: number;
  nemsis_question_option_id: number;
  text_answer: string | null;
  date: string | null;
  time: string | null;

  //Custom for update attributes
  _destroy?: boolean;
}

//NEMSIS Reports

// export type NemsisReportStatus = 'draft' | 'pending_approval' | 'rejected' | 'finalized' | 'transmitted';

export enum NemsisReportStatus {
  Draft = 'draft',
  PendingApproval = 'pending_approval',
  Rejected = 'rejected',
  Finalized = 'finalized',
  Transmitted = 'transmitted'
}

export interface NemsisReportsResponse {
  nemsis_reports: NemsisReport[];
}

export interface NemsisReport {
  id: number;
  created_at: string;
  status: NemsisReportStatus;
  accident_report_id: number;
  accident_report_number?: number;
  dispatch_event_id: number;
  user_id: number;
  rejection_comments: string;
  template_name?: string;
  nemsis_template_id?: number;
}

export interface NemsisReportDetail extends NemsisReport {
  nemsis_template: Partial<NemsisTemplate>;
  template_sections: Partial<NemsisTemplateSection>[];
}

//NEMSIS Requests

export interface NemsisReportCreation {
  nemsis_template_id: number;
  accident_report_id?: number;
  nemsis_report_answers_attributes: Partial<NemsisQuestionAnswer>[];
  status: NemsisReportStatus;
}

export interface NemsisReportCreationResponse extends Partial<NemsisTemplate> {
  status: string;
  rejection_comments: string;
  accident_report_id: number;
  dispatch_event_id: number;
  user_id: number;
  nemsis_template: {
    id: number;
    name: string;
  };
}

export interface NemsisLocalReport {
  uuid: string;
  templateId: number;
  templateName: string;
  timestamp: string;
  formGroup: any;
  incidentReport?: { id: number; report_number: number };
  linkedIncidentFields: string[];
}

export interface NemsisReportSubmitData {
  form: FormGroup;
  incidentReport?: { id: number; report_number: number };
  reportId?: string | number;
}
