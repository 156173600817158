import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { append, patch } from '@ngxs/store/operators';

import { EMPTY, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import _ from 'lodash';

import { Incident, InProgressIncidentReport } from '@ea-models-v4/incident-report';
import { IncidentReportService } from '@ea-services-v4';
import { ClearReports, GeneralError } from '../app/app.action';
import {
  GetInProgressIncidentReports,
  GetIncidentReportDetail,
  GetIncidentReportDetailSuccess
} from './incident-report.action';
import { HttpErrorResponse } from '@angular/common/http';

export interface IncidentReportStateModel {
  inProgressReports: InProgressIncidentReport[];
  selectedIncident: Incident;
}

export const incidentReportStateDefaults: IncidentReportStateModel = {
  inProgressReports: [],
  selectedIncident: null
};

const INCIDENT_REPORTS_STATE_TOKEN = new StateToken<IncidentReportStateModel>('incidentReport');

@State({
  name: INCIDENT_REPORTS_STATE_TOKEN,
  defaults: incidentReportStateDefaults
})
@Injectable()
export class IncidentReportState {
  constructor(private incidentReportService: IncidentReportService) {}

  //#region @Selector
  @Selector()
  static getInProgressReports(state: IncidentReportStateModel) {
    return state.inProgressReports || [];
  }

  @Selector()
  static getSelectedIncidentReport(s: IncidentReportStateModel) {
    return s.selectedIncident;
  }
  //#endregion

  //#region  @Action
  @Action(GetInProgressIncidentReports)
  getInProgressReports(
    ctx: StateContext<IncidentReportStateModel>,
    { start_index, limit }: GetInProgressIncidentReports
  ) {
    return this.incidentReportService.getInProgressReports(start_index, limit).pipe(
      tap((reports) =>
        ctx.setState(
          patch<IncidentReportStateModel>({
            inProgressReports: append(reports)
          })
        )
      ),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      catchError((e: HttpErrorResponse | Error) => {
        let message =
          'There was a problem while fetching In Progress Incident Reports. Please try again or contact Support.';
        ctx.dispatch(new GeneralError(message));
        return EMPTY;
      })
    );
  }

  @Action(ClearReports)
  clearReports(ctx: StateContext<IncidentReportStateModel>) {
    ctx.patchState({ inProgressReports: [] });
  }

  @Action(GetIncidentReportDetail)
  getIncidentReportDetail(ctx: StateContext<IncidentReportStateModel>, payload: GetIncidentReportDetail) {
    return this.incidentReportService.getIncidentReportDetail(payload.id).pipe(
      map((res) => {
        _.forEach(res, (value, key) => {
          if (_.isNil(value)) {
            if (typeof res[key] === 'object') {
            }
            delete res[key];
          }
        });
        return res;
      }),
      tap((detail) => ctx.dispatch(new GetIncidentReportDetailSuccess(detail))),
      catchError((error) => {
        let message = 'There was an error fetching the Incident Report Detail.';
        ctx.dispatch(new GeneralError(message));
        return throwError(() => new Error(JSON.stringify(error)));
      })
    );
  }

  @Action(GetIncidentReportDetailSuccess)
  getIncidentReportDetailSuccess(ctx: StateContext<IncidentReportStateModel>, payload: GetIncidentReportDetailSuccess) {
    ctx.patchState({ selectedIncident: payload.detail });
  }
  //#endregion
}
