import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class PopUpService {
  constructor(public alertCtrl: AlertController) {}

  async popupAlert(title, message, alertCtrl: AlertController, handler = () => null, backdropDismiss?: boolean) {
    alertCtrl = alertCtrl || this.alertCtrl;
    const alert = await alertCtrl.create({
      header: title,
      message: message,
      backdropDismiss: !!backdropDismiss,
      buttons: [
        {
          text: 'OK',
          role: 'ok',
          handler: () => (handler ? handler() : null)
        }
      ]
    });
    await alert.present();
  }

  async popupConfirm(title, message, alertCtrl: AlertController, yesHandler = () => null, noHandler = () => null) {
    alertCtrl = alertCtrl || this.alertCtrl;
    const confirm = await alertCtrl.create({
      header: title,
      message: message,
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          handler: () => noHandler()
        },
        {
          text: 'Yes',
          role: 'ok',
          handler: () => yesHandler()
        }
      ]
    });
    await confirm.present();
  }

  async popupCustomConfirm(
    title,
    message,
    yesText,
    noText,
    alertCtrl: AlertController,
    yesHandler = () => null,
    noHandler = () => null
  ) {
    alertCtrl = alertCtrl || this.alertCtrl;
    const confirm = await alertCtrl.create({
      header: title,
      message: message,
      buttons: [
        {
          text: noText,
          role: 'cancel',
          handler: () => noHandler()
        },
        {
          text: yesText,
          role: 'ok',
          handler: () => yesHandler()
        }
      ]
    });
    await confirm.present();
  }

  async popupError(error, alertCtrl: AlertController, handler = () => null, title = 'Error') {
    alertCtrl = alertCtrl || this.alertCtrl;
    const message =
      typeof error === 'string' ? error : error.body && error.body.msg ? error.body.msg : 'Unexpected error';
    const alert = await alertCtrl.create({
      header: title,
      message,
      buttons: [
        {
          text: 'OK',
          role: 'ok',
          handler: () => handler()
        }
      ]
    });
    await alert.present();
  }
}
