import { Incident } from '@ea-models-v4/incident-report';

const STATE_KEY = '[Incident Report]';

const TYPE = (text: string) => STATE_KEY + ' ' + text;

export class GetInProgressIncidentReports {
  static readonly type = TYPE('Get In Progress Reports');
  constructor(public readonly start_index: number, public readonly limit: number) {}
}

export class GetIncidentReportDetail {
  static readonly type = TYPE('Get Incident Report Detail');
  constructor(public id: number) {}
}

export class GetIncidentReportDetailSuccess {
  static readonly type = TYPE('Get Incident Report Detail Success');
  constructor(public detail: Incident) {}
}
