import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_BASE_URL_V3 } from './constants';
import { HttpClient } from '@angular/common/http';
import { IAttachment, UploadAttachedPhoto } from '@ea-models';

interface UploadAttachmentResponse {
  object_attachment: { url: string; id: number };
  success: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AttachmentsService {
  constructor(private httpClient: HttpClient) {}

  public uploadAttachment(token: string, uploadData: UploadAttachedPhoto): Observable<UploadAttachmentResponse> {
    return this.httpClient.post<UploadAttachmentResponse>(
      `${API_BASE_URL_V3}/object_attachments?token=${token}`,
      uploadData
    );
  }

  // note: not implemented on backend yet
  public updateAttachments(token: string, attachmentsId: number, attachments: IAttachment): Observable<any> {
    return this.httpClient.put<any>(
      `${API_BASE_URL_V3}/object_attachments/${attachmentsId}?token=${token}`,
      attachments
    );
  }

  // note: not implemented on backend yet
  public deleteAttachments(token: string, attachmentsId: number): Observable<any> {
    return this.httpClient.delete<any>(`${API_BASE_URL_V3}/object_attachments/${attachmentsId}?token=${token}`);
  }

  public getEncodeAttachmentByURL(token: string, url: string): Observable<any> {
    return this.httpClient.post<any>(`${API_BASE_URL_V3}/encode_attachments?token=${token}`, { attachment_url: url });
  }
}
