<ea-header title="Finish Shift" [hasCloseButton]="true"></ea-header>

<ion-content class="groomer-finish-shift-container">
  <ion-grid class="section-component__body" style="width: 100%">
    <ion-row>
      <div class="title">Shift</div>
      <div class="control">
        <ng-select
                   [items]="groomerOperatorShifts"
                   [selectOnTab]="true"
                   class="standard-select"
                   bindLabel="shift_name"
                   bindValue="id"
                   [(ngModel)]="model.groomer_operator_shift_id"
                   [disabled]="!groomerOperatorShifts?.length">
        </ng-select>
      </div>
    </ion-row>
    <ion-row>
      <div class="title">Did the vehicle run for the full shift?</div>
      <div class="control flex">
        <div
             class="option"
             [class.active]="!!model.full_shift"
             (click)="changeFullShift(true)">
          Yes
        </div>
        <div
             class="option"
             [class.active]="model.full_shift === false"
             (click)="changeFullShift(false)">
          No
        </div>
      </div>
    </ion-row>
    <ion-row *ngIf="model.full_shift === false">
      <div class="title">Reason</div>
      <div class="control cbx" *ngFor="let item of reasons">
        <div>{{ item.value }}</div>
        <ion-checkbox
                      [disabled]="item.selected"
                      [checked]="item.selected"
                      (click)="changeReason(item)"></ion-checkbox>
      </div>
    </ion-row>
    <ion-row *ngIf="model.reason_for_unavailable === breakdownReason">
      <div class="title">Hours lost due to breakdown</div>
      <div class="control">
        <ion-input
                   type="{{ isMobilePlatform ? 'number' : 'text' }}"
                   debounce="400"
                   [(ngModel)]="model.hours_lost_due_to_breakdown"
                   class="input">
        </ion-input>
      </div>
    </ion-row>
    <ion-row>
      <div class="title no-p-b">Machine hours at end of shift</div>
      <div class="control">
        <input
               type="number"
               inputmode="numeric"
               class="input number"
               [(ngModel)]="model.hours_at_end_of_shift"
               (keydown)="utility.NumbersOnly($event, 'hours_at_end_of_shift', model)" />
        <!-- <ion-input  [(ngModel)]="model.hours_at_end_of_shift" class="input" type="{{globalService.isMobile ? 'number' : 'text'}}"
                    (ionChange)="onTextChange($event.target.value, 'hours_at_end_of_shift')">
        </ion-input> -->
      </div>
    </ion-row>
  </ion-grid>
</ion-content>
<div class="btn-groomer-finish-shift-container">
  <button class="btn-cancel no-ripple" (click)="reset()">
    Clear Shift Report
  </button>
  <button
          class="btn-success no-ripple"
          [disabled]="!allowSave"
          (click)="save()">
    Save and Submit
  </button>
</div>
<div class="successful-result-container" *ngIf="isSuccessful">
  <div class="over-lay"></div>
  <div class="successful-result-content">
    <div class="successful-result">
      <img
           src="/assets/image/black-close.svg"
           width="20px"
           (click)="close(true)" />
      <div class="result-container">
        <div class="result">
          <div>
            <img src="/assets/image/alert-circle-outline.svg" width="52px" />
          </div>
          <div class="large-text">Success</div>
          <div>Groomer Shift Submitted Successfully.</div>
          <div class="btn">
            <button class="btn-close no-ripple" (click)="close(true)">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>