import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable()
export class SpinnerService {
  public isLoading = false;
  public loading;

  constructor(public loadingController: LoadingController) {}

  async show(content, duration?: number, spinner?: string, cssClass?: string) {
    this.isLoading = true;

    await this.loadingController
      .create({
        message: content,
        duration: duration || 30000,
        spinner: (spinner as any) || 'crescent',
        cssClass: cssClass
      })
      .then((a) => {
        a.present().then(() => {
          if (!this.isLoading) {
            a.dismiss();
          }
        });
      });
  }

  async hide(force = false) {
    if (this.isLoading || force) {
      await this.loadingController.dismiss().catch((error) => console.log(error));
    }
    this.isLoading = false;
  }
}
