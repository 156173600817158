import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { GlobalService } from './global.service';
import { SpinnerService } from './spinner.service';
import { ConfigService } from './config.service';
import { AlertController } from '@ionic/angular';

type API_REQUEST_TYPE = 'get' | 'post' | 'put' | 'delete' | 'patch';
const API_REQUEST: { [method: string]: API_REQUEST_TYPE } = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
  DELETE: 'delete'
};

interface APIRequestData {
  method: string;
  url: string;
  headers?: Headers;
  params?: any;
  body?: any;
}

export interface APIRequestOptions {
  loadingText?: string;
  asData?: boolean;
  isShowFailedAlert?: boolean;
}
@Injectable()
export class ApiService {
  constructor(
    private http: HttpClient,
    public spinnerService: SpinnerService,
    public globalService: GlobalService,
    public configService: ConfigService,
    private alertController: AlertController
  ) {}

  /** Send an api request */
  public request<T>(_data: APIRequestData, options: APIRequestOptions = {}) {
    // Setup request options
    const token = this.globalService._currentUser ? this.globalService._currentUser.token : null;
    const params = token ? { token: token } : {};

    const headers = new HttpHeaders().set('Authorization', token);

    const data = Object.assign({ headers }, _data, { body: _data.body });
    data.params = { ...params, ...data.params };

    try {
      // Show spinner if loading text provided
      if (options.loadingText) {
        this.spinnerService.show(options.loadingText);
      }

      // Send request
      return this.http
        .request<T>(_data.method, _data.url, {
          body: data.body,
          params: data.params,
          headers: headers,
          observe: 'response'
        })
        .pipe(
          map((res) => this.extractData(res)),
          map((res) => (options.asData ? { data: res } : res)),
          catchError((error) => this.handleError(error, true, options))
        );
    } catch (err) {
      this.handleError(err);
    }
  }

  public get(url: string, params: any, loadingText: string = '') {
    return this.request({ method: API_REQUEST.GET, url, params }, { loadingText });
  }

  public patch(url: string, body: any, loadingText: string = '') {
    return this.request({ method: API_REQUEST.PATCH, url, body }, { loadingText });
  }

  public getWithOptions(url: string, params: any, options: APIRequestOptions = {}) {
    return this.request({ method: API_REQUEST.GET, url, params }, { asData: true, ...options }).toPromise();
  }

  public post(url: string, body: any, loadingText: string = '', isShowFailedAlert: boolean = true) {
    return this.request({ method: API_REQUEST.POST, url, body }, { loadingText, isShowFailedAlert });
  }

  public postWithOptions(url: string, body: any, params: any = {}, options: APIRequestOptions = {}) {
    return this.request({ method: API_REQUEST.POST, url, params, body }, { asData: true, ...options }).toPromise();
  }

  public put(url: string, body: any, loadingText: string = '') {
    return this.request({ method: API_REQUEST.PUT, url, body }, { loadingText });
  }

  public putWithOptions(url: string, body: any, params: any) {
    return this.request({
      method: API_REQUEST.PUT,
      url,
      body,
      params
    }).toPromise();
  }

  public delete(url: string, loadingText: string = '') {
    return this.request({ method: API_REQUEST.DELETE, url }, { loadingText }).toPromise();
  }

  public loadLocalJSON(url: string) {
    return this.request({ method: API_REQUEST.GET, url });
  }

  public extractData(res) {
    if (this.spinnerService.loading) {
      this.spinnerService.hide();
    }

    return res?.body;
  }

  public handleError(error: any, isReponse: boolean = false, options: APIRequestOptions = {}) {
    console.error('Handling error', error);
    this.spinnerService.hide();
    const defaultErrMessage = `
      Request failed due to network or server issue. <br>
      Please retry the request previously sent.<br>
      If the issue persists, please contact Support.
    `;
    const errMsg = error.message
      ? error.message
      : error.status
      ? error.status + ' - ' + error.statusText
      : defaultErrMessage;
    console.error('Request Error', errMsg);
    if (this.globalService.showReponseErrorPopup && options.isShowFailedAlert != false) {
      this.globalService.popupAlert('Request Error', errMsg, this.alertController);
    }

    if (!isReponse) {
      error.body = JSON.parse(error._body);
    }
    return throwError(error);
  }
}
