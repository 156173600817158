<ion-grid class="birth-date">
  <ion-row>
    <ion-col size-xs="12">
      <ion-item lines="none">
        <ion-label>Date of Birth</ion-label>
      </ion-item>
    </ion-col>
  </ion-row>
  <ion-row [class.required-add]="!!requiredObjects && requiredObjects.birthday">
    <ion-col size-xs="4">
      <ion-item lines="none">
        <ion-label>Month <span class="required">*</span></ion-label>
      </ion-item>
      <div class="ion-padding-start ion-padding-end">
        <ng-select
          [items]="months"
          [selectOnTab]="true"
          class="standard-select"
          bindLabel="name"
          [dropdownPosition]="isMobilePlatform ? 'top' : 'auto'"
          (search)="onSearching($event, 'month')"
          (close)="onClose('month', months, 'name', 'number')"
          (focus)="incidentProvider.scrollToElement(refEle?.nativeElement)"
          bindValue="number"
          [(ngModel)]="model.month"
          (change)="onChange()"
          [class.in-valid]="!model.month"
        >
        </ng-select>
      </div>
    </ion-col>
    <ion-col size-xs="4">
      <ion-item lines="none">
        <ion-label>Day <span class="required">*</span></ion-label>
      </ion-item>
      <div class="ion-padding-start ion-padding-end">
        <ng-select
          [items]="days"
          [selectOnTab]="true"
          class="standard-select"
          [class.in-valid]="!model.day"
          (search)="onSearching($event, 'day')"
          (close)="onClose('day', days)"
          (focus)="incidentProvider.scrollToElement(refEle?.nativeElement)"
          [(ngModel)]="model.day"
          (change)="onChange()"
          [dropdownPosition]="isMobilePlatform ? 'top' : 'auto'"
        >
        </ng-select>
      </div>
    </ion-col>
    <ion-col size-xs="4">
      <ion-item lines="none">
        <ion-label>Year <span class="required">*</span></ion-label>
      </ion-item>
      <div class="ion-padding-start ion-padding-end">
        <ng-select
          [items]="years"
          [selectOnTab]="true"
          class="standard-select"
          [(ngModel)]="model.year"
          (search)="onSearching($event, 'year')"
          (close)="onClose('year', years)"
          (focus)="incidentProvider.scrollToElement(refEle?.nativeElement)"
          (change)="onChange()"
          [class.in-valid]="!model.year"
          [dropdownPosition]="isMobilePlatform ? 'top' : 'auto'"
        >
        </ng-select>
      </div>
    </ion-col>
  </ion-row>
  <ion-row *ngIf="age >= 0">
    <ion-col size-xs="12">
      <ion-item lines="none">
        <ion-label>Age: {{ age }}</ion-label>
      </ion-item>
    </ion-col>
  </ion-row>
</ion-grid>
