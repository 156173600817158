export interface ReportFolder {
  id: number;
  name: string;
  parent_id?: number;
  sort_value?: number;
}

export interface IReport {
  id: number;
  icon: string;
  name: string;
  enabled: boolean;
  allow_staff_review_historical_data: boolean;
  allow_link_to_accident_report: boolean;
  allow_save_incomplete_report: boolean;
  report_folder_id: number;
  sections: IReportSection[];
  accident_report_number?: number;
  accident_report_id?: number;
}

export interface IReportSectionObject {
  object_id: number;
  object_name: string;
  is_enabled?: boolean;
  done?: boolean;
  expanded?: boolean;

  models?: any[];
}

export enum ReportQuestionType {
  select = 'select',
  boolean = 'boolean',
  text = 'text',
  number = 'number',
  checkbox = 'checkbox',
  time = 'time',
  signature = 'signature',
  patroller = 'patroller',
  lift_operator = 'lift_operator',
  lift_maintenance_staff = 'lift_maintenance_staff',
  park_staff = 'park_staff',
  ski_instructor = 'ski_instructor',
  image = 'image',
  date = 'date',
  diagram = 'diagram',
  string = 'string',
  multi_select = 'multi_select'
}

export interface IReportSectionQuestion {
  question_id: number;
  question: string;
  type: ReportQuestionType;
  required: boolean;
  options?: {
    id: number;
    text: string;
  }[];
}

export interface IReportSection {
  heading: string;
  template: 'single_page' | 'multi_page' | 'grid';
  save_status: boolean;
  save_label: string;
  objects: IReportSectionObject[];
  questions: IReportSectionQuestion[];
}

export interface IAttachmentsAttribute {
  id?: number;
  file?: string;
  note?: string;
  _destroy?: boolean;
}

export interface IDynamicComponentModel {
  submission_id?: number;
  question_id?: number;
  feature_id?: number;
  diagram_id?: number;
  temp_file?: string;
  diagram_image?: string;
  name?: string | boolean | number;
  value?: string | boolean | number;
  multi_values?: number[];
  time?: string;
  date?: string;
  toggler?: boolean;
  answer_save?: boolean;
  object_attachments_attributes?: IAttachmentsAttribute[];
  answer_id?: number;
  temp_diagram_image?: string;
  remove_file?: boolean;
}

export interface IDynamicComponentModelWrapper {
  model: IDynamicComponentModel;
  index: number;
  feature_id: number;
}
