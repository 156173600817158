import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { IAttachment } from '@ea-models';
import { REMOVE_IMAGE_MSG } from '../../pages/pages.constants';

@Component({
  selector: 'app-image-gallery-preview',
  templateUrl: './image-gallery-preview.component.html',
  styleUrls: ['./image-gallery-preview.component.scss']
})
export class ImageGalleryPreviewComponent implements OnInit {
  @Input() attachment: IAttachment;
  @Input() hideNote: boolean;
  @Input() hideRemove: boolean;

  constructor(
    private modalController: ModalController,
    private alertController: AlertController,
    private toastController: ToastController
  ) {}

  ngOnInit() {}

  close() {
    this.modalController.dismiss(this.attachment);
  }

  remove() {
    this.attachment.object_attachment._destroy = true;
    this.close();
  }

  async confirmRemove() {
    const alert = await this.alertController.create({
      header: 'Alert',
      message: REMOVE_IMAGE_MSG,
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          handler: () => {}
        },
        {
          text: 'Yes',
          handler: () => this.remove()
        }
      ]
    });

    await alert.present();
  }

  async presentToast() {
    const toast = await this.toastController.create({
      message: 'Image description has been saved.',
      color: 'warning',
      position: 'top',
      duration: 1700
    });
    toast.present();
  }

  noteChanged() {
    this.presentToast();
  }
}
