import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpClient,
  HttpParams
} from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { Store } from '@ngxs/store';

import { AuthState } from '@edgeauditor/app/store/auth/auth.state';
import { AuthenticationService, AUTH_SESSION } from '@ea-services-v4';
import { AlertButton, AlertController } from '@ionic/angular';
import { GlobalService, RecentUsers, SpinnerService } from '@ea-services';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  constructor(
    private store: Store,
    private authService: AuthenticationService,
    private alertCtrl: AlertController,
    private recentUsersService: RecentUsers,
    private globalService: GlobalService,
    private httpClient: HttpClient,
    private spinnerService: SpinnerService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let headers = request.headers;
    let params = request.params;
    const token = this.store.selectSnapshot(AuthState.getV4JWT);
    if (request.url.includes('api/v4') && !request.headers.has('Authorization') && token) {
      headers = headers.set('Authorization', token);
    }
    request = request.clone({ headers });

    return next.handle(request).pipe(
      catchError((e) => {
        if (this.isAuthError(e)) {
          return this.authService.refreshToken().pipe(
            tap((token) => this.refreshTokenHandler(token, params, request)),
            switchMap((_) => from(this.confirmRetry())),
            switchMap((isConfirm) => (isConfirm ? this.httpClient.request(request) : throwError(() => e)))
          );
        }
        return throwError(() => e);
      })
    );
  }

  private refreshTokenHandler(token: string, params: HttpParams, request: HttpRequest<unknown>) {
    const user = this.globalService._currentUser;
    user.token = token;
    this.recentUsersService.update(user);
    this.globalService.setCurrentUser(user);
    sessionStorage.setItem(AUTH_SESSION, JSON.stringify({ user }));
    params.set('token', this.globalService._currentUser.token);
    request = request.clone({ params });
  }

  private async confirmRetry(): Promise<boolean> {
    this.spinnerService.hide();
    const buttons: AlertButton[] = [
      { text: 'Cancel', role: 'cancel' },
      { text: 'Retry', role: 'ok' }
    ];
    const alert = await this.alertCtrl.create({ header: 'Expired Token', message: 'Do you want to retry', buttons });
    await alert.present();
    const data = await alert.onWillDismiss();
    return data.role === 'ok';
  }

  private isAuthError(error: any): boolean {
    return error instanceof HttpErrorResponse && error.status === 401;
  }
}
