<ea-header title="New Sign" [hasNetworkIndicator]="true"></ea-header>

<ion-content scrollY="true">
  <ion-grid class="section-component__body" style="width: 100%">
    <ion-row>
      <ion-col size-xs="12">
        <app-image-picker
          (imageSelected)="onImageChange($event)"
          [hideNote]="true"
          [singleImageOnly]="true"
          [isSupportResizePhoto]="true"
        >
        </app-image-picker>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size-xs="12" size-sm="6">Location</ion-col>
      <ion-col size-xs="12" size-sm="6">
        <div><strong>Latitude:</strong> {{ coords.latitude }}</div>
        <div><strong>Longitude:</strong> {{ coords.longitude }}</div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size-xs="12" size-sm="6"> Sign Name</ion-col>
      <ion-col size-xs="12" size-sm="6">
        <ion-input class="standard-input" [(ngModel)]="sign.name"></ion-input>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size-xs="12" size-sm="6">Sign Category</ion-col>
      <ion-col size-xs="12" size-sm="6">
        <ng-select
          [items]="sign_types"
          [selectOnTab]="true"
          class="standard-select"
          bindValue="id"
          [searchFn]="customSearchFn"
          notFoundText="No item found"
          placeholder="Select Sign Category"
          bindLabel="name"
          [(ngModel)]="sign.category_id"
          [searchable]="true"
        >
        </ng-select>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size-xs="12">Comments</ion-col>
      <ion-col size-xs="12">
        <ion-textarea
          rows="5"
          class="standard-input"
          [(ngModel)]="sign.notes"
        ></ion-textarea>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size-xs="6">
        <ion-button expand="full" color="danger" (click)="close()"
          >Cancel</ion-button
        >
      </ion-col>
      <ion-col size-xs="6">
        <ion-button
          expand="full"
          color="success"
          [disabled]="!isReadyToSave"
          (click)="save()"
          >Save</ion-button
        >
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
