import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  ElementRef,
  AfterContentInit
} from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import moment from 'moment';
import _ from 'lodash';

import {
  IDynamicComponentModel,
  IReportSectionQuestion,
  ReportQuestionType,
  IDynamicComponentModelWrapper
} from '@ea-models/reports';
import { User, IAttachment } from '@ea-models';
import { ReportService } from '@ea-services';

@Component({
  selector: 'app-dynamic-component',
  templateUrl: './dynamic-component.component.html',
  styleUrls: ['./dynamic-component.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicComponentComponent implements OnInit, AfterContentInit {
  @Input() question: IReportSectionQuestion;
  @Input() objectId: number;
  @Input() objectIndex: number;
  @Input() questionIndex: number;
  @Input() sectionIndex: number;
  @Input() userEvent: Observable<User>;
  @Input() restoreSectionEvent: Observable<any>;
  @Input() isSupportResizePhoto: boolean;
  @Input() showWarningResizePhoto: boolean;
  @Input() parentChangeDetectorRef: ChangeDetectorRef;
  @Input() isReadyToRender: boolean = true;
  @Input() renderQuestionName: boolean;
  @Output() modelChanged = new EventEmitter<IDynamicComponentModel>();

  readonly ReportQuestionType = ReportQuestionType;
  readonly SelectTypes = {
    [ReportQuestionType.patroller]: true,
    [ReportQuestionType.lift_operator]: true,
    [ReportQuestionType.lift_maintenance_staff]: true,
    [ReportQuestionType.park_staff]: true,
    [ReportQuestionType.ski_instructor]: true
  };
  readonly InputTypes = [ReportQuestionType.number, ReportQuestionType.string];
  readonly DateTimeTypes = [ReportQuestionType.date, ReportQuestionType.time];
  readonly selectsMapping = {
    [ReportQuestionType.patroller]: 'patrollers',
    [ReportQuestionType.lift_operator]: 'lift_operators',
    [ReportQuestionType.lift_maintenance_staff]: 'lift_maintenance_staff',
    [ReportQuestionType.park_staff]: 'park_staff',
    [ReportQuestionType.ski_instructor]: 'ski_instructors'
  };
  maxDate = moment().endOf('day').toDate();
  currentUser: User;
  model: IDynamicComponentModel = { value: null, date: null, time: null };
  signSubject: ReplaySubject<string> = new ReplaySubject<string>(1);
  diagramSubject: ReplaySubject<any> = new ReplaySubject<any>(1);
  imagesSubject: ReplaySubject<any> = new ReplaySubject<any>(1);
  dataCI: string;
  private isInit = false;

  constructor(public elementRef: ElementRef, private reportService: ReportService) {}

  ngOnInit() {
    if (this.userEvent) {
      this.userEvent
        .pipe(
          take(1),
          tap((user) => (this.currentUser = user)),
          tap(() => {
            const key = this.selectsMapping[this.question.type];
            if (key) {
              this.currentUser[key].forEach((item: any) => (item.name = `${item.first_name} ${item.last_name}`));
            }
          })
        )
        .subscribe();
    }
  }

  ngAfterContentInit() {
    if (!this.isInit) this.init();
  }

  init() {
    if (this.reportService.currReportSections && this.reportService.currReportSections.length > 0) {
      this.restoreData(this.reportService.currReportSections);
    }
    if (this.question.type === ReportQuestionType.checkbox || this.question.type === ReportQuestionType.boolean) {
      let value = this.model.value || false;
      if (
        value &&
        typeof value === 'string' &&
        this.question.type === ReportQuestionType.boolean &&
        this.question.options
      ) {
        const index = this.question.options.findIndex((o) => o.text === value);
        value = index === 0 ? true : false;
        this.model.toggler = value;
      } else if (typeof value === 'string' && this.question.type === ReportQuestionType.checkbox) {
        value = value === '0' ? false : value;
      }
      this.model.value = value;
    }
    this.emitChangeEvent();
    this.isInit = true;
    this.dataCI = `${this.sectionIndex}${this.objectIndex}${this.questionIndex}`;
  }

  onChangeEvent(e: Event, custom: ReportQuestionType = null) {
    this.onChange((<HTMLTextAreaElement>e.target).value, custom);
  }

  onCheckboxChange(e: Event) {
    this.onChange((<HTMLIonCheckboxElement>e.target).checked, ReportQuestionType.checkbox);
  }

  onChange(value: string | number | boolean | { id: number; text: string }[], custom: ReportQuestionType = null) {
    if (value instanceof Array) {
      this.model.question_id = this.question.question_id;
      this.model.multi_values = _.isEmpty(value) ? [] : value.map((i) => i.id);
      const obj: IDynamicComponentModelWrapper = {
        index: this.questionIndex,
        model: { ...this.model },
        feature_id: this.objectId
      };
      this.questionChanged(obj, this.sectionIndex);
    } else {
      const dtVal = (value as any) instanceof Date ? moment(value as unknown as Date).format() : '';
      const customValMapping = {
        checkbox: value ? 1 : 0,
        boolean: this.question.options ? (this.question.options[value ? 0 : 1] || ({} as any)).text || null : null,
        date: dtVal,
        time: dtVal
      };
      const key = ['date', 'time'].includes(custom) ? custom : 'value';
      this.model.question_id = this.question.question_id;
      if (custom) this.model[key] = customValMapping[custom];
      const obj: IDynamicComponentModelWrapper = {
        model: { ...this.model },
        index: this.questionIndex,
        feature_id: this.objectId
      };
      this.questionChanged(obj, this.sectionIndex);
    }

    // this.modelChanged.emit({...this.model});
  }

  onSelectChange(newVal: _.Dictionary<string> | { id: number; text: string }[], key: string, isMultiple = false) {
    if (isMultiple && newVal instanceof Array) {
      this.onChange(newVal);
    } else {
      const _value: any = !_.isNil(newVal) && !_.isNil(newVal[key]) ? newVal[key] : newVal;
      this.onChange(!_.isNil(_value) ? _value : '');
    }
  }

  onTextChange(e: Event) {
    const value = (<HTMLIonTextareaElement | HTMLIonInputElement>e.target).value;
    this.model.value = value;
    this.onChange(value);
  }

  onDiagramChange(newVal: IDynamicComponentModel) {
    this.model.diagram_id = newVal.diagram_id;
    this.model.diagram_image = newVal.diagram_image;
    this.model.object_attachments_attributes = newVal.object_attachments_attributes || [];
    this.onChange(newVal.diagram_image);
  }

  onSignatureChange(signatureData: string, isClear?: boolean) {
    this.model.temp_file = signatureData;
    if (isClear) this.model['remove_file'] = isClear;
    else delete this.model['remove_file'];
    this.onChange(signatureData);
  }

  onImageChange(imagesData: { currentImage: IAttachment; images: IAttachment[] }) {
    const attachments = imagesData.images.map((img) => img.object_attachment) || [];
    if (this.model.object_attachments_attributes && this.model.object_attachments_attributes.length) {
      const deletedAttachments = [];
      this.model.object_attachments_attributes.forEach((img) => {
        if (img.id && !attachments.some((i) => i['id'] === img.id)) {
          img._destroy = true;
          deletedAttachments.push(img);
        }
      });
      this.model.object_attachments_attributes = [...attachments, ...deletedAttachments];
    } else {
      this.model.object_attachments_attributes = attachments;
    }
    this.onChange('');
  }

  restoreData(section) {
    const findFn = (item) => {
      return (
        item &&
        !item.isRestored &&
        this.question &&
        item.question_id === this.question.question_id &&
        (item.feature_id || item.object_id) === this.objectId
      );
    };
    const compData = section && section.find && section.find(findFn);
    if (compData) {
      compData.isRestored = true;
      this.model = { ...compData };
    }
    if (this.model.temp_file && this.model.temp_file !== '') {
      this.signSubject.next(this.model.temp_file);
    }
    if (this.question.type === ReportQuestionType.diagram) {
      this.diagramSubject.next({
        diagram_image: this.model.diagram_image,
        diagram_id: this.model.diagram_id,
        object_attachments_attributes: this.model.object_attachments_attributes
      });
    }
    if (this.model.object_attachments_attributes && this.question.type === ReportQuestionType.image) {
      this.imagesSubject.next(
        this.model.object_attachments_attributes.map((img) => ({
          object_attachment: img
        }))
      );
    }
  }

  private emitChangeEvent() {
    const customTypes = [
      ReportQuestionType.date,
      ReportQuestionType.time,
      ReportQuestionType.boolean,
      ReportQuestionType.checkbox
    ];
    const value = this.model.date || this.model.time || this.model.value || '';
    let custom = null;
    if (customTypes.includes(this.question.type)) custom = this.question.type;
    this.onChange(value, custom);
  }

  questionChanged($event: IDynamicComponentModelWrapper, sectionIndex: number) {
    const item = { ...$event.model };
    item.feature_id = $event.feature_id;
    item.answer_save = true;
    this.addUpdateQuestion(item, sectionIndex);
  }

  addUpdateQuestion(item, sectionIndex: number) {
    this.reportService.currReportModel[sectionIndex] = this.reportService.currReportModel[sectionIndex] || [];
    // const idx = this.reportService.currReportModel[sectionIndex].findIndex((obj) => obj.feature_id === item.feature_id && obj.question_id === item.question_id);
    const index = +`${this.objectIndex + 1}${this.questionIndex}`;
    this.reportService.currReportModel[sectionIndex][index] = item;
  }
}
