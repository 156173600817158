// IPC event constants
export const IPC_EVENTS = {
  LOGOUT: 'logout',
  GO_TO: 'goTo',
  LOGIN_SUCCESS: 'loginSuccess',
  LOGOUT_SUCCESS: 'logoutSuccess',
  UPDATE_STARTED: 'updateStarted',
  UPDATE_PROGRESS: 'updateProgress'
};

// Constants for getting type references
export const FEATURE_BUILD_PAGE = '/feature-build';
export const SPECIAL_EVENT_PAGE = '/special-events-build';
export const OBJECT_INSPECT_PAGE = '/objects/inspect';
export const GROOMER_PAGE = '/groomer/assignments';
export const LOGIN_FAIL = 'The credentials you entered are not valid. Please try again.';

export const NETWORK_ERROR = 'CONNECTION ERROR';
export const NETWORK_ERROR_ACCESS_FEATURE = 'You need a working internet connection to access this feature.';
export const WARNING_HEADER = 'WARNING';
export const NETWORK_ERROR_DASHBOARD_MSG = `You are using EDGEauditor without a working internet connection. 
Only items under NEW REPORT, HEALTH & SAFETY and EMERGENCY OPERATION PLANS can be used in this offline mode. 
Incident reports saved as in progress to cloud will not be available without an internet connection.`;

export const NETWORK_ERROR_INCIDENT_REPORT_NO_ID_MSG = `You are using EDGEauditor without a working internet connection. 
Incident location mapping will not be available in this offline mode. A Report ID will be generated after you connect to the internet and save/submit report to the cloud.`;
export const NETWORK_ERROR_INCIDENT_REPORT_MSG = `You are using EDGEauditor without a working internet connection. Incident location mapping will not be available in this offline mode.`;
export const NO_CACHED_DATA_FOR_OFFLINE =
  'You have not accessed this feature on this device before. It requires an internet connection to access it for the first time. Once you have a connection again open this feature on this device so you will be able to use it offline.';
export const EXIT_MSG = 'Are you sure want to exit?';

export const CANNOT_CLEAR_CACHED_USERS = `Unable to clear previous logins to increase available space since one or more users have a locally saved report. 
Please ask the previous user/users to clear their reports from PENDING LOCAL REPORTS in order to allow additional logins on this device.`;
export const CANNOT_CLEAR_CACHED_USER = `We tried to clear the following login to increase available space, 
but they have a locally saved report. Please ask user to clear the report from PENDING LOCAL REPORTS to free up space. 
The login associated with this user is `;

export const INCIDENT_EXIT_MSG = EXIT_MSG;

export const DIAGRAM_EXIT_MSG = `
Are you sure you want to close this diagram? If you close, any changes made to the diagram will not be saved.
`;

export const REPORT_SAVED_LOCALLY_HEADER = 'Report Saved';
export const REPORT_SAVED_LOCALLY_MSG = `Report has been saved to Pending Local Reports on this device.`;
export const INCIDENT_SAVED_LOCALLY_MSG = `
Incident Report has been saved to Pending Local Reports on this device.
You must finish the report on this device unless you choose "Save Incomplete Report to Cloud" the next time you save the report.
`;

export const FEATURES_SUBMITTED_MSG = 'Features successfully submitted.';

export const INCIDENT_SAVED_TO_CLOUD_MSG = 'Report has been successfully uploaded.';

export const REPORT_SAVED_TO_CLOUD_MSG = INCIDENT_SAVED_TO_CLOUD_MSG;
export const REPORT_SAVED_TO_CLOUD_HEADER = 'Report Complete';
export const INCIDENT_REPORT_SAVED_TO_CLOUD_HEADER = 'Incident Report Complete';
//---TODO: remove this when ready
export const INCOMPLETE_INCIDENT_REPORT_SAVED_TO_CLOUD_MSG =
  'To access this report go to Reports Saved In Progress To Cloud > Incident Reports In Progress.';
// export const INCOMPLETE_INCIDENT_REPORT_SAVED_TO_CLOUD_MSG = 'To access this report go to Reports Saved In Progress To Cloud.';
export const INCOMPLETE_INCIDENT_REPORT_SAVED_TO_CLOUD_HEADER = 'Incident Report Saved';
export const INCOMPLETE_REPORT_SAVED_TO_CLOUD_MSG =
  'To access this report go to Reports Saved In Progress To Cloud > Other Reports In Progress.';
export const INCOMPLETE_REPORT_SAVED_TO_CLOUD_HEADER = 'Report Saved';

export const BUILD_SAVED_TO_CLOUD_HEADER = 'Build Completed';
export const BUILD_SAVED_TO_CLOUD_MSG = `Build has been submitted successfully.`;

export const SPECIAL_EVENT_BUILD_SAVED_TO_CLOUD_MSG = `Special Event Build has been submitted successfully.`;

export const BUILD_SAVED_TO_LOCAL_HEADER = 'Build Saved';
export const BUILD_SAVED_TO_LOCAL_MSG = `Build has been saved to Pending Local Reports on this device.
The build you saved can only be accessed on this device. To submit the build, choose "Save and Close Out to Cloud" the next time you save this build.`;

export const INCIDENT_BACKUP_DETECTED_MSG = `
An unsaved incident report has been detected. Do you want to continue editing this report or delete it from the app?
`;

export const REMOVE_REPORT_MSG = 'Are you sure you want to delete this report?';
export const REMOVE_OBJECT_MSG = 'Are you sure you want to delete this object?';

export const REMOVE_IMAGE_MSG = 'Are you sure you want to delete this image?';

export const SUBMIT_BUILD_MSG = 'Have you added ALL the features for this build?';
export const REMOVE_PARK_BUILD_MSG = 'Are you sure you want to delete this build?';

export const DELETE_BUILD_FEATURES_MSG = 'Do you wish to delete all pending features?';

//#region Health and Safety
export const MEETING_SAVED_TO_CLOUD_HEADER = 'Safety Meeting Submitted';
export const MEETING_SAVED_TO_CLOUD_MESSAGE = 'Safety Meeting has been successfully submitted.';

export const MEETING_SAVED_TO_DEVICE_HEADER = 'Safety Meeting Saved';
export const MEETING_SAVED_TO_DEVICE_MESSAGE = 'To access this meeting again, please go to PENDING LOCAL REPORTS.';

export const FORMAL_TRAINING_SAVED_TO_CLOUD_HEADER = 'Training Submitted';
export const FORMAL_TRAINING_SAVED_TO_CLOUD_MESSAGE = 'Training has been successfully submitted.';

export const FORMAL_TRAINING_SAVED_TO_DEVICE_HEADER = 'Training Saved';
export const FORMAL_TRAINING_SAVED_TO_DEVICE_MESSAGE =
  'To access this training again, please go to PENDING LOCAL REPORTS.';

////#endregion
// json from https://gist.github.com/Keeguon/2310008
export const INCIDENT_COUNTRIES = [
  { name: 'Canada', code: 'CA' },
  { name: 'United States', code: 'US' },
  { name: 'Afghanistan', code: 'AF' },
  { name: 'Åland Islands', code: 'AX' },
  { name: 'Albania', code: 'AL' },
  { name: 'Algeria', code: 'DZ' },
  { name: 'American Samoa', code: 'AS' },
  { name: 'AndorrA', code: 'AD' },
  { name: 'Angola', code: 'AO' },
  { name: 'Anguilla', code: 'AI' },
  { name: 'Antarctica', code: 'AQ' },
  { name: 'Antigua and Barbuda', code: 'AG' },
  { name: 'Argentina', code: 'AR' },
  { name: 'Armenia', code: 'AM' },
  { name: 'Aruba', code: 'AW' },
  { name: 'Australia', code: 'AU' },
  { name: 'Austria', code: 'AT' },
  { name: 'Azerbaijan', code: 'AZ' },
  { name: 'Bahamas', code: 'BS' },
  { name: 'Bahrain', code: 'BH' },
  { name: 'Bangladesh', code: 'BD' },
  { name: 'Barbados', code: 'BB' },
  { name: 'Belarus', code: 'BY' },
  { name: 'Belgium', code: 'BE' },
  { name: 'Belize', code: 'BZ' },
  { name: 'Benin', code: 'BJ' },
  { name: 'Bermuda', code: 'BM' },
  { name: 'Bhutan', code: 'BT' },
  { name: 'Bolivia', code: 'BO' },
  { name: 'Bosnia and Herzegovina', code: 'BA' },
  { name: 'Botswana', code: 'BW' },
  { name: 'Bouvet Island', code: 'BV' },
  { name: 'Brazil', code: 'BR' },
  { name: 'British Indian Ocean Territory', code: 'IO' },
  { name: 'Brunei Darussalam', code: 'BN' },
  { name: 'Bulgaria', code: 'BG' },
  { name: 'Burkina Faso', code: 'BF' },
  { name: 'Burundi', code: 'BI' },
  { name: 'Cambodia', code: 'KH' },
  { name: 'Cameroon', code: 'CM' },

  { name: 'Cape Verde', code: 'CV' },
  { name: 'Cayman Islands', code: 'KY' },
  { name: 'Central African Republic', code: 'CF' },
  { name: 'Chad', code: 'TD' },
  { name: 'Chile', code: 'CL' },
  { name: 'China', code: 'CN' },
  { name: 'Christmas Island', code: 'CX' },
  { name: 'Cocos (Keeling) Islands', code: 'CC' },
  { name: 'Colombia', code: 'CO' },
  { name: 'Comoros', code: 'KM' },
  { name: 'Congo', code: 'CG' },
  { name: 'Congo, The Democratic Republic of the', code: 'CD' },
  { name: 'Cook Islands', code: 'CK' },
  { name: 'Costa Rica', code: 'CR' },
  { name: 'Cote D"Ivoire', code: 'CI' },
  { name: 'Croatia', code: 'HR' },
  { name: 'Cuba', code: 'CU' },
  { name: 'Cyprus', code: 'CY' },
  { name: 'Czech Republic', code: 'CZ' },
  { name: 'Denmark', code: 'DK' },
  { name: 'Djibouti', code: 'DJ' },
  { name: 'Dominica', code: 'DM' },
  { name: 'Dominican Republic', code: 'DO' },
  { name: 'Ecuador', code: 'EC' },
  { name: 'Egypt', code: 'EG' },
  { name: 'El Salvador', code: 'SV' },
  { name: 'Equatorial Guinea', code: 'GQ' },
  { name: 'Eritrea', code: 'ER' },
  { name: 'Estonia', code: 'EE' },
  { name: 'Ethiopia', code: 'ET' },
  { name: 'Falkland Islands (Malvinas)', code: 'FK' },
  { name: 'Faroe Islands', code: 'FO' },
  { name: 'Fiji', code: 'FJ' },
  { name: 'Finland', code: 'FI' },
  { name: 'France', code: 'FR' },
  { name: 'French Guiana', code: 'GF' },
  { name: 'French Polynesia', code: 'PF' },
  { name: 'French Southern Territories', code: 'TF' },
  { name: 'Gabon', code: 'GA' },
  { name: 'Gambia', code: 'GM' },
  { name: 'Georgia', code: 'GE' },
  { name: 'Germany', code: 'DE' },
  { name: 'Ghana', code: 'GH' },
  { name: 'Gibraltar', code: 'GI' },
  { name: 'Greece', code: 'GR' },
  { name: 'Greenland', code: 'GL' },
  { name: 'Grenada', code: 'GD' },
  { name: 'Guadeloupe', code: 'GP' },
  { name: 'Guam', code: 'GU' },
  { name: 'Guatemala', code: 'GT' },
  { name: 'Guernsey', code: 'GG' },
  { name: 'Guinea', code: 'GN' },
  { name: 'Guinea-Bissau', code: 'GW' },
  { name: 'Guyana', code: 'GY' },
  { name: 'Haiti', code: 'HT' },
  { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
  { name: 'Holy See (Vatican City State)', code: 'VA' },
  { name: 'Honduras', code: 'HN' },
  { name: 'Hong Kong', code: 'HK' },
  { name: 'Hungary', code: 'HU' },
  { name: 'Iceland', code: 'IS' },
  { name: 'India', code: 'IN' },
  { name: 'Indonesia', code: 'ID' },
  { name: 'Iran, Islamic Republic Of', code: 'IR' },
  { name: 'Iraq', code: 'IQ' },
  { name: 'Ireland', code: 'IE' },
  { name: 'Isle of Man', code: 'IM' },
  { name: 'Israel', code: 'IL' },
  { name: 'Italy', code: 'IT' },
  { name: 'Jamaica', code: 'JM' },
  { name: 'Japan', code: 'JP' },
  { name: 'Jersey', code: 'JE' },
  { name: 'Jordan', code: 'JO' },
  { name: 'Kazakhstan', code: 'KZ' },
  { name: 'Kenya', code: 'KE' },
  { name: 'Kiribati', code: 'KI' },
  { name: 'Korea, Democratic People"S Republic of', code: 'KP' },
  { name: 'Korea, Republic of', code: 'KR' },
  { name: 'Kuwait', code: 'KW' },
  { name: 'Kyrgyzstan', code: 'KG' },
  { name: 'Lao People"S Democratic Republic', code: 'LA' },
  { name: 'Latvia', code: 'LV' },
  { name: 'Lebanon', code: 'LB' },
  { name: 'Lesotho', code: 'LS' },
  { name: 'Liberia', code: 'LR' },
  { name: 'Libyan Arab Jamahiriya', code: 'LY' },
  { name: 'Liechtenstein', code: 'LI' },
  { name: 'Lithuania', code: 'LT' },
  { name: 'Luxembourg', code: 'LU' },
  { name: 'Macao', code: 'MO' },
  { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
  { name: 'Madagascar', code: 'MG' },
  { name: 'Malawi', code: 'MW' },
  { name: 'Malaysia', code: 'MY' },
  { name: 'Maldives', code: 'MV' },
  { name: 'Mali', code: 'ML' },
  { name: 'Malta', code: 'MT' },
  { name: 'Marshall Islands', code: 'MH' },
  { name: 'Martinique', code: 'MQ' },
  { name: 'Mauritania', code: 'MR' },
  { name: 'Mauritius', code: 'MU' },
  { name: 'Mayotte', code: 'YT' },
  { name: 'Mexico', code: 'MX' },
  { name: 'Micronesia, Federated States of', code: 'FM' },
  { name: 'Moldova, Republic of', code: 'MD' },
  { name: 'Monaco', code: 'MC' },
  { name: 'Mongolia', code: 'MN' },
  { name: 'Montserrat', code: 'MS' },
  { name: 'Morocco', code: 'MA' },
  { name: 'Mozambique', code: 'MZ' },
  { name: 'Myanmar', code: 'MM' },
  { name: 'Namibia', code: 'NA' },
  { name: 'Nauru', code: 'NR' },
  { name: 'Nepal', code: 'NP' },
  { name: 'Netherlands', code: 'NL' },
  { name: 'Netherlands Antilles', code: 'AN' },
  { name: 'New Caledonia', code: 'NC' },
  { name: 'New Zealand', code: 'NZ' },
  { name: 'Nicaragua', code: 'NI' },
  { name: 'Niger', code: 'NE' },
  { name: 'Nigeria', code: 'NG' },
  { name: 'Niue', code: 'NU' },
  { name: 'Norfolk Island', code: 'NF' },
  { name: 'Northern Mariana Islands', code: 'MP' },
  { name: 'Norway', code: 'NO' },
  { name: 'Oman', code: 'OM' },
  { name: 'Pakistan', code: 'PK' },
  { name: 'Palau', code: 'PW' },
  { name: 'Palestinian Territory, Occupied', code: 'PS' },
  { name: 'Panama', code: 'PA' },
  { name: 'Papua New Guinea', code: 'PG' },
  { name: 'Paraguay', code: 'PY' },
  { name: 'Peru', code: 'PE' },
  { name: 'Philippines', code: 'PH' },
  { name: 'Pitcairn', code: 'PN' },
  { name: 'Poland', code: 'PL' },
  { name: 'Portugal', code: 'PT' },
  { name: 'Puerto Rico', code: 'PR' },
  { name: 'Qatar', code: 'QA' },
  { name: 'Reunion', code: 'RE' },
  { name: 'Romania', code: 'RO' },
  { name: 'Russian Federation', code: 'RU' },
  { name: 'RWANDA', code: 'RW' },
  { name: 'Saint Helena', code: 'SH' },
  { name: 'Saint Kitts and Nevis', code: 'KN' },
  { name: 'Saint Lucia', code: 'LC' },
  { name: 'Saint Pierre and Miquelon', code: 'PM' },
  { name: 'Saint Vincent and the Grenadines', code: 'VC' },
  { name: 'Samoa', code: 'WS' },
  { name: 'San Marino', code: 'SM' },
  { name: 'Sao Tome and Principe', code: 'ST' },
  { name: 'Saudi Arabia', code: 'SA' },
  { name: 'Senegal', code: 'SN' },
  { name: 'Serbia and Montenegro', code: 'CS' },
  { name: 'Seychelles', code: 'SC' },
  { name: 'Sierra Leone', code: 'SL' },
  { name: 'Singapore', code: 'SG' },
  { name: 'Slovakia', code: 'SK' },
  { name: 'Slovenia', code: 'SI' },
  { name: 'Solomon Islands', code: 'SB' },
  { name: 'Somalia', code: 'SO' },
  { name: 'South Africa', code: 'ZA' },
  { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
  { name: 'Spain', code: 'ES' },
  { name: 'Sri Lanka', code: 'LK' },
  { name: 'Sudan', code: 'SD' },
  { name: 'Suriname', code: 'SR' },
  { name: 'Svalbard and Jan Mayen', code: 'SJ' },
  { name: 'Swaziland', code: 'SZ' },
  { name: 'Sweden', code: 'SE' },
  { name: 'Switzerland', code: 'CH' },
  { name: 'Syrian Arab Republic', code: 'SY' },
  { name: 'Taiwan, Province of China', code: 'TW' },
  { name: 'Tajikistan', code: 'TJ' },
  { name: 'Tanzania, United Republic of', code: 'TZ' },
  { name: 'Thailand', code: 'TH' },
  { name: 'Timor-Leste', code: 'TL' },
  { name: 'Togo', code: 'TG' },
  { name: 'Tokelau', code: 'TK' },
  { name: 'Tonga', code: 'TO' },
  { name: 'Trinidad and Tobago', code: 'TT' },
  { name: 'Tunisia', code: 'TN' },
  { name: 'Turkey', code: 'TR' },
  { name: 'Turkmenistan', code: 'TM' },
  { name: 'Turks and Caicos Islands', code: 'TC' },
  { name: 'Tuvalu', code: 'TV' },
  { name: 'Uganda', code: 'UG' },
  { name: 'Ukraine', code: 'UA' },
  { name: 'United Arab Emirates', code: 'AE' },
  { name: 'United Kingdom', code: 'GB' },

  { name: 'United States Minor Outlying Islands', code: 'UM' },
  { name: 'Uruguay', code: 'UY' },
  { name: 'Uzbekistan', code: 'UZ' },
  { name: 'Vanuatu', code: 'VU' },
  { name: 'Venezuela', code: 'VE' },
  { name: 'Viet Nam', code: 'VN' },
  { name: 'Virgin Islands, British', code: 'VG' },
  { name: 'Virgin Islands, U.S.', code: 'VI' },
  { name: 'Wallis and Futuna', code: 'WF' },
  { name: 'Western Sahara', code: 'EH' },
  { name: 'Yemen', code: 'YE' },
  { name: 'Zambia', code: 'ZM' },
  { name: 'Zimbabwe', code: 'ZW' }
];

export const INCIDENT_PROVINCES = [
  { prov: 'ALBERTA', code: 'AB' },
  { prov: 'BRITISH COLUMBIA', code: 'BC' },
  { prov: 'MANITOBA', code: 'MB' },
  { prov: 'NEW BRUNSWICK', code: 'NB' },
  { prov: 'NEWFOUNDLAND AND LABRADOR', code: 'NL' },
  { prov: 'NOVA SCOTIA', code: 'NS' },
  { prov: 'ONTARIO', code: 'ON' },
  { prov: 'PRINCE EDWARD ISLAND', code: 'PE' },
  { prov: 'QUEBEC', code: 'QC' },
  { prov: 'SASKATCHEWAN', code: 'SK' },
  { prov: 'NORTHWEST TERRITORIES', code: 'NT' },
  { prov: 'NUNAVUT', code: 'NU' },
  { prov: 'YUKON TERRITORIES', code: 'YT' }
];
// US
export const INCIDENT_STATES = [
  { prov: 'ALABAMA', code: 'AL' },
  { prov: 'ALASKA', code: 'AK' },
  { prov: 'ARIZONA', code: 'AZ' },
  { prov: 'ARKANSAS', code: 'AR' },
  { prov: 'CALIFORNIA', code: 'CA' },
  { prov: 'COLORADO', code: 'CO' },
  { prov: 'CONNECTICUT', code: 'CT' },
  { prov: 'DELAWARE', code: 'DE' },
  { prov: 'DISTRICT OF COLUMBIA', code: 'DC' },
  { prov: 'FLORIDA', code: 'FL' },
  { prov: 'GEORGIA', code: 'GA' },
  { prov: 'HAWAII', code: 'HI' },
  { prov: 'IDAHO', code: 'ID' },
  { prov: 'ILLINOIS', code: 'IL' },
  { prov: 'INDIANA', code: 'IN' },
  { prov: 'IOWA', code: 'IA' },
  { prov: 'KANSAS', code: 'KS' },
  { prov: 'KENTUCKY', code: 'KY' },
  { prov: 'LOUISIANA', code: 'LA' },
  { prov: 'MAINE', code: 'ME' },
  { prov: 'MARYLAND', code: 'MD' },
  { prov: 'MASSACHUSETTS', code: 'MA' },
  { prov: 'MICHIGAN', code: 'MI' },
  { prov: 'MINNESOTA', code: 'MN' },
  { prov: 'MISSISSIPPI', code: 'MS' },
  { prov: 'MISSOURI', code: 'MO' },
  { prov: 'MONTANA', code: 'MT' },
  { prov: 'NEBRASKA', code: 'NE' },
  { prov: 'NEVADA', code: 'NV' },
  { prov: 'NEW HAMPSHIRE', code: 'NH' },
  { prov: 'NEW JERSEY', code: 'NJ' },
  { prov: 'NEW MEXICO', code: 'NM' },
  { prov: 'NEW YORK', code: 'NY' },
  { prov: 'NORTH CAROLINA', code: 'NC' },
  { prov: 'NORTH DAKOTA', code: 'ND' },
  { prov: 'OHIO', code: 'OH' },
  { prov: 'OKLAHOMA', code: 'OK' },
  { prov: 'OREGON', code: 'OR' },
  { prov: 'PENNSYLVANIA', code: 'PA' },
  { prov: 'RHODE ISLAND', code: 'RI' },
  { prov: 'SOUTH CAROLINA', code: 'SC' },
  { prov: 'SOUTH DAKOTA', code: 'SD' },
  { prov: 'TENNESSEE', code: 'TN' },
  { prov: 'TEXAS', code: 'TX' },
  { prov: 'UTAH', code: 'UT' },
  { prov: 'VERMONT', code: 'VT' },
  { prov: 'VIRGINIA', code: 'VA' },
  { prov: 'WASHINGTON', code: 'WA' },
  { prov: 'WEST VIRGINIA', code: 'WV' },
  { prov: 'WISCONSIN', code: 'WI' },
  { prov: 'WYOMING', code: 'WY' }
  // {prov: 'OTHER', code: ''}
];

export const INCIDENT_GENDER = ['Male', 'Female', 'Other'];
// export const INCIDENT_GENDER = ['Male', 'Female'];

export const INCIDENT_PATIENT_TYPE = ['Guest', 'Member', 'Staff-Off', 'Staff-Work', 'Unknown'];

export const TICKET_TYPE_CUSTOM_ORGS = [
  'deer',
  'sm',
  'mammoth',
  'mammothtraining',
  'june',
  'palisades',
  'squaw',
  'alpine'
];

export const INCIDENT_TICKET_TYPE_1 = [
  'Day Pass',
  'Multi-Day Pass',
  'Group Pass',
  'Resort Season Pass',
  'IKON Pass',
  'Other',
  'Unknown'
];

export const INCIDENT_TICKET_TYPE = [
  'Day Ticket',
  'Multi-Day Ticket',
  'Season Pass',
  'Other Pass',
  'Not Applicable',
  'Unknown'
];
export const INCIDENT_TICKET_TYPE_CWSAA = [
  'Day Ticket',
  'Multi-Day Ticket',
  'Season Pass',
  'Other Pass',
  'Not Applicable',
  'Unknown',
  'Volunteer'
];

export const BOREAL_TICKET_TYPE = ['Night', 'Take 3', 'WWT', 'Pedestrian', 'Tubing', 'Employee'];
export const INCIDENT_SIGNATURE_TYPE = ['Patient', 'Parent', 'Guardian', 'Spouse'];
export const INCIDENT_PART = [
  'Foot',
  'Ankle',
  'Lower Leg',
  'Knee',
  'Calf',
  'Thigh',
  'Hip',
  'Lower Ab',
  'Upper Ab',
  'Chest',
  'Neck',
  'Clavicle',
  'Shoulder',
  'Upper Arm',
  'Elbow',
  'Lower Arm',
  'Wrist',
  'Hand',
  'Thumb',
  'Finger',
  'Pelvis',
  'Upper Back',
  'Lower Back',
  'Tailbone',
  'Head',
  'Face',
  'Eyes',
  'Nose',
  'Rib',
  'Upper Leg',
  'Medical',
  'No Injury',
  'N/A',
  'Other'
];

export const INCIDENT_RIDDEN_TYPE = ['Trail', 'Feature', 'Lift'];

export const INCIDENT_SIDE = ['Left', 'Right', 'Both', 'N/A'];
export const INCIDENT_TREATMENT_PROTOCOL = [
  'Fracture',
  'Sprain',
  'Abrasion',
  'Strain',
  'Bruise',
  'Laceration',
  'Dislocation',
  'Cardiac',
  'Stroke',
  'Concussion',
  'Hypothermia',
  'Backboard/C-Spine',
  'Frostbite',
  'Internal',
  'Insect Bite/Sting',
  'Illness',
  'Deceased',
  'Burn',
  'Unknown',
  'Puncture',
  'Other',
  'Swelling',
  'Deformity',
  'Contusion',
  'Avulsion',
  'N/A'
];

export const PATROLLER_ROLES = ['First In', '2nd In', '3rd In', 'On-Scene', 'Transport', 'Clinic/Aid'];

export const INCIDENT_LOCATION = [
  'Marked Ski Run',
  'Freestyle Terrain',
  'Competition Terrain',
  'Out of Bounds',
  'Closed inbounds',
  'Lift Incident',
  'Tubing',
  'Zip Lining',
  'Biking or Hiking',
  'Other Attraction',
  'Golfing',
  'Premises',
  'Unknown',
  'Other',
  'N/A'
];

export const INCIDENT_ACTIVITY = [
  'Alpine Skiing',
  'Snowboard',
  'Telemark',
  'Nordic',
  'Touring',
  'Tubing',
  'Zip Line',
  'Hiking',
  'Biking',
  'Euro-Bungee',
  'Climbing',
  'Golfing',
  'Other'
];

export const COPPER_ACTIVITY = ['Ski', 'Snowboard', 'Telemark', 'Other'];

export const COPPER_LESSON = ['Woodward', 'Ski and Ride'];

export const COPPER_ABILITY = ['Beginner', 'Intermediate', 'Advanced', 'Expert', 'Unknown/NA'];

export const COPPER_WEATHER = [
  'Clear',
  'Cloudy',
  'Heavy Rain',
  'Heavy Snow',
  'Light Rain',
  'Light Snow',
  'Partly Cloudy',
  'Thunderstorms',
  'Unknown/Walk In'
];

export const COPPER_DESTINATION = ['CMC', 'Flights', 'Ambulance', 'ROC'];

export const COPPER_TRANSPORT = ['EMV', 'ROC', 'Walk', 'Ambulance', 'Helicopter'];

export const COPPER_AID_LOCATION = ['East', 'West', 'Egress'];

export const COPPER_VISIBILITY = ['Fair', 'Good', 'Reduced Snow', 'Reduced Fog', 'Unknown/Walk In', 'Unlimited'];

export const COPPER_SURFACE = [
  'Powder',
  'Packed Powder',
  'Powder/Packed Powder',
  'Spring Conditions',
  'Manmade',
  'Hard Pack',
  'Groomed',
  'Unknown/Walk In'
];

export const COPPER_EQUIPMENT = ['Owned', 'Copper Rented', 'Other Rented', 'Borrowed', 'Unknown/NA'];

export const INCIDENT_KILLINGTON_ACTIVITY_SUMMER = [
  'MTN BIKING',
  'MTN COASTER',
  'GOLF',
  'HIKING',
  'SKYERIDGE',
  'LIFT RELATED',
  'PREMISE',
  'SEGWAY',
  'UTV',
  'SKYEJUMP',
  'RACE/COMP',
  'ROPES COURSE/MAZE',
  'TUBING',
  'OTHER'
];

export const BOREAL_ACTIVITY = [
  'Bike',
  'Skate',
  'Scooter',
  'Gym',
  'Alpine Skiing',
  'Snowboard',
  'Telemark',
  'Nordic',
  'Touring',
  'Tubing',
  'Non-skiing',
  'Other'
];

export const INCIDENT_INVOLVEMENT = [
  'Recreation',
  'Recreation jumping',
  'Competition',
  'Training',
  'Lesson - Snowboard',
  'Lesson - Ski',
  'Tube slide',
  'Terrain Park Feature',
  'Unknown',
  'Other'
];

export const INCIDENT_WEATHER = [
  'Clear',
  'Overcast',
  'Snowing',
  'Raining',
  'Fog',
  'Snow Being Made at Scene',
  'Unknown',
  'N/A'
];

export const INCIDENT_SUMMER_WEATHER = ['Clear', 'Overcast', 'Raining', 'Fog', 'Other', 'Unknown/Walk In', 'N/A'];

export const INCIDENT_LIGHT = ['Sharp', 'Flat', 'Whiteout', 'Lights', 'Overcast', 'Dark', 'Unknown/Walk In', 'N/A'];

export const INCIDENT_TEMPERATURE = [
  'Over 30',
  '21 - 30',
  '10 - 20',
  '0 to 10',
  '-10 to 0',
  '-20 to -11',
  'Below 20',
  'Unknown/Walk In',
  'N/A'
];

export const NSAA_TEMPERATURE = ['Below 0', '0 to 32', 'Above 32', 'Unknown/Walk In', 'N/A'];

export const INCIDENT_WIND = ['Light', 'Moderate', 'High', 'Unknown/Walk In', 'N/A'];

export const INCIDENT_SNOW = ['No new', '0 to 5', '5 to 10', '10 to 15', 'Over 15', 'Unknown/Walk In', 'N/A'];

export const INCIDENT_SURFACE = [
  'Packed Powder',
  'Groomed',
  'Moguls',
  'Powder',
  'Variable',
  'Granular',
  'Hard',
  'Unknown',
  'Padded',
  'Other',
  'Unknown/Walk In',
  'Concrete',
  'Dirt/Gravel - Wet',
  'Dirt/Gravel - Dry',
  'Grass',
  'Sand',
  'Rock',
  'N/A'
];
export const INCIDENT_SURFACE_BACKUP = [
  { text: 'Packed Powder', group: 'Winter' },
  { text: 'Groomed', group: 'Winter' },
  { text: 'Moguls', group: 'Winter' },
  { text: 'Powder', group: 'Winter' },
  { text: 'Variable', group: 'Winter' },
  { text: 'Granular', group: 'Winter' },
  { text: 'Hard', group: 'Winter' },
  { text: 'Unknown', group: 'Winter' },
  { text: 'Padded', group: 'Winter' },
  { text: 'Other', group: 'Winter' },
  { text: 'Unknown/Walk In', group: 'Winter' },
  { text: 'N/A', group: 'Winter' },
  { text: 'Concrete', group: 'Summer' },
  { text: 'Dirt/Gravel - Wet', group: 'Summer' },
  { text: 'Dirt/Gravel - Dry', group: 'Summer' },
  { text: 'Grass', group: 'Summer' },
  { text: 'Sand', group: 'Summer' },
  { text: 'Rock', group: 'Summer' },
  { text: 'N/A', group: 'Summer' }
];

export const INCIDENT_SUMMER_SURFACE = ['Dry', 'Wet', 'Other'];

export const INCIDENT_EQUIPMENT = [
  'Owned',
  'None',
  'Area rental',
  'Other rental',
  'Area demos',
  'Other demos',
  'Borrowed',
  'Unknown',
  'N/A'
];

export const COPPER_BINDING = ['None', 'Left only', 'Right only', 'Both'];

export const COPPER_HELMET = ['Yes', 'No'];

export const INCIDENT_BINDING = ['None', 'Left only', 'Right only', 'Both', 'Pre-released', 'Unknown', 'N/A'];

export const INCIDENT_ABILITY = ['Beginner', 'Intermediate', 'Advanced/Expert', 'Unknown', 'N/A'];

export const INCIDENT_HELMET = [
  'None',
  'Owned',
  'Area rental',
  'Other rental',
  'Area demos',
  'Other demos',
  'Borrowed',
  'Unknown',
  'N/A'
];

export const INCIDENT_LESSON = ['Never', 'This year', 'Other', 'N/A'];

export const NSAA_LESSON = ['In Lesson', 'N/A'];

export const EQUIPMENT_REMOVED_BY = [
  'Fall',
  'Fall/Patrol',
  'Fall/Injured Party',
  'Injured',
  'Patrol',
  'Bystander',
  'Other',
  'N/A'
];

export const INCIDENT_PRIMARY = [
  { text: 'Fall - skier lost control', group: 'Non-collision' },
  { text: 'Fall - caught an edge', group: 'Non-collision' },
  { text: 'Fall & struck by own equipment', group: 'Non-collision' },
  { text: 'Fall - jumping', group: 'Non-collision' },
  { text: 'Fall - change of conditions', group: 'Non-collision' },
  { text: 'Fall - change of terrain', group: 'Non-collision' },
  {
    text: 'Fall - caused by natural element (trees, branches, etc.)',
    group: 'Non-collision'
  },
  { text: 'Near Collision', group: 'Non-collision' },
  { text: 'Prior medical condition', group: 'Non-collision' },
  { text: 'Skied off trail', group: 'Non-collision' },
  { text: 'Equipment failure (not binding)', group: 'Non-collision' },
  { text: 'Binding pre-released', group: 'Non-collision' },
  { text: "Hit by other's equipment", group: 'Non-collision' },

  { text: 'Clothing caught on lift', group: 'Lift related' },
  { text: 'Fall while loading', group: 'Lift related' },
  { text: 'Fall after unload', group: 'Lift related' },
  { text: 'Fall from lift', group: 'Lift related' },
  { text: 'Jump chair lift', group: 'Lift related' },
  { text: 'Injured by restraining bar', group: 'Lift related' },
  { text: 'Struck by chair', group: 'Lift related' },

  { text: 'Slip & fall (non-skiing)', group: 'Non-skiing related' },
  { text: 'Cold/weather related', group: 'Non-skiing related' },
  { text: 'Not otherwise classified', group: 'Non-skiing related' },
  { text: 'Other', group: 'Non-skiing related' }
];

export const INCIDENT_AID = [
  'Walk/Ski',
  'Toboggan',
  'Snowmobile',
  'Motor Vehicle',
  'Helicopter',
  'Download',
  'On-hill',
  'Other',
  'Unknown',
  'N/A'
];

export const KILLINGTON_SUMMER_TRANSPORT_FROM_AID = ['Patrol', 'Walked', 'Other', 'Unknown', 'N/A'];

export const INCIDENT_BASE = [
  'Private Car',
  'Taxi',
  'Company',
  'Ambulance',
  'Bus',
  'Helicopter',
  'Walk/Ski',
  'Other',
  'Unknown',
  'N/A'
];

export const KILLINGTON_SUMMER_TRANSPORT_TO_AID = ['Walked Out', 'Assisted to Car/Bus', 'Ambulance', 'Other'];

export const INCIDENT_DESTINATION = [
  'Home',
  'Doctor',
  'Hospital',
  'Clinic',
  'Hotel',
  'Return to Activity',
  'Other',
  'Unknown',
  'N/A'
];

export const TUBING_START_METHOD = ['SITTING DOWN', 'RUNNING', 'LAYING DOWN', 'GROUP TIED TOGETHER'];

export const TUBING_EXPERIENCE_FALLS_TODAY = ['FIRST', '2-9', '10 OR MORE'];

export const TUBING_EXPERIENCE_RUNS_TODAY = ['FIRST', '2-9', '10 OR MORE'];

export const TUBING_EXPERIENCE_PREVIOUS = ['ONCE', '1-5 TIMES', '5+ TIMES'];

export const ODYSSEY_INCIDENT_PATIENT_TYPE = ['Participant', 'Spectator', 'Staff-Off', 'Staff-On'];
export const ODYSSEY_INCIDENT_SIGNATURE_TYPE = ['Patient', 'Parent', 'Guardian', 'Spouse', 'Unable to Obtain'];

export const ODYSSEY_INCIDENT_AID = [
  'Golf Cart',
  'Gator',
  'Polaris',
  'Walk',
  'Wheelchair',
  'Stretcher',
  'Unknown/Walk-In',
  'Motor Vehicle',
  'Ambulance',
  'Other'
];

export const ODYSSEY_INCIDENT_BASE = [
  'Golf Cart',
  'Gator',
  'Polaris',
  'Walk',
  'Wheelchair',
  'Stretcher',
  'Unknown/Walk-In',
  'Motor Vehicle',
  'Ambulance',
  'Other'
];

export const ODYSSEY_HELMET = ['No', 'Yes'];

export const AVPU = ['A', 'V', 'P', 'U'];

export const COLLISION_EQUIPMENT_OTHER_PARTY = ['Alpine Skiing', 'Snowboard', 'Telemark', 'Other'];
export const FEATURE_BUILD_TYPE = {
  park: 'park',
  building: 'building',
  lift: 'lift',
  trail: 'trail'
};

export const OBJECTS_TEMPLATES = [
  { link: 'skiruns', name: 'Ski Runs', type: 'SkiRun' },
  { link: 'buildings', name: 'Buildings', type: 'Building' },
  { link: 'lifts', name: 'Lifts', type: 'Lift' },
  { link: 'trails', name: 'Trails', type: 'Trail' }
];

export const OBJECTS_TEMPLATE_NAMES = {
  SkiRun: 'Ski Run',
  Building: 'Building',
  Lift: 'Lift',
  Trail: 'Trail'
};
