import { Component, OnDestroy } from '@angular/core';
import { ConfigService, GlobalService, ApiService, SpinnerService } from '@ea-services';
import { Observable } from 'rxjs';
import { AlertController, ModalController } from '@ionic/angular';
import { CustomerDetailsModal } from '@ea-modals/customer-details/customer-details';
import { Router } from '@angular/router';
import { cloneDeep } from 'lodash';
import { RouterConstant } from '@edgeauditor/app/constants/router.constant';

@Component({
  selector: 'customer-search',
  templateUrl: 'customer-search.html',
  styleUrls: ['customer-search.scss']
})
export class CustomerSearchComponent implements OnDestroy {
  emptyFilter: any = {
    name: '',
    phone: '',
    email: ''
  };
  filter: any = { ...this.emptyFilter };
  customers: any[] = [];

  constructor(
    public globalService: GlobalService,
    public configService: ConfigService,
    public apiService: ApiService,
    public spinnerService: SpinnerService,
    public alertController: AlertController,
    public modalController: ModalController,
    public router: Router
  ) {}

  ngOnDestroy(): void {
    this.modalController.dismiss().catch(() => {});
  }

  public findCustomers() {
    //Create new object to avoid requests with empty values
    let data = cloneDeep(this.filter);

    console.log(data);

    if (data.name == null || data.name.trim() == '') {
      delete data.name;
    }
    if (data.phone == null || data.phone.toString().trim() == '') {
      delete data.phone;
    }
    if (data.email == null || data.email.trim() == '') {
      delete data.email;
    }

    if (data.name || data.phone || data.email) {
      this.spinnerService.show('Searching customers...');

      this.findCustomersRequest(data).subscribe((response) => {
        if (response.customers) {
          this.customers = response.customers;
        }
        this.spinnerService.hide();
      });
    } else {
      this.showSearchAlert();
    }
  }

  public async showSearchAlert() {
    const alert = await this.alertController.create({
      header: 'Alert',
      message: 'Please enter valid values to continue',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Okay',
          handler: () => {
            this.filter = { ...this.emptyFilter };
          }
        }
      ]
    });

    await alert.present();
  }

  public findCustomersRequest(data): Observable<any> {
    return this.apiService.get(
      this.configService.apiURL() + 'locations/' + this.globalService._currentUser.location.id + '/customers',
      data
    );
  }

  public async showDetails(id) {
    console.log('openReturnModal', id);
    console.log('openReturnModal url', this.router.url);

    let componentProps: any = {
      customer_id: id,
      is_rental: false
    };

    if (this.router.url === '/' + RouterConstant.EQUIPMENT_ASSIGNMENT_RENTAL_PAGE) {
      componentProps.is_rental = true;
    }

    const modal = await this.modalController.create({
      component: CustomerDetailsModal,
      cssClass: 'customer-details-modal',
      componentProps: componentProps
    });

    await modal.present();
  }
}
