import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  OnDestroy,
  Output,
  ChangeDetectorRef,
  AfterViewInit
} from '@angular/core';
import { Observable, ReplaySubject, Subscription, throwError } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { SelectSnapshot, ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { AlertController } from '@ionic/angular';
import moment from 'moment';
import _ from 'lodash';

import { User, Location as LocationFlags, ILatLng } from '@ea-models';
import * as selects from '@ea-models/templates/selects';
import { DatetimeUtilsService } from '@ea-services/datetime-utils.service';
import { IncidentReportProviderService } from '@ea-services/provider/incident-report-provider.service';
import { GlobalService, IncidentReportService, ReportService, SpinnerService, PopUpService } from '@ea-services';
import { LogRocketProvider } from '@ea-services/provider/logrocket';
import { AppState } from '@edgeauditor/app/store/app/app.state';
import { DevicePlatform } from '@ea-models-v4/common';
import { IRPeopleAttribute, MapObject } from '@ea-models-v4';
import {
  INCIDENT_REPORT_MAT_TAB_GROUP,
  LOCATION_MAPPING,
  SUPPORT_DRIVER_LICENSE_SCANNING,
  SUPPORT_SCANNING_INCIDENT_TEMPLATES,
  scanningTypes
} from '@ea-constants';
import { ValidSectionResult } from '@ea-pages/incident-reports/incident-reports.page';
import { ITemplate, ITemplateCol } from '@ea-models/templates';
import { HttpErrorResponse } from '@angular/common/http';
import { TEMPLATE_NAME } from '@ea-models/templates/constants';

@Component({
  selector: 'app-dynamic-template',
  templateUrl: './dynamic-template.component.html',
  styleUrls: ['./dynamic-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicTemplateComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewSelectSnapshot(AppState.getIsMobilePlatform) readonly isMobilePlatform: boolean;
  @ViewSelectSnapshot(AppState.getDevicePlatform) readonly devicePlatform: Readonly<DevicePlatform>;
  @SelectSnapshot(AppState.isAndroid) private readonly isAndroid: boolean;

  @Input() restoreDataEvent: Observable<IRPeopleAttribute>;
  @Input() userEvent: Observable<User>;
  @Input() template: ITemplate;
  @Input() parentChangeDetectorRef: ChangeDetectorRef;
  @Input() dynamicFields: any;
  @Input() mapObject: MapObject;
  @Input() sectionKey: string;

  @Output() changed = new EventEmitter<Map<string, string | number | boolean | ILatLng>>();
  @Output() changedV2 = new EventEmitter<any>();
  @Output() rtpBarcodeScanned = new EventEmitter<string>();
  @Output() showVitals = new EventEmitter();

  readonly incidentReportMatTabGroup = INCIDENT_REPORT_MAT_TAB_GROUP;
  selects: _.Dictionary<any> = {};
  maxDate = moment().endOf('day').toDate();
  model: Partial<IRPeopleAttribute> & _.Dictionary<any> = { true: true };
  customData: _.Dictionary<any>;
  signSubject: ReplaySubject<string> = new ReplaySubject<string>(1);
  nameSubject: ReplaySubject<string> = new ReplaySubject<string>(1);
  dateSubject: ReplaySubject<string> = new ReplaySubject<string>(1);
  locationModelSubject: ReplaySubject<{ [key: string]: any }> = new ReplaySubject<{ [key: string]: any }>(1);
  userSubject: ReplaySubject<User> = new ReplaySubject<User>(1);
  currentUser: User;
  flags: Partial<LocationFlags> = {};
  requiredQuestions: { [key: string]: any };
  readonly scanningTypes = scanningTypes;
  driverLicenseScanningSupport = false;
  supportScanning: boolean;
  resetDataAfterScanning: boolean;
  supportRTP: boolean;
  isSingleScanning: boolean;
  private subscriptions = new Subscription();
  private scanningType: number = 0;
  private locationFlagsMapping = {
    enable_minor_release: {
      removeIf: false,
      fields: ['minor_released_to', 'minor_released_to_relationship']
    },
    enable_patient_email_collection: { removeIf: false, fields: ['email'] },
    include_how_prevented: {
      removeIf: false,
      fields: ['prevention_description']
    },
    enable_dinn_cwsaa: {
      removeIf: false,
      fields: [
        'equipment_right_din_heel',
        'equipment_right_din_toe',
        'equipment_left_din_heel',
        'equipment_left_din_toe'
      ]
    }
  };
  private chkTextFields = ['medications', 'allergies'];
  vitalsEnabled = false;

  constructor(
    public globalService: GlobalService,
    public incidentProvider: IncidentReportProviderService,
    private dateTimeUtils: DatetimeUtilsService,
    private incidentReportService: IncidentReportService,
    private reportService: ReportService,
    private popUpService: PopUpService,
    private alertController: AlertController,
    private spinnerService: SpinnerService,
    private logRocket: LogRocketProvider,
    private cd: ChangeDetectorRef
  ) {
    this.listenToCheckValidations();
  }

  ngOnInit() {
    this.template.forEach((row) => row.cols.forEach((col) => this.initDefaults(col)));
    this.userEvent
      .pipe(
        take(1),
        tap((user) => (this.currentUser = user)),
        tap(() => this.userSubject.next(this.currentUser)),
        tap(() => this.getSectionsQuestionsRequired()),
        tap(() => this.processLocationFlags()),
        tap(() => {
          const userSelects = {
            in_lesson: this.currentUser.lesson_programs,
            patrollers: this.currentUser.patrollers,
            patroller_roles: this.currentUser.patroller_roles,
            equipment_rented_from: this.currentUser.rental_shops
          };
          this.selects = { ...selects, ...userSelects };
          this.cd.detectChanges();
        })
      )
      .subscribe();
    this.restoreDataEvent
      .pipe(
        take(1),
        tap((data) => {
          this.model = { true: true, ...data };
          this.customData = this.customData || {};
          this.restoreAddress(data);
          this.restoreBirthDate(data);
          this.restoreSignature(data);
          this.restoreInjuries(data);
          this.restorePatrollers(data);
          this.restoreLocation(data);
          this.template.forEach((row) => row.cols.forEach((col) => this.extraAdjustments(col)));
          this.cd.detectChanges();
        })
      )
      .subscribe();

    if (this.sectionKey === 'first_aid') {
      this.vitalsEnabled = [TEMPLATE_NAME.nsaa, TEMPLATE_NAME.cwsaa].includes(this.currentUser.accident_template)
        && this.currentUser.location.enable_vitals;
    }
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
    this.initScanning();
  }

  ngOnDestroy(): void {
    try {
      this.subscriptions.unsubscribe();
    } catch {}
  }

  onSignatureChange(newVal: string, fieldName: string) {
    const newData = new Map<string, boolean>();
    newData.set('remove_signature', !newVal);
    this.changed.emit(newData);
    this.onChange(newVal, fieldName);
  }

  onChangeEventChecked(e: Event, fieldName: string) {
    this.onChange((<HTMLIonToggleElement>e.target).checked, fieldName);
  }

  onChangeEventValue(e: Event, fieldName: string) {
    type HTMLInputElement = HTMLIonTextareaElement | HTMLIonInputElement;
    this.onChange((<HTMLInputElement>e.target).value, fieldName);
  }

  onChange(newVal: string | number | boolean | ILatLng, fieldName: string, convertDateTime: boolean = false) {
    const newData = new Map<string, string | number | boolean | ILatLng>();
    const val = convertDateTime ? moment(newVal as unknown as Date).format() : newVal;
    newData.set(fieldName, val);
    this.changed.emit(newData);
    this.model[fieldName] = newVal;
  }

  onCustomDataChange($event: Map<string, string>) {
    this.changed.emit($event);
    if (!$event) return;
    if (!this.customData) {
      this.customData = { key: {} };
    }
    const key = Object.keys(this.customData)[0];
    const [fieldName] = $event.keys();
    if (!this.customData[key]) {
      this.customData[key] = {};
    }
    this.customData[key][fieldName] = $event.get(fieldName);
  }

  onScanning(type: number) {
    this.scanningType = type;
    this.incidentProvider.openCameraScanning(type === scanningTypes.driverLicense);
  }

  onSelectChange(newVal: { [key: string]: string }, key: string, field: string) {
    const _value: any = !_.isNil(newVal) && !_.isNil(newVal[key]) ? newVal[key] : newVal;
    this.onChange(!_.isNil(_value) ? _value : '', field);
  }

  onCbxHasValue(field: string, value: any) {
    if (this.model && field && value === '0') {
      setTimeout(() => {
        this.model[field] = false;
      }, 100);
    }
  }

  private restoreAddress(data?: Partial<IRPeopleAttribute>) {
    if (data) {
      this.customData.address = {
        address: data.address,
        country: data.country,
        province: data.province,
        city: data.city,
        postal_code: data.postal_code
      };
    }
  }

  private restoreBirthDate(data: Partial<IRPeopleAttribute>) {
    if ('birthday' in data && data.birthday) {
      const [year, month, day] = data.birthday.split('-');
      this.customData.birthday = {
        year: parseInt(year, 10),
        month: parseInt(month, 10),
        day: parseInt(day, 10)
      };
    }
  }

  private restoreSignature(data: Partial<IRPeopleAttribute>) {
    if ('signature' in data && data.signature) {
      this.signSubject.next(data.signature);
    }
    if ('completed_by' in data && data.completed_by) {
      this.nameSubject.next(data.completed_by);
    }
    if ('completed_date' in data && data.completed_date) {
      this.dateSubject.next(data.completed_date);
    }
  }

  private restoreInjuries(data: Partial<IRPeopleAttribute>) {
    if ('injuries' in data && data.injuries) {
      this.customData.injuries = JSON.parse(data.injuries);
    }
  }

  private restorePatrollers(data: Partial<IRPeopleAttribute>) {
    if ('patrollers' in data && data.patrollers) {
      this.customData.patrollers = JSON.parse(data.patrollers);
    }
  }

  private restoreLocation(data: Partial<IRPeopleAttribute>) {
    if ('locationModel' in data && data.locationModel) {
      this.locationModelSubject.next(data.locationModel);
    }
  }

  private initDefaults(col: ITemplateCol) {
    if (col.type === 'checkbox' && col.val !== undefined) {
      this.model[col.field] = col.val;
    }
  }

  private extraAdjustments(col: ITemplateCol) {
    this.adjustTimeFields(col);
    this.adjustCheckboxesWithText(col);
  }

  private adjustTimeFields(col: ITemplateCol): void {
    if (col.type === 'datetime' && col.displayFormat === 'H:mm') {
      this.model[col.field] = this.dateTimeUtils.fromUTC(this.model[col.field] as string);
    }
  }

  private adjustCheckboxesWithText(col: ITemplateCol) {
    if (
      col.type === 'textarea' &&
      this.chkTextFields.includes(col.field) &&
      this.model[col.field] &&
      this.model[col.field] !== ''
    ) {
      this.model[`has_${col.field}`] = true;
    }
  }

  private initScanning() {
    if (
      !this.globalService._currentUser ||
      !this.isMobilePlatform ||
      (!this.globalService._currentUser.location.rtp_enabled && this.devicePlatform === 'ios')
    ) {
      return;
    }
    this.supportRTP = this.globalService._currentUser.location.rtp_enabled;
    this.isSingleScanning = !this.supportRTP || this.devicePlatform === 'ios';
    const tpl = this.globalService._currentUser.accident_template;
    this.supportScanning = this.sectionKey === 'patients' && SUPPORT_SCANNING_INCIDENT_TEMPLATES.includes(tpl);

    if (!this.supportScanning) return;

    this.driverLicenseScanningSupport = this.isAndroid && SUPPORT_DRIVER_LICENSE_SCANNING.includes(tpl);

    this.subscriptions.add(
      this.incidentProvider.barcode$.subscribe((barcode) => {
        if (!barcode) return;
        if (this.scanningType === scanningTypes.driverLicense) {
          this.logRocket.logEvent('scanning driver license');
          this.logRocket.logInfo('scanning driver license, draw barcode', barcode);
          const values = this.incidentProvider.parseScanningLicense(barcode);
          this.logRocket.logInfo('scanning driver license, decoded barcode', values);
          if (_.isNil(values) || _.isEmpty(values)) {
            this.popUpService.popupAlert(
              'Scanning Error',
              `The driver's license you scanned is not in a supported format. Please enter the information manually.`,
              this.alertController
            );
            return;
          }
          this.processParsingLicense(values);
        } else if (this.scanningType === scanningTypes.liftAccessPass) {
          this.getCustomerByScanningCode(barcode);
        }
      })
    );
  }

  private processParsingLicense(data: any) {
    this.model = { ...this.model, ...data };
    if (!this.customData) this.customData = {};
    if (this.model.birthday?.length >= 8) {
      const birthday: string = this.model.birthday;
      const [m, d, y] = [birthday.substr(0, 2), birthday.substr(2, 2), birthday.substr(4, 4)];
      this.model.birthday = `${y}-${m}-${d}`;
      this.restoreBirthDate(this.model);
    }

    this.restoreAddress(this.model);
    if (this.model.gender) {
      this.model.gender = this.model.gender.toLowerCase().indexOf('m') === 0 ? 'Male' : 'Female';
    }
    this.changedV2.emit(this.model);
    this.resetDataAfterScanning = true;
    this.cd.detectChanges();
    setTimeout(() => (this.resetDataAfterScanning = false), 100);
  }

  private getCustomerByScanningCode(barCode: string) {
    if (!this.globalService._currentUser || !barCode) return;

    this.spinnerService.show('...processing');
    this.reportService
      .getCustomerByBarCode(barCode, this.globalService._currentUser.token)
      .pipe(
        tap((res) => {
          this.spinnerService.hide();
          if (!res?.responseData?.length || res.statusCode !== 200) {
            this.popUpService.popupAlert(
              'Scanning Error',
              'Cannot get information for the scanned barcode. Please enter the information manually.',
              this.alertController
            );
            return;
          }
          const firstData = _.first(res.responseData);
          this.model.first_name = firstData.FirstName || firstData['firstName'];
          this.model.last_name = firstData.LastName || firstData['lastName'];
          this.model.email = firstData.Email || firstData['email'];
          this.model.phone = firstData.ContactNumber || firstData['contactNumber'];
          this.model.birthday = firstData.PersonDOB || firstData['personDOB'];

          this.customData = {
            ...this.customData,
            address: {
              country: firstData.country,
              province: firstData.state_or_province,
              city: firstData.city,
              postal_code: firstData.zip_or_postal_code,
              address: firstData.address
            }
          };

          if (this.model.birthday?.length > 10) {
            this.model.birthday = this.model.birthday.substr(0, 10);
          }
          if (!this.customData) this.customData = {};
          this.restoreBirthDate(this.model);
          this.changedV2.emit(this.model);
          this.rtpBarcodeScanned.emit(firstData.UniquePersonId || firstData['uniquePersonId']);
          this.resetDataAfterScanning = true;
          this.cd.detectChanges();
          setTimeout(() => (this.resetDataAfterScanning = false), 100);
        }),
        catchError((err: HttpErrorResponse) => {
          this.spinnerService.hide();
          return throwError(() => err);
        })
      )
      .subscribe();
  }

  private processTemplateLocationFlags(flagName: string) {
    this.template.forEach((row) =>
      row.cols.forEach((col, idx) => {
        const delFn = (f) => (f.fields.length > 1 ? (row.cols = []) : row.cols.splice(idx, 1));
        const flag = this.locationFlagsMapping[flagName];
        if (flag.fields.includes(col.field) && flag.removeIf === this.currentUser.location[flagName]) {
          delFn(flag);
          if (this.requiredQuestions) {
            flag.fields.forEach((f) => delete this.requiredQuestions[f]);
          }
        }
      })
    );
  }

  private processLocationFlags() {
    this.flags = this.currentUser.location;
    Object.keys(this.locationFlagsMapping).forEach((flagName) => this.processTemplateLocationFlags(flagName));
  }

  private listenToCheckValidations() {
    const sub = this.incidentReportService.sectionsValidation$.subscribe((res) => {
      if (!res || !this.requiredQuestions) return;
      //leave logic validate sections data here
      const results: ValidSectionResult = { key: this.sectionKey, isValid: true };
      let data = this.model;
      if (this.customData) {
        data = {
          ...data,
          ...this.customData[Object.keys(this.customData)[0]]
        };
      }
      const rootSection =
        this.sectionKey === 'location'
          ? _.cloneDeep(this.currentUser.accident_template_questions || []).find(
              (item) => item.section.key === 'incident_location'
            )
          : null;
      const specialKeys: Readonly<string[]> = [
        LOCATION_MAPPING.markedSkiRun.aliasField,
        LOCATION_MAPPING.freestyleTerrain.aliasField,
        'park_feature',
        'trail_feature',
        LOCATION_MAPPING.bikingHiking.aliasField,
        LOCATION_MAPPING.liftIncident.aliasField
      ];
      const keys = Object.keys(this.requiredQuestions);
      results.isValid = !keys.some((k) => {
        const _k = k === 'url' ? 'signature' : k;
        const required = this.requiredQuestions[k];
        const locationModel = this.model.locationModel;
        let isValueNil = false;
        if (rootSection) {
          if (this.requiredQuestions[k] && specialKeys.includes(k)) {
            if (!this.model.incident_location) return false;

            const question = rootSection.questions.find((s) => s.key === k);
            const _dependValue = this.model.incident_location;
            if (question) {
              switch (_dependValue) {
                case LOCATION_MAPPING.markedSkiRun.name:
                  if (k === LOCATION_MAPPING.markedSkiRun.aliasField) {
                    const skiRunId =
                      this.model[LOCATION_MAPPING.markedSkiRun.field] ||
                      (locationModel && locationModel[LOCATION_MAPPING.markedSkiRun.field]);
                    if (_.isNil(skiRunId)) {
                      isValueNil = true;
                    }
                  }
                  break;
                case LOCATION_MAPPING.freestyleTerrain.name:
                  switch (k) {
                    case LOCATION_MAPPING.freestyleTerrain.aliasField:
                      const parkId = this.model.park_id || locationModel?.park_id;
                      if (_.isNil(parkId)) {
                        isValueNil = true;
                      }
                      break;
                    case 'park_feature':
                      const parkFeatureId = this.model.park_feature_id || locationModel?.park_feature_id;
                      if (_.isNil(parkFeatureId)) {
                        isValueNil = true;
                      }
                      break;
                    case 'trail_feature':
                      const trailFeatureId = this.model.trail_feature_id || locationModel?.trail_feature_id;
                      if (_.isNil(trailFeatureId)) {
                        isValueNil = true;
                      }
                      break;
                  }
                  break;
                case LOCATION_MAPPING.bikingHiking.name:
                  const trailId = this.model.trail_id || locationModel?.trail_id;
                  if (k === LOCATION_MAPPING.bikingHiking.aliasField && _.isNil(trailId)) {
                    isValueNil = true;
                  }
                  break;
                case LOCATION_MAPPING.liftIncident.name:
                  const liftId = this.model.lift_id || locationModel?.lift_id;
                  if (k === LOCATION_MAPPING.liftIncident.aliasField && _.isNil(liftId)) {
                    isValueNil = true;
                  }
                  break;
              }
              return isValueNil;
            }
          }
          if (this.requiredQuestions[k] && ['longitude', 'latitude'].includes(k)) {
            if (this.currentUser.location.disable_accident_geo_map !== false || !this.model['incident_location']) {
              return false;
            }
            isValueNil = Object.values(this.model['incidentMarker']).some((value: number) => _.isNil(value));
            return isValueNil;
          }
        }
        if (typeof required !== 'boolean' && required.key) {
          isValueNil =
            (data[required.key] === required.value || (required.or || []).includes(data[required.key])) &&
            (!required.sub ||
              data[required.sub.key] === required.sub.value ||
              (required.sub.or || []).includes(data[required.sub.key])) &&
            (_.isNil(data[_k]) || !(data[_k] + ''));

          return isValueNil;
        }
        isValueNil = required && (_.isNil(data[_k]) || !(data[_k] + ''));
        return isValueNil;
      });
      this.incidentReportService.sectionsValidationResults$.next(results);
    });
    this.subscriptions.add(sub);
  }

  private getSectionsQuestionsRequired() {
    this.getSectionsQuestionsRequiredHandler(this.incidentReportService.sectionsQuestionsRequired$.value);
    const sub = this.incidentReportService.sectionsQuestionsRequired$.subscribe((res) => {
      this.getSectionsQuestionsRequiredHandler(res);
    });
    this.subscriptions.add(sub);
  }

  private getSectionsQuestionsRequiredHandler(res: _.Dictionary<_.Dictionary<boolean>>) {
    if (!res) return;
    this.requiredQuestions = res[this.sectionKey];
    if (!this.requiredQuestions) {
      this.subscriptions.unsubscribe();
      return;
    }

    this.template.forEach((item) => {
      item.cols.forEach((col) => {
        const _is = this.requiredQuestions[col.field];
        if (_is && col.type !== 'checkbox') {
          col.required = _is;
          if (item.if && item.if.key !== 'true') {
            if (typeof this.requiredQuestions[col.field] !== 'boolean') {
              const _or = this.requiredQuestions[col.field].or || [];
              this.requiredQuestions[col.field].or = [..._or, ...(_.cloneDeep(item.if.or) || []), ...[item.if.value]];
            } else {
              this.requiredQuestions[col.field] = _.cloneDeep(item.if);
            }

            if (col.if && col.if.key !== 'true') {
              this.requiredQuestions[col.field].sub = _.cloneDeep(col.if);
            }
          } else if (col.if && col.if.key !== 'true') {
            this.requiredQuestions[col.field] = _.cloneDeep(col.if);
          }
        } else {
          delete this.requiredQuestions[col.field];
        }
      });
    });

    if (this.currentUser.accident_template === 'cwsaa') {
      this.locationFlagsMapping.enable_minor_release.fields.forEach((k) => delete this.requiredQuestions[k]);
    }
    this.cd.detectChanges();
  }

  showVitalsClicked() {
    this.showVitals.emit();
  }
}
