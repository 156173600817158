import { CommonModule } from '@angular/common';
import { SignaturePadModule } from 'angular2-signaturepad';
import { FormsModule } from '@angular/forms';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgModule } from '@angular/core';
import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
  OWL_DATE_TIME_FORMATS
} from '@danielmoncada/angular-datetime-picker';
import { MatExpansionModule } from '@angular/material/expansion';
import { IonicModule } from '@ionic/angular';

import { IncidentLocationComponent } from './incident-reports/incident-location/incident-location.component';
import { ReportCompletionComponent } from './incident-reports/report-completion/report-completion.component';
import { IncidentLocationMapComponent } from './incident-reports/incident-location/incident-location-map.component';
import { NsaaPatientsComponent } from './incident-reports/sections/patients/nsaa-patients/nsaa-patients.component';
import { CwsaaPatientsComponent } from './incident-reports/sections/patients/cwsaa-patients/cwsaa-patients.component';
import { CopperPatientsComponent } from './incident-reports/sections/patients/copper-patients/copper-patients.component';
import { AddressComponent } from './address/address.component';
import { PatrollersComponent } from './patrollers/patrollers.component';
import { VitalsComponent } from '../modals/vitals/vitals.component';
import {
  SinglePageReportComponent,
  SinglePageReportItemComponent
} from './reports/single-page-report/single-page-report.component';
import {
  MultiPageReportComponent,
  MultiPageReportItemComponent
} from './reports/multi-page-report/multi-page-report.component';
import { GridReportComponent, GridReportItemComponent } from './reports/grid-report/grid-report.component';
import { NoObjectReportComponent } from './reports/no-object-report/no-object-report.component';
import { DynamicComponentComponent } from './reports/dynamic-component/dynamic-component.component';
import { SignatureComponent } from './signature/signature.component';
import { CustomerSearchComponent } from './customer-search/customer-search';
import { CustomerDetailsModalModule } from '../modals/customer-details/customer-details.module';
import { NumberDirective } from '../directives/numbers-only.directive';
import { ImagePickerComponent } from './image-picker/image-picker.component';
import { DiagramComponent } from './reports/diagram/diagram.component';
import { DiagramDrawingComponent } from './reports/diagram/diagram-drawing.component';
import { LinkReportComponent } from '../modals/link-report/link-report.component';
import { CurrentLocationMapComponent } from '../modals/current-location-map/current-location-map.component';
import { ParkBuildFeatureDetailsComponent } from '../modals/park-build-feature-details/park-build-feature-details.component';
import { ImageGalleryComponent } from './image-gallery/image-gallery.component';
import { ImageGalleryPreviewComponent } from './image-gallery/image-gallery-preview.component';
import { SpecialEventsDetailsComponent } from '../modals/special-events-details/special-events-details.component';
import { ViewPhotoModalModule } from '../modals/view-photo/view-photo.module';
import { ReportHistoryModalComponent } from '../modals/report/report-history.component';
import { AddObjectModalComponent } from '../modals/object/add-object/add-object.component';
import { InspectObjectModalComponent } from '../modals/object/inspect-object/inspect-object.component';
import { PreloadDynamicComponent } from './preload/dynamic-component/dynamic.component';
import { FilterGroomerModalComponent } from '../modals/groomer/filter-groomer/filter-groomer.component';
import { LogFuelModalComponent } from '../modals/groomer/log-fuel/log-fuel.component';
import { FinishShiftModalComponent } from '../modals/groomer/finish-shift/finish-shift.component';
import { GroomerAssignmentModalComponent } from '../modals/groomer/groomer-assignment/groomer-assignment.component';
import { CommonHeaderModule } from './header/header.module';
import { FilterTasksModalComponent } from '../modals/task/filter-task/filter-task.component';
import { TaskDetailModalComponent } from '../modals/task/task-detail/task-detail.component';
import { CreateTaskModalComponent } from '../modals/task/create-task/create-task.component';
import { SharedModule } from '@edgeauditor/app/components/shared/shared.module';
import { BirthdateComponent } from './birthdate/birthdate.component';
import { DynamicTemplateComponent } from './dynamic-template/dynamic-template.component';
import { ComplaintComponent } from './complaint/complaint.component';
import { DynamicSelectCustomPipeModule } from '@edgeauditor/app/pipes/dynamic-select-custom-pipe/dynamic-select-custom-pipe.module';
import { NgSelectAutoScrollDirectiveModule } from '@edgeauditor/app/directives/ng-select-auto-scroll';

const NATIVE_FORMATS = {
  fullPickerInput: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false
  },
  datePickerInput: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour12: false
  },
  timePickerInput: { hour: 'numeric', minute: 'numeric', hour12: false },
  monthYearLabel: { year: 'numeric', month: 'short' },
  dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
  monthYearA11yLabel: { year: 'numeric', month: 'long' }
};

const components = [
  IncidentLocationComponent,
  ReportCompletionComponent,
  IncidentLocationMapComponent,
  NsaaPatientsComponent,
  CwsaaPatientsComponent,
  CopperPatientsComponent,
  BirthdateComponent,
  DynamicTemplateComponent,
  AddressComponent,
  ComplaintComponent,
  PatrollersComponent,
  VitalsComponent,
  SinglePageReportComponent,
  MultiPageReportComponent,
  GridReportComponent,
  NoObjectReportComponent,
  DynamicComponentComponent,
  MultiPageReportItemComponent,
  GridReportItemComponent,
  SinglePageReportItemComponent,
  SignatureComponent,
  CustomerSearchComponent,
  ImagePickerComponent,
  DiagramComponent,
  DiagramDrawingComponent,
  LinkReportComponent,
  CurrentLocationMapComponent,
  ParkBuildFeatureDetailsComponent,
  SpecialEventsDetailsComponent,
  ImageGalleryComponent,
  ImageGalleryPreviewComponent,
  ReportHistoryModalComponent,
  AddObjectModalComponent,
  InspectObjectModalComponent,
  PreloadDynamicComponent,
  FilterGroomerModalComponent,
  LogFuelModalComponent,
  FinishShiftModalComponent,
  GroomerAssignmentModalComponent,
  FilterTasksModalComponent,
  TaskDetailModalComponent,
  CreateTaskModalComponent
];

const modules = [
  CommonModule,
  SignaturePadModule,
  FormsModule,
  GooglePlaceModule,
  CustomerDetailsModalModule,
  ViewPhotoModalModule,
  CommonHeaderModule
];

@NgModule({
  declarations: [...components, NumberDirective],
  imports: [
    IonicModule,
    ...modules,
    NgSelectModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatExpansionModule,
    DynamicSelectCustomPipeModule,
    SharedModule,
    NgSelectAutoScrollDirectiveModule
  ],
  providers: [{ provide: OWL_DATE_TIME_FORMATS, useValue: NATIVE_FORMATS }],
  exports: [...components, ...modules],
  entryComponents: [...components]
})
export class EAComponentsModule {}
