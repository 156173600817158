<ea-header [hasCloseButton]="true" title="Filter"></ea-header>
<ion-content scrollY="true" class="groomer-filter-container">
  <div class="section-component__body" style="width: 100%">
    <ion-row>
      <div class="title border">Area</div>
      <div class="filter-item" *ngFor="let item of areas">
        <div>{{ item.name }}</div>
        <ion-checkbox
          [(ngModel)]="item.selected"
          (click)="onChangeArea(item)"
        ></ion-checkbox>
      </div>
    </ion-row>
    <ion-row>
      <div class="title">Sort By</div>
      <div class="sort-container">
        <div
          class="sort-item"
          *ngFor="let sort of sorts"
          [class.active]="!!sort.selected"
          (click)="onChange(sort, sorts, true)"
        >
          {{ sort.name }}
        </div>
      </div>
    </ion-row>
    <ion-row>
      <div class="title">Priority</div>
      <div class="sort-container">
        <div
          class="sort-item"
          *ngFor="let sort of priorities"
          [class.active]="!!sort.selected"
          (click)="onChangePriority(sort, priorities)"
        >
          {{ sort.name }}
        </div>
      </div>
    </ion-row>
    <ion-row>
      <div class="title">Show Completed</div>
      <div class="sort-container">
        <div
          class="sort-item"
          *ngFor="let sort of completeds"
          [class.active]="!!sort.selected"
          (click)="onChange(sort, completeds)"
        >
          {{ sort.name }}
        </div>
      </div>
    </ion-row>
    <ion-row>
      <div class="title">Show % Completed</div>
      <div class="sort-container">
        <div
          class="sort-item"
          *ngFor="let sort of completedPercents"
          [class.active]="!!sort.selected"
          (click)="onChange(sort, completedPercents, true)"
        >
          {{ sort.name }}
        </div>
      </div>
    </ion-row>
    <ion-row>
      <div class="title border">Activity</div>
      <div class="filter-item" *ngFor="let item of activityTypes">
        <div>{{ item.name }}</div>
        <ion-checkbox
          [disabled]="item.selected"
          [checked]="item.selected"
          (click)="onChange(item, activityTypes)"
        ></ion-checkbox>
      </div>
    </ion-row>
    <ion-row>
      <div class="title border">Type</div>
      <div class="filter-item" *ngFor="let item of types">
        <div>{{ item.name }}</div>
        <ion-checkbox
          [checked]="item.selected"
          (click)="onChange(item, types, true)"
        ></ion-checkbox>
      </div>
    </ion-row>
  </div>
</ion-content>
<div class="btn-groomer-filter-container">
  <button class="btn-cancel no-ripple" (click)="reset()">Remove Filters</button>
  <button class="btn-success no-ripple" (click)="applyFilter()">
    Apply Filters
  </button>
</div>
