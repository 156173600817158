import { Component, OnInit, Input } from '@angular/core';
import { GlobalService, SpinnerService, PopUpService, GroomersService } from '@ea-services';
import { catchError, take, tap, delay } from 'rxjs/operators';
import { ModalController, AlertController } from '@ionic/angular';
import { of } from 'rxjs';
import _ from 'lodash';
import { LogRocketProvider } from '@ea-services/provider/logrocket';
import { AppState } from '@edgeauditor/app/store/app/app.state';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Utility } from '@edgeauditor/utilities/utility';

@Component({
  selector: 'modal-finish-shift',
  templateUrl: './finish-shift.component.html',
  styleUrls: ['./finish-shift.component.scss']
})
export class FinishShiftModalComponent implements OnInit {
  @Input() params: {
    vehicle_id: number;
    groomer_shift_id: number;
    hours_at_start_of_shift: number;
  };

  model: {
    vehicle_id: number;
    groomer_shift_id: number;
    hours_at_start_of_shift: number;
    location_id: number;
    reason_for_unavailable: string;
    groomer_operator_shift_id: number;
    full_shift: boolean;
    hours_lost_due_to_breakdown: number;
    hours_at_end_of_shift: number;
  } = {} as any;
  currentUser: any;
  groomerOperatorShifts: any;
  reasons = [];
  readonly breakdownReason = 'Breakdown';
  isSuccessful: boolean;
  utility = Utility;

  get allowSave() {
    return (
      !!this.model.vehicle_id &&
      !!this.model.groomer_shift_id &&
      !_.isNil(this.model.full_shift) &&
      this.model.hours_at_end_of_shift > 0 &&
      (this.model.full_shift !== false || !!this.model.reason_for_unavailable) &&
      (this.model.reason_for_unavailable !== this.breakdownReason || this.model.hours_lost_due_to_breakdown > 0)
    );
  }

  @ViewSelectSnapshot(AppState.getIsMobilePlatform) isMobilePlatform: boolean;

  constructor(
    public globalService: GlobalService,
    private spinnerService: SpinnerService,
    private groomerService: GroomersService,
    private popUpService: PopUpService,
    private logRocketProvider: LogRocketProvider,
    private alertController: AlertController,
    public modalController: ModalController
  ) {}

  ngOnInit() {
    if (this.globalService._currentUser) {
      this.currentUser = this.globalService._currentUser;
      this.groomerOperatorShifts = _.cloneDeep(this.currentUser.groomer_operator_shifts);
    }
    this.isSuccessful = false;
    this.initReasons();
    this.initModel();
  }

  changeFullShift(is) {
    this.model.full_shift = is;
    if (is) {
      this.model.reason_for_unavailable = null;
      this.initReasons();
    }
  }

  changeReason(item) {
    if (item.selected) {
      return;
    }
    this.model.reason_for_unavailable = item.value;
    if (item.value !== this.breakdownReason) {
      this.model.hours_lost_due_to_breakdown = null;
    }
    of(null)
      .pipe(
        delay(100),
        take(1),
        tap(() => {
          this.reasons.forEach((element) => {
            element.selected = false;
          });
          item.selected = true;
        })
      )
      .subscribe();
  }

  async close(result?: any) {
    await this.modalController.dismiss(result);
  }

  reset() {
    this.initModel();
    this.initReasons();
  }

  save() {
    if (!this.allowSave) return;
    this.spinnerService.show('processing...');
    this.groomerService
      .finishShift(this.currentUser.token, this.model)
      .pipe(
        take(1),
        tap((res) => {
          this.spinnerService.hide();
          if (!res || !res.success) {
            this.popUpService.popupError('Finish Shift failed.', this.alertController);
            this.logRocketProvider.captureMessage(
              'finish groomer shift failed',
              'finish groomer shift error',
              'finish groomer shift'
            );
            return;
          }
          this.isSuccessful = true;
        }),
        catchError((e) => {
          this.popUpService.popupError('Finish Shift failed.', this.alertController);
          this.logRocketProvider.captureMessage(e, 'finish groomer shift error', 'finish groomer shift');
          return of(null);
        })
      )
      .subscribe();
    this.logRocketProvider.logEvent('finish groomer shift');
    this.logRocketProvider.logInfo('finish groomer shift', this.model);
  }

  private initModel() {
    this.model = {
      vehicle_id: this.params.vehicle_id,
      groomer_shift_id: this.params.groomer_shift_id,
      hours_at_start_of_shift: this.params.hours_at_start_of_shift,
      location_id: this.currentUser.location.id,
      reason_for_unavailable: null,
      groomer_operator_shift_id: null,
      full_shift: null,
      hours_lost_due_to_breakdown: null,
      hours_at_end_of_shift: null
    };
  }

  private initReasons() {
    this.reasons = [
      { value: 'No Driver', selected: false },
      { value: this.breakdownReason, selected: false },
      { value: 'Waiting for Parts', selected: false },
      { value: 'Scheduled Maintenance', selected: false }
    ];
  }
}
