<ea-header title="Add a New Task" [hasCloseButton]="true"></ea-header>
<ion-content>
  <div
    class="create-task-container"
    (scroll)="scrollEnd($event)"
    #scrollerCreateTask
  >
    <div class="section-component__body" style="width: 100%">
      <ion-row>
        <div class="control flex">
          <button
            [hidden]="linkedIncident"
            class="btn-linked no-ripple"
            (click)="presentLinkIncidentModal()"
          >
            Link To Incident Report
          </button>
          <button
            [hidden]="!linkedIncident"
            class="btn-linked linked no-ripple"
            (click)="removeLinkToIncident()"
          >
            Linked To Incident Report #{{ linkedIncident?.report_number }}
          </button>
        </div>
      </ion-row>
      <ion-row>
        <div class="title">Task Name</div>
        <div class="control">
          <ion-input debounce="400" [(ngModel)]="model.name" class="input">
          </ion-input>
        </div>
      </ion-row>
      <ion-row>
        <div class="title">Action</div>
        <div class="sort-container">
          <div
            class="sort-item"
            *ngFor="let action of actions"
            [class.active]="action.selected"
            (click)="onChangeAction(action, 'action_id', actions)"
          >
            {{ action.name }}
          </div>
        </div>
      </ion-row>
      <ion-row *ngIf="model.action_id === ACTION_COMPLETE_REPORT_ID">
        <div class="title">Report Name</div>
        <div class="control">
          <ng-select
            [items]="reports"
            [selectOnTab]="true"
            class="standard-select"
            bindLabel="name"
            bindValue="id"
            [(ngModel)]="model.report_id"
            [disabled]="!reports?.length"
            [virtualScroll]="reports?.length > 20"
            [placeholder]="!reports?.length ? 'No Item' : ''"
          >
          </ng-select>
        </div>
      </ion-row>
      <ion-row>
        <div class="title">Department <span>(optional)</span></div>
        <div class="control">
          <ng-select
            [items]="departments"
            [selectOnTab]="true"
            class="standard-select"
            bindLabel="name"
            bindValue="id"
            [(ngModel)]="model.department_id"
            [disabled]="!departments?.length"
            [virtualScroll]="departments?.length > 20"
            [placeholder]="!departments?.length ? 'No Item' : ''"
          >
          </ng-select>
        </div>
      </ion-row>
      <ion-row>
        <div class="title">Area <span>(optional)</span></div>
        <div class="control">
          <ng-select
            [items]="areas"
            [selectOnTab]="true"
            class="standard-select"
            bindLabel="name"
            bindValue="id"
            [(ngModel)]="model.area_id"
            [disabled]="!areas?.length"
            [virtualScroll]="areas?.length > 20"
            [placeholder]="!areas?.length ? 'No Item' : ''"
          >
          </ng-select>
        </div>
      </ion-row>

      <ion-row>
        <div class="title">Feature <span>(optional)</span></div>
        <div class="control">
          <ng-select
            [items]="features"
            [selectOnTab]="true"
            class="standard-select"
            bindLabel="name"
            bindValue="id"
            [(ngModel)]="model.feature_id"
            [disabled]="!features?.length"
            [virtualScroll]="features?.length > 20"
            [placeholder]="!features?.length ? 'No Item' : ''"
          >
          </ng-select>
        </div>
      </ion-row>
      <ion-row>
        <div class="title">Type <span>(optional)</span></div>
        <div class="control">
          <ng-select
            [items]="types"
            [selectOnTab]="true"
            class="standard-select"
            bindLabel="name"
            bindValue="id"
            [(ngModel)]="model.task_type_id"
            [disabled]="!types?.length"
            [virtualScroll]="types?.length > 20"
            [placeholder]="!types?.length ? 'No Item' : ''"
          >
          </ng-select>
        </div>
      </ion-row>
      <ion-row>
        <div class="title">Priority <span>(optional)</span></div>
        <div class="sort-container">
          <div
            class="sort-item"
            *ngFor="let priority of priorities"
            [class.active]="priority.selected"
            (click)="onChange(priority, 'priority_id', priorities)"
          >
            {{ priority.name }}
          </div>
        </div>
      </ion-row>
      <ion-row>
        <div class="title">Due Date <span>(optional)</span></div>
        <div class="control due_date">
          <div>
            <div *ngIf="model.due_date" class="due_date_display">
              {{ model.due_date | date: 'MMM dd, yyyy' }}
            </div>
            <input
              readonly
              class="standard-input"
              [owlDateTime]="dt1"
              [owlDateTimeTrigger]="dt1"
              [min]="minDate"
              [(ngModel)]="model.due_date"
              (click)="focusOnInputTime()"
            />
            <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
          </div>
        </div>
      </ion-row>
      <ion-row>
        <div class="title">Add Image(s) <span>(optional)</span></div>
        <div class="control">
          <app-image-picker
            [isSupportResizePhoto]="true"
            [hideNote]="true"
            (imageSelected)="onImageChange($event)"
          ></app-image-picker>
        </div>
      </ion-row>
      <ion-row>
        <div class="title">Notes <span>(optional)</span></div>
        <div class="control">
          <ion-textarea debounce="400" rows="5" [(ngModel)]="model.description">
          </ion-textarea>
        </div>
      </ion-row>
    </div>
  </div>
  <div class="btn-create-task-container">
    <button class="btn-cancel no-ripple" (click)="close()">Cancel</button>
    <button
      class="btn-success no-ripple"
      [disabled]="!allowSave"
      (click)="save()"
    >
      Create Task
    </button>
  </div>
</ion-content>
