import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Address } from 'ngx-google-places-autocomplete/objects/address';

// TODO: Move interfaces & template to separate files
interface ITemplateCondition {
  key: string;
  value: boolean;
}

interface ITemplateSelectData {
  key: string;
  value: string;
}

interface ITemplateCol {
  if: ITemplateCondition;
  xs: number;
  sm: number;
  label: string;
  field: string;
  required?: boolean;
  data?: ITemplateSelectData;
  type: 'input' | 'select' | 'checkbox' | 'address' | 'birthdate';
}

interface ITemplateRow {
  if: ITemplateCondition;
  cols: ITemplateCol[];
}

type ITemplate = ITemplateRow[];

const DEF_KEY = {
  if: { key: 'true', value: true }
};

const DEF_COL_12 = {
  ...DEF_KEY,
  xs: 12,
  sm: 12
};

const DEF_COL_6 = {
  ...DEF_KEY,
  xs: 12,
  sm: 6
};

const DEF_COL_4 = {
  ...DEF_KEY,
  xs: 12,
  sm: 4
};

const CWSAA_TEMPLATE: ITemplate = [
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_6,
        label: 'First Name',
        field: 'first_name',
        type: 'input',
        required: true
      },
      {
        ...DEF_COL_6,
        label: 'Last Name',
        field: 'last_name',
        type: 'input',
        required: true
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [{ ...DEF_COL_12, label: 'Address', field: 'address', type: 'address' }]
  },
  {
    ...DEF_KEY,
    cols: [
      { ...DEF_COL_6, label: 'Phone', field: 'phone', type: 'input' },
      { ...DEF_COL_6, label: 'Email', field: 'email', type: 'input' }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Date of Birth',
        field: 'birthday',
        type: 'birthdate'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_4,
        label: 'Gender',
        field: 'gender',
        type: 'select',
        data: { key: 'name', value: 'name' }
      },
      { ...DEF_COL_4, label: 'Height', field: 'height', type: 'input' },
      { ...DEF_COL_4, label: 'Weight', field: 'weight', type: 'input' }
    ]
  }
];

@Component({
  selector: 'app-cwsaa-patients',
  templateUrl: './cwsaa-patients.component.html',
  styleUrls: ['./cwsaa-patients.component.scss']
})
export class CwsaaPatientsComponent implements OnInit {
  @Output() changed = new EventEmitter<Map<string, string | boolean>>();
  @Input() countries: {
    name: string;
    code: string;
  }[];
  @Input() states: {
    prov: string;
    code: string;
  }[];
  cwsaa: ITemplate;
  select = {
    gender: [{ name: 'Male' }, { name: 'Female' }]
  };
  model = {
    true: true,
    country: 'CA',
    state: '',
    city: '',
    postalCode: '',
    address: ''
  };

  constructor() {}

  ngOnInit() {
    this.cwsaa = CWSAA_TEMPLATE;
  }

  onChangeEventChecked(e: Event, fieldName: string) {
    this.onChange((<HTMLInputElement>e.target).checked, fieldName);
  }

  onChangeEventValue(e: Event, fieldName: string) {
    this.onChange((<HTMLTextAreaElement>e.target).value, fieldName);
  }

  onChange(newVal: string | boolean, fieldName: string) {
    const newData = new Map<string, string | boolean>();
    newData.set(fieldName, newVal);
    this.changed.emit(newData);
  }
}
