import { Injectable } from '@angular/core';
import { ReportService } from './api';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { sections as allSections } from '@ea-models/templates/sections';
import * as templates from '@ea-models/templates';
import { ITemplate } from '@ea-models/templates';
import { map, take } from 'rxjs/operators';
import { IncidentTemplate } from '@ea-models';
import _ from 'lodash';
import { ValidSectionResult } from '@ea-pages/incident-reports/incident-reports.page';

type KeyVal = _.Dictionary<any>;

type KeyValStrArr = _.Dictionary<string[]>;

@Injectable({
  providedIn: 'root'
})
export class IncidentReportService {
  private currentTemplate: IncidentTemplate;
  allSections = _.cloneDeep(allSections);
  sectionsQuestionsRequired$: BehaviorSubject<_.Dictionary<_.Dictionary<boolean>>> = new BehaviorSubject(null);
  sectionsValidation$: Subject<boolean> = new Subject();
  sectionsValidationResults$: Subject<ValidSectionResult> = new Subject();
  constructor(private reportService: ReportService) {}
  readonly root_fields = [
    'ability',
    'is_collision',
    'days_all_areas',
    'days_this_area',
    'equipment',
    'helmet',
    'activity',
    'was_in_lesson',
    'activity_other',
    'lesson',
    'previous_year_injured',
    'binding_release',
    'rental_ski',
    'equipment_make_model',
    'equipment_left_din_toe',
    'equipment_left_din_heel',
    'equipment_right_din_toe',
    'equipment_right_din_heel',
    'collision_party_name',
    'collision_party_relationship',
    'collision_party_equipment',
    'collision_party_equipment_other',
    'in_lesson',
    'instructor',
    'equipment_removed_by',
    'medical_insurance',
    'group_name'
  ];
  readonly accident_people_attributes_fields = [
    'first_name',
    'last_name',
    'address',
    'city',
    'province',
    'province_other',
    'postal_code',
    'phone',
    'email',
    'country',
    'statement',
    'prevention_description',
    'refused_care',
    'usage_today',
    'usage_past',
    'ticket_number',
    'wear_glasses',
    'used_glasses'
  ];
  readonly mainFields = [
    'completed_by',
    'completed_date',
    'signature',
    'incident_time',
    'incident_date',
    'incident_location'
  ];
  readonly customSections = ['patients', 'witnesses'];

  readonly tplSpecific = {
    nsaa: {
      description_fields: [
        'description',
        'signature_type',
        'other_signature_name',
        'signature',
        'remove_signature',
        'prevention_description',
        'refused_care'
      ]
    }
  };

  readonly mapping = {
    reverseSectionsMapping: {
      patient_attributes: () => 'patients',
      witnesses_attributes: () => 'witnesses',
      accident_patrollers_attributes: () => (this.currentTemplate !== 'copper' ? 'transport' : 'first_aid'),
      complaints_attributes: () => (this.currentTemplate !== 'copper' ? 'complaints' : 'first_aid'),
      vitals_attributes: () => null
    },
    reverseProcessing: {
      patient_attributes: (section: KeyVal, distSection: KeyVal, sectionsObj: KeyVal) =>
        this.customSectionsReverse(section, distSection, 'patients', sectionsObj),
      witnesses_attributes: (section: KeyVal, distSection: KeyVal) =>
        this.customSectionsReverse(section, distSection, 'witnesses'),
      accident_patrollers_attributes: (section: KeyVal, distSection: KeyVal) => {
        const arr = section.accident_patrollers_attributes.map((a) => ({
          staff: { id: a.staff_id },
          role: { id: a.patroller_role_id },
          id: a.id
        }));
        distSection.patrollers = JSON.stringify(arr);
      },
      complaints_attributes: (section: KeyVal, distSection: KeyVal) => {
        distSection.injuries = JSON.stringify(section.complaints_attributes);
      },
      vitals_attributes: (section: KeyVal, distSection: KeyVal) => {
        distSection.vitals = [...section.vitals_attributes];
      }
    },
    customProcessing: {
      incidentMarker: (section: KeyVal, result: KeyVal) => {
        result.latitude = section.incidentMarker.lat;
        result.longitude = section.incidentMarker.lng;
      },
      patrollers: (section: KeyVal, result: KeyVal) => {
        const apAttrs = JSON.parse(section.patrollers).map((o) => ({
          staff_id: o.staff && o.staff.id,
          patroller_role_id: o.role && o.role.id,
          id: o.id,
          _destroy: o._destroy
        }));
        result.accident_patrollers_attributes = [...apAttrs];
      },
      injuries: (section: KeyVal, result: KeyVal) => {
        result.complaints_attributes = [...JSON.parse(section.injuries)];
      },
      vitals: (section: KeyVal, result: KeyVal) => {
        result.vitals_attributes = [...section.vitals];
      }
    },
    patients: {
      dist: 'patient_attributes',
      exclude: [
        {
          fields: [...this.accident_people_attributes_fields],
          dist: 'accident_people_attributes'
        },
        {
          fields: [...this.root_fields],
          dist: ''
        }
      ]
    },
    witnesses: {
      dist: 'witnesses_attributes',
      index: 0,
      exclude: [
        {
          fields: [...this.accident_people_attributes_fields],
          dist: 'accident_people_attributes'
        }
      ]
    }
  };

  private customSectionsReverse(section: KeyVal, distSection: KeyVal, distName: string, sectionsObj: KeyVal = null) {
    // 'patients' or 'witnesses'
    const srcName = this.mapping[distName].dist;
    const currSrcSection = distName === 'patients' ? section[srcName] : section[srcName][0];
    Object.keys(currSrcSection).forEach((propName) => {
      // process template-specific fields/sections
      this.tplSpecificSectionsReverse(propName, section, distSection, distName, sectionsObj);
      // process 'patients' or 'witnesses'
      if (propName === 'accident_people_attributes') {
        Object.keys(currSrcSection['accident_people_attributes'][0]).forEach((apAttr) => {
          distSection[apAttr] = currSrcSection['accident_people_attributes'][0][apAttr];
          if (apAttr === 'id') {
            distSection._accident_people_id = currSrcSection['accident_people_attributes'][0].id;
          }
        });
      } else {
        distSection[propName] = currSrcSection[propName];
      }
    });
  }

  private tplSpecificSectionsReverse(
    propName: string,
    section: KeyVal,
    distSection: KeyVal,
    distName: string,
    sectionsObj
  ) {
    if (distName !== 'patients') {
      return false;
    }
    if (this.currentTemplate === 'nsaa') {
      return this.nsaaSpecificSectionsReverse(propName, section, distSection, distName, sectionsObj);
    }
    return false;
  }

  private nsaaSpecificSectionsReverse(
    propName: string,
    section: KeyVal,
    distSection: KeyVal,
    distName: string,
    sectionsObj
  ) {
    let isProcessed = false;
    const srcName = this.mapping[distName].dist;
    let dist = distSection;
    const description_fields = this.tplSpecific.nsaa.description_fields;
    const switchSections = (field: string) => {
      if (description_fields.includes(field)) {
        dist = sectionsObj.description;
        isProcessed = true;
      } else {
        dist = distSection;
      }
    };
    if (propName === 'accident_people_attributes') {
      Object.keys(section[srcName]['accident_people_attributes'][0]).forEach((apAttr) => {
        switchSections(apAttr);
        dist[apAttr] = section[srcName]['accident_people_attributes'][0][apAttr];
      });
    } else {
      switchSections(propName);
      dist[propName] = section[srcName][propName];
    }
    return isProcessed;
  }

  public reverseTransformIncidentById(reportId: number, token: string, currentTemplate: IncidentTemplate) {
    this.currentTemplate = currentTemplate;
    return this.reportService.getIncidentReport(token, reportId).pipe(
      take(1),
      map((rep) => ({
        incident: _.cloneDeep(rep),
        transformIncident: this.reverseTransformIncident(rep, currentTemplate)
      }))
    );
  }

  public reverseTransformIncident(submittedReport: KeyVal, currentTemplate: IncidentTemplate) {
    this.currentTemplate = currentTemplate;
    const rep = { ...submittedReport };
    const allFields = this.getAllFields(currentTemplate);
    const sections = Object.keys(allFields).reduce((acc, key) => ({ ...acc, [key]: {} }), {});
    const inSection = (prop) => {
      const sArr = Object.keys(sections);
      for (let i = 0, len = sArr.length; i < len; i++) {
        const sectionName = sArr[i];
        if (allFields[sectionName].includes(prop)) {
          return sectionName;
        }
      }
      return null;
    };
    if (submittedReport.patient_attributes) {
      this.reverseTransformTplExceptions(sections, submittedReport);
    }
    Object.keys(submittedReport).forEach((prop) => {
      const mainFields = [...this.mainFields];
      // process copper's exception(s)
      if (this.currentTemplate === 'copper') {
        mainFields.splice(mainFields.indexOf('completed_by'), 1);
      }
      // main section props
      if (mainFields.includes(prop)) {
        return;
      }
      // patients, witnesses, etc. (non-standard props)
      if (Object.keys(this.mapping.reverseProcessing).includes(prop)) {
        const distName = this.mapping.reverseSectionsMapping[prop]();
        this.mapping.reverseProcessing[prop](submittedReport, distName ? sections[distName] : rep, sections);
        delete rep[prop];
      }
      // standard props
      const s = inSection(prop);
      if (s) {
        sections[s][prop] = submittedReport[prop];
        delete rep[prop];
      }
    });
    // rest of the props that don't belong to any section will be in 'rep'
    return {
      mainSection: { ...rep },
      sections
    };
  }

  private reverseTransformTplExceptions(sections, submittedReport: KeyVal) {
    if (this.currentTemplate === 'copper' && sections['first_aid']) {
      sections['first_aid'].medications = submittedReport.patient_attributes.medications;
    }
    if (this.currentTemplate === 'nsaa' && sections['transport']) {
      sections['transport'].local_accomodation_info = submittedReport.patient_attributes.local_accomodation_info;
    }
    if (this.currentTemplate === 'nsaa' && sections['additional_questions']) {
      sections['additional_questions'].drugs_alcohol = submittedReport.patient_attributes.drugs_alcohol;
    }
    if (this.currentTemplate === 'nsaa' && sections['equipment']) {
      sections['equipment'].rental_bike = submittedReport.patient_attributes.rental_bike;
      sections['equipment'].rental_shoe = submittedReport.patient_attributes.rental_shoe;
    }
    if (this.currentTemplate === 'cwsaa' && sections['transport']) {
      ['completed_by', 'completed_date', 'signature'].forEach((f) => (sections['transport'][f] = submittedReport[f]));
    }
  }

  public getAllFields(currentTemplate: string): KeyValStrArr {
    const sectionFields = {};
    const currSections = this.allSections[currentTemplate];
    Object.keys(currSections).forEach((sectionName) => {
      const TPL = currentTemplate.toUpperCase();
      const SECTION = sectionName.toUpperCase();
      sectionFields[sectionName] = this.getSectionFields(templates[`${TPL}_${SECTION}_TEMPLATE`]);
    });
    return sectionFields;
  }

  public getSectionFields(templateRows: ITemplate) {
    const fields = [];
    templateRows.forEach((row) => row.cols.forEach((col) => fields.push(col.field)));
    return fields;
  }

  public getNewIncidentNumber(token: string): Observable<{ report_number: number }> {
    return this.reportService.getNewIncidentNumber(token);
  }

  public camelToSnake(str: string) {
    return str.replace(/[\w]([A-Z])/g, (m) => m[0] + '_' + m[1]).toLowerCase();
  }

  public submit(token: string, report: KeyVal, currentTemplate: IncidentTemplate, closeout = false) {
    this.currentTemplate = currentTemplate;
    const status = closeout ? 'pre-approval' : 'available';
    this.applyTplSpecificLogic(report);
    const prepared: KeyVal = {
      accident_report: { ...this.transformIncidentData(report), status }
    };
    this.setUpIds(prepared);
    this.cleanUpLocationObjects(prepared);
    return this.reportService[prepared.accident_report.id ? 'updateIncident' : 'submitIncident'](token, prepared);
  }

  private cleanUpLocationObjects(prepared: any) {
    const locationMapping = {
      'Lift Incident': ['lift_id'],
      'Marked Ski Run': ['ski_run_id'],
      'Biking or Hiking': ['trail_id'],
      'Freestyle Terrain': ['park_id', 'park_feature_id']
    };
    const locationKeys = Object.keys(locationMapping);
    const incidentLocation = prepared.accident_report.incident_location;
    const cleanUpFn = (locName) => {
      if (incidentLocation !== locName) {
        locationMapping[locName].forEach((f) => (prepared.accident_report[f] = null));
      }
    };
    if (incidentLocation) {
      locationKeys.forEach((locName) => cleanUpFn(locName));
    }
  }

  private setUpIds(prepared: any) {
    // Set up an ID for 'patients'
    if (prepared.accident_report.patient_attributes) {
      this.swapIds(prepared.accident_report.patient_attributes);
    }
    // Set up an ID for 'witnesses'
    if (prepared.accident_report.witnesses_attributes && prepared.accident_report.witnesses_attributes[0]) {
      this.swapIds(prepared.accident_report.witnesses_attributes[0]);
    }
  }

  private applyTplSpecificLogic(report: KeyVal) {
    if (this.currentTemplate === 'copper' && report.sections.patients) {
      this.applyCopperSpecificLogic(report);
    }
    if (this.currentTemplate === 'nsaa' && report.sections.patients) {
      this.applyNsaaSpecificLogic(report);
    }
  }

  private applyCopperSpecificLogic(report: KeyVal) {
    if (report.sections.first_aid && report.sections.patients) {
      report.sections.patients.medications = report.sections.first_aid.medications || null;
    }
  }

  private applyNsaaSpecificLogic(report: KeyVal) {
    if (report.sections.description) {
      const desc_fields = this.tplSpecific.nsaa.description_fields;
      desc_fields.forEach((f) => (report.sections.patients[f] = report.sections.description[f]));
      report.sections.description = {};
    }
    if (report.sections.transport) {
      report.sections.patients.local_accomodation_info = report.sections.transport.local_accomodation_info;
    }
    if (report.sections.additional_questions) {
      report.sections.patients.drugs_alcohol = report.sections.additional_questions.drugs_alcohol;
    }
    if (report.sections.equipment) {
      report.sections.patients.rental_bike = report.sections.equipment.rental_bike;
      report.sections.patients.rental_shoe = report.sections.equipment.rental_shoe;
    }
  }

  private swapIds(attrs: any) {
    // const attrs = key === 'patient_attributes' ? preparedReport.accident_report[key] : preparedReport.accident_report[key][0];
    attrs.id = attrs.identifiable_id;
    if (attrs.accident_people_attributes && attrs.accident_people_attributes.length > 0 && attrs._accident_people_id) {
      attrs.accident_people_attributes[0].id = attrs._accident_people_id;
    }
  }

  private transformIncidentData(report: KeyVal) {
    const { mainSection, sections } = report;
    const customSections = this.customSections;
    let result: KeyVal = this.mainProcessing(mainSection);
    const sectionNames = Object.keys(sections);
    sectionNames.forEach((section) => {
      const data = customSections.includes(section)
        ? this.sectionsProcessing(sections[section], section)
        : sections[section];
      result = { ...result, ...this.mainProcessing(data) };
    });
    return result;
  }

  private mainProcessing(sectionData: KeyVal) {
    const custom = Object.keys(this.mapping.customProcessing);
    const sectionKeys = Object.keys(sectionData);
    const result: KeyVal = {};
    sectionKeys.forEach((fieldName) => {
      if (custom.includes(fieldName)) {
        if (sectionData[fieldName]) {
          this.mapping.customProcessing[fieldName](sectionData, result);
        }
      } else {
        result[fieldName] = sectionData[fieldName];
      }
    });
    return result;
  }

  private sectionsProcessing(sectionData: KeyVal, section: string) {
    const sectionKeys = Object.keys(sectionData);
    const result: KeyVal = {
      [this.mapping[section].dist]: this.mapping[section].dist !== 'patient_attributes' ? [{}] : {}
    };
    const defaultDist = result[this.mapping[section].dist];
    const idx = this.mapping[section].index;
    const defDist = idx !== undefined ? defaultDist[idx] : defaultDist;
    // iterate over all section fields
    sectionKeys.forEach((fieldName) => {
      let isExcluded = false;
      this.mapping[section].exclude.forEach((exc) => {
        // create a 'dist' array if it doesn't exist
        if (exc.dist !== '' && !defDist[exc.dist]) {
          defDist[exc.dist] = [{}];
        }
        // copy field to it's dist
        if (exc.fields.includes(fieldName)) {
          isExcluded = true;
          if (exc.dist !== '') {
            defDist[exc.dist][0][fieldName] = sectionData[fieldName];
          } else {
            result[fieldName] = sectionData[fieldName];
          }
        }
      });
      // default copying
      if (!isExcluded) {
        defDist[fieldName] = sectionData[fieldName];
      }
    });
    return result;
  }

  get sectionsQuestionsRequired() {
    return this.sectionsQuestionsRequired$.value;
  }
}
