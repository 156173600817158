import { User, UserCredentials } from '@ea-models';
import { AuthStateModel } from './auth.state';

export class Login {
  static readonly type = '[Auth] Login';
  constructor(public credentials: UserCredentials) {}
}

export class LoginSuccess {
  static readonly type = '[Auth] Login Success';
  constructor(public credentials: UserCredentials, public user: User) {}
}

export class LoginError {
  static readonly type = '[Auth] Login Error';
  constructor(public errorMessage: string) {}
}

export class SetupUser {
  static readonly type = '[Auth] Setup User';
  constructor(public user: User) {}
}

export class SetupUserError {
  static readonly type = '[Auth] Setup User Error';
  constructor(public errorMessage: string) {}
}

export class LoginComplete {
  static readonly type = '[Auth] Login Complete';
}

export class Logout {
  static readonly type = '[Auth] Logout';
}

export class UpdateAuthState {
  static readonly type = '[Auth] Update Auth State';
  constructor(public newState: Partial<AuthStateModel>) {}
}
