import { Injectable } from '@angular/core';
import { DispatchConsoleEvent } from '@ea-models';
import { dispatchEventArraySchema, NormalizedDispatchEvent, NormalizedEntities } from '@ea-models-v4/normalizedModels';
import { DispatchConsoleService } from '@ea-services-v4';
import { NormalizrHelper } from '@edgeauditor/app/helpers/normalizr.helper';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, tap } from 'rxjs/operators';
import { GeneralError } from '../app/app.action';
import { GetDispatchEvents, GetDispatchEventsSuccess, LockDispatchEvent, UnlockDispatchEvent } from './dispatch.action';
import { produce } from 'immer';
import _ from 'lodash';
import { denormalize } from 'normalizr';

export interface DispatchStateModel {
  events: {
    byId: { [id: number]: NormalizedDispatchEvent };
    allIds: number[];
  };
  selectedEvent: number;
}

@State<DispatchStateModel>({
  name: 'dispatchConsole',
  defaults: {
    events: {
      byId: null,
      allIds: []
    },
    selectedEvent: null
  }
})
@Injectable({
  providedIn: 'root'
})
export class DispatchState {
  constructor(private dispatchConsoleService: DispatchConsoleService) {}

  @Selector()
  static getEvents(state: DispatchStateModel) {
    if (!state.events.byId) {
      return [];
    } else {
      const entities: NormalizedEntities = {
        events: state.events.byId
      };
      const events: DispatchConsoleEvent[] = denormalize(state.events.allIds, dispatchEventArraySchema, entities) || [];
      return events;
    }
  }

  @Action(GetDispatchEvents)
  getDispatchEvents(ctx: StateContext<DispatchStateModel>, {}: GetDispatchEvents) {
    return this.dispatchConsoleService.getEvents().pipe(
      tap((events: DispatchConsoleEvent[]) => {
        ctx.dispatch(new GetDispatchEventsSuccess(events));
      }),
      catchError((e) => {
        let message = 'There was a problem while fetching Dispatch Events. Please try again or contact Support.';
        return ctx.dispatch(new GeneralError(message));
      })
    );
  }

  @Action(GetDispatchEventsSuccess)
  getDispatchEventsSuccess(ctx: StateContext<DispatchStateModel>, { dispatchEvents }: GetDispatchEventsSuccess) {
    const normalizedEvents = NormalizrHelper.normalizeDispatchEvents(dispatchEvents).entities.events;

    ctx.setState(
      produce((draft: DispatchStateModel) => {
        if (normalizedEvents) {
          draft.events = {
            byId: _.merge(draft.events.byId, normalizedEvents),
            allIds: _.union(
              draft.events.allIds,
              Object.keys(normalizedEvents).map((key) => +key)
            )
          };
        }
      })
    );
  }

  @Action(LockDispatchEvent)
  lockDispatchEvent(ctx: StateContext<DispatchStateModel>, { id }: LockDispatchEvent) {
    return this.dispatchConsoleService.lockEvent(id).pipe(
      catchError((e) => {
        let message = 'There was a problem while locking the Dispatch Event. Please try again or contact Support.';
        return ctx.dispatch(new GeneralError(message));
      })
    );
  }

  @Action(UnlockDispatchEvent)
  unlockDispatchEvent(ctx: StateContext<DispatchStateModel>, { id }: UnlockDispatchEvent) {
    return this.dispatchConsoleService.unlockEvent(id).pipe(
      catchError((e) => {
        let message = 'There was a problem while unlocking the Dispatch Event. Please try again or contact Support.';
        return ctx.dispatch(new GeneralError(message));
      })
    );
  }
}
