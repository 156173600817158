import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  NemsisQuestionAnswer,
  NemsisReport,
  NemsisReportCreation,
  NemsisReportCreationResponse,
  NemsisReportDetail,
  NemsisReportsResponse,
  NemsisReportStatus,
  NemsisTemplatesResponse
} from '@ea-models-v4/nemsis';
import { ConfigService } from '@ea-services';

interface UploadNemsisReport {
  status?: NemsisReportStatus;
  accident_report_id?: number;
  dispatch_event_id?: number;
  nemsis_template_id?: number;
  nemsis_report_answers_attributes?: Partial<NemsisQuestionAnswer>[];
}

@Injectable({
  providedIn: 'root'
})
export class NemsisService {
  constructor(private http: HttpClient, private configService: ConfigService) {}

  getTemplates() {
    return this.http
      .get<NemsisTemplatesResponse>(this.configService.getBaseUrl(4) + 'nemsis_templates')
      .pipe(map((response) => response.nemsis_templates));
  }

  getReports(status: NemsisReportStatus): Observable<NemsisReport[]> {
    return this.http
      .get<NemsisReportsResponse>(this.configService.getBaseUrl(4) + `nemsis_reports`, { params: { status } })
      .pipe(map((response) => response.nemsis_reports));
  }

  getReport(id: number): Observable<NemsisReportDetail> {
    return this.http
      .get<NemsisReportDetail>(this.configService.getBaseUrl(4) + `nemsis_reports/${id}`)
      .pipe(map((response) => response));
  }

  updateNemsisReport(id: number, data: UploadNemsisReport): Observable<NemsisReportDetail> {
    const url = this.configService.getBaseUrl(4) + `nemsis_reports/${id}`;
    return this.http.put<NemsisReportDetail>(url, data);
  }

  createReport(requestData: NemsisReportCreation) {
    return this.http
      .post<NemsisReportCreationResponse>(this.configService.getBaseUrl(4) + 'nemsis_reports', requestData)
      .pipe(map((response) => response));
  }
}
