import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dynamicSelectCustom'
})
export class DynamicSelectCustomPipe implements PipeTransform {
  transform(value: any[] | ((org: string) => any[]), org: string): any[] {
    if (typeof value === 'function') {
      return value(org);
    }

    return value;
  }
}
