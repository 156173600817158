import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthState } from '@edgeauditor/app/store/auth/auth.state';
import { RouterConstant } from '@edgeauditor/app/constants/router.constant';
import { NavController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private store: Store, private navController: NavController) {}

  canActivate() {
    return this.store.select(AuthState.getUser).pipe(
      map((user) => {
        if (!user) {
          this.navController.navigateRoot([RouterConstant.LOGIN_PAGE]);
          return false;
        }
        return true;
      })
    );
  }
}
