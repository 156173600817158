import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
  ChangeDetectorRef
} from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { ChangeDetectionStrategy } from '@angular/core';
import { GlobalService } from '@ea-services';
import { DevicePlatform } from '@ea-models-v4/common';
import { AppState } from '@edgeauditor/app/store/app/app.state';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';

@Component({
  selector: 'app-report-completion',
  templateUrl: './report-completion.component.html',
  styleUrls: ['./report-completion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportCompletionComponent implements AfterViewInit {
  private signaturePad: SignaturePad;
  @ViewChild('_signaturePad') set _signaturePad(sign: any) {
    if (sign && sign.signaturePad) {
      this.signaturePad = sign.signaturePad;
      if (window.document.body.clientWidth) {
        this.resizeCanvas(window.document.body.clientWidth);
      }
    }
  }
  @Output() nameChanged = new EventEmitter<string>();
  @Output() dateChanged = new EventEmitter<string>();
  @Output() signatureChanged = new EventEmitter<string>();
  @Input() signatureEvent: Observable<string>;
  @Input() nameEvent: Observable<string>;
  @Input() dateEvent: Observable<string>;
  @Input() signatureOnly: boolean;
  @Input() hideHeader: boolean;
  @Input() requiredObjects: { [key: string]: boolean };
  @Input() parentChangeDetectorRef: ChangeDetectorRef;
  maxDate = new Date();
  canvasWidth = 350;
  canvasHeight = 161;
  imgStyle = {
    height: '300px',
    width: '350px'
  };
  signaturePadOptions: Object = {
    minWidth: 1,
    maxWidth: 4,
    canvasWidth: 350,
    canvasHeight: 161
  };
  signature = '';
  s3signature = null;
  model = {
    completed_by: '',
    completed_date: ''
  };

  @ViewSelectSnapshot(AppState.getDevicePlatform) devicePlatform: DevicePlatform;

  constructor(public globalService: GlobalService) {}

  ngAfterViewInit() {
    if (this.signatureEvent) {
      this.signatureEvent
        .pipe(
          take(1),
          tap((signature) => (this.s3signature = signature))
        )
        .subscribe();
    }
    if (this.nameEvent) {
      this.nameEvent
        .pipe(
          take(1),
          tap((name) => (this.model.completed_by = name))
        )
        .subscribe();
    }
    if (this.dateEvent) {
      this.dateEvent
        .pipe(
          take(1),
          tap((dt) => (this.model.completed_date = dt))
        )
        .subscribe();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize($event) {
    this.resizeCanvas($event.target.innerWidth);
    if (!this.s3signature && this.signature && this.signature !== '') {
      this.signaturePad.fromDataURL(this.signature, {
        width: this.canvasWidth,
        height: this.canvasHeight
      });
    }
  }

  private resizeCanvas(clientWidth) {
    // extra padding for a signature in a tab
    const extra = this.signatureOnly ? 73 : 0;
    // calculate total width
    this.canvasWidth = clientWidth - 70 - extra;
    this.signaturePad['_canvas'].width = this.canvasWidth;
    this.imgStyle.width = this.canvasWidth + 'px';
    this.imgStyle.height = this.canvasHeight + 'px';
  }

  drawStart() {
    if (this.parentChangeDetectorRef && this.devicePlatform === 'android') this.parentChangeDetectorRef.detach();
  }

  drawComplete() {
    try {
      this.signature = this.signaturePad.toDataURL();
      this.signatureChanged.emit(this.signature);
    } catch {}
    if (this.parentChangeDetectorRef && this.devicePlatform === 'android') this.parentChangeDetectorRef.reattach();
  }

  clearSignature() {
    this.signaturePad.clear();
    this.signature = '';
    this.signatureChanged.emit('');
    this.s3signature = null;
  }

  onDateChange(value: Date) {
    this.dateChanged.emit(value.toISOString());
  }

  onChangeEvent(e: Event) {
    const value = (<HTMLTextAreaElement>e.target).value;
    this.nameChanged.emit(value);
  }
}
