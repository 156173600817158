/**
 * * Inspired by https://github.com/RenovoSolutions/TypeScript-Angular-Utilities/tree/master/source/services/timezone
 */

export interface ITimezone {
  offset: string;
  display: string;
  momentName: string;
  offsetMinutes?: number;
  eaTimezone?: string;
}

export interface ITimezones {
  AST: ITimezone;
  EST: ITimezone;
  CST: ITimezone;
  MST: ITimezone;
  PST: ITimezone;
  AKST: ITimezone;
  HAST: ITimezone;

  get(offset: string): ITimezone;
  all(): ITimezone[];
}

export const UTC: ITimezone = {
  offset: '+00:00',
  display: 'UTC',
  momentName: 'UTC',
  offsetMinutes: 0,
  eaTimezone: '0'
};

export interface ITimezoneService {
  getTimezone(isoString: string): ITimezone;
  getMomentTimezone(isoString: string): string;
  setCurrentTimezone(offset: string): void;
  currentTimezone: ITimezone;
  buildMomentWithTimezone(dateValue: string | moment.Moment, timezone: ITimezone, format?: string): moment.Moment;
}
