<div class="vitals-component" (scroll)="logScrollStart($event)" #vitalsScroller>
  <ng-container *ngFor="let model of vitals; let idx = index">
    <div class="vitals-component__title" [attr.data-ci]="idx + 1">
      {{ 'Vitals' + (vitals.length > 1 ? ' (' + (idx + 1) + ')' : '') }}
    </div>
    <ion-grid class="vitals-component__body" [attr.data-ci]="idx + 1">
      <ion-row>
        <ion-col size-xs="12" size-sm="2" class="time">
          <ion-item lines="none">
            <ion-label>Time</ion-label>
          </ion-item>
          <ion-item lines="none">
            <input
              readonly
              class="standard-input"
              [owlDateTime]="vitalsTime"
              [max]="maxDate"
              [owlDateTimeTrigger]="vitalsTime"
              [(ngModel)]="model.vitals_time"
              (click)="focusOnInputTime()"
              (dateTimeChange)="onTimeChange($event.value, model)"
            />
            <owl-date-time pickerType="timer" #vitalsTime></owl-date-time>
            <!--<ion-datetime displayFormat="H:mm" [(ngModel)]="model.vitals_time"></ion-datetime>-->
          </ion-item>
        </ion-col>
        <ion-col size-xs="12" size-sm="2" class="avpu">
          <ion-item lines="none">
            <ion-label>AVPU</ion-label>
          </ion-item>
          <div class="ion-padding-start ion-padding-end">
            <ng-select
              [items]="['A', 'V', 'P', 'U']"
              [selectOnTab]="true"
              placeholder=""
              class="standard-select"
              [(ngModel)]="model.vitals_avpu"
            >
            </ng-select>
          </div>
        </ion-col>
        <ion-col size-xs="12" size-sm="2" class="hr-min">
          <ion-item lines="none">
            <ion-label>HR/min</ion-label>
          </ion-item>
          <ion-item lines="none">
            <ion-input debounce="100" [(ngModel)]="model.vitals_hr"></ion-input>
          </ion-item>
        </ion-col>
        <ion-col size-xs="12" size-sm="2" class="bp">
          <ion-item lines="none">
            <ion-label>BP</ion-label>
          </ion-item>
          <ion-item lines="none">
            <ion-input debounce="100" [(ngModel)]="model.vitals_bp"></ion-input>
          </ion-item>
        </ion-col>
        <ion-col size-xs="12" size-sm="2" class="rr-min">
          <ion-item lines="none">
            <ion-label>RR/min</ion-label>
          </ion-item>
          <ion-item lines="none">
            <ion-input debounce="100" [(ngModel)]="model.vitals_rr"></ion-input>
          </ion-item>
        </ion-col>
        <ion-col size-xs="12" size-sm="2" class="temp">
          <ion-item lines="none">
            <ion-label>Temp</ion-label>
          </ion-item>
          <ion-item lines="none">
            <ion-input
              debounce="100"
              [(ngModel)]="model.vitals_temp"
            ></ion-input>
          </ion-item>
        </ion-col>
        <ion-col size-xs="12" size-sm="2" class="o2-sat">
          <ion-item lines="none">
            <ion-label>O2 SAT %</ion-label>
          </ion-item>
          <ion-item lines="none">
            <ion-input
              debounce="100"
              [(ngModel)]="model.vitals_o2sat"
            ></ion-input>
          </ion-item>
        </ion-col>
        <ion-col size-xs="12" size-sm="2" class="glucose">
          <ion-item lines="none">
            <ion-label>Glucose</ion-label>
          </ion-item>
          <ion-item lines="none">
            <ion-input
              debounce="100"
              [(ngModel)]="model.vitals_glucose"
            ></ion-input>
          </ion-item>
        </ion-col>
        <ion-col size-xs="12" size-sm="2" class="skin">
          <ion-item lines="none">
            <ion-label>Skin</ion-label>
          </ion-item>
          <ion-item lines="none">
            <ion-input
              debounce="100"
              [(ngModel)]="model.vitals_skin"
            ></ion-input>
          </ion-item>
        </ion-col>
        <ion-col size-xs="12" size-sm="2" class="pupil-l">
          <ion-item lines="none">
            <ion-label>Pupil (L)</ion-label>
          </ion-item>
          <ion-item lines="none">
            <ion-input
              debounce="100"
              [(ngModel)]="model.vitals_pupil_l"
            ></ion-input>
          </ion-item>
        </ion-col>
        <ion-col size-xs="12" size-sm="2" class="pupil-r">
          <ion-item lines="none">
            <ion-label>Pupil (R)</ion-label>
          </ion-item>
          <ion-item lines="none">
            <ion-input
              debounce="100"
              [(ngModel)]="model.vitals_pupil_r"
            ></ion-input>
          </ion-item>
        </ion-col>
        <ion-col size-xs="12" size-sm="2" class="other">
          <ion-item lines="none">
            <ion-label>Other</ion-label>
          </ion-item>
          <ion-item lines="none">
            <ion-input
              debounce="100"
              [(ngModel)]="model.vitals_other"
            ></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size-xs="12">
          <ion-button expand="full" color="danger" (click)="removeVitals(idx)"
            >Remove</ion-button
          >
        </ion-col>
      </ion-row>
    </ion-grid>
  </ng-container>
</div>
<div class="btn-container">
  <div class="btn-item full">
    <ion-button expand="full" color="primary" (click)="addVitals()"
      >Add More</ion-button
    >
  </div>
  <div class="btn-item">
    <ion-button color="danger" (click)="close()">Cancel</ion-button>
    <ion-button color="success" (click)="save()">Save</ion-button>
  </div>
</div>
