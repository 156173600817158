import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { take, tap } from 'rxjs/operators';
import { ModalController, AlertController } from '@ionic/angular';
import _ from 'lodash';

import { GlobalService, SpinnerService, PopUpService, ReportService } from '@ea-services';
import { IAttachment } from '@ea-models/attachments';
import { WARNING_HEADER } from '../../../pages/pages.constants';
import { TasksService } from '@ea-services/api/tasks.service';
import { LogRocketProvider } from '@ea-services/provider/logrocket';

@Component({
  selector: 'task-detail-modal',
  templateUrl: './task-detail.component.html',
  styleUrls: ['./task-detail.component.scss']
})
export class TaskDetailModalComponent implements OnInit, OnDestroy {
  @Input() params: { task: any; tab: number };

  model: { completed_by: string; complete: boolean; comment: string };
  tabId = 1;
  currentUser: any;
  isSuccessful: boolean;
  task: any;
  isShowWarning: boolean;
  askBeforeLeaving = false;
  attachments: IAttachment[];
  features: any = [];

  get allowSave() {
    return !!this.model.completed_by && !!this.model.completed_by.trim();
  }

  constructor(
    public globalService: GlobalService,
    public modalController: ModalController,
    private spinnerService: SpinnerService,
    private logRocketProvider: LogRocketProvider,
    private taskService: TasksService,
    private reportService: ReportService,
    private popUpService: PopUpService,
    private alertController: AlertController
  ) {}

  ngOnInit() {
    if (this.globalService._currentUser) {
      this.currentUser = this.globalService._currentUser;
    }
    this.isSuccessful = false;
    this.tabId = this.params.tab;
    this.initModel();
  }

  ngOnDestroy(): void {}

  ionViewWillEnter() {
    this.getDetail();
    this.getFeatures();
  }

  ionViewDidEnter() {}

  async close(result?: any) {
    if (this.tabId === 2 && !result) {
      this.popUpService.popupAlert(WARNING_HEADER, 'Are you sure want to cancel?', this.alertController, () =>
        this.modalController.dismiss(result)
      );
      return;
    }
    await this.modalController.dismiss(result);
  }

  reset() {
    this.initModel();
  }

  save() {
    if (!this.allowSave) return;
    this.askBeforeLeaving = false;
    this.spinnerService.show('processing...');
    const model = { id: this.task.id, task: this.model };
    this.taskService
      .completeTask(model, this.currentUser.token)
      .pipe(
        take(1),
        tap((res: any) => {
          this.spinnerService.hide();
          if (!res || !res.success) {
            this.logRocketProvider.captureMessage('Finish Task failed', 'Create Task failed', 'create task');
            this.popUpService.popupError('Finish Task failed.', this.alertController);
            return;
          }
          this.close(true);
        })
      )
      .subscribe();
  }

  private initModel() {
    this.model = { completed_by: null, complete: true, comment: null };
  }

  private getDetail() {
    if (!this.params.task) return;
    this.spinnerService.show('loading...');
    this.taskService
      .getTaskDetail(this.params.task.id, this.currentUser.token)
      .pipe(
        take(1),
        tap((res: any) => {
          this.spinnerService.hide();
          if (!res.success || !res.data) {
            return;
          }

          this.task = res.data;
          const _attachments = (res.data.object_attachments || []).reverse();
          this.attachments = _attachments.map((a) => {
            return { object_attachment: { file: a.url } };
          });
          if (this.reportService.reports) {
            const report = this.reportService.reports.find((r) => r.id === this.task.report_id);
            if (report) {
              this.task.report_name = report.name;
            }
          }

          if (this.taskService.departmentsObject) {
            this.task.department_name = this.taskService.departmentsObject[this.task.department_id];
          }
          if (this.taskService.areasObject) {
            this.task.area_name = this.taskService.areasObject[this.task.area_id];
          }
          if (this.taskService.typesObject) {
            this.task.type_name = this.taskService.typesObject[this.task.task_type_id];
          }
          //this.attachments = [{object_attachment: {file: this.tasksProService.fakeAttachment}}];
          if (this.task.feature_id && this.features?.length) {
            this.task.feature_name = this.features.find((i) => i.id === this.task.feature_id)?.name;
          }
        })
      )
      .subscribe();
  }

  private getFeatures() {
    this.taskService
      .getFeatures(this.currentUser.token)
      .pipe(
        take(1),
        tap((res) => {
          const types = {
            building: true,
            lift: true,
            park: true,
            trail: true,
            sign: true,
            skirun: true
          };
          this.features = _.cloneDeep(res).filter((f) => types[(f.type || '').toLowerCase()]);
          if (this.task?.feature_id) {
            this.task.feature_name = this.features.find((i) => i.id === this.task.feature_id)?.name;
          }
        })
      )
      .subscribe();
  }
}
