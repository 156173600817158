import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ViewPhotoModal } from './view-photo';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@edgeauditor/app/components/shared/shared.module';

@NgModule({
  declarations: [ViewPhotoModal],
  imports: [CommonModule, IonicModule, FormsModule, ReactiveFormsModule, SharedModule],
  entryComponents: [ViewPhotoModal]
})
export class ViewPhotoModalModule {}
