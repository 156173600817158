import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { ConfigService, ApiService, SpinnerService, GlobalService, GroomersService } from '@ea-services';
import { of } from 'rxjs';
import { tap, take, delay } from 'rxjs/operators';
import { priority } from '../../../constants/tasks.constants';
import _ from 'lodash';

@Component({
  selector: 'modal-filter-task',
  templateUrl: 'filter-task.component.html',
  styleUrls: ['filter-task.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FilterTasksModalComponent implements OnInit {
  @Input() types: any;
  @Input() areas: any;
  @Input() departments: any;
  @Input() selectedOptions: {
    area_id: number;
    priority: number;
    department_id: number;
    type_id: number;
    remove_filters?: boolean;
  };

  model: any;
  public readonly priorities = [
    { id: 1, name: 'Low', value: priority.low, selected: false },
    { id: 2, name: 'Medium', value: priority.medium },
    { id: 3, name: 'High', value: priority.high }
  ];

  constructor(
    public configService: ConfigService,
    public apiService: ApiService,
    public globalService: GlobalService,
    public groomersService: GroomersService,
    public spinnerService: SpinnerService,
    public modalController: ModalController,
    public alertController: AlertController
  ) {}

  ngOnInit() {}

  ionViewWillEnter() {}

  ionViewDidEnter() {
    this.priorities.forEach((i) => {
      i.selected = i.value === this.selectedOptions.priority;
    });
    this.types.forEach((i) => {
      i.selected = i.value === this.selectedOptions.type_id;
    });
    this.areas.forEach((i) => {
      i.selected = i.value === this.selectedOptions.area_id;
    });
    this.departments.forEach((i) => {
      i.selected = i.value === this.selectedOptions.department_id;
    });
  }

  async close() {
    await this.modalController.dismiss();
  }

  async applyFilter(removeFilters?: boolean) {
    if (removeFilters) {
      await this.modalController.dismiss({
        type_id: 0,
        area_id: 0,
        priority: 0,
        department_id: 0,
        remove_filters: true
      });
      return;
    }
    const model = this.selectedOptions;
    const priority = this.priorities.find((a) => a.selected);
    model.priority = priority ? priority.value : 0;
    model.remove_filters = false;
    await this.modalController.dismiss(model);
  }

  onChange(item, list, allowEmpty?: boolean) {
    if (item.selected && !allowEmpty) {
      return;
    }
    of(null)
      .pipe(
        delay(100),
        take(1),
        tap(() => {
          const _selected = item.selected;
          list.forEach((element) => {
            element.selected = false;
          });
          item.selected = allowEmpty ? !_selected : true;
        })
      )
      .subscribe();
  }
}
