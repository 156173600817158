<ea-header title="Select Filter(s)" [hasCloseButton]="true"></ea-header>

<ion-content class="health-safety-staff-filter">
  <ion-toolbar class="ion-padding-horizontal">
    Filtered result may change if you choose another department or training type
    from the previous screen.
  </ion-toolbar>
  <div class="container ion-padding-horizontal">
    <h4 class="bold">Staff:</h4>
    <ion-list *ngIf="filters" lines="none" #filterList>
      <ion-item *ngFor="let filter of getKeys(filters);">
        <ion-checkbox
          slot="end"
          mode="ios"
          [(ngModel)]="filters[filter].selected"
          [value]="filters[filter]"
          (click)="onChangeFilter($event, filter)"
        ></ion-checkbox>
        <ion-label>{{ filters[filter].name }}</ion-label>
      </ion-item>
    </ion-list>
  </div>
  <!-- <ion-footer> -->
  <ion-button expand="full" color="success" (click)="save();">
    SAVE
  </ion-button>
  <ion-button expand="full" color="warning" (click)="reset();">
    RESET
  </ion-button>
  <!-- </ion-footer> -->
</ion-content>
