import { Injectable } from '@angular/core';

@Injectable()
export class LogService {
  constructor() {}

  info(msg) {
    console.log('info: ', msg);
  }

  debug(msg) {
    console.log('debug: ', msg);
  }
}
