import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { ConfigService } from '../config.service';

@Injectable({
  providedIn: 'root'
})
export class EmergencyOperationalPlansAPI {
  constructor(private httpClient: HttpClient, private apiService: ApiService, private configService: ConfigService) {}

  public getEmergencyOperationalPlanDocument(planId: number): Observable<any> {
    var params = { emergency_operation_plan_category_id: planId };
    return this.apiService.get(`${this.configService.apiURL()}emergency_operation_plan_documents`, params);
  }

  public getAllEmergencyOperationalPlans(): Observable<any> {
    var params = {};
    return this.apiService.get(`${this.configService.apiURL()}emergency_operation_plan_categories`, params);
  }
}
