import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { HealthSafetyStaffFilterModal } from './health-safety-staff-filter';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { SharedModule } from '@edgeauditor/app/components/shared/shared.module';

@NgModule({
  declarations: [HealthSafetyStaffFilterModal],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    MatDatepickerModule,
    SharedModule
  ],
  entryComponents: [HealthSafetyStaffFilterModal]
})
export class HealthSafetyStaffFilterModule {}
