<ion-grid class="complaint-component">
  <ion-row
           *ngFor="let injury of model.injuries; let idx = index"
           [hidden]="injury._destroy"
           class="ion-padding-top ion-padding-bottom">
    <ion-col
             size-xs="12"
             size-sm="4"
             class="ion-padding-top ion-padding-bottom"
             [class.required-add]="requiredObjects?.part">
      <ion-item lines="none">
        <ion-label>{{ labels.part || 'Part' }}<span class="required">*</span></ion-label>
      </ion-item>
      <div class="ion-padding-start ion-padding-end">
        <ng-select
                   [items]="selects.incident_part"
                   [selectOnTab]="true"
                   class="standard-select"
                   bindLabel="name"
                   bindValue="name"
                   [(ngModel)]="injury.part"
                   [dropdownPosition]="!isMobilePlatform ? 'bottom' : 'top'"
                   (change)="rowChange(idx)"
                   [class.in-valid]="!injury.part"
                   (focus)="incidentProvider.scrollToElement($event)">
        </ng-select>
      </div>
    </ion-col>
    <ion-col
             size-xs="12"
             size-sm="4"
             class="ion-padding-top ion-padding-bottom"
             [class.required-add]="requiredObjects?.side">
      <ion-item lines="none">
        <ion-label>Side <span class="required">*</span></ion-label>
      </ion-item>
      <div class="ion-padding-start ion-padding-end">
        <ng-select
                   [items]="incidentSideItems"
                   [selectOnTab]="true"
                   class="standard-select"
                   bindLabel="name"
                   bindValue="name"
                   [(ngModel)]="injury.side"
                   [dropdownPosition]="!isMobilePlatform ? 'bottom' : 'top'"
                   (change)="rowChange(idx)"
                   [class.in-valid]="!injury.side"
                   (focus)="incidentProvider.scrollToElement($event)">
        </ng-select>
      </div>
    </ion-col>
    <ion-col
             size-xs="12"
             size-sm="4"
             class="ion-padding-top ion-padding-bottom"
             [hidden]="isCopper"
             [class.required-add]="requiredObjects?.treatment_protocol">
      <ion-item lines="none">
        <ion-label>{{ labels.probable || label
          }}<span class="required">*</span></ion-label>
      </ion-item>
      <div class="ion-padding-start ion-padding-end">
        <ng-select
                   [items]="selects.incident_treatment_protocol"
                   [selectOnTab]="true"
                   class="standard-select"
                   bindLabel="name"
                   bindValue="name"
                   [(ngModel)]="injury.treatment_protocol"
                   (change)="rowChange(idx)"
                   (focus)="incidentProvider.scrollToElement($event)"
                   [dropdownPosition]="!isMobilePlatform ? 'bottom' : 'top'"
                   [class.in-valid]="!injury.treatment_protocol"
                   (focus)="incidentProvider.scrollToElement($event)">
        </ng-select>
      </div>
    </ion-col>
    <ion-col
             size-xs="12"
             size-sm="6"
             class="ion-padding-top ion-padding-bottom"
             [hidden]="injury.part !== 'Other' || isCopper"
             [class.required-add]="requiredObjects?.other_injury">
      <ion-item lines="none">
        <ion-label>Other Injury {{ labels.part || 'Part' }}
          <span class="required">*</span></ion-label>
      </ion-item>
      <ion-item lines="none">
        <ion-textarea
                      class="standard-input"
                      debounce="400"
                      rows="5"
                      autocapitalize="sentences"
                      [class.in-valid]="!injury.other_injury"
                      [(ngModel)]="injury.other_injury"
                      (ionChange)="rowChange(idx)">
        </ion-textarea>
      </ion-item>
    </ion-col>
    <ion-col
             size-xs="12"
             size-sm="6"
             class="ion-padding-top ion-padding-bottom"
             [hidden]="injury.treatment_protocol !== 'Other'"
             [class.required-add]="requiredObjects?.other_treatment">
      <ion-item lines="none">
        <ion-label>Other {{ labels.probable || label }}
          <span class="required">*</span></ion-label>
      </ion-item>
      <ion-item lines="none">
        <ion-textarea
                      class="standard-input"
                      debounce="400"
                      rows="5"
                      autocapitalize="sentences"
                      [class.in-valid]="injury.other_treatment"
                      [(ngModel)]="injury.other_treatment"
                      (ionChange)="rowChange(idx)">
        </ion-textarea>
      </ion-item>
    </ion-col>
    <ion-col
             size-xs="12"
             size-sm="8"
             class="ion-padding-top ion-padding-bottom">
      <ion-item lines="none" class="ion-padding-bottom">
        <ion-toggle
                    mode="ios"
                    slot="end"
                    [(ngModel)]="injury.is_primary"
                    (ionChange)="rowChange(idx)">
        </ion-toggle>
        <div slot="end">Is This The Primary Injury?</div>
      </ion-item>
    </ion-col>
    <ion-col
             size-xs="12"
             size-sm="3"
             class="ion-padding-top ion-padding-bottom">
      <ion-item lines="none" class="ion-padding-bottom">
        <ion-button
                    color="danger"
                    [disabled]="model.injuries.length < 2"
                    (click)="removeInjury($event, idx)">Remove</ion-button>
      </ion-item>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size-xs="12">
      <ion-item lines="none">
        <ion-button (click)="addInjury()">Add Injury</ion-button>
      </ion-item>
    </ion-col>
  </ion-row>
</ion-grid>