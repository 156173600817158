import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { NgxActioncableService, Channel } from '@csventures/ngx-actioncable';
import { Dictionary, get } from 'lodash';
import { Subject, Subscription } from 'rxjs';

import { WSActionCable } from '@ea-models-v4/web-socket';
import { environment } from '@edgeauditor/environments/environment';

interface WsSubscription {
  subscription: Subscription;
  obs: Subject<any>;
}

@Injectable({
  providedIn: 'root'
})
export class WebSocketProvider {
  private wsHost: string;
  isSubscribed: boolean;
  cable: WSActionCable.Cable;
  wsSubscriptions: Dictionary<WsSubscription> = {};

  constructor(private cableService: NgxActioncableService) {}

  /** Initiate the websocket cable */
  init(token: string) {
    const apiBaseUrl = environment.apiUrl;
    const { protocol, hostname } = new URL(apiBaseUrl);
    const wsProtocol = protocol.includes('https') ? 'wss' : 'ws';
    this.wsHost = `${wsProtocol}://${hostname}/cable?token=${token}`;
  }

  /** Subscribe to websocket channel */
  subscribeTo<T>(channel: string, params: any = {}, callback: (value: T) => void = null) {
    try {
      // Unsubscribe from any previous subscriptions
      this.unsubscribe(channel);

      // Create channel
      const cable: Channel = this.cableService.cable(this.wsHost).channel(channel, params);

      // Subscribe to websocket
      const obs = new Subject<T>();

      const subscription = cable.received().subscribe((message) => {
        obs.next(message);
      });

      // setup local variable
      this.wsSubscriptions[channel] = { subscription, obs };

      // Handle broadcasts
      if (callback) {
        obs.subscribe(callback);
      } else {
        return obs.asObservable();
      }
    } catch (error) {
      // this.logRocket.error('Subscribing ws channel error: ', error);
    }
  }

  /** Unsubscribe from current channel */
  unsubscribe(channel: string) {
    // Get the subscription and the cable
    const { subscription, obs } = get(this.wsSubscriptions, channel, {} as WsSubscription);
    // Disconnect if channel present
    if (subscription) {
      // this.logRocket.log(`Unsubscribing from ws channel ${channel}`);
      subscription.unsubscribe();
      delete this.wsSubscriptions[channel];
      obs.unsubscribe();
    }
  }
}
