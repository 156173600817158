import { Injectable } from '@angular/core';
import { Dictionary } from 'lodash';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/angular';
import { environment } from '@edgeauditor/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LogRocketProvider {
  //todo: change app id
  APP_ID = 'apeduw/edgeauditor-resort';
  private isInitiated: boolean;
  //private initInfo: any;

  /**
   * Init log rokcet.
   * @param uid user id
   * @param userVars user variables
   */
  init() {
    if (environment.isDeployedBuild) {
      LogRocket.init(this.APP_ID, {
        release: environment.appVersion,
        dom: {
          baseHref:
            environment.environment === 'production'
              ? 'https://browser.edgeauditor.com/'
              : 'https://staging.browser.edgeauditor.com'
        }
      });
      this.addSessionToSentry();
    }
  }

  startNewSession(userVars: Dictionary<any>) {
    if (!this.isInitiated) this.init();

    LogRocket.startNewSession();

    const uuid: string = `${userVars.name} | ${userVars.email}`;
    const userInfo: any = {
      name: uuid,
      email: userVars.location_code
    };

    console.log('startNewSession', uuid, userInfo, userVars);
    LogRocket.identify(uuid, userInfo);
    this.logInfo('app version', environment.appVersion);
  }

  log(name: string, data?: any) {
    if (!environment.isDeployedBuild) {
      console.log(name, data ? { ...data } : '');
    } else {
      LogRocket.log(name, data ? { ...data } : '');
    }
  }

  error(name: string, data?: any) {
    if (!environment.isDeployedBuild) {
      console.error(name, data ? { ...data } : '');
    } else {
      LogRocket.error(name, data ? { ...data } : '');
    }
  }
  logEvent(eventName: string) {
    if (!environment.isDeployedBuild) {
      return;
    }
    LogRocket.track(eventName);
  }

  logInfo(message: string, data: any) {
    if (!environment.isDeployedBuild) {
      console.info(message, data);
      return;
    }
    if (typeof data === 'string') {
      LogRocket.info(`${message} ${data}`);
      return;
    }
    LogRocket.info(message);
    LogRocket.info(data);
  }

  logWarn(message: string, data: any) {
    if (!environment.isDeployedBuild) {
      console.warn(message, data);
      return;
    }
    if (typeof data === 'string') {
      LogRocket.warn(`${message} ${data}`);
      return;
    }
    LogRocket.warn(message);
    LogRocket.warn(data);
  }

  logDebug(message: string, data: any) {
    if (!environment.isDeployedBuild) {
      console.debug(message, data);
      return;
    }
    if (typeof data === 'string') {
      LogRocket.debug(`${message} ${data}`);
      return;
    }
    LogRocket.debug(message);
    LogRocket.debug(data);
  }

  logError(message: string, data: any) {
    if (!environment.isDeployedBuild) {
      console.error(message, data);
      return;
    }
    if (typeof data === 'string') {
      LogRocket.error(`${message} ${data}`);
      return;
    }
    LogRocket.error(message);
    LogRocket.error(data);
  }

  /**
   * capture message.
   * @param message message
   * @param tag additional data to be grouped as "tags"
   * @param pageName additional arbitrary data associated with the event
   */
  captureMessage(message: string, tag?: string, pageName?: string) {
    if (!environment.isDeployedBuild) {
      console.error(message, pageName);
      return;
    }
    LogRocket.captureMessage(message, {
      tags: {
        // additional data to be grouped as "tags"
        subscription: tag
      },
      extra: {
        // additional arbitrary data associated with the event
        pageName: pageName
      }
    });
  }

  /**
   * capture exception.
   * @param message message
   * @param tag additional data to be grouped as "tags"
   * @param pageName additional arbitrary data associated with the event
   */
  captureException(error: Error, tag?: string, pageName?: string) {
    if (!environment.isDeployedBuild) {
      console.error(pageName, tag, error);
      return;
    }
    LogRocket.captureException(error, {
      tags: {
        // additional data to be grouped as "tags"
        subscription: tag
      },
      extra: {
        // additional arbitrary data associated with the event
        pageName: pageName
      }
    });
  }

  private addSessionToSentry() {
    LogRocket.getSessionURL((sessionURL) => {
      Sentry.configureScope((scope) => {
        scope.setExtra('sessionURL', sessionURL);
      });
    });
  }
}
