import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import * as Sentry from '@sentry/angular';
export let IS_PROD: boolean = false;

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  isProd: boolean;

  constructor() {
    try {
      this.isProd = environment.isDeployedBuild;
      IS_PROD = this.isProd;
      // Initialize sentry if production
      if (this.isProd) {
        Sentry.init({
          dsn: 'https://c908f6457d26491ab99248cd69e99e32@o127241.ingest.sentry.io/5397189',
          release: environment.appVersion,
          environment: environment.environment === 'production' ? 'production' : 'staging'
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  handleError(error: any) {
    if (this.isProd) {
      Sentry.captureException(error.originalError || error);
    } else if (error !== 'cordova_not_available') {
      console.error(error);
    }
  }
}
