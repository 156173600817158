import { TEMPLATE_NAME } from '@ea-models/templates/constants';

export const INCIDENT_REPORT_MAT_TAB_GROUP = 'incident-report-mat-tab-group';
export const LOCATION_MAPPING = {
  liftIncident: {
    name: 'Lift Incident',
    title: 'Lift',
    field: 'lift_id',
    aliasField: 'lift'
  },
  markedSkiRun: {
    name: 'Marked Ski Run',
    title: 'Ski Run',
    field: 'ski_run_id',
    aliasField: 'ski_run'
  },
  bikingHiking: {
    name: 'Biking or Hiking',
    title: 'Trail',
    field: 'trail_id',
    aliasField: 'trail_name'
  },
  freestyleTerrain: {
    name: 'Freestyle Terrain',
    title: 'Terrain Park',
    field: 'park_id',
    aliasField: 'park'
  }
};

enum LocationMappingKey {
  liftIncident = 'liftIncident',
  markedSkiRun = 'markedSkiRun',
  bikingHiking = 'bikingHiking',
  freestyleTerrain = 'freestyleTerrain'
}

interface LocationMappingValue {
  name: string;
  title: string;
  field: string;
  aliasField: string;
}

export const scanningTypes = { driverLicense: 1, liftAccessPass: 2 };

export const DLAbbrDesMap = {
  DCS: 'last_name',
  DAC: 'first_name',
  DBB: 'birthday',
  DBC: 'gender',
  DAU: 'height',
  DAG: 'address',
  DAI: 'city',
  DAJ: 'province',
  DAK: 'postal_code',
  DCG: 'country',
  DAH: 'address',
  DAW: 'weight',
  DAX: 'weight',
  DAA: 'first_name',
  DAB: 'last_name',
  DAL: 'address',
  DAM: 'address',
  DAN: 'city',
  DAV: 'height'
  // 'DCA': 'Jurisdiction-specific vehicle class',
  // 'DBA': 'Expiry Date',
  // 'DBD': 'Issue Date',
  // 'DAY': 'Eye Color',
  // 'DAQ': 'License Number',
  // 'DCF': 'Document Discriminator',
  // 'DAZ': 'Hair Color',
  // 'DCI': 'Place of birth',
  // 'DCJ': 'Audit information',
  // 'DCK': 'Inventory Control Number',
  // 'DBN': 'Alias / AKA Family Name',
  // 'DBG': 'Alias / AKA Given Name',
  // 'DBS': 'Alias / AKA Suffix Name',
  // 'DCU': 'Name Suffix',
  // 'DCE': 'Physical Description Weight Range',
  // 'DCL': 'Race / Ethnicity',
  // 'DCM': 'Standard vehicle classification',
  // 'DCN': 'Standard endorsement code',
  // 'DCO': 'Standard restriction code',
  // 'DCP': 'Jurisdiction-specific vehicle classification description',
  // 'DCQ': 'Jurisdiction-specific endorsement code description',
  // 'DCR': 'Jurisdiction-specific restriction code description',
  // 'DDA': 'Compliance Type',
  // 'DDB': 'Card Revision Date',
  // 'DDC': 'HazMat Endorsement Expiration Date',
  // 'DDD': 'Limited Duration Document Indicator',
  // 'DDH': 'Under 18 Until',
  // 'DDI': 'Under 19 Until',
  // 'DDJ': 'Under 21 Until',
  // 'DDK': 'Organ Donor Indicator',
  // 'DDL': 'Veteran Indicator',
  // old standard
  // 'DAE': 'Name Suffix',
  // 'DAF': 'Name Prefix',
  // 'DAO': 'Residence Jurisdiction Code',
  // 'DAR': 'License Classification Code',
  // 'DAS': 'License Restriction Code',
  // 'DAT': 'License Endorsements Code',
  // 'DBE': 'Issue Timestamp',
  // 'DBF': 'Number of Duplicates',
  // 'DBH': 'Organ Donor',
  // 'DBI': 'Non-Resident Indicator',
  // 'DBJ': 'Unique Customer Identifier',
  // 'DBK': 'Social Security Number',
  // 'DBM': 'Social Security Number',
  // 'DCH': 'Federal Commercial Vehicle Codes',
  // 'DBR': 'Name Suffix',
  // 'PAA': 'Permit Classification Code',
  // 'PAB': 'Permit Expiration Date',
  // 'PAC': 'Permit Identifier',
  // 'PAD': 'Permit IssueDate',
  // 'PAE': 'Permit Restriction Code',
  // 'PAF': 'Permit Endorsement Code',
  // 'ZVA': 'Court Restriction Code',
  // 'DAD': 'Middle Name'
};

export const LOADING_TIMEOUT = 60000;

export const SUPPORT_DRIVER_LICENSE_SCANNING = [TEMPLATE_NAME.nsaa];
export const SUPPORT_SCANNING_INCIDENT_TEMPLATES = [TEMPLATE_NAME.nsaa, TEMPLATE_NAME.cwsaa];
