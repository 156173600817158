import { Injectable } from '@angular/core';
import { environment } from '@edgeauditor/environments/environment';

const photoOptions = {
  maxResolution: {
    width: 1600,
    height: 1600
  },
  maxByteSize: 1000000
};

@Injectable()
export class ConfigService {
  version = environment.appVersion;
  photoResolutionsLimitation = photoOptions.maxResolution;
  photoSizeLimitation = photoOptions.maxByteSize;

  /** Get the api urls for the given provider */
  apiURL(): string {
    return `${environment.apiUrl}/api/v1/`;
  }
  dispatchURL(): string {
    return `${environment.apiUrl}/api/dispatch/v1/`;
  }
  apiURLv3(): string {
    return `${environment.apiUrl}/api/v3/`;
  }

  getBaseUrl(version: number) {
    return `${environment.apiUrl}/api/v${version}/`;
  }
}
