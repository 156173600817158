import { Component, Input, OnInit } from '@angular/core';
import { User, IAttachment, SpecialEventCategory, Coordinates } from '@ea-models';
import { GlobalService, ParkBuildsService, SpecialEventsService } from '@ea-services';
import { ModalController } from '@ionic/angular';
import { ReplaySubject } from 'rxjs';
import _ from 'lodash';

@Component({
  selector: 'special-events-details',
  templateUrl: './special-events-details.component.html',
  styleUrls: ['./special-events-details.component.scss']
})
export class SpecialEventsDetailsComponent implements OnInit {
  @Input() coords: Coordinates;
  @Input() feature: { [key: string]: any } = {};
  imgSubject: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  currentUser: User;
  event_feature_categories: SpecialEventCategory[];

  private cloneFeature: any;

  constructor(private globalService: GlobalService, private modalController: ModalController) {}

  ngOnInit() {
    if (this.globalService._currentUser && Object.keys(this.feature).length === 0) {
      this.currentUser = this.globalService._currentUser;
      this.cloneFeature = _.cloneDeep(this.feature);
      this.event_feature_categories = (_.cloneDeep(this.currentUser.event_feature_categories) as any) || [];
      this.event_feature_categories = this.event_feature_categories.sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      );
    }
    this.initFeature();
  }

  onImageChange(imagesData: { currentImage: IAttachment; images: IAttachment[] }) {
    this.feature.object_attachments_attributes = imagesData.images.map((img) => {
      return img.object_attachment;
    });
    this.feature.unchange = false;
  }

  close() {
    this.modalController.dismiss();
  }

  save() {
    const feature = _.cloneDeep(this.feature);
    feature.newAdded = true;
    this.modalController.dismiss({
      feature: feature,
      clone: this.cloneFeature
    });
  }

  onFeatureTypeChange(id: number) {
    this.feature.is_snow = id;
  }

  private initFeature() {
    this.setFeatureLocation();
    this.setFeatureNewFlag();
    this.restoreFeatureImages();
  }

  customSearchFn(term: string, item) {
    return item.name.toLocaleLowerCase().indexOf(term) > -1;
  }

  private restoreFeatureImages() {
    let listImages = [];
    if (this.feature.unchange !== false && this.feature.images) {
      listImages = this.feature.images.map((img) => ({
        object_attachment: { file: img && img.url && img.url.url }
      }));
    } else if (this.feature.unchange === false && this.feature.object_attachments_attributes) {
      listImages = [
        ...listImages,
        ...this.feature.object_attachments_attributes.map((img) => ({
          object_attachment: { file: img.file }
        }))
      ];
    }

    this.imgSubject.next(listImages);
  }

  private setFeatureNewFlag() {
    if (!this.feature.id) {
      this.feature.new = 1;
    }
  }

  private setFeatureLocation() {
    if (!this.feature.location && this.coords) {
      this.feature.location = [this.coords.longitude, this.coords.latitude];
    }
  }
}
