import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { AddressComponent as AddressPart } from 'ngx-google-places-autocomplete/objects/addressComponent';
import { IProvinceSelectOption, ISelectOption } from '@ea-models/templates';
import { INCIDENT_COUNTRIES, INCIDENT_STATES, INCIDENT_PROVINCES } from '../../pages/pages.constants';
import { GlobalService } from '@ea-services';
import { INCIDENT_REPORT_MAT_TAB_GROUP } from '../../constants/incident-report.constants';
import { IncidentReportProviderService } from '@ea-services/provider/incident-report-provider.service';
import { AppState } from '@edgeauditor/app/store/app/app.state';
import { NetworkStatus } from '@capacitor/network';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit, OnChanges {
  @Output() changed = new EventEmitter<Map<string, string>>();
  @Input() modelData: { [key: string]: any };
  @Input() requiredObjects: { [key: string]: boolean };
  @Input() defaultCountry: string;

  @Select(AppState.getNetworkStatus) networkStatus$: Observable<NetworkStatus>;
  @ViewSelectSnapshot(AppState.getIsMobilePlatform) isMobilePlatform: boolean;

  countries: ISelectOption[];
  states: {
    CA: IProvinceSelectOption[];
    US: IProvinceSelectOption[];
  };
  model = {
    country: '',
    province: '',
    city: '',
    postal_code: '',
    address: ''
  };
  readonly incidentReportMatTabGroup = INCIDENT_REPORT_MAT_TAB_GROUP;

  constructor(
    public globalService: GlobalService,
    public incidentProvider: IncidentReportProviderService,
    public refEle: ElementRef
  ) {}

  ngOnInit() {
    this.countries = INCIDENT_COUNTRIES;
    this.states = { US: INCIDENT_STATES, CA: INCIDENT_PROVINCES };
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('modelData' in changes && changes.modelData.currentValue && changes.modelData.currentValue.address) {
      this.model = { ...changes.modelData.currentValue.address };
      this.provinceChanged();
      this.countryChanged();
    }
  }

  private provinceChanged() {
    if (this.model.province) {
      this.onChange(this.model.province, 'province');
    }
  }

  private countryChanged() {
    if (this.model.province) {
      this.onChange(this.model.country, 'country');
    }
  }

  getComponentByType(address: Address, type: string): AddressPart {
    if (!type || !address || !address.address_components || address.address_components.length === 0) {
      return null;
    }
    type = type.toLowerCase();
    for (const comp of address.address_components) {
      if (comp.types && comp.types.length > 0 && comp.types.findIndex((x) => x.toLowerCase() === type) > -1) {
        return comp;
      }
    }
    return null;
  }

  onChangeEventValue(e: Event, fieldName: string) {
    this.onChange((<HTMLTextAreaElement>e.target).value, fieldName);
  }

  onChange(newVal: string, fieldName: string) {
    const newData = new Map<string, string>();
    newData.set(fieldName, newVal);
    this.changed.emit(newData);
  }

  onSelectChange(newVal: { [key: string]: string }, fieldName: string) {
    this.onChange(newVal ? newVal.code : '', fieldName);
  }

  handleAddressChange(address: Address) {
    const getC = (compName) =>
      this.getComponentByType(address, compName) ? this.getComponentByType(address, compName).short_name : '';
    this.model.country = getC('country');
    this.model.province = getC('administrative_area_level_1');
    this.model.city = getC('locality');
    this.model.postal_code = getC('postal_code');
    [this.model.address] = address ? address.formatted_address.split(',') : [''];
    this.onChange(this.model.province, 'province');
    this.onChange(this.model.address, 'address');
    this.onChange(this.model.country, 'country');
  }
}
