import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgSelectAutoScrollDirective } from './ng-select-auto-scroll.directive';

@NgModule({
  declarations: [NgSelectAutoScrollDirective],
  imports: [CommonModule],
  exports: [NgSelectAutoScrollDirective]
})
export class NgSelectAutoScrollDirectiveModule {}
