import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CustomPreloadingStrategyService } from '@ea-services-v4/custom-preloading-strategy.service';
import { RouterConstant } from '@edgeauditor/app/constants/router.constant';
import { AuthGuard } from '@edgeauditor/app/guards/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: RouterConstant.LOGIN_PAGE, pathMatch: 'full' },
  {
    path: RouterConstant.LOGIN_PAGE,
    loadChildren: () => import('../pages/login/login.module').then((m) => m.LoginPageModule)
  },
  {
    path: 'login/:reload',
    loadChildren: () => import('../pages/login/login.module').then((m) => m.LoginPageModule)
  },
  //Reset Password
  {
    path: RouterConstant.RESET_PASSWORD_PAGE,
    loadChildren: () => import('../pages/reset-password/reset-password.module').then((m) => m.ResetPasswordModule),
    canActivate: [AuthGuard]
  },
  //Dashboard
  {
    path: RouterConstant.DASHBOARD_PAGE,
    data: { preload: true },
    loadChildren: () => import('../pages/dashboard/dashboard.module').then((m) => m.DashboardPageModule),
    canActivate: [AuthGuard]
  },
  //Dispatch Console Module
  {
    path: RouterConstant.DISPATCH_CONSOLE_PAGE,
    loadChildren: () => import('../pages/dispatch/dispatch.module').then((m) => m.DispatchPageModule),
    canActivate: [AuthGuard]
  },
  //New Reports Module
  {
    path: RouterConstant.NEW_REPORT_PAGE,
    pathMatch: 'full',
    data: { preload: true },
    loadChildren: () => import('../pages/reports/reports.module').then((m) => m.ReportsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: RouterConstant.NEW_INCIDENT_REPORT,
    pathMatch: 'full',
    data: { preload: true },
    loadChildren: () =>
      import('../pages/incident-reports/incident-reports.module').then((m) => m.IncidentReportsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'incident-reports/:type/:idx',
    pathMatch: 'full',
    loadChildren: () =>
      import('../pages/incident-reports/incident-reports.module').then((m) => m.IncidentReportsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: RouterConstant.NEW_OTHER_REPORT_PAGE + '/:id',
    pathMatch: 'full',
    data: { preload: true },
    loadChildren: () => import('../pages/report/report.module').then((m) => m.ReportPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'report/:type/:idx/:submissionId',
    pathMatch: 'full',
    loadChildren: () => import('../pages/report/report.module').then((m) => m.ReportPageModule),
    canActivate: [AuthGuard]
  },
  //In Progress Reports Module
  {
    path: RouterConstant.PENDING_REPORTS,
    pathMatch: 'full',
    loadChildren: () => import('./pages/reports/in-progress/in-progress.module').then((m) => m.InProgressPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: RouterConstant.PENDING_LOCAL_REPORTS_PAGE,
    data: { preload: true },
    pathMatch: 'full',
    loadChildren: () =>
      import('../pages/pending-local-reports/pending-local-reports.module').then(
        (m) => m.PendingLocalReportsPageModule
      ),
    canActivate: [AuthGuard]
  },
  //Rejected Reports Module
  {
    path: RouterConstant.REJECTED_REPORTS,
    pathMatch: 'full',
    loadChildren: () =>
      import('../pages/declined-reports/declined-reports.module').then((m) => m.DeclinedReportsPageModule),
    canActivate: [AuthGuard]
  },
  // Health and Safety Module
  {
    path: RouterConstant.HEALTH_AND_SAFETY_PAGE,
    pathMatch: 'full',
    data: { preload: true },
    loadChildren: () => import('../pages/health-safety/health-safety.module').then((m) => m.HealthSafetyPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: RouterConstant.HEALTH_AND_SAFETY_MEETING_PAGE,
    pathMatch: 'full',
    data: { preload: true },
    loadChildren: () =>
      import('../pages/health-safety/health-safety-meeting/health-safety-meeting.module').then(
        (m) => m.HealthSafetyMeetingPageModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: RouterConstant.HEALTH_AND_SAFETY_MEETING_PAGE + '/:idx',
    pathMatch: 'full',
    data: { preload: true },
    loadChildren: () =>
      import('../pages/health-safety/health-safety-meeting/health-safety-meeting.module').then(
        (m) => m.HealthSafetyMeetingPageModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: RouterConstant.FORMAL_TRAINING_PAGE,
    pathMatch: 'full',
    data: { preload: true },
    loadChildren: () =>
      import('../pages/health-safety/health-safety-formal-training/health-safety-formal-training.module').then(
        (m) => m.HealthSafetyFormalTrainingPageModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: RouterConstant.FORMAL_TRAINING_PAGE + '/:idx',
    pathMatch: 'full',
    data: { preload: true },
    loadChildren: () =>
      import('../pages/health-safety/health-safety-formal-training/health-safety-formal-training.module').then(
        (m) => m.HealthSafetyFormalTrainingPageModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: RouterConstant.STAFF_SUMMARY_PAGE,
    pathMatch: 'full',
    data: { preload: true },
    loadChildren: () =>
      import('../pages/health-safety/health-safety-staff/health-safety-staff.module').then(
        (m) => m.HealthSafetyStaffPageModule
      ),
    canActivate: [AuthGuard]
  },
  // Emergency Operation Plans Module
  {
    path: RouterConstant.EMERGENCY_OPERATION_PLANS_PAGE,
    pathMatch: 'full',
    data: { preload: true },
    loadChildren: () =>
      import('../pages/emergency-operation-plans/emergency-operation-plans.module').then(
        (m) => m.EmergencyOperationPlansPageModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: RouterConstant.EMERGENCY_OPERATION_PLANS_PAGE + '/:id',
    pathMatch: 'full',
    data: { preload: true },
    loadChildren: () =>
      import('../pages/emergency-operation-plans/emergency-operation-plans.module').then(
        (m) => m.EmergencyOperationPlansPageModule
      ),
    canActivate: [AuthGuard]
  },
  //Special Events Module
  {
    path: RouterConstant.SPECIAL_EVENTS_PAGE,
    pathMatch: 'full',
    loadChildren: () => import('../pages/special-events/special-events.module').then((m) => m.SpecialEventsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'special-events-build/:id',
    loadChildren: () =>
      import('../pages/special-events-build/special-events-build.module').then((m) => m.SpecialEventsBuildPageModule),
    canActivate: [AuthGuard]
  },
  //Lifts Module
  {
    path: RouterConstant.LIFT_STOP_PAGE,
    loadChildren: () => import('../pages/lift-stop/lift-stop.module').then((m) => m.LiftStopPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: RouterConstant.LIFT_STOP_PAGE + '/:id',
    loadChildren: () => import('../pages/lift-stop/lift-stop.module').then((m) => m.LiftStopPageModule),
    canActivate: [AuthGuard]
  },
  // Add Park/Trail Feature Module
  {
    path: RouterConstant.ADD_FEATURE_PAGE + '/:type',
    loadChildren: () => import('../pages/park-features/park-features.module').then((m) => m.ParkFeaturesPageModule),
    canActivate: [AuthGuard]
  },
  //Add Park/Trail Build Module
  {
    path: RouterConstant.FEATURE_BUILD_PAGE + '/:type',
    loadChildren: () => import('../pages/park-builds/park-builds.module').then((m) => m.ParkBuildsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'feature-build/:id/:isNew/:isLocal/:buildId/:type',
    loadChildren: () => import('../pages/park-build/park-build.module').then((m) => m.ParkBuildPageModule),
    canActivate: [AuthGuard]
  },
  // Equipment Assignment Module
  {
    path: RouterConstant.EQUIPMENT_ASSIGNMENT_PAGE,
    loadChildren: () =>
      import('../pages/equipment-assignment/equipment-assignment.module').then((m) => m.EquipmentAssignmentPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: RouterConstant.EQUIPMENT_ASSIGNMENT_RENTAL_PAGE,
    loadChildren: () =>
      import('../pages/equipment-rental/equipment-rental.module').then((m) => m.EquipmentRentalPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: RouterConstant.EQUIPMENT_ASSIGNMENT_RENTAL_PAGE + '/:id',
    loadChildren: () =>
      import('../pages/equipment-rental-customer/equipment-rental-customer.module').then(
        (m) => m.EquipmentRentalCustomerPageModule
      ),
    canActivate: [AuthGuard]
  },
  // Equipment Maintenance Module
  {
    path: RouterConstant.EQUIPMENT_MAINTENANCE_PAGE,
    redirectTo: 'equipment/maintenance/pending',
    pathMatch: 'full'
  },
  {
    path: RouterConstant.EQUIPMENT_MAINTENANCE_PAGE + '/pending',
    loadChildren: () =>
      import('../pages/equipment-maintenance/equipment-maintenance.module').then(
        (m) => m.EquipmentMaintenancePageModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: RouterConstant.EQUIPMENT_MAINTENANCE_PAGE + '/all',
    loadChildren: () =>
      import('../pages/equipment-maintenance/equipment-maintenance.module').then(
        (m) => m.EquipmentMaintenancePageModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: RouterConstant.EQUIPMENT_MAINTENANCE_PAGE + '/log-work',
    loadChildren: () =>
      import('../pages/equipment-maintenance-log-work/equipment-maintenance-log-work.module').then(
        (m) => m.EquipmentMaintenanceLogWorkPageModule
      ),
    canActivate: [AuthGuard]
  },
  // Waiver Lookup Module
  {
    path: RouterConstant.WAIVER_LOOKUP_PAGE,
    loadChildren: () =>
      import('../pages/equipment-rental/equipment-rental.module').then((m) => m.EquipmentRentalPageModule),
    canActivate: [AuthGuard]
  },
  // Signs Module
  {
    path: RouterConstant.OBJECTS_PAGE,
    loadChildren: () => import('../pages/object-start/object-start.module').then((m) => m.ObjectStartPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'objects/:mode',
    loadChildren: () => import('../pages/objects/objects.module').then((m) => m.ObjectsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'object/inspect/:type',
    loadChildren: () => import('../pages/inspect-object/inspect-object.module').then((m) => m.InspectObjectPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'object/inspect/:type/:id',
    loadChildren: () => import('../pages/inspect-object/inspect-object.module').then((m) => m.InspectObjectPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'object/add/:type',
    loadChildren: () => import('../pages/add-object/add-object.module').then((m) => m.AddObjectPageModule),
    canActivate: [AuthGuard]
  },
  // Groomer Tracking Module
  {
    path: RouterConstant.GROOMER_TRACKING_PAGE,
    loadChildren: () => import('../pages/groomer-start/groomer-start.module').then((m) => m.GroomerStartPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'groomer/assignments/:groomer_shift_id/:vehicle_id/:hours',
    loadChildren: () => import('../pages/groomer/groomer.module').then((m) => m.GroomerPageModule),
    canActivate: [AuthGuard]
  },
  // Tasks Module
  {
    path: RouterConstant.TASKS_PAGE,
    loadChildren: () => import('../pages/tasks/tasks.module').then((m) => m.TasksPageModule),
    canActivate: [AuthGuard]
  },
  // NEMSIS Module
  {
    path: RouterConstant.NEMSIS_PAGE,
    pathMatch: 'full',
    loadChildren: () => import('./pages/nemsis-filing/nemsis-filing.module').then((m) => m.NemsisFilingPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: RouterConstant.NEMSIS_PAGE + '/:editMode/:id',
    pathMatch: 'full',
    data: { preload: true, isEdit: true },
    loadChildren: () => import('./pages/nemsis-filing/nemsis-filing.module').then((m) => m.NemsisFilingPageModule),
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: CustomPreloadingStrategyService })],
  exports: [RouterModule],
  providers: [CustomPreloadingStrategyService]
})
export class AppRoutingModule {}
