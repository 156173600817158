<ea-header title="Signature" [hasCloseButton]="true" [confirmExit]="true"></ea-header>

<ion-content class="signature-pad">
  <div>
    <app-signature
      #signaturePad
      [showClear]="false"
      [fullWidth]="true"
      [signatureEvent]="signatureEvent.asObservable()"
      (signatureChanged)="onChange($event)"
      class="ion-padding"
    >
    </app-signature>
  </div>
  <!-- <ion-footer> -->

  <ion-button
    expand="full"
    color="warning"
    (click)="signaturePad.clearSignature();"
  >
    CLEAR SIGNATURE
  </ion-button>
  <ion-button expand="full" color="success" (click)="save();">
    SAVE
  </ion-button>
  <!-- </ion-footer> -->
</ion-content>
