export const DEF_KEY = {
  if: { key: 'true', value: true }
};

export const DEF_KEY_VAL = {
  data: { key: 'name', value: 'name' },
  _: ''
};

export const DEF_KEY_VAL_POS_TOP = {
  data: { key: 'name', value: 'name', pos: 'top' },
  _: ''
};

export const DEF_ID_NAME_KEY_VAL = {
  data: { key: 'id', value: 'name' },
  _: ''
};

export const WITNESSES_KEY = {
  if: { key: 'known_witnesses', value: false }
};

const DEF_COL = {
  ...DEF_KEY,
  xs: 12
};

export const DEF_AUTO_COL = {
  ...DEF_COL,
  sm: ''
};

export const DEF_COL_12 = {
  ...DEF_COL,
  sm: 12
};

export const DEF_COL_6 = {
  ...DEF_COL,
  sm: 6
};

export const DEF_COL_4 = {
  ...DEF_COL,
  sm: 4
};

export const DEF_COL_3 = {
  ...DEF_COL,
  sm: 3
};

export const TEMPLATE_NAME = {
  nsaa: 'nsaa',
  cwsaa: 'cwsaa',
  copper: 'copper'
};
