<ea-header color="dark" [hasCloseButton]="true" [title]="customer ? customer.first_name + ' ' + customer.last_name : ''"></ea-header>
<ion-content *ngIf="customer">
  <ion-grid class="information-grid">
    <ion-row>
      <ion-col size="4">
        <ion-label class="bold">Name</ion-label>
      </ion-col>
      <ion-col size="8">
        <ion-label
          >{{ customer.first_name }} {{ customer.last_name }}</ion-label
        >
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="4">
        <ion-label class="bold">Phone #</ion-label>
      </ion-col>
      <ion-col size="8">
        <ion-label>{{ customer.phone }}</ion-label>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="customer.email && customer.email != ''">
      <ion-col size="4">
        <ion-label class="bold">Email</ion-label>
      </ion-col>
      <ion-col size="8">
        <ion-label>{{ customer.email }}</ion-label>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="4">
        <ion-label class="bold">Birthday</ion-label>
      </ion-col>
      <ion-col size="8">
        <ion-label
          >{{ customer.birthday | date:'MMM d, yyyy' }} (Age: {{ age
          }})</ion-label
        >
      </ion-col>
    </ion-row>
    <ion-row *ngIf="customer.address && customer.address != ''">
      <ion-col size="4">
        <ion-label class="bold">Address</ion-label>
      </ion-col>
      <ion-col size="8">
        <ion-label>{{ customer.address }}</ion-label>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="customer.city && customer.city != ''">
      <ion-col size="4">
        <ion-label class="bold">City</ion-label>
      </ion-col>
      <ion-col size="8">
        <ion-label>{{ customer.city }}</ion-label>
      </ion-col>
    </ion-row>
    <ion-row
      *ngIf="(customer.state || customer.alternate_state) && (customer.state != '' || customer.alternate_state != '')"
    >
      <ion-col size="4">
        <ion-label class="bold">State/Province</ion-label>
      </ion-col>
      <ion-col size="8">
        <ion-label *ngIf="customer.state">{{ customer.state }}</ion-label>
        <ion-label *ngIf="customer.alternate_state"
          >{{ customer.alternate_state }}</ion-label
        >
      </ion-col>
    </ion-row>
    <ion-row *ngIf="customer.country && customer.country != ''">
      <ion-col size="4">
        <ion-label class="bold">Country</ion-label>
      </ion-col>
      <ion-col size="8">
        <ion-label>{{ customer.country }}</ion-label>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="customer.zip_code && customer.zip_code != ''">
      <ion-col size="4">
        <ion-label class="bold">Zip Code</ion-label>
      </ion-col>
      <ion-col size="8">
        <ion-label>{{ customer.zip_code }}</ion-label>
      </ion-col>
    </ion-row>
  </ion-grid>
  <hr />
  <!-- Waivers -->
  <div class="waivers-container">
    <ion-grid class="waivers-grid">
      <ion-row class="w-header">
        <ion-col [attr.size]="hasPhoto?'3':'4'" class="first">
          <ion-label class="bold">Waiver</ion-label>
        </ion-col>
        <ion-col [attr.size]="2" class="second">
          <ion-label class="bold">Completed Date</ion-label>
        </ion-col>
        <ion-col [attr.size]="2" class="second">
          <ion-label class="bold">Valid Until</ion-label>
        </ion-col>
        <ion-col [attr.size]="hasPhoto?'3':'4'">
          <ion-label class="bold">Signatures</ion-label>
        </ion-col>
        <ion-col *ngIf="hasPhoto" size="2">
          <ion-label class="bold">Photo</ion-label>
        </ion-col>
      </ion-row>
      <div *ngFor="let waiver of customer.waivers; index as i" class="w-data">
        <ion-row class="w-data-info">
          <ion-col [attr.size]="hasPhoto?'3':'4'" class="first">
            <ion-label>{{ waiver.name }}</ion-label>
          </ion-col>
          <ion-col [attr.size]="2" class="second">
            <ion-label>{{ waiver.waiver_completed_date }}</ion-label>
          </ion-col>
          <ion-col [attr.size]="2" class="second">
            <ion-label>{{ waiver.valid_end_date }}</ion-label>
          </ion-col>
          <ion-col [attr.size]="hasPhoto?'3':'4'">
            <ion-row>
              <ion-col
                size-xs="12"
                size-sm="12"
                size-md="6"
                size-lg="6"
                size-xl="4"
                *ngFor="let sig of waiver.waiver_sections"
              >
                <img class="w-signature" src="{{ sig.signature.url }}" />
              </ion-col>
            </ion-row>
          </ion-col>
          <ion-col *ngIf="hasPhoto" size="2" class="view-photo">
            <ion-button
              *ngIf="waiver.image"
              (click)="viewPhoto(customer,waiver)"
              color="primary"
              >View Photo
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row
          *ngFor="let question of waiver.customer_questions; index as k"
          class="w-data-questions"
        >
          <ion-col class="customer-questions" size="12">
            <ion-row *ngIf="k === 0">
              <ion-col>
                <ion-label class="bold">Customer Questions</ion-label>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="4">
                <ion-label>{{ question.question }}</ion-label>
              </ion-col>
              <ion-col size="8">
                <ion-label *ngIf="question.answer && question.answer === '1'"
                  >Yes</ion-label
                >
                <ion-label *ngIf="question.answer && question.answer === '0'"
                  >No</ion-label
                >
                <ion-label
                  *ngIf="question.answer && question.answer !== '1' && question.answer !== '0'"
                >
                  {{ question.answer }}</ion-label
                >
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
      </div>
    </ion-grid>
  </div>
</ion-content>
<ion-footer *ngIf="is_rental">
  <ion-button expand="full" color="primary" (click)="continueRentals()"
    >CONTINUE</ion-button
  >
</ion-footer>
