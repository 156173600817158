import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';

const STORAGE_KEY = 'EA';

@Injectable({
  providedIn: 'root'
})
export class StorageProvider {
  async set(key: string, value: any) {
    await Preferences.set({
      key: `${STORAGE_KEY}_${key}`,
      value: JSON.stringify(value)
    });
  }

  async get<T>(key: string): Promise<T> {
    const { value } = await Preferences.get({ key: `${STORAGE_KEY}_${key}` });

    if (value) {
      return JSON.parse(value);
    } else {
      return null;
    }
  }

  async clear(key: string) {
    await Preferences.remove({ key: `${STORAGE_KEY}_${key}` });
  }
}
