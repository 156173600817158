import { Component, Input, OnInit } from '@angular/core';
import { NetworkStatus } from '@capacitor/network';
import { AppState } from '@edgeauditor/app/store/app/app.state';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

const DEFAULT_CONFIRM_BACK_MESSAGE =
  'Are you sure you want to exit? If you leave this screen, your data will not be saved.';

@Component({
  selector: 'ea-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() color: string = 'main';
  @Input() title: string;
  @Input() subtitle: string;
  @Input() hasBackButton = false;
  @Input() confirmExit = false;
  @Input() confirmExitMessage: string = DEFAULT_CONFIRM_BACK_MESSAGE;
  @Input() hasNetworkIndicator = false;
  @Input() hasCloseButton = false;
  @Input() backUrl?: string;

  @Select(AppState.getNetworkStatus) networkStatus$: Observable<NetworkStatus>;

  constructor(
    private navController: NavController,
    private alertController: AlertController,
    private modalController: ModalController
  ) {}

  ngOnInit(): void {}

  async exit(data = null) {
    if (this.confirmExit) {
      const alert = await this.alertController.create({
        message: this.confirmExitMessage,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel'
          },
          {
            text: 'OK',
            role: 'confirm',
            handler: () => (this.hasBackButton ? this.navController.back() : this.modalController.dismiss(data))
          }
        ]
      });

      alert.present();
    } else {
      if (this.hasBackButton) {
        this.backUrl ? this.navController.navigateRoot([this.backUrl]) : this.navController.back();
      } else {
        this.modalController.dismiss(data);
      }
    }
  }
}
