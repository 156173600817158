import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { GetRejectedReports, UpdateRejectReports } from './rejected-report.actions';
import { DeclinedReport, DeclinedAccidentReport, RejectedParkBuild } from '@ea-models-v4/rejected-report.model';
import _ from 'lodash';
import { NemsisReportDetail } from '@ea-models-v4';

export interface RejectedReportStateModel {
  declinedReports: DeclinedReport[];
  rejectedParkBuilds: RejectedParkBuild[];
  declinedAccidentReports: DeclinedAccidentReport[];
  rejectedNemsisReports: NemsisReportDetail[];
}

const defaults: RejectedReportStateModel = {
  declinedReports: null,
  rejectedParkBuilds: null,
  declinedAccidentReports: null,
  rejectedNemsisReports: null
};

@State<RejectedReportStateModel>({
  name: 'rejected_report',
  defaults
})
@Injectable({
  providedIn: 'root'
})
export class RejectedReportState {
  constructor() {}

  @Selector()
  static getTotalRejectedReport(s: RejectedReportStateModel): number {
    const total = _.sumBy(
      [s.declinedAccidentReports, s.declinedReports, s.rejectedParkBuilds, s.rejectedNemsisReports],
      (i) => (_.isArray(i) ? i.length : 0)
    );
    return total;
  }

  @Selector()
  static getNemsisReports(s: RejectedReportStateModel): NemsisReportDetail[] {
    return s.rejectedNemsisReports;
  }

  @Action(GetRejectedReports)
  getRejectedReports(ctx: StateContext<RejectedReportStateModel>, payload: GetRejectedReports) {
    ctx.setState(payload.reports);
  }

  @Action(UpdateRejectReports)
  updateRejectReports(ctx: StateContext<RejectedReportStateModel>, payload: UpdateRejectReports) {
    ctx.patchState({ ...payload.reports });
  }
}
