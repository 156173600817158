<ion-row *ngIf="question" [id]="question.question_id">
  <ion-col size-xs="12" size-sm="6">{{question.question}}&nbsp;<ion-text *ngIf="question.required"
              color="danger">*</ion-text></ion-col>
  <ion-col size-xs="12" size-sm="6" [ngSwitch]="question.type">
    <ng-container *ngSwitchCase="ReportQuestionType.select">
      <ng-select [items]="question.options"
                 [selectOnTab]="true"
                 class="standard-select"
                 [ngClass]="{'invalid': question.required && !model.value}"
                 bindLabel="text"
                 bindValue="text"
                 placeholder="Select {{ question.question }}"
                 [(ngModel)]="model.value"
                 [virtualScroll]="question.options?.length > 20"
                 [attr.data-ci]="dataCI"
                 (change)="onSelectChange($event, 'text')">
      </ng-select>
    </ng-container>
    <ng-container *ngSwitchCase="ReportQuestionType.boolean">
      <div lines="none" class="toggle">
        <span>{{ question.options[1]?.text }}</span>
        <ion-toggle [ngModel]="model.toggler"
                    [ngClass]="{'invalid': question.required && !model.toggler}"
                    (ionChange)="onChangeEvent($event, ReportQuestionType.boolean)"
                    mode="ios"
                    [attr.data-ci]="dataCI"></ion-toggle>
        <span>{{ question.options[0]?.text }}</span>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="ReportQuestionType.text">
      <ion-textarea rows="5"
                    debounce="400"
                    placeholder="Enter {{ question.question }}"
                    autocapitalize="sentences"
                    [ngModel]="model.value"
                    [ngClass]="{'invalid': question.required && !model.value}"
                    (ionChange)="onTextChange($event)"
                    [attr.data-ci]="dataCI">
      </ion-textarea>
    </ng-container>
    <ng-container *ngSwitchCase="ReportQuestionType.checkbox">
      <ion-checkbox debounce="400"
                    [ngModel]="model.value"
                    [class.invalid]="question.required && !model.value"
                    [attr.data-ci]="dataCI"
                    (ionChange)="onCheckboxChange($event)"></ion-checkbox>
    </ng-container>
    <ng-container *ngSwitchCase="DateTimeTypes.includes(question.type) ? question.type : ''">
      <input readonly
             class="standard-input"
             [owlDateTime]="dt1"
             [max]="maxDate"
             [owlDateTimeTrigger]="dt1"
             [ngModel]="model[question.type]"
             [ngClass]="{'invalid': question.required && !model[question.type]}"
             [attr.data-ci]="dataCI"
             (dateTimeChange)="onChange($event.value,question.type === ReportQuestionType.date ? ReportQuestionType.date : ReportQuestionType.time)" />
      <owl-date-time [pickerType]="question.type === ReportQuestionType.date ? 'calendar' : 'timer'"
                     #dt1></owl-date-time>
    </ng-container>
    <ng-container *ngSwitchCase="ReportQuestionType.signature">
      <app-signature [attr.data-ci]="dataCI"
                     (signatureChanged)="onSignatureChange($event)"
                     [signatureEvent]="signSubject.asObservable()"
                     [parentChangeDetectorRef]="parentChangeDetectorRef"
                     (clear)="onSignatureChange('', true)"
                     [isRequired]="question.required"></app-signature>
    </ng-container>
    <ng-container *ngSwitchCase="SelectTypes[question.type] ? question.type : ''">
      <ng-select bindValue="name"
                 [items]="currentUser[selectsMapping[question.type]]"
                 [selectOnTab]="true"
                 class="standard-select"
                 bindLabel="first_name"
                 placeholder="Select {{ question.question }}"
                 [virtualScroll]="currentUser[selectsMapping[question.type]]?.length > 20"
                 [(ngModel)]="model.value"
                 [ngClass]="{'invalid': question.required && !model.value}"
                 (change)="onSelectChange($event, 'id')"
                 [attr.data-ci]="dataCI">
        <ng-template ng-label-tmp let-item="item">
          <span>{{ item.first_name }} {{ item.last_name }}</span>
        </ng-template>
        <ng-template ng-option-tmp
                     let-item="item"
                     let-search="searchTerm"
                     let-index="index">
          <span>{{ item.first_name }} {{ item.last_name }}</span>
        </ng-template>
      </ng-select>
    </ng-container>
    <ng-container *ngSwitchCase="ReportQuestionType.image">
      <app-image-picker [newToRight]="true"
                        [isSupportResizePhoto]="isSupportResizePhoto"
                        [showWarningResizePhoto]="showWarningResizePhoto"
                        (imageSelected)="onImageChange($event)"
                        [restoreImagesEvent]="imagesSubject.asObservable()"
                        [attr.data-ci]="dataCI"
                        [isRequired]="question.required"></app-image-picker>
    </ng-container>
    <ng-container *ngSwitchCase="ReportQuestionType.diagram">
      <app-diagram [userEvent]="userEvent"
                   (modelChanged)="onDiagramChange($event)"
                   [diagramEvent]="diagramSubject.asObservable()"
                   [isSupportResizePhoto]="isSupportResizePhoto"
                   [showWarningResizePhoto]="showWarningResizePhoto"
                   [parentChangeDetectorRef]="parentChangeDetectorRef"
                   [attr.data-ci]="dataCI"
                   [isRequired]="question.required">
      </app-diagram>
    </ng-container>
    <ng-container *ngSwitchCase="InputTypes.includes(question.type) ? question.type : ''">
      <ion-input debounce="400"
                 [type]="question.type === ReportQuestionType.number ? 'tel' : 'text'"
                 [ngModel]="model.value"
                 [ngClass]="{'invalid': question.required && !model.value}"
                 [attr.data-ci]="dataCI"
                 placeholder="Enter {{ question.question }}"
                 (ionChange)="onTextChange($event)"
                 autocapitalize="sentences">
      </ion-input>
    </ng-container>
    <ng-container *ngSwitchCase="ReportQuestionType.multi_select">
      <ng-select bindLabel="text"
                 bindValue="id"
                 [items]="question.options"
                 [(ngModel)]="model.multi_values"
                 [multiple]="true"
                 [selectOnTab]="true"
                 [virtualScroll]="question.options?.length > 20"
                 [class.invalid]="question.required && (!model.multi_values || model.multi_values.length === 0)"
                 (change)="onSelectChange($event, 'text', true)"></ng-select>
    </ng-container>
  </ion-col>
</ion-row>