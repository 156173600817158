import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { GlobalService } from '../global.service';
import { HealthAndSafetyAPI } from './health-and-safety.api';
import {
  SafetyMeeting,
  FormalTraining,
  Staff,
  FormalTrainingPostData,
  SafetyMeetingPostData,
  SafetyMeetingImageAttribute,
  IHealthSafetyAttachment
} from '@ea-models';
import moment from 'moment';
import { map, take, tap } from 'rxjs/operators';
import { LogRocketProvider } from '@ea-services/provider/logrocket';
import _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class HealthSafetySaveToCloudUtility {
  constructor(
    public globalService: GlobalService,
    private hnsService: HealthAndSafetyAPI,
    private logRocketProvider: LogRocketProvider
  ) {}

  saveFormalTrainingToCloud(training: FormalTraining): Observable<any> {
    const currentUser = this.globalService._currentUser;
    const staff_training: FormalTrainingPostData = {
      instructor_full_name: training.instructor,
      instructor_signature_base64: training.signature,
      training_requirement_id: training.training_type.id,
      training_location: training.training_location,
      time_of_training: moment(training.time).format('YYYY-MM-DDTHH:mm:ss.000Z'),
      date_of_training: moment(training.date).format('YYYY-MM-DDTHH:mm:ss.000Z'),
      length_str: training.length_str,
      instructor_comments: training.comments,
      location_id: currentUser.location.id,
      user_id: currentUser.id,
      staff_training_staffs_attributes:
        training.staff_list.map((staff: Staff) => {
          return {
            staff_id: staff.id,
            staff_signature_base64: staff.signature
          };
        }) || []
    };

    return this.hnsService.submitFormalTraining({ staff_training });
  }

  saveSafetyMeetingToCloud(meeting: SafetyMeeting): Observable<any> {
    const safety_meeting: SafetyMeetingPostData = {
      organizer_full_name: meeting.meeting_organizer,
      meeting_location: meeting.meeting_location,
      name: meeting.meeting_name,
      time_of_meeting: moment(meeting.time).format('YYYY-MM-DDTHH:mm:ss.000Z'),
      date_of_meeting: moment(meeting.date).format('YYYY-MM-DDTHH:mm:ss.000Z'),
      length_str: meeting.length_str,
      description: meeting.comments,
      location_id: this.globalService._currentUser.location.id,
      user_id: this.globalService._currentUser.id,
      safety_meeting_staffs_attributes:
        meeting.staff_list.map((staff: Staff) => {
          return {
            staff_id: staff.id,
            staff_signature_base64: staff.signature
          };
        }) || []
    };

    return new Observable((observer) => {
      const generatePostDataForImages = () => {
        safety_meeting.safety_meeting_staffs_attributes = [];
        safety_meeting.safety_meeting_files_attributes = meeting.image_list.map((image: IHealthSafetyAttachment) => {
          const img: SafetyMeetingImageAttribute = {
            file: '',
            title: image.title,
            filePath: image.object_attachment.filePath,
            fileName: image.object_attachment.fileName
          };
          return img;
        });
        return safety_meeting;
      };

      this.hnsService
        .submitSafetyMeeting({ safety_meeting })
        .pipe(
          take(1),
          tap((meetingRes) => {
            if (_.isEmpty(meeting?.image_list)) {
              // if no images then return
              observer.next(meetingRes);
              observer.complete();
            } else {
              // create array of oberavable responses of file
              safety_meeting.id = meetingRes.id;
              this.hnsService.submitAllImages(generatePostDataForImages()).then(
                (res) => {
                  observer.next(res);
                  observer.complete();
                },
                (error) => {
                  observer.complete();
                  observer.error(error);
                  this.logRocketProvider.captureException(error, 'submit all images failed', 'health and safety');
                }
              );
            }
          }),
          tap((item) => {
            console.log('from hns serice submit', item);
          })
        )
        .subscribe();
    });
  }
}
