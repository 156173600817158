import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';

@Injectable()
export class StorageService {
  private readonly STORAGE_PREFIX = 'edgeauditor_storage';

  constructor() {}

  async getObject(key: string) {
    try {
      const res = await Preferences.get({ key: this.STORAGE_PREFIX + '_' + key });
      return JSON.parse(res.value);
    } catch (error) {
      return null;
    }
  }

  async setObject(key, data) {
    try {
      await Preferences.set({
        key: this.STORAGE_PREFIX + '_' + key,
        value: JSON.stringify(data)
      });
    } catch (error) {
      console.log(error);
    }
  }

  async clearAll() {
    try {
      console.log('[warning]: clearAll');
      await Preferences.clear();
    } catch (error) {
      console.log(error);
    }
  }

  async clearObject(key: string) {
    try {
      await Preferences.remove({ key: this.STORAGE_PREFIX + '_' + key });
    } catch (error) {
      console.log(error);
    }
  }
}
