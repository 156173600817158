import { Injectable } from '@angular/core';
import { ISign, IInspection } from '@ea-models/object-module';
import { ObjectsService } from '@ea-services/api/objects.service';
import { GlobalService } from '@ea-services/global.service';
import { AttachmentsService } from '@ea-services/api/attachments.service';
import { concatMap, catchError } from 'rxjs/operators';
import { of, from } from 'rxjs';
import { UploadAttachedPhoto } from '@ea-models';
import _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ObjectsProviderService {
  public currentFeatures: any;
  public backUrl: string;
  public selectedInspectSign: ISign;
  constructor(
    private objService: ObjectsService,
    private globalService: GlobalService,
    private attachmentService: AttachmentsService
  ) {}
  failedSubmissions = [];
  public submitNewObjects() {
    if (this.globalService._currentUser.pendingObjects) {
      let index = this.globalService._currentUser.pendingObjects.findIndex((i) => i['add']);
      if (index < 0) return of(0);
      let add = this.globalService._currentUser.pendingObjects[index].add;
      let newObjects = null;
      if (add && add.data) {
        newObjects = _.cloneDeep(add.data.filter((i) => i.checked));
      }
      if (!newObjects || !newObjects.length) return of(0);
      let counts = newObjects.length;
      const token = this.globalService._currentUser.token;
      let totalAttachments = 0;
      const submitObject = (object) => {
        const _clone = _.cloneDeep(object);
        delete _clone.object_attachments_attributes;
        const submitData = { sign: { ..._clone } };
        return this.objService.submitSigns(token, submitData).pipe(
          concatMap((result) => {
            if (!result.success) {
              this.failedSubmissions.push(object);
              return of(null);
            }
            object.id = result.sign.id;
            return of(object);
          }),
          catchError(() => {
            this.failedSubmissions.push(object);
            return of(null);
          })
        );
      };
      const removeData = (object) => {
        if (!object) return;
        add.data = add.data.filter((i) => i.local_id !== object.local_id);
        if (!add.data.length) {
          index = this.globalService._currentUser.pendingObjects.findIndex((i) => i['add']);
          if (index >= 0) this.globalService._currentUser.pendingObjects.splice(index, 1);
        }
      };
      const submitAttachments = (result: any) => {
        if (!result || !result.object_attachments_attributes || !result.object_attachments_attributes.length) {
          removeData(result);
          return of(0);
        }
        totalAttachments += result.object_attachments_attributes.length;
        return from(result.object_attachments_attributes).pipe(
          concatMap((attachment) => {
            const submitData = {
              id: result.id,
              object_attachment: attachment,
              type: 'Sign'
            };
            return this.uploadAttachedPhotos(submitData, token);
          }),
          concatMap(() => {
            totalAttachments--;
            if (totalAttachments <= 0) {
              removeData(result);
            }
            return of(totalAttachments + counts);
          }),
          catchError(() => {
            totalAttachments--;
            if (totalAttachments <= 0) {
              removeData(result);
            }
            return of(totalAttachments + counts);
          })
        );
      };
      return from(newObjects).pipe(
        concatMap((obj) => {
          return submitObject(obj);
        }),
        concatMap((result) => {
          counts--;
          return submitAttachments(result);
        })
      );
    }
    return of(0);
  }

  public submitInspections() {
    if (this.globalService._currentUser.pendingObjects) {
      let index = this.globalService._currentUser.pendingObjects.findIndex((i) => i['inspect']);
      if (index < 0) return of(0);
      let inspect = this.globalService._currentUser.pendingObjects[index].inspect;
      let newObjects = null;
      if (inspect && inspect.data) {
        newObjects = _.cloneDeep(inspect.data.filter((i) => i.checked));
      }
      if (!newObjects || !newObjects.length) return of(0);
      let counts = newObjects.length;
      const token = this.globalService._currentUser.token;
      let totalAttachments = 0;
      const submitObject = (object) => {
        const _clone = _.cloneDeep(object);
        delete _clone.object_attachments_attributes;
        const submitData = { inspection: { ..._clone } };
        return this.objService.submitInjections(token, submitData).pipe(
          concatMap((result: any) => {
            if (!result.success) {
              this.failedSubmissions.push(object);
              return of(null);
            }
            object.id = result.inspection.id;
            return of(object);
          }),
          catchError(() => {
            this.failedSubmissions.push(object);
            return of(null);
          })
        );
      };
      const removeData = (object) => {
        if (!object) return;
        inspect.data = inspect.data.filter((i) => i.local_id !== object.local_id);
        if (!inspect.data.length) {
          index = this.globalService._currentUser.pendingObjects.findIndex((i) => i['inspect']);
          if (index >= 0) this.globalService._currentUser.pendingObjects.splice(index, 1);
        }
      };
      const submitAttachments = (result: IInspection) => {
        if (!result || !result.object_attachments_attributes || !result.object_attachments_attributes.length) {
          removeData(result);
          return of(0);
        }
        totalAttachments += result.object_attachments_attributes.length;
        return from(result.object_attachments_attributes).pipe(
          concatMap((attachment: any) => {
            const point = `POINT(${attachment.geo_point.lat} ${attachment.geo_point.lng})`;
            attachment.geo_point = point;
            const submitData = {
              id: result.id,
              object_attachment: attachment,
              type: 'Inspection'
            };
            return this.uploadAttachedPhotos(submitData, token);
          }),
          concatMap(() => {
            totalAttachments--;
            if (totalAttachments <= 0) {
              removeData(result);
            }
            return of(totalAttachments + counts);
          }),
          catchError(() => {
            totalAttachments--;
            if (totalAttachments <= 0) {
              removeData(result);
            }
            return of(totalAttachments + counts);
          })
        );
      };
      return from(newObjects).pipe(
        concatMap((obj) => {
          return submitObject(obj);
        }),
        concatMap((result) => {
          counts--;
          return submitAttachments(result);
        })
      );
    }
    return of(0);
  }

  private uploadAttachedPhotos(data, token) {
    const uploadData: UploadAttachedPhoto = {
      feature_id: data.id,
      object_attachment: data.object_attachment,
      feature_type: data.type
    };
    return this.attachmentService.uploadAttachment(token, uploadData);
  }
}
