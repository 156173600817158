import { Component } from '@angular/core';
import { Logout } from '@edgeauditor/app/store/auth/auth.action';
import { AuthState } from '@edgeauditor/app/store/auth/auth.state';
import { RouterConstant } from '@edgeauditor/app/constants/router.constant';
import { environment } from '@edgeauditor/environments/environment';
import { MenuController, NavController } from '@ionic/angular';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Store } from '@ngxs/store';

@Component({
  selector: 'ea-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent {
  version = environment.appVersion;

  constructor(private menuController: MenuController, private navController: NavController, private store: Store) {}

  routerConstant = RouterConstant;
  @ViewSelectSnapshot(AuthState.getPasswordPermission) allowPasswordChange: boolean;

  changePassword() {
    this.menuController.close();
    this.navController.navigateForward('/' + this.routerConstant.RESET_PASSWORD_PAGE);
  }

  logout() {
    this.menuController.close();
    this.store.dispatch(new Logout());
  }
}
