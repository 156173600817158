import { Directive, ElementRef, Host, HostListener } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { NgSelectComponent } from '@ng-select/ng-select';

@Directive({
  selector: 'ng-select'
})
export class NgSelectAutoScrollDirective {
  private readonly _ngSelectEle: HTMLElement;

  constructor(@Host() private readonly ngSelect: NgSelectComponent, private readonly el: ElementRef) {
    this._ngSelectEle = this.el.nativeElement;
  }

  @HostListener('focus')
  onFocusHandler() {
    if (Capacitor.getPlatform() === 'ios') {
      const headerHeight = 200;
      const scrollingHeight = 400;
      const ionContent = this._ngSelectEle.closest('ion-content');
      const top = this._ngSelectEle.getBoundingClientRect().top;
      const windowInnerHeight = window.innerHeight;
      const isBottom = top > windowInnerHeight - 300;

      if (!ionContent) {
        return;
      }

      if (isBottom) {
        this.ngSelect.dropdownPosition = 'top';
        ionContent.scrollByPoint(0, top - scrollingHeight, 0);
      } else {
        // this.ngSelect.drop
        this.ngSelect.dropdownPosition = 'bottom';
        ionContent.scrollByPoint(0, top - headerHeight, 0);
      }
    }
  }
}
