import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { Incident, InProgressIncidentReport, InProgressIncidentReportsResponse } from '@ea-models-v4/incident-report';
import { GlobalService } from '@ea-services';
import { ConfigService } from '../../services/config.service';
import { IncidentObjects } from '@ea-models';

@Injectable({
  providedIn: 'root'
})
export class IncidentReportService {
  constructor(private http: HttpClient, private configService: ConfigService, private globalService: GlobalService) {}

  getInProgressReports(start_index: number, limit: number): Observable<InProgressIncidentReport[]> {
    const token = this.globalService._currentUser.token;
    return this.http
      .get<InProgressIncidentReportsResponse>(this.configService.apiURL() + `pending_accidents`, {
        params: { token, start_index, limit }
      })
      .pipe(
        switchMap((res) =>
          res?.success ? of(res.accidents) : throwError(() => new Error('Fetch Pending Accidents Failed'))
        )
      );
  }

  getIncidentReportDetail(id: number): Observable<Incident> {
    const token = this.globalService._currentUser.token;
    return this.http
      .get<Incident>(this.configService.getBaseUrl(3) + `incidents/${id}`, { params: { token } })
      .pipe(map((response) => response));
  }

  getIncidentObjects(): Observable<IncidentObjects> {
    const token = this.globalService._currentUser.token;
    const url = this.configService.apiURL() + 'incident_objects.json';
    return this.http.get<IncidentObjects>(url, { params: { token } });
  }
}
