import { DevicePlatform } from '@ea-models-v4/common';
import { FEATURE_BUILD_TYPE } from '@ea-pages/pages.constants';
import { RouterConstant } from './router.constant';

export interface DashboardModule {
  name: string;
  networkRequired: boolean;
  path: string;
  counter: boolean;
  counterType?: 'in-progress' | 'local' | 'rejected';
  color: string;
  image: string;
  permissionKey?: string;
  supportedPlatforms?: DevicePlatform[];
}

export const DASHBOARD_MODULES: DashboardModule[] = [
  {
    name: 'New Report',
    networkRequired: false,
    path: RouterConstant.NEW_REPORT_PAGE,
    counter: false,
    color: '#6DC183',
    image: 'assets/image/new-report.svg',
    supportedPlatforms: ['ios', 'android', 'web']
  },
  {
    name: 'Reports Saved In Progress To Cloud',
    networkRequired: true,
    path: RouterConstant.PENDING_REPORTS,
    counter: true,
    counterType: 'in-progress',
    color: '#ed7d31',
    image: 'assets/image/document-file-ed7d31.svg',
    supportedPlatforms: ['ios', 'android', 'web']
  },
  {
    name: 'Pending Local Reports',
    networkRequired: false,
    path: RouterConstant.PENDING_LOCAL_REPORTS_PAGE,
    counter: true,
    counterType: 'local',
    color: '#FDC12F',
    image: 'assets/image/pending-report.svg',
    supportedPlatforms: ['ios', 'android', 'web']
  },
  {
    name: 'Rejected Reports',
    networkRequired: true,
    path: RouterConstant.REJECTED_REPORTS,
    counter: true,
    counterType: 'rejected',
    color: '#EC5A41',
    image: 'assets/image/rejected-report.svg',
    supportedPlatforms: ['ios', 'android', 'web']
  },
  {
    name: 'Add Park Build',
    networkRequired: true,
    path: RouterConstant.FEATURE_BUILD_PAGE + '/' + FEATURE_BUILD_TYPE.park,
    counter: false,
    color: '#9ECD68',
    image: 'assets/image/park-build.svg',
    permissionKey: 'park_module',
    supportedPlatforms: ['ios', 'android', 'web']
  },
  {
    name: 'Add Park Feature',
    networkRequired: true,
    path: RouterConstant.ADD_FEATURE_PAGE + '/' + FEATURE_BUILD_TYPE.park,
    counter: false,
    color: '#699F3A',
    image: 'assets/image/park-feature.svg',
    permissionKey: 'park_module',
    supportedPlatforms: ['ios', 'android', 'web']
  },
  {
    name: 'Add Trail Build',
    networkRequired: true,
    path: RouterConstant.FEATURE_BUILD_PAGE + '/' + FEATURE_BUILD_TYPE.trail,
    counter: false,
    color: '#9ECD68',
    image: 'assets/image/park-build.svg',
    permissionKey: 'trail_module',
    supportedPlatforms: ['ios', 'android', 'web']
  },
  {
    name: 'Add Trail Feature',
    networkRequired: true,
    path: RouterConstant.ADD_FEATURE_PAGE + '/' + FEATURE_BUILD_TYPE.trail,
    counter: false,
    color: '#699F3A',
    image: 'assets/image/park-feature.svg',
    permissionKey: 'trail_module',
    supportedPlatforms: ['ios', 'android', 'web']
  },
  {
    name: 'Dispatch Console',
    networkRequired: true,
    path: RouterConstant.DISPATCH_CONSOLE_PAGE,
    counter: false,
    color: '#AC48BD',
    image: 'assets/image/dispatch.svg',
    permissionKey: 'dispatch_module',
    supportedPlatforms: ['web']
  },
  {
    name: 'Emergency Operation Plans',
    networkRequired: false,
    path: RouterConstant.EMERGENCY_OPERATION_PLANS_PAGE,
    counter: false,
    color: '#617D8B',
    image: 'assets/image/emergency-o-plan.svg',
    permissionKey: 'eop_module',
    supportedPlatforms: ['ios', 'android', 'web']
  },
  {
    name: 'Equipment Assignment',
    networkRequired: true,
    path: RouterConstant.EQUIPMENT_ASSIGNMENT_PAGE,
    counter: false,
    color: '#24BDD6',
    image: 'assets/image/equipment-assignment.svg',
    permissionKey: 'equipment_module',
    supportedPlatforms: ['ios', 'android', 'web']
  },
  {
    name: 'Equipment Maintenance',
    networkRequired: true,
    path: RouterConstant.EQUIPMENT_MAINTENANCE_PAGE,
    counter: false,
    color: '#1B9689',
    image: 'assets/image/equipment-maintenance.svg',
    permissionKey: 'equipment_module',
    supportedPlatforms: ['ios', 'android', 'web']
  },
  {
    name: 'Groomer Tracking',
    networkRequired: true,
    path: RouterConstant.GROOMER_TRACKING_PAGE,
    counter: false,
    color: '#89ced9',
    image: 'assets/image/groomer.svg',
    permissionKey: 'groomer_module',
    supportedPlatforms: ['ios', 'android', 'web']
  },
  {
    name: 'Health & Safety',
    networkRequired: false,
    path: RouterConstant.HEALTH_AND_SAFETY_PAGE,
    counter: false,
    color: '#CEDB49',
    image: 'assets/image/health-safety.svg',
    permissionKey: 'health_safety_module',
    supportedPlatforms: ['ios', 'android', 'web']
  },
  {
    name: 'Lift Stop',
    networkRequired: true,
    path: RouterConstant.LIFT_STOP_PAGE,
    counter: false,
    color: '#FDC12F',
    image: 'assets/image/lift-stop.svg',
    permissionKey: 'lift_module',
    supportedPlatforms: ['ios', 'android', 'web']
  },
  {
    name: 'Objects',
    networkRequired: true,
    path: RouterConstant.OBJECTS_PAGE,
    counter: false,
    color: '#F491B1',
    image: 'assets/image/objects.svg',
    permissionKey: 'sign_module',
    supportedPlatforms: ['ios', 'android', 'web']
  },
  {
    name: 'Special Events',
    networkRequired: true,
    path: RouterConstant.SPECIAL_EVENTS_PAGE,
    counter: false,
    color: '#F491B1',
    image: 'assets/image/special-event.svg',
    permissionKey: 'special_event_module',
    supportedPlatforms: ['ios', 'android', 'web']
  },
  {
    name: 'Tasks',
    networkRequired: true,
    path: RouterConstant.TASKS_PAGE,
    counter: false,
    color: '#4051B5',
    image: 'assets/image/tasks.svg',
    permissionKey: 'task_module',
    supportedPlatforms: ['ios', 'android', 'web']
  },
  {
    name: 'Waiver Lookup',
    networkRequired: true,
    path: RouterConstant.WAIVER_LOOKUP_PAGE,
    counter: false,
    color: '#E72565',
    image: 'assets/image/waiver-lookup.svg',
    permissionKey: 'waiver_module',
    supportedPlatforms: ['ios', 'android', 'web']
  }
];
