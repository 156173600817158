import { take, map, switchMap, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { Observable, from, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActionSheetController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import moment from 'moment';

import { GlobalService } from '../global.service';
import { HealthAndSafetyAPI } from './health-and-safety.api';
import { PendingReportType, PendingReportKey } from '@ea-models/misc';
import { SafetyMeeting, FormalTraining, User, Department, Staff } from '@ea-models';
import { PendingLocalReportsService } from '@ea-services/pending-local-reports.service';
import { HealthSafetySaveToCloudUtility } from './health-safety-save-to-cloud-utility';
import { SpinnerService } from '@ea-services/spinner.service';
import { RecentUsers } from '../recent-users.service';
import { AppState } from '@edgeauditor/app/store/app/app.state';

@Injectable({
  providedIn: 'root'
})
export class HealthSafetyOfflineDataUtility {
  observables: Array<Observable<any>> = [];

  private readonly pendingTrainingsKey: PendingReportType = PendingReportKey.formalTrainings.value;
  private readonly pendingMeetingsKey: PendingReportType = PendingReportKey.safetyMeetings.value;
  staffKey: string = `staff_list`;
  departmentsKey: string = `departments`;

  constructor(
    private globalService: GlobalService,
    private spinnerService: SpinnerService,
    private hnsService: HealthAndSafetyAPI,
    private actionSheetController: ActionSheetController,
    private pendingReports: PendingLocalReportsService,
    private hnsSaveToCloud: HealthSafetySaveToCloudUtility,
    private recentUsers: RecentUsers,
    private store: Store // private storageService: StorageService, // private apiService: ApiService, // private httpClient: HttpClient,
  ) {}

  private async saveAndReturn(storageKey: string, response: Staff[]) {
    if (!this.globalService._currentUser) {
      return response;
    }
    const user: User = this.recentUsers.get(
      this.globalService._currentUser?.org,
      this.globalService._currentUser.username
    );
    if (!user) return response;
    if (!user.health_and_safety) {
      user.health_and_safety = {};
    }
    user.health_and_safety = {
      ...user.health_and_safety,
      [storageKey]: response
    };
    await this.recentUsers.update(user);
    return response;
  }

  private readData(storageKey: string): Observable<Staff[]> {
    if (!this.globalService._currentUser) return of(null);
    const user: User = this.recentUsers.get(
      this.globalService._currentUser?.org,
      this.globalService._currentUser.username
    );
    if (!user) return of(null);
    let staffs: Staff[] = null;
    if (user.health_and_safety && user.health_and_safety[storageKey]) staffs = user.health_and_safety[storageKey];
    else if (user.staffs) staffs = user.staffs;
    return of(staffs);
  }

  getStaff(): Promise<Staff[]> {
    const networkStatus = this.store.selectSnapshot(AppState.getNetworkStatus);

    return (
      networkStatus.connected
        ? this.hnsService.getStaff().pipe(
            take(1),
            switchMap((response) => from(this.saveAndReturn(this.staffKey, response)))
          )
        : this.readData(this.staffKey)
    ).toPromise();
  }

  getDepartments(): Promise<any> {
    const networkStatus = this.store.selectSnapshot(AppState.getNetworkStatus);
    if (networkStatus.connected) {
      return this.hnsService
        .getDepartments()
        .pipe(
          take(1),
          map(async (response) => await this.saveAndReturn(this.departmentsKey, response))
        )
        .toPromise();
    } else {
      return of({ departments: this.globalService._currentUser.departments }).toPromise();
    }
  }

  saveToCouldHandler: () => {};

  async saveConfirmation(moduleName: string) {
    const networkStatus = this.store.selectSnapshot(AppState.getNetworkStatus);
    const isOnline = networkStatus.connected;
    const actionSheet = await this.actionSheetController.create({
      header: 'SAVE',
      buttons: [
        {
          text: `Save Incomplete ${moduleName} to This Device`,
          role: 'device',
          icon: 'share'
        },
        {
          text: `Save and Close Out ${moduleName} to Cloud`,
          role: 'cloud',
          icon: 'cloud-upload',
          cssClass: !isOnline ? 'custom-disabled-btn' : ''
        },
        {
          text: 'Cancel',
          icon: 'close',
          role: 'cancel'
        }
      ]
    });

    if (!isOnline) {
      let elements = actionSheet.getElementsByClassName('custom-disabled-btn');
      elements.length && elements[0].setAttribute('disabled', 'true');
    }

    await actionSheet.present();
    return from(actionSheet.onDidDismiss()).toPromise();
  }

  getPendingFormalTrainings() {
    return this.pendingReports.readData(this.pendingTrainingsKey);
  }

  async savePendingFormalTraining(training: FormalTraining, idx: number) {
    return this.getPendingFormalTrainings().then(async (trainings: Array<FormalTraining>) => {
      if (idx >= 0) {
        trainings[idx] = training;
      } else {
        trainings.push(training);
      }
      training.reportDateTime = moment(new Date()).format('YYYY-MM-DD - HH:mm');
      training.last_updated = moment().format();
      let data: any = await this.pendingReports.saveData(this.pendingTrainingsKey, trainings);
      if (data && data.length) {
        return { storage: 'device', status: 'saved' };
      }
    });
  }

  getPendingSafetyMeetings() {
    return this.pendingReports.readData(this.pendingMeetingsKey);
  }

  async savePendingSafetyMeeting(meeting: SafetyMeeting, idx: number) {
    return this.getPendingSafetyMeetings().then(async (meetings: Array<SafetyMeeting>) => {
      meeting['reportDateTime'] = moment(new Date()).format('YYYY-MM-DD - HH:mm');

      if (idx >= 0) {
        meetings[idx] = meeting;
      } else {
        meetings.push(meeting);
      }
      meeting.last_updated = moment().format();
      let data = await this.pendingReports.saveData(this.pendingMeetingsKey, meetings);

      if (data && data.length) {
        return { storage: 'device', status: 'saved' };
      }
    });
  }

  async submitSafetyMeeting(meeting: SafetyMeeting, idx: number = -1) {
    return await this.saveConfirmation('Meeting').then(async (res: { role: string }) => {
      const { role } = res;
      if (role === 'device') {
        const rs2 = await this.savePendingSafetyMeeting(meeting, idx);
        return rs2;
      } else if (role === 'cloud') {
        this.spinnerService.show('Submission in progress...');
        return this.hnsSaveToCloud
          .saveSafetyMeetingToCloud(meeting)
          .toPromise()
          .then(async (res) => {
            if (idx > -1) {
              let meetings = await this.pendingReports.readData(this.pendingMeetingsKey);
              await this.pendingReports.deleteItem(this.pendingMeetingsKey, meetings, idx); //deleted and removed from storage
            }
            return res && res.id ? { storage: 'cloud', status: 'saved' } : res;
          });
      }
    });
  }

  async submitFormalTraining(training: FormalTraining, idx: number = -1) {
    return await this.saveConfirmation('Formal Training').then(async (res: { role: string }) => {
      const { role } = res;
      if (role === 'device') {
        return await this.savePendingFormalTraining(training, idx);
      } else if (role === 'cloud') {
        this.spinnerService.show('Submission in progress...');
        return this.hnsSaveToCloud
          .saveFormalTrainingToCloud(training)
          .toPromise()
          .then(async (res) => {
            if (idx > -1) {
              let trainings = await this.pendingReports.readData(this.pendingTrainingsKey);
              await this.pendingReports.deleteItem(this.pendingTrainingsKey, trainings, idx); //deleted and removed from storage
            }
            return res && res.id ? { storage: 'cloud', status: 'saved' } : res;
          });
      }
    });
  }
}
