import {
  NemsisReportStatus,
  NemsisReportSubmitData,
  NemsisReportCreation,
  NemsisLocalReport
} from '@ea-models-v4/nemsis';

const STATE_KEY = '[NEMSIS]';
const TYPE = (text: string) => STATE_KEY + ' ' + text;

export class GetTemplates {
  static readonly type = '[NEMSIS] Get Templates';
}

export class SelectTemplate {
  static readonly type = '[NEMSIS] Select Template';
  constructor(public id: number) {}
}

export class CreateCloudNemsisReport {
  static readonly type = '[NEMSIS] Create Cloud Nemsis Report';
  constructor(public data: NemsisReportSubmitData, public closeReport: boolean) {}
}

export class GetLocalReports {
  static readonly type = '[NEMSIS] Get Local Reports';
}
export class CreateLocalReport {
  static readonly type = '[NEMSIS] Create Local Report';
  constructor(public localReport: NemsisReportSubmitData) {}
}

export class UpdateLocalReport {
  static readonly type = '[NEMSIS] Update Local Report';
  constructor(public localReport: NemsisReportSubmitData) {}
}

export class CancelReport {
  static readonly type = '[NEMSIS] Cancel Report';
}

export class DeleteLocalReport {
  static readonly type = '[NEMSIS] Delete Local Report';
  constructor(public uuid: string) {}
}

export class DeleteLocalReportSuccess {
  static readonly type = '[NEMSIS] Create Local Report Success';
}

export class GetCloudReports {
  static readonly type = '[NEMSIS] Get Cloud Reports';
  constructor(
    public readonly start_index: number,
    public readonly limit: number,
    public readonly status: NemsisReportStatus = NemsisReportStatus.Draft
  ) {}
}

export class GetCloudReport {
  static readonly type = '[NEMSIS] Get Cloud Report';
  constructor(public id: number) {}
}

export class UpdateNemsisCloudReport {
  static readonly type = TYPE('Update Nemsis Cloud Report');
  constructor(public data: NemsisReportCreation) {}
}

export class GetNemsisLocalReport {
  static readonly type = TYPE('Get Nemsis Local Report');
  constructor(public uuid: string) {}
}

export class GetNemsisLocalReportSuccess {
  static readonly type = TYPE('Get Nemsis Local Report Success');
  constructor(public report: NemsisLocalReport) {}
}

export class LeaveNemsisPage {
  static readonly type = TYPE('Leave Nemsis Page');
}
