import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { GlobalService } from '@ea-services';
import { IncidentReportProviderService } from '@ea-services/provider/incident-report-provider.service';
import { AppState } from '@edgeauditor/app/store/app/app.state';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { INCIDENT_REPORT_MAT_TAB_GROUP } from '../../constants/incident-report.constants';

interface ISelectObject {
  id?: string;
  title?: string;
  first_name?: string;
  last_name?: string;
  staff_number?: string;
}

@Component({
  selector: 'app-patrollers',
  templateUrl: './patrollers.component.html',
  styleUrls: ['./patrollers.component.scss']
})
export class PatrollersComponent implements OnInit, OnChanges {
  @Output() changed = new EventEmitter<Map<string, string>>();
  @Input() modelData: { [key: string]: any };
  @Input() selects: {
    [key: string]: ISelectObject[];
  } = {};
  patroller: {
    staff?: any;
    role?: any;
  } = {};
  model: {
    patrollers: {
      id?: number;
      staff?: any;
      role?: any;
      _destroy?: boolean;
    }[];
  } = {
    patrollers: []
  };
  readonly incidentReportMatTabGroup = INCIDENT_REPORT_MAT_TAB_GROUP;

  @ViewSelectSnapshot(AppState.getIsMobilePlatform) isMobilePlatform: boolean;

  constructor(
    public globalService: GlobalService,
    public incidentProvider: IncidentReportProviderService,
    public ref: ElementRef
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      'modelData' in changes &&
      changes.modelData.currentValue &&
      changes.modelData.currentValue.patrollers &&
      changes.modelData.currentValue.patrollers.length > 0
    ) {
      this.model.patrollers = changes.modelData.currentValue.patrollers.map((patroller) => ({
        staff: this.selects.patrollers
          ? this.selects.patrollers.find((p) => patroller.staff && p.id === patroller.staff.id)
          : null,
        role: this.selects.patroller_roles
          ? this.selects.patroller_roles.find((r) => patroller.role && r.id === patroller.role.id)
          : null,
        id: patroller.id
      }));
    }
  }

  onChange(newVal: string, fieldName: string) {
    const newData = new Map<string, string>();
    newData.set(fieldName, newVal);
    this.changed.emit(newData);
  }

  addPatroller() {
    this.model.patrollers.push({ ...this.patroller });
    this.onChange(JSON.stringify(this.model.patrollers), 'patrollers');
    this.patroller = {};
  }

  removePatroller(index) {
    if (this.model.patrollers[index].id) {
      this.model.patrollers[index]._destroy = true;
    } else {
      this.model.patrollers.splice(index, 1);
    }
    this.onChange(JSON.stringify(this.model.patrollers), 'patrollers');
  }

  customSearchFn(term: string, item: ISelectObject) {
    term = term.toLowerCase();
    return item.first_name.toLowerCase().indexOf(term) > -1 || item.last_name.toLowerCase().indexOf(term) > -1;
  }
}
