import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ImageGalleryPreviewComponent } from './image-gallery-preview.component';
import { IAttachment } from '@ea-models';

@Component({
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss']
})
export class ImageGalleryComponent implements OnInit {
  @Input() hidePreview: boolean;
  @Input() hideNote: boolean;
  @Input() hideRemove: boolean;
  @Input() attachments: IAttachment[] = [];
  @Output() attachmentsChanged = new EventEmitter<IAttachment[]>();
  preview = (attachment: IAttachment, idx: number) => this.previewModal(attachment, idx);
  removeImage = (idx: number) => this.attachments.splice(idx, 1);

  constructor(private modalController: ModalController) {}

  ngOnInit() {}

  private async previewModal(attachment: IAttachment, idx: number) {
    const hideNote = this.hideNote;
    const hideRemove = this.hideRemove;
    const modal = await this.modalController.create({
      component: ImageGalleryPreviewComponent,
      cssClass: 'full-size-modal',
      componentProps: { attachment, hideNote, hideRemove }
    });
    modal.present();
    const { data } = await modal.onWillDismiss();
    if (data && data.object_attachment && data.object_attachment._destroy) {
      // const idx = this.attachments.findIndex((a) => a.object_attachment._destroy === true);
      this.removeImage(idx);
      this.attachmentsChanged.emit(this.attachments);
    }
    // console.log('previewModal data:', data);
    // console.log('attachments data:', this.attachments);
  }
}
