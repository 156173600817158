<ion-header class="task-header">
  <ion-toolbar color="main">
    <ion-icon
              size="large"
              slot="start"
              name="arrow-back-outline"
              class="arrow-back"
              (click)="close()"></ion-icon>
    <ion-title class="ion-text-center">Task</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content
             class="task-container task-detail-page"
             [class.full]="tabId === 1"
             [scrollY]="tabId !== 2">
  <div class="section-component__body">
    <div class="tab">
      <div class="tab-item" [class.active]="tabId === 1" (click)="tabId = 1">
        Task Details
      </div>
      <div class="tab-item" [class.active]="tabId === 2" (click)="tabId = 2">
        Finish Task
      </div>
    </div>

    <!-- detail -->
    <div class="tab-content" [hidden]="tabId !== 1">
      <ion-grid>
        <ion-row class="no-border">
          <div class="priority">Task</div>
          <div class="assigned name">{{ task?.name }}</div>
        </ion-row>
        <ion-row *ngIf="task?.accident_report_number" class="no-border">
          <div class="linked">
            Linked to Incident Report # {{ task?.accident_report_number }}
          </div>
        </ion-row>
        <ion-row class="no-border">
          <div class="details">
            <div class="detail-header">
              <div class="action">
                <div class="priority">Action</div>
                <div class="assigned">
                  {{ task?.action || task?.action_name }}
                </div>
              </div>
              <div class="date">
                <div class="line"></div>
                <div class="priority">Due Date</div>
                <div class="assigned">
                  {{ task?.due_date || '' | date: 'MMM dd, yyyy' }}
                </div>
              </div>
              <div class="prio">
                <div class="priority">Priority</div>
                <div class="assigned">{{ task?.priority }}</div>
              </div>
            </div>
            <div class="detail-item">
              <div class="department">
                <div class="priority">Department</div>
                <div class="assigned">{{ task?.department_name }}</div>
              </div>
              <div class="area">
                <div class="priority">Area</div>
                <div class="assigned">{{ task?.area_name }}</div>
              </div>
              <div class="feature">
                <div class="priority">Feature</div>
                <div class="assigned">{{ task?.feature_name }}</div>
              </div>
            </div>
            <div class="detail-item">
              <div>
                <div class="priority">Type</div>
                <div class="assigned">{{ task?.type_name }}</div>
              </div>
            </div>
          </div>
        </ion-row>
        <ion-row *ngIf="task?.report_id">
          <div class="report-name">
            <div class="priority">Report Name</div>
            <div class="assigned">{{ task?.report_name }}</div>
          </div>
        </ion-row>
        <ion-row class="no-border">
          <div class="priority">Notes</div>
          <div class="assigned description">{{ task?.description }}</div>
        </ion-row>

        <ion-row class="no-border">
          <app-image-gallery
                             [hideNote]="true"
                             [hideRemove]="true"
                             [attachments]="attachments"></app-image-gallery>
        </ion-row>
      </ion-grid>
    </div>

    <!-- finish task -->
    <div class="tab-content finish-task" [hidden]="tabId !== 2">
      <div class="finish-task-cotent">
        <ion-grid class="section-component__body" style="height: auto">
          <ion-row class="no-border">
            <div class="title">Completed By</div>
            <div class="control">
              <ion-input
                         debounce="400"
                         [(ngModel)]="model.completed_by"
                         class="input">
              </ion-input>
            </div>
          </ion-row>
          <ion-row class="no-border">
            <div class="title">Notes <span>(optional)</span></div>
            <div class="control">
              <ion-textarea
                            debounce="400"
                            rows="5"
                            [(ngModel)]="model.comment"
                            class="input">
              </ion-textarea>
            </div>
          </ion-row>
        </ion-grid>
      </div>
      <div class="btn-task-container" [hidden]="tabId === 1">
        <ion-button color="medium" (click)="close()">Cancel</ion-button>
        <ion-button color="success" [disabled]="!allowSave" (click)="save()">Submit and Finish Task</ion-button>
      </div>
    </div>
  </div>
</ion-content>