<ng-container *ngIf="object">
  <div class="section-component__subtitle">
    <ion-grid>
      <ion-row>
        <ion-col size-xs="6">{{ object.object_name }}</ion-col>
        <ion-col size-xs="6" *ngIf="section">
          <ion-checkbox *ngIf="section.save_status"
                        [(ngModel)]="object.is_enabled">
            {{ section.save_label }}
          </ion-checkbox>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
  <ion-grid class="section-component__body" *ngIf="section">
    <ng-container *ngFor="let question of section.questions; let qIndex = index">
      <ion-row *ngIf="question"
               class="single-report-item"
               [id]="question.question_id">
        <ion-col size-xs="12" size-sm="6">
          {{question.question}}&nbsp;<ion-text color="danger" *ngIf="question.required">*</ion-text>
        </ion-col>
        <ion-col size-xs="12" size-sm="6">
          <div #target [attr.data-q-index]="qIndex"></div>
          <ng-container [ngSwitch]="question.type"
                        *ngIf="object['models']?.length > qIndex">
            <ng-container *ngSwitchCase="ReportQuestionType.select">
              <ng-select [items]="question.options"
                         [selectOnTab]="true"
                         class="standard-select"
                         bindLabel="text"
                         bindValue="text"
                         placeholder="Select {{ question.question }}"
                         [(ngModel)]="object['models'][qIndex]['model'].value"
                         [class.invalid]="question.required && !object['models'][qIndex]['model'].value"
                         [virtualScroll]="question.options?.length > 20"
                         [attr.data-ci]="sectionIndex + '' + objectIndex + '' + qIndex"
                         (change)="onSelectChange($event,'text',question,object['models'][qIndex]['model'],qIndex,object.object_id)">
              </ng-select>
            </ng-container>
            <ng-container *ngSwitchCase="DateTimeTypes.includes(question.type) ? question.type : ''">
              <input readonly
                     class="standard-input"
                     [owlDateTime]="dt1"
                     [owlDateTimeTrigger]="dt1"
                     [ngModel]="object['models'][qIndex]['model'][question.type]"
                     [class.invalid]="question.required && !object['models'][qIndex]['model'][question.type]"
                     [attr.data-ci]="sectionIndex + '' + objectIndex + '' + qIndex"
                     (dateTimeChange)="onChange($event.value,question.type === ReportQuestionType.date 
                     ? ReportQuestionType.date : ReportQuestionType.time,question,object['models'][qIndex]['model'],qIndex,object.object_id)" />
              <owl-date-time [pickerType]="question.type === ReportQuestionType.date ? 'calendar' : 'timer'"
                             #dt1></owl-date-time>
            </ng-container>
            <ng-container *ngSwitchCase="ReportQuestionType.signature">
              <app-signature [parentChangeDetectorRef]="parentChangeDetectorRef"
                             (signatureChanged)="onSignatureChange($event,question,object['models'][qIndex]['model'],qIndex,object.object_id)"
                             [signatureEvent]="object['models'][qIndex]['signSubject'].asObservable()"
                             (clear)="onSignatureChange('',question,object['models'][qIndex]['model'],qIndex,object.object_id,true)"
                             [attr.data-ci]="sectionIndex + '' + objectIndex + '' + qIndex"
                             [isRequired]="question.required"></app-signature>
            </ng-container>
            <ng-container *ngSwitchCase="SelectTypes[question.type] ? question.type : ''">
              <ng-container *ngIf="object['models'][qIndex]['currentUser']">
                <ng-select [items]="object['models'][qIndex]['currentUser'][selectsMapping[question.type]]"
                           [selectOnTab]="true"
                           class="standard-select"
                           bindLabel="name"
                           bindValue="name"
                           placeholder="Select {{ question.question }}"
                           [attr.data-ci]="sectionIndex + '' + objectIndex + '' + qIndex"
                           [virtualScroll]="object['models'][qIndex]['currentUser'][selectsMapping[question.type]]?.length > 20"
                           [(ngModel)]="object['models'][qIndex]['model'].value"
                           [class.invalid]="question.required && !object['models'][qIndex]['model'].value"
                           (change)="onSelectChange($event,'id',question,object['models'][qIndex]['model'],qIndex,object.object_id)">
                  <ng-template ng-option-tmp
                               let-item="item"
                               let-search="searchTerm"
                               let-index="index">
                    <span>{{ item.first_name }} {{ item.last_name }}</span>
                  </ng-template>
                </ng-select>
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="ReportQuestionType.image">
              <app-image-picker [isSupportResizePhoto]="isSupportResizePhoto"
                                [showWarningResizePhoto]="showWarningResizePhoto"
                                [newToRight]="true"
                                [attr.data-ci]="sectionIndex + '' + objectIndex + '' + qIndex"
                                (imageSelected)="onImageChange($event,question,object['models'][qIndex]['model'],qIndex,object.object_id)"
                                [restoreImagesEvent]="object['models'][qIndex]['imagesSubject'].asObservable()"
                                [isRequired]="question.required">
              </app-image-picker>
            </ng-container>
            <ng-container *ngSwitchCase="ReportQuestionType.diagram">
              <app-diagram (modelChanged)="onDiagramChange($event,question,object['models'][qIndex]['model'],qIndex,object.object_id)"
                           [userEvent]="userEvent"
                           [diagramEvent]="object['models'][qIndex]['diagramSubject'].asObservable()"
                           [isSupportResizePhoto]="isSupportResizePhoto"
                           [showWarningResizePhoto]="showWarningResizePhoto"
                           [parentChangeDetectorRef]="parentChangeDetectorRef"
                           [attr.data-ci]="sectionIndex + '' + objectIndex + '' + qIndex"
                           [isRequired]="question.required">
              </app-diagram>
            </ng-container>
            <ng-container *ngSwitchCase="ReportQuestionType.multi_select">
              <ng-select [items]="question.options"
                         [(ngModel)]="object['models'][qIndex]['model'].multi_values"
                         [multiple]="true"
                         [selectOnTab]="true"
                         bindLabel="text"
                         bindValue="id"
                         (change)="onSelectChange($event,'text',question,object['models'][qIndex]['model'],qIndex,object.object_id, true)"
                         [class.invalid]="question.required && (!object['models'][qIndex]['model'].multi_values || object['models'][qIndex]['model'].multi_values.length === 0)"></ng-select>
            </ng-container>
          </ng-container>
        </ion-col>
      </ion-row>
    </ng-container>
  </ion-grid>
</ng-container>