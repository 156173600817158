<div class="section-component__title">{{ section?.heading }}</div>
<div class="section-component__body grid-report"
     (scroll)="scrollHandler($event)">
  <ion-row class="grid-report-item"
           [ngStyle]="{ 'min-width': minWidthForEachRow + 'px' }">
    <ion-col size="2" class="question-text"></ion-col>
    <ion-col size="2" class="cbx"></ion-col>
    <ion-col size=""
             *ngFor="let question of section?.questions"
             [id]="question.question_id"
             [class.cbx]="question.type === ReportQuestionType.checkbox"
             [class.select]="question.type === ReportQuestionType.select || !!SelectTypes[question.type]"
             [class.toggle]="question.type === ReportQuestionType.boolean">
      {{ question.question }}&nbsp;<ion-text color="danger" *ngIf="question.required">*</ion-text>
    </ion-col>
  </ion-row>
  <ng-container #viewContainerRef></ng-container>
</div>