import {
  DispatchConsoleEvent,
  DispatchEquipment,
  DispatchEvent,
  DispatchNotes,
  DispatchPatroller,
  DispatchTimeLog,
  DispatchTransportLog
} from '@ea-models';
import { schema } from 'normalizr';

export const dispatchEventSchema = new schema.Entity<DispatchConsoleEvent>('events');
export const dispatchEventArraySchema = new schema.Array(dispatchEventSchema);

export interface NormalizedDispatchEvent {
  id?: number;
  location_id?: number;
  event_type: string;
  created_by?: string;
  reported_by?: string;
  lead_staff_id?: number;
  lead_staff_other?: string;
  feature_id?: number;
  location_other?: string;
  location_description?: string;
  status?: string;
  title: string;
  created_at?: string;
  updated_at?: string;
  event_id?: number;
  region?: string;
  occurence_site?: string;
  trail_lift_other?: string;
  reported_by_location?: string;
  reported_time_old?: string;
  lead_patroller?: string;
  last_edited_by?: string;
  other_region?: string;
  other_occurence_site?: string;
  dispatch_category_id?: number;
  dispatch_category_name?: string;
  reported_time?: string;
  arrived?: string;
  reported_time_num?: number;
  patroller_role?: string;
  occurence_selector?: string;
  deleted?: boolean;
  deleted_at?: string;
  dispatch_event_status_id?: number;
  dispatch_event_status_name?: string;
  dispatch_time_logs_attributes?: DispatchTimeLog[];
  patroller_dispatches_attributes?: DispatchPatroller[];
  equipment_dispatches_attributes?: DispatchEquipment[];
  notes_attributes?: DispatchNotes[];
  transport_logs_attributes?: DispatchTransportLog[];
  event_attributes?: DispatchEvent;
  expanded?: boolean;
}
