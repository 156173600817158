import { Injectable, NgZone } from '@angular/core';
import { catchError, finalize, tap } from 'rxjs/operators';
import { Idle } from '@ng-idle/core';
import { NavController } from '@ionic/angular';
import _ from 'lodash';

import { User } from '@ea-models';
import { Credentials } from '@edgeauditor/app/models/auth';
import { CANNOT_CLEAR_CACHED_USERS } from '@ea-pages/pages.constants';
import { GlobalService, ParkBuildsService, RecentUsers, ReportService, SpinnerService } from '@ea-services';
import { AuthenticationService, AUTH_SESSION } from '@ea-services-v4';
import { LogRocketProvider } from '@ea-services/provider/logrocket';
import { environment } from '@edgeauditor/environments/environment';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { AppState } from '../app/app.state';
import {
  Login,
  LoginComplete,
  LoginError,
  LoginSuccess,
  Logout,
  SetupUser,
  SetupUserError,
  UpdateAuthState
} from './auth.action';
import { RouterConstant } from '@edgeauditor/app/constants/router.constant';
import { WebSocketProvider } from '@ea-providers-v4';
import { throwError } from 'rxjs';

export interface AuthStateModel {
  jwt: string;
  user: User;
}

export const AUTH_STATE_TOKEN = new StateToken<AuthStateModel>('auth');

@State({
  name: AUTH_STATE_TOKEN,
  defaults: {
    jwt: null,
    user: null
  }
})
@Injectable()
export class AuthState {
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly globalService: GlobalService,
    private readonly recentUsersService: RecentUsers,
    private readonly store: Store,
    private readonly reportService: ReportService,
    private readonly logRocketProvider: LogRocketProvider,
    private readonly ngZone: NgZone,
    private readonly idle: Idle,
    private readonly navController: NavController,
    private readonly spinnerService: SpinnerService,
    private readonly webSocketProvider: WebSocketProvider,
    private readonly parkBuildsService: ParkBuildsService
  ) {}

  //Returns API base
  @Selector()
  static getV4JWT(state: AuthStateModel) {
    return state.jwt;
  }

  //Returns API base
  @Selector()
  static getUser(state: AuthStateModel) {
    return state.user;
  }

  @Selector()
  static getLocationId(state: AuthStateModel) {
    return state.user && state.user.location && state.user.location.id;
  }

  @Selector()
  static getPasswordPermission(state: AuthStateModel) {
    return state.user && state.user.permit_change_password;
  }

  @Selector()
  static getLocationTimezone(s: AuthStateModel) {
    const timezone = JSON.stringify(s.user.location?.timezone);
    return timezone;
  }

  //Sets host and domain data
  @Action(Login)
  login(ctx: StateContext<AuthStateModel>, { credentials }: Login) {
    this.spinnerService.show('Signing in...');

    return this.authenticationService.login(credentials).pipe(
      tap((response: User) => {
        ctx.dispatch(new LoginSuccess(credentials, response));
      }),
      catchError((err) => {
        let message = 'There was an error with your request. Please try again or contact support.';
        //If error does not have an HTTP status, It's a throw error from authenticationService.login function
        if (!err.hasOwnProperty('status')) {
          message = 'Wrong Credentials. Invalid username, password or location code.';
        }
        ctx.dispatch(new LoginError(message));
        const errMsg = err instanceof Error ? err.message : JSON.stringify(err);
        return throwError(errMsg);
      }),
      finalize(() => this.spinnerService.hide(true))
    );
  }

  @Action(LoginSuccess)
  loginSuccess(ctx: StateContext<AuthStateModel>, { credentials, user }: LoginSuccess) {
    //Transform v1 Credentials to v4
    const credentialsV4: Credentials = {
      username: credentials.user.username,
      password: credentials.user.password,
      org_code: credentials.org
    };

    return this.authenticationService.loginV4(credentialsV4).pipe(
      tap((jwt: string) => {
        ctx.patchState({ jwt });

        //Add username to v1 User
        user.username = credentialsV4.username;
        user.org = credentialsV4.org_code;
        user.secret_key = credentialsV4.password;

        ctx.dispatch(new SetupUser(user));
      }),
      catchError((err) => {
        this.spinnerService.hide();
        let message = 'There was an error with your request. Please try again or contact support.';
        return ctx.dispatch(new LoginError(message));
      })
    );
  }

  @Action(SetupUser)
  setupUser(ctx: StateContext<AuthStateModel>, { user }: SetupUser) {
    const canAddUserResult = this.recentUsersService.canAddUser(user);

    // Proceed with user sign in if able to save it to recent users
    if (canAddUserResult.success || canAddUserResult.reason === 'already_exists') {
      const preparedUserData = this.prepareUserData(user);
      ctx.patchState({ user: preparedUserData });

      //TODO: Refactor to store user into state. Store session into SQLite
      const clonedUser = _.cloneDeep(preparedUserData);
      this.globalService.setCurrentUser(clonedUser);
      sessionStorage.setItem(AUTH_SESSION, JSON.stringify({ clonedUser }));

      //Initialize LR
      if (environment.isDeployedBuild) {
        const logRocketInfo = {
          location_id: user.location.id,
          location_code: user.org,
          username: user.username,
          email: user.email,
          name: user.name,
          role: user.role
        };

        this.logRocketProvider.startNewSession(logRocketInfo);
      }

      ctx.dispatch(new LoginComplete());
    } else {
      ctx.dispatch(new SetupUserError(CANNOT_CLEAR_CACHED_USERS));
    }
  }

  @Action(LoginComplete)
  loginComplete(ctx: StateContext<AuthStateModel>) {
    this.spinnerService.hide();

    //Init WS Provider
    const state = ctx.getState();
    this.webSocketProvider.init(state.user.token);

    //Redirect user to dashboard
    this.ngZone.run(() => {
      this.idle.watch();
      this.navController.navigateRoot(`/${RouterConstant.DASHBOARD_PAGE}`);
    });
  }

  @Action(UpdateAuthState)
  updateAuthState({ patchState }: StateContext<AuthStateModel>, payload: UpdateAuthState) {
    patchState({ ...payload.newState });
  }

  @Action(Logout)
  logout(ctx: StateContext<AuthStateModel>) {
    //TODO: Review what these do and refactor
    this.globalService.setCurrentUser(null);
    sessionStorage.removeItem(AUTH_SESSION);
    sessionStorage.removeItem('lastSession');
    this.reportService.cleanUpTempData();
    this.parkBuildsService.resetData();
    // End TODO

    this.ngZone.run(() => {
      this.navController.navigateRoot(`/${RouterConstant.LOGIN_PAGE}`);
    });
  }

  private prepareUserData(user: User) {
    const localUser = this.recentUsersService.get(user.org, user.username);
    if (localUser) {
      user.dispatchedIncidentReports = localUser.dispatchedIncidentReports || [];
      user.pendingIncidentReports = localUser.pendingIncidentReports || [];
      user.declinedReports = localUser.declinedReports || [];
      user.declinedIncidents = localUser.declinedIncidents || [];
      user.emergency_operation_plans = localUser.emergency_operation_plans || [];
      user.health_and_safety = localUser.health_and_safety || {};
      user.pendingReports = localUser.pendingReports || [];
      user.pendingStartDay = localUser.pendingStartDay || [];
      user.pendingObjects = localUser.pendingObjects || [];
      user.pendingFeatures = localUser.pendingFeatures || [];
      user.pendingParkBuilds = localUser.pendingParkBuilds || [];
      user.pendingEvent = localUser.pendingEvent || {};
      user.pendingLifts = localUser.pendingLifts || [];
      user.incident_report = localUser.incident_report || {};
      user.pendingSafetyMeetings = localUser.pendingSafetyMeetings || [];
      user.pendingFormalTrainings = localUser.pendingFormalTrainings || [];
      user.org = localUser.org;
    } else {
      this.initLocalDataForUser(user);
      this.recentUsersService.add(user);
    }

    const networkStatus = this.store.selectSnapshot(AppState.getNetworkStatus);
    //Clear reports if login is with network
    if (networkStatus.connected) {
      this.reportService.reports = null;
    }

    return user;
  }

  private initLocalDataForUser(user: User) {
    user.dispatchedIncidentReports = [];
    user.pendingIncidentReports = [];
    user.declinedReports = [];
    user.declinedIncidents = [];
    user.pendingReports = [];
    user.pendingStartDay = [];
    user.pendingObjects = [];
    user.pendingFeatures = [];
    user.pendingParkBuilds = [];
    user.pendingEvent = {};
    user.pendingLifts = [];
    user.incident_report = {};
  }
}
