<ion-header>
  <ion-toolbar color="main">
    <ion-icon
      size="large"
      slot="start"
      name="arrow-back-outline"
      (click)="cancel()"
      class="arrow-back"
    ></ion-icon>
    <ion-title class="ion-text-center">Inspect Object</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div
    class="location-map-canvas"
    id="inspection_map_canvas"
    #locationMapCanvas
  ></div>
  <ion-grid class="section-component__body" style="width: 100%">
    <ion-row *ngIf="type !== 'Sign'">
      <ion-col size-xs="12">{{ OBJECTS_TEMPLATE_NAMES[type] || type }}</ion-col>
      <ion-col size-xs="12">
        <ng-select
          [items]="objProviderService?.currentFeatures"
          [selectOnTab]="true"
          class="standard-select"
          bindValue="id"
          [searchFn]="filterFeatures"
          notFoundText="No item found"
          (change)="onChangeFeature($event)"
          placeholder="Select Feature"
          bindLabel="name"
          [(ngModel)]="model.feature_id"
          [searchable]="true"
        >
        </ng-select>
      </ion-col>
    </ion-row>
    <ng-container *ngIf="isShowQuestion">
      <ion-row>
        <ion-col size-xs="12">Inspection Condition</ion-col>
        <ion-col size-xs="12">
          <ng-select
            [items]="currentUser?.inspection_conditions"
            [selectOnTab]="true"
            class="standard-select"
            bindValue="id"
            [searchFn]="filterConditions"
            notFoundText="No item found"
            placeholder="Select Condition"
            bindLabel="title"
            [(ngModel)]="model.inspection_condition_id"
            [searchable]="true"
          >
          </ng-select>
        </ion-col>
      </ion-row>
      <ion-row style="margin-top: 15px">
        <ion-col size-xs="12">Comments</ion-col>
        <ion-col size-xs="12">
          <ion-textarea
            rows="5"
            class="standard-input"
            [(ngModel)]="model.comments"
          ></ion-textarea>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size-xs="12" style="margin: 30px 0 15px">
          <app-image-picker
            (imageSelected)="onImageChange($event)"
            [hideNote]="true"
            [hidePreview]="true"
            [isSupportResizePhoto]="true"
            [singleImageOnly]="true"
            [disabled]="!location"
          >
          </app-image-picker>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size-xs="12">
          <ion-row class="item-line">
            <ion-col size-xs="5" class="head">Title</ion-col>
            <ion-col size-xs="5" class="head">Image</ion-col>
            <ion-col size-xs="2" class="head end">Actions</ion-col>
          </ion-row>
          <ion-row *ngIf="!model?.object_attachments_attributes?.length">
            <ion-col><strong>Add at least one image</strong></ion-col>
          </ion-row>
          <ion-row
            *ngFor="
              let item of model?.object_attachments_attributes;
              let _index = index
            "
            class="list-imgs"
          >
            <ion-col size-xs="5" class="input">
              <ion-input
                type="text"
                debounce="400"
                [(ngModel)]="item.note"
                debounce="700"
                class="standard-input"
                placeholder="Enter Image Title"
                (ionChange)="changeFeatureName($event, item)"
              >
              </ion-input>
            </ion-col>
            <ion-col size-xs="5">
              <img src="{{ item.file }}" />
            </ion-col>
            <ion-col size-xs="2" class="end">
              <div class="icons-container top">
                <div
                  class="delete-icon-container"
                  (click)="removeAttachment(_index)"
                >
                  <ion-icon
                    slot="icon-only"
                    mode="md"
                    size="large"
                    class="delete-icon"
                    name="trash"
                  ></ion-icon>
                </div>
              </div>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
      <ion-row class="btn-container">
        <ion-col size-xs="6">
          <ion-button color="danger" expand="full" (click)="cancel()"
            >Cancel</ion-button
          >
        </ion-col>
        <ion-col size-xs="6">
          <ion-button
            color="success"
            expand="full"
            [disabled]="!isReadyToSave"
            (click)="save()"
            >Save Inspection</ion-button
          >
        </ion-col>
      </ion-row>
    </ng-container>
  </ion-grid>
</ion-content>
