import {
  DEF_AUTO_COL,
  DEF_COL_12,
  DEF_COL_4,
  DEF_COL_6,
  DEF_COL_3,
  DEF_KEY,
  DEF_KEY_VAL,
  WITNESSES_KEY,
  DEF_KEY_VAL_POS_TOP
} from './constants';
import { ITemplate } from './types';

export const CWSAA_PATIENTS_TEMPLATE: ITemplate = [
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_6,
        label: 'First Name',
        field: 'first_name',
        type: 'input',
        required: true
      },
      {
        ...DEF_COL_6,
        label: 'Last Name',
        field: 'last_name',
        type: 'input',
        required: true
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Address',
        field: 'address',
        type: 'custom',
        custom: 'address',
        defaultCountry: 'CA'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      { ...DEF_COL_6, label: 'Phone', field: 'phone', type: 'input' },
      { ...DEF_COL_6, label: 'Email', field: 'email', type: 'input' }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Date of Birth',
        field: 'birthday',
        type: 'custom',
        custom: 'birthdate'
      }
    ]
  },
  {
    ...{ ...DEF_KEY, customCss: 'no-break' },
    cols: [
      {
        ...{ ...DEF_AUTO_COL, xs: '' },
        label: 'Gender',
        field: 'gender',
        type: 'select',
        ...DEF_KEY_VAL
      },
      {
        ...{ ...DEF_AUTO_COL, xs: '' },
        label: 'Gender - Other',
        field: 'gender_notes',
        type: 'input',
        if: { key: 'gender', value: 'Other' }
      },
      {
        ...{ ...DEF_AUTO_COL, xs: '' },
        label: 'Height',
        field: 'height',
        type: 'input'
      },
      {
        ...{ ...DEF_AUTO_COL, xs: '' },
        label: 'Weight',
        field: 'weight',
        type: 'input'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_6,
        label: 'Patient Type',
        field: 'patient_type',
        type: 'select',
        ...DEF_KEY_VAL_POS_TOP
      },
      {
        ...DEF_COL_6,
        label: 'Ticket Type',
        field: 'ticket_type',
        type: 'select',
        ...DEF_KEY_VAL,
        _: 'cwsaa_'
      },
      {
        ...DEF_COL_6,
        label: 'Ticket Number #',
        field: 'ticket_number',
        type: 'input'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_6,
        label: 'Allergies',
        field: 'has_allergies',
        type: 'checkbox'
      },
      {
        ...DEF_COL_6,
        label: 'Medications',
        field: 'has_medications',
        type: 'checkbox'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_6,
        label: 'List Allergies',
        field: 'allergies',
        type: 'textarea',
        if: { key: 'has_allergies', value: true }
      },
      {
        ...DEF_COL_6,
        label: 'List Medications',
        field: 'medications',
        type: 'textarea',
        if: { key: 'has_medications', value: true }
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_6,
        label: 'Drugs or Alcohol Used?',
        field: 'drugs_alcohol',
        type: 'input'
      },
      {
        ...DEF_COL_6,
        label: 'Related Medical History',
        field: 'history',
        type: 'input'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Is Patient Wearing a Medic Alert?',
        field: 'medic_alert',
        type: 'checkbox'
      }
    ]
  },
  {
    if: { key: 'medic_alert', value: true },
    cols: [
      {
        ...DEF_COL_12,
        label: 'What Is The Medic Alert For?',
        field: 'medic_alert_value',
        type: 'input'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Injury Is a Re-Occurrence of a Previous One',
        field: 'is_reccurence',
        type: 'checkbox'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Occupation / School Group',
        field: 'occupation',
        type: 'input'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      // eslint-disable-next-line max-len
      {
        ...DEF_COL_12,
        label: 'If Patient Is a Student, Is He/She Participating in a School Program?',
        field: 'is_student_ski_program',
        type: 'checkbox'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Visitor Local Accommodation',
        field: 'local_accomodation_info',
        type: 'input'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Signs & Symptoms by Dr/Patrol',
        field: 'signs_symptoms',
        type: 'textarea'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Patient Description of Incident',
        field: 'description',
        type: 'textarea'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Against The Advice Given, The Patient Has Refused Care',
        field: 'refused_care',
        type: 'checkbox'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Last Meal Time',
        field: 'last_meal',
        type: 'datetime',
        displayFormat: 'H:mm'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_6,
        label: 'Signature Type',
        field: 'signature_type',
        type: 'select',
        ...DEF_KEY_VAL
      },
      // eslint-disable-next-line max-len
      {
        ...DEF_COL_6,
        label: 'Name of Person Signing',
        field: 'other_signature_name',
        type: 'input',
        if: {
          key: 'signature_type',
          value: 'Parent',
          or: ['Guardian', 'Spouse']
        }
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Signature',
        field: 'signature',
        type: 'custom',
        custom: 'signature',
        signatureOnly: true
      }
    ]
  }
];

export const CWSAA_FIRST_AID_TEMPLATE: ITemplate = [
  {
    ...DEF_KEY,
    cols: [{ ...DEF_COL_12, label: 'At Scene', field: '', type: 'label' }]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_6,
        label: 'Time on Scene',
        field: 'on_scene',
        type: 'datetime',
        displayFormat: 'H:mm'
      },
      {
        ...DEF_COL_6,
        label: 'Time Transported',
        field: 'transported',
        type: 'datetime',
        displayFormat: 'H:mm'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'First Aid on Scene',
        field: 'first_aid_scene',
        type: 'textarea'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [{ ...DEF_COL_12, label: 'At Base', field: '', type: 'label' }]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_6,
        label: 'Time Base in',
        field: 'base_in',
        type: 'datetime',
        displayFormat: 'H:mm'
      },
      {
        ...DEF_COL_6,
        label: 'Time Base out',
        field: 'base_out',
        type: 'datetime',
        displayFormat: 'H:mm'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'First Aid at Base',
        field: 'first_aid_base',
        type: 'textarea'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Medication Administered at',
        field: 'med_admin_time',
        type: 'datetime',
        displayFormat: 'H:mm'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Medication Administered',
        field: 'med_admin',
        type: 'textarea'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Additional Information',
        field: 'additional_information',
        type: 'textarea'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Administered by',
        field: 'med_admin_by',
        type: 'input'
      }
    ]
  }
];

export const CWSAA_WITNESSES_TEMPLATE: ITemplate = [
  {
    ...DEF_KEY,
    cols: []
  },
  {
    ...WITNESSES_KEY,
    cols: [
      { ...DEF_COL_6, label: 'First Name', field: 'first_name', type: 'input' },
      { ...DEF_COL_6, label: 'Last Name', field: 'last_name', type: 'input' }
    ]
  },
  {
    ...WITNESSES_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Address',
        field: 'address',
        type: 'custom',
        custom: 'address',
        defaultCountry: ''
      }
    ]
  },
  {
    ...WITNESSES_KEY,
    cols: [
      { ...DEF_COL_6, label: 'Phone', field: 'phone', type: 'input' },
      { ...DEF_COL_6, label: 'Email', field: 'email', type: 'input' }
    ]
  },
  {
    ...WITNESSES_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Statement',
        field: 'statement',
        type: 'textarea'
      }
    ]
  },
  {
    ...WITNESSES_KEY,
    cols: [
      {
        ...DEF_COL_4,
        label: 'Witnessed by?',
        field: 'is_witnessed_by',
        type: 'checkbox'
      },
      {
        ...DEF_COL_4,
        label: 'Accompanied by?',
        field: 'is_accompanied_by',
        type: 'checkbox'
      },
      {
        ...DEF_COL_4,
        label: 'Collided with?',
        field: 'is_collided_with',
        type: 'checkbox'
      }
    ]
  },
  {
    ...WITNESSES_KEY,
    cols: [{ ...DEF_COL_12, label: 'Other', field: 'other', type: 'textarea' }]
  },
  {
    ...WITNESSES_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Signature',
        field: 'signature',
        type: 'custom',
        custom: 'signature',
        signatureOnly: true
      }
    ]
  }
];

export const CWSAA_ADDITIONAL_QUESTIONS_TEMPLATE: ITemplate = [
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Incident Type',
        field: 'accident_category',
        type: 'select',
        ...DEF_KEY_VAL
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Lift' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Location at lift',
        field: 'lift_location_at_lift',
        type: 'input'
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Lift' },
    cols: [
      {
        ...DEF_COL_12,
        label: '# Passengers on chair',
        field: 'lift_total_passengers_on_chair',
        type: 'input'
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Lift' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Were any other riders related or acquainted to you?',
        field: 'lift_related_to_other_riders',
        type: 'select',
        ...DEF_KEY_VAL
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Lift' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Seat position',
        field: 'lift_seat_position',
        type: 'input'
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Lift' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Previous rides on lift',
        field: 'lift_total_previous_rides',
        type: 'input'
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Lift' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Rides on lift today',
        field: 'lift_total_rides_today',
        type: 'input'
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Lift' },
    cols: [
      // eslint-disable-next-line max-len
      {
        ...DEF_COL_12,
        label: 'Did you notice warning information?',
        field: 'lift_did_you_notice_warning_information',
        type: 'select',
        ...DEF_KEY_VAL
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Lift' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Did you ask for assistance?',
        field: 'lift_did_you_ask_for_assistance',
        type: 'select',
        ...DEF_KEY_VAL
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Lift' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Did you ask the operator to slow or stop the lift?',
        field: 'lift_ask_operator_to_stop_lift',
        type: 'select',
        ...DEF_KEY_VAL
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Lift' },
    cols: [
      // eslint-disable-next-line max-len
      {
        ...DEF_COL_12,
        label: 'Are you familiar with the lift operations?',
        field: 'lift_are_you_familiar_with_its_operation',
        type: 'select',
        ...DEF_KEY_VAL_POS_TOP
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Collision' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Do you wear glasses?',
        field: 'corrective_lenses_needed',
        type: 'select',
        ...DEF_KEY_VAL
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Collision' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Were they worn?',
        field: 'corrective_lenses_worn',
        type: 'select',
        ...DEF_KEY_VAL
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Tubing' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Tubing lane',
        field: 'tubing_lane',
        type: 'input'
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Tubing' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Tubing lift',
        field: 'tubing_lift',
        type: 'input'
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Tubing' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Location',
        field: 'tubing_accident_location',
        type: 'input'
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Tubing' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Footwear',
        field: 'tubing_footwear',
        type: 'input'
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Tubing' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Alone or in a group?',
        field: 'tubing_alone_or_group',
        type: 'select',
        ...DEF_KEY_VAL
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Tubing' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Number of people',
        field: 'tubing_number_of_people',
        type: 'input'
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Tubing' },
    cols: [{ ...DEF_COL_12, label: 'Who', field: 'tubing_who', type: 'input' }]
  },
  {
    if: { key: 'accident_category', value: 'Tubing' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Previous experience',
        field: 'tubing_experience_previous',
        type: 'select',
        ...DEF_KEY_VAL
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Tubing' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Runs today',
        field: 'tubing_experience_runs_today',
        type: 'select',
        ...DEF_KEY_VAL
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Tubing' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Falls today',
        field: 'tubing_experience_falls_today',
        type: 'select',
        ...DEF_KEY_VAL
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Tubing' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Start method',
        field: 'tubing_start_method',
        type: 'select',
        ...DEF_KEY_VAL_POS_TOP
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Terrain Park' },
    cols: [
      // eslint-disable-next-line max-len
      {
        ...DEF_COL_12,
        label: 'Did you enter the park through an entrance gate?',
        field: 'terrain_park_did_you_enter_through_entrance_gate',
        type: 'select',
        ...DEF_KEY_VAL
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Terrain Park' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'How and where did you enter the park?',
        field: 'terrain_park_alternate_entrance_details',
        type: 'input'
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Terrain Park' },
    cols: [
      // eslint-disable-next-line max-len
      {
        ...DEF_COL_12,
        label: 'Did you read the informational and warning signs posted at the entrance?',
        field: 'terrain_park_did_you_notice_warning_information_signs',
        type: 'select',
        ...DEF_KEY_VAL
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Terrain Park' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Have you been in this park before? If yes, how often?',
        field: 'terrain_park_previous_usage',
        type: 'input'
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Terrain Park' },
    cols: [
      // eslint-disable-next-line max-len
      {
        ...DEF_COL_12,
        label: 'Have you ridden this feature before?',
        field: 'terrain_park_previous_attempts',
        type: 'select',
        ...DEF_KEY_VAL
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Terrain Park' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'If yes, how many times today?',
        field: 'terrain_park_previous_attempts_today',
        type: 'input'
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Terrain Park' },
    cols: [
      // eslint-disable-next-line max-len
      {
        ...DEF_COL_12,
        label: 'Did you scope out the feature before using it today?',
        field: 'terrain_park_scope_feature',
        type: 'select',
        ...DEF_KEY_VAL
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Terrain Park' },
    cols: [
      // eslint-disable-next-line max-len
      {
        ...DEF_COL_12,
        label: 'Do you believe the incident was due to action/inaction on your part?',
        field: 'terrain_park_do_you_believe_this_was_from_your_action',
        type: 'select',
        ...DEF_KEY_VAL
      }
    ]
  },
  {
    if: { key: 'accident_category', value: 'Terrain Park' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Please explain',
        field: 'terrain_park_explain_action',
        type: 'textarea'
      }
    ]
  }
];

export const CWSAA_COMPLAINTS_TEMPLATE: ITemplate = [
  {
    ...DEF_KEY,
    cols: [{ ...DEF_COL_12, label: ' ', field: '', type: 'label' }]
  },
  {
    ...DEF_KEY,
    cols: [{ ...DEF_COL_12, label: ' ', field: '', type: 'label' }]
  },
  {
    ...DEF_KEY,
    cols: [{ ...DEF_COL_12, label: ' ', field: '', type: 'label' }]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: '',
        labels: { part: 'Part/Type', probable: 'Treatment Protocol' },
        field: 'complaint',
        type: 'custom',
        custom: 'complaint'
      }
    ]
  }
];

export const CWSAA_TRANSPORT_TEMPLATE: ITemplate = [
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_4,
        label: 'Transport To Base/1st Aid',
        field: 'transport_to_base',
        type: 'select',
        ...DEF_KEY_VAL,
        _: 'cwsaa_'
      },
      {
        ...DEF_COL_4,
        label: 'Transport From Resort',
        field: 'transport_to_aid',
        type: 'select',
        ...DEF_KEY_VAL,
        _: 'cwsaa_'
      },
      {
        ...DEF_COL_4,
        label: 'Destination',
        field: 'destination',
        type: 'select',
        ...DEF_KEY_VAL
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      // eslint-disable-next-line max-len
      {
        ...DEF_COL_4,
        label: 'Other Transport Base/1st Aid',
        field: 'other_to_base',
        type: 'input',
        if: { key: 'transport_to_base', value: 'Other' }
      },
      // eslint-disable-next-line max-len
      {
        ...DEF_COL_4,
        label: 'Ambulance/Helicopter #',
        field: 'ambulance',
        type: 'input',
        if: { key: 'transport_to_aid', value: 'Ambulance', or: ['Helicopter'] }
      },
      // eslint-disable-next-line max-len
      {
        ...DEF_COL_4,
        label: 'Other Transport From Resort',
        field: 'other_to_aid',
        type: 'input',
        if: { key: 'transport_to_aid', value: 'Other' }
      },
      {
        ...DEF_COL_4,
        label: 'Other Destination',
        field: 'destination_other',
        type: 'input',
        if: { key: 'destination', value: 'Other' }
      },
      {
        ...DEF_COL_4,
        label: 'Hospital',
        field: 'hospital',
        type: 'input',
        if: { key: 'destination', value: 'Hospital' }
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Transport & Patrollers',
        field: 'patrollers',
        type: 'custom',
        custom: 'patrollers'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Additional Personnel Involved (Name & ID)',
        field: 'patrol',
        type: 'textarea'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Signature',
        field: 'signature',
        type: 'custom',
        custom: 'signature',
        signatureOnly: false
      }
    ]
  }
];

export const CWSAA_LOCATION_TEMPLATE: ITemplate = [
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_AUTO_COL,
        label: 'Activity',
        field: 'activity',
        type: 'select',
        dynamicKey: 'default_activity',
        expect: true,
        ...DEF_KEY_VAL
      },
      {
        ...DEF_AUTO_COL,
        label: 'Activity',
        field: 'activity',
        type: 'select',
        dynamicKey: 'activity',
        expect: true,
        ...DEF_KEY_VAL
      },
      {
        ...DEF_AUTO_COL,
        label: 'Other Activity',
        field: 'activity_other',
        type: 'input',
        if: { key: 'activity', value: 'Other' }
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_AUTO_COL,
        label: 'Involvement',
        field: 'involvement',
        type: 'select',
        ...DEF_KEY_VAL
      },
      {
        ...DEF_AUTO_COL,
        label: 'Other Involvement',
        field: 'involvement_other',
        type: 'input',
        if: { key: 'involvement', value: 'Other' }
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Select Location',
        field: 'incident_location',
        type: 'custom',
        custom: 'location'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Description of Location',
        field: 'exact_location',
        type: 'textarea'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_6,
        label: 'Days This Area',
        field: 'days_this_area',
        type: 'input'
      },
      {
        ...DEF_COL_6,
        label: 'Days All Areas',
        field: 'days_all_areas',
        type: 'input'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Previous Times Using This Run/Lift/Attraction?',
        field: 'ridden_count',
        type: 'input'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_4,
        label: 'Weather',
        field: 'weather',
        type: 'select',
        ...DEF_KEY_VAL
      },
      {
        ...DEF_COL_4,
        label: 'Light',
        field: 'light',
        type: 'select',
        ...DEF_KEY_VAL
      },
      {
        ...DEF_COL_4,
        label: 'Temperature',
        field: 'temperature',
        type: 'select',
        ...DEF_KEY_VAL
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_AUTO_COL,
        label: 'Snow',
        field: 'snow',
        type: 'select',
        ...DEF_KEY_VAL,
        if: {
          key: 'incident_location',
          value: 'N/A',
          or: [
            'Marked Ski Run',
            'Freestyle Terrain',
            'Competition Terrain',
            'Out of Bounds',
            'Closed inbounds',
            'Lift Incident',
            'Tubing',
            'Zip Lining',
            'Other Attraction',
            'Golfing',
            'Premises',
            'Unknown',
            'Other'
          ]
        }
      },
      {
        ...DEF_AUTO_COL,
        label: 'Surface',
        field: 'surface',
        type: 'select',
        ...DEF_KEY_VAL
      },
      {
        ...DEF_AUTO_COL,
        label: 'Equipment',
        field: 'equipment',
        type: 'select',
        ...DEF_KEY_VAL
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      // eslint-disable-next-line max-len
      {
        ...DEF_AUTO_COL,
        label: 'Binding Release',
        field: 'binding_release',
        type: 'select',
        ...DEF_KEY_VAL,
        if: { key: 'activity', value: 'Alpine Skiing', or: ['Snowboard'] }
      },
      {
        ...DEF_AUTO_COL,
        label: 'Helmet',
        field: 'helmet',
        type: 'select',
        ...DEF_KEY_VAL
      },
      {
        ...DEF_AUTO_COL,
        label: 'Lesson',
        field: 'lesson',
        type: 'select',
        ...DEF_KEY_VAL
      }
    ]
  },
  {
    if: { key: 'lesson', value: 'Other' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Other Lesson',
        field: 'lesson_other',
        type: 'input'
      }
    ]
  },
  {
    // eslint-disable-next-line max-len
    if: {
      key: 'activity',
      value: 'Other',
      or: [
        'Alpine Skiing',
        'Snowboard',
        'Telemark',
        'Nordic',
        'Touring',
        'Tubing',
        'Zip Line',
        'Hiking',
        'Euro-Bungee',
        'Climbing',
        'Golfing'
      ]
    },
    cols: [
      {
        ...DEF_AUTO_COL,
        label: 'Rental Boot',
        field: 'rental_boot',
        type: 'input',
        if: { key: 'equipment', value: 'Area rental' }
      },
      {
        ...DEF_AUTO_COL,
        label: 'Rental Ski',
        field: 'rental_ski',
        type: 'input',
        if: { key: 'equipment', value: 'Area rental' }
      }
    ]
  },
  {
    if: { key: 'equipment', value: 'Area rental' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Rental Wristband',
        field: 'rental_wristband',
        type: 'input'
      }
    ]
  },
  {
    if: { key: 'equipment', value: 'Other rental' },
    cols: [
      // eslint-disable-next-line max-len
      {
        ...DEF_COL_12,
        label: 'Rental Helmet',
        field: 'rental_helmet_number',
        type: 'input',
        if: {
          key: 'helmet',
          value: undefined,
          or: [null, '', 'None', 'Owned', 'Other rental', 'Area demos', 'Other demos', 'Borrowed', 'Unknown', 'N/A']
        }
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Rental Helmet',
        field: 'rental_helmet_number',
        type: 'input',
        if: { key: 'helmet', value: 'Area rental' }
      }
    ]
  },
  {
    if: { key: 'equipment', value: 'Area rental', or: ['Owned'] },
    cols: [
      // eslint-disable-next-line max-len
      {
        ...DEF_COL_3,
        label: 'Left Din Toe',
        field: 'equipment_left_din_toe',
        type: 'input',
        if: { key: 'activity', value: 'Alpine Skiing' }
      },
      // eslint-disable-next-line max-len
      {
        ...DEF_COL_3,
        label: 'Left Din Heel',
        field: 'equipment_left_din_heel',
        type: 'input',
        if: { key: 'activity', value: 'Alpine Skiing' }
      },
      // eslint-disable-next-line max-len
      {
        ...DEF_COL_3,
        label: 'Right Din Toe',
        field: 'equipment_right_din_toe',
        type: 'input',
        if: { key: 'activity', value: 'Alpine Skiing' }
      },
      // eslint-disable-next-line max-len
      {
        ...DEF_COL_3,
        label: 'Right Din Heel',
        field: 'equipment_right_din_heel',
        type: 'input',
        if: { key: 'activity', value: 'Alpine Skiing' }
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Ability',
        field: 'ability',
        type: 'select',
        ...DEF_KEY_VAL_POS_TOP,
        _: 'cwsaa_'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Was Accident The Result of a Collision?',
        field: 'is_collision',
        type: 'checkbox'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      // eslint-disable-next-line max-len
      {
        ...DEF_COL_12,
        label: 'What Was The Collision?',
        field: 'collision_description',
        type: 'textarea',
        if: { key: 'is_collision', value: true }
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Primary Cause',
        field: 'primary_cause',
        type: 'select',
        ...DEF_KEY_VAL
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      // eslint-disable-next-line max-len
      {
        ...DEF_COL_12,
        label: 'Was The Cause Due To Another Reason Not Listed?',
        field: 'primary_cause_other',
        type: 'textarea',
        if: { key: 'primary_cause', value: 'Other' }
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [{ ...DEF_COL_12, label: ' ', field: '', type: 'label' }]
  },
  {
    ...DEF_KEY,
    cols: [{ ...DEF_COL_12, label: ' ', field: '', type: 'label' }]
  },
  {
    ...DEF_KEY,
    cols: [{ ...DEF_COL_12, label: ' ', field: '', type: 'label' }]
  }
];
