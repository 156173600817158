import { AfterViewInit, Component, Input, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad';
import { AlertController, ModalController } from '@ionic/angular';
import { DIAGRAM_EXIT_MSG } from '../../../pages/pages.constants';
import { GlobalService, AttachmentsService } from '@ea-services';
import { DevicePlatform } from '@ea-models-v4/common';
import { AppState } from '@edgeauditor/app/store/app/app.state';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';

@Component({
  selector: 'app-diagram-drawing',
  templateUrl: './diagram-drawing.component.html',
  styleUrls: ['./diagram-drawing.component.scss']
})
export class DiagramDrawingComponent {
  @Input() diagram_id: number;
  @Input() diagram_image: string;
  @Input() url: string;
  @Input() parentChangeDetectorRef: ChangeDetectorRef;
  signaturePad: SignaturePad;
  @ViewChild('_signaturePad') set _signaturePad(sign: any) {
    if (sign && sign.signaturePad) {
      this.signaturePad = sign.signaturePad;
      this.initData();
    }
  }
  canvasStyle: any = {};

  readonly options: Object = {
    minWidth: 5,
    maxWidth: 5,
    penColor: '#ff0000',
    canvasWidth: 350,
    canvasHeight: 300
  };
  readonly colorOptions = [
    { name: 'Red', value: '#ff0000' },
    { name: 'Orange', value: '#ffa500' },
    { name: 'Yellow', value: '#ffff00' },
    { name: 'Green', value: '#008000' },
    { name: 'Blue', value: '#0000FF' },
    { name: 'Purple', value: '#800080' },
    { name: 'Pink', value: '#ffc0cb' },
    { name: 'Brown', value: '#986928' },
    { name: 'Black', value: '#000000' },
    { name: 'White', value: '#ffffff' }
  ];
  readonly sizeOptions = [
    { name: 'Small', value: 2 },
    { name: 'Normal', value: 5 },
    { name: 'Large', value: 10 },
    { name: 'Huge', value: 20 }
  ];
  readonly toolOptions = [
    { name: 'Marker', value: 'marker' },
    { name: 'Eraser', value: 'eraser' }
  ];
  model: {
    tool: 'marker' | 'eraser';
    size: number;
    color: string;
    image: string;
  } = {
    tool: 'marker',
    size: 5,
    color: '#ff0000',
    image: null
  };

  @ViewSelectSnapshot(AppState.getDevicePlatform) devicePlatform: DevicePlatform;

  constructor(
    private modalController: ModalController,
    private alertController: AlertController,
    private globalService: GlobalService,
    private attachmentsService: AttachmentsService
  ) {}

  drawStart() {
    if (this.parentChangeDetectorRef && this.devicePlatform === 'android') this.parentChangeDetectorRef.detach();
  }

  drawComplete() {
    try {
      this.model.image = this.signaturePad.toDataURL();
    } catch {}
    if (this.parentChangeDetectorRef && this.devicePlatform === 'android') this.parentChangeDetectorRef.reattach();
  }

  onToolChange($event) {
    const val = $event ? $event.value : 'marker';
    const ctx = this.signaturePad['_canvas'].getContext('2d');
    ctx.globalCompositeOperation = val === 'eraser' ? 'destination-out' : 'source-over';
  }

  onSizeChange($event) {
    const val = $event ? $event.value : 5;
    this.signaturePad['minWidth'] = val;
    this.signaturePad['maxWidth'] = val;
  }

  onColorChange($event) {
    const val = $event ? $event.value : '#ff0000';
    this.signaturePad['penColor'] = val;
  }

  clear() {
    this.signaturePad.clear();
  }

  save() {
    this.model.image = this.signaturePad.toDataURL();
    this.modalController.dismiss({
      diagram: this.model.image,
      diagram_id: this.diagram_id,
      success: true
    });
  }

  close() {
    this.modalController.dismiss({
      diagram: this.model.image,
      diagram_id: null,
      success: false
    });
  }

  async confirmClose() {
    const alert = await this.alertController.create({
      header: 'Alert',
      message: DIAGRAM_EXIT_MSG,
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          handler: () => {}
        },
        {
          text: 'Yes',
          handler: () => this.close()
        }
      ]
    });

    await alert.present();
  }

  private initData() {
    const body = window.document.body;
    if (body.clientWidth) {
      const size = (body.clientWidth > body.clientHeight ? body.clientHeight - 150 : body.clientWidth) - 10;
      this.signaturePad['_canvas'].width = size;
      this.signaturePad['_canvas'].height = size;
      this.canvasStyle = {
        background: `#ffffff url('${this.url}') no-repeat center/contain`
      };
      if (!this.diagram_image) return;
      if (this.diagram_image && this.diagram_image !== '' && this.diagram_image.indexOf('http') >= 0) {
        this.download(this.diagram_image, size);
      } else {
        this.signaturePad.fromDataURL(this.diagram_image, {
          width: size,
          height: size
        });
      }
    }
  }

  download(url, size) {
    this.attachmentsService.getEncodeAttachmentByURL(this.globalService._currentUser.token, url).subscribe(
      (res) => {
        if (!res || !res.success || !res.data) return;
        this.signaturePad.fromDataURL(`data:image/jpg;base64,${res.data}`, {
          width: size,
          height: size
        });
      },
      (error) => {
        this.signaturePad.fromDataURL(url, { width: size, height: size });
        console.log(error);
      }
    );
  }
}
