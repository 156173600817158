<ng-container *ngIf="object">
  <ion-row class="grid-report-item"
           [ngStyle]="{ 'min-width': minWidthForEachRow + 'px' }">
    <ion-col size="2" class="question-text">{{object.object_name}}</ion-col>
    <ion-col size="2" class="cbx">
      <ion-checkbox *ngIf="section?.save_status"
                    [(ngModel)]="object.is_enabled">
        {{ section.save_label }}
      </ion-checkbox>
    </ion-col>
    <ion-col size=""
             *ngFor="let question of section?.questions; let qIndex = index"
             [class.select]="question.type === ReportQuestionType.select || !!SelectTypes[question.type]"
             [class.cbx]="question.type === ReportQuestionType.checkbox"
             [class.toggle]="question.type === ReportQuestionType.boolean">
      <div #target [attr.data-q-index]="qIndex"></div>
      <ng-container [ngSwitch]="question.type"
                    *ngIf="object['models']?.length > qIndex">
        <ng-container *ngSwitchCase="ReportQuestionType.select">
          <ng-select #ddlSelect
                     [items]="question.options"
                     [selectOnTab]="true"
                     class="standard-select"
                     bindLabel="text"
                     bindValue="text"
                     placeholder="Select {{ question.question }}"
                     [(ngModel)]="object['models'][qIndex]['model'].value"
                     [virtualScroll]="question.options?.length > 20"
                     (close)="isDDLOpen$.next({ event: ddlSelect, is: false })"
                     (open)="isDDLOpen$.next({ event: ddlSelect, is: true })"
                     (change)="onSelectChange($event,'text',question,object['models'][qIndex]['model'],qIndex,object.object_id)"
                     [attr.data-ci]="sectionIndex + '' + objectIndex + '' + qIndex">
          </ng-select>
        </ng-container>
        <ng-container *ngSwitchCase="ReportQuestionType.signature">
          <app-signature (signatureChanged)="onSignatureChange($event,question,object['models'][qIndex]['model'],qIndex,object.object_id)"
                         [signatureEvent]="object['models'][qIndex]['signSubject'].asObservable()"
                         [parentChangeDetectorRef]="parentChangeDetectorRef"
                         (clear)="onSignatureChange('',question,object['models'][qIndex]['model'],qIndex,object.object_id,true)"
                         [attr.data-ci]="sectionIndex + '' + objectIndex + '' + qIndex"
                         [isRequired]="question.required"></app-signature>
        </ng-container>
        <ng-container *ngSwitchCase="ReportQuestionType.image">
          <app-image-picker [isSupportResizePhoto]="isSupportResizePhoto"
                            [showWarningResizePhoto]="showWarningResizePhoto"
                            [newToRight]="true"
                            [attr.data-ci]="sectionIndex + '' + objectIndex + '' + qIndex"
                            (imageSelected)="onImageChange($event,question,object['models'][qIndex]['model'],qIndex,object.object_id)"
                            [restoreImagesEvent]="object['models'][qIndex]['imagesSubject'].asObservable()"></app-image-picker>
        </ng-container>
        <ng-container *ngSwitchCase="ReportQuestionType.diagram">
          <app-diagram (modelChanged)="onDiagramChange($event,question,object['models'][qIndex]['model'],qIndex,object.object_id)"
                       [userEvent]="userEvent"
                       [diagramEvent]="object['models'][qIndex]['diagramSubject'].asObservable()"
                       [isSupportResizePhoto]="isSupportResizePhoto"
                       [showWarningResizePhoto]="showWarningResizePhoto"
                       [parentChangeDetectorRef]="parentChangeDetectorRef"
                       [attr.data-ci]="sectionIndex + '' + objectIndex + '' + qIndex">
          </app-diagram>
        </ng-container>
        <ng-container *ngSwitchCase="ReportQuestionType.multi_select">
          <ng-select bindLabel="text"
                     bindValue="id"
                     [items]="question.options"
                     [(ngModel)]="object['models'][qIndex]['model'].multi_values"
                     [multiple]="true"
                     [selectOnTab]="true"
                     [virtualScroll]="question.options?.length > 20"
                     [class.invalid]="question.required && (!object['models'][qIndex]['model'].multi_values || object['models'][qIndex]['model'].multi_values.length === 0)"
                     (change)="onSelectChange($event,'text',question,object['models'][qIndex]['model'],qIndex,object.object_id, true)"></ng-select>
        </ng-container>
        <ng-container *ngSwitchCase="DateTimeTypes.includes(question.type) ? question.type : ''">
          <input readonly
                 class="standard-input"
                 [owlDateTime]="dt1"
                 [owlDateTimeTrigger]="dt1"
                 [ngModel]="object['models'][qIndex]['model'][question.type]"
                 [attr.data-ci]="sectionIndex + '' + objectIndex + '' + qIndex"
                 (dateTimeChange)="onChange($event.value,question.type === ReportQuestionType.date ? ReportQuestionType.date : ReportQuestionType.time,question,object['models'][qIndex]['model'],qIndex,object.object_id)" />
          <owl-date-time [pickerType]="question.type === ReportQuestionType.date ? 'calendar' : 'timer'"
                         #dt1></owl-date-time>
        </ng-container>
        <ng-container *ngSwitchCase="SelectTypes[question.type] ? question.type : ''">
          <ng-container *ngIf="object['models'][qIndex]['currentUser']">
            <ng-select #ddlSelect
                       [items]="object['models'][qIndex]['currentUser'][selectsMapping[question.type]]"
                       [selectOnTab]="true"
                       class="standard-select"
                       bindLabel="first_name"
                       bindValue="name"
                       placeholder="Select {{ question.question }}"
                       [attr.data-ci]="sectionIndex + '' + objectIndex + '' + qIndex"
                       (close)="isDDLOpen$.next({ event: ddlSelect, is: false })"
                       (open)="isDDLOpen$.next({ event: ddlSelect, is: true })"
                       [virtualScroll]="object['models'][qIndex]['currentUser'][selectsMapping[question.type]]?.length > 20"
                       [(ngModel)]="object['models'][qIndex]['model'].value"
                       (change)="onSelectChange($event,'id',question,object['models'][qIndex]['model'],qIndex,object.object_id)">
              <ng-template ng-label-tmp let-item="item">
                <span>{{ item.first_name }} {{ item.last_name }}</span>
              </ng-template>
              <ng-template ng-option-tmp
                           let-item="item"
                           let-search="searchTerm"
                           let-index="index">
                <span>{{ item.first_name }} {{ item.last_name }}</span>
              </ng-template>
            </ng-select>
          </ng-container>
        </ng-container>
      </ng-container>
    </ion-col>
  </ion-row>
</ng-container>