// import { AngularFireModule } from '@angular/fire';
// import { AngularFireDatabaseModule } from '@angular/fire/database';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule, Injectable, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { File as NgxFile } from '@awesome-cordova-plugins/file/ngx';
import { NgIdleModule } from '@ng-idle/core';
import { RouteReuseStrategy } from '@angular/router';
import { MatNativeDateModule } from '@angular/material/core';
import { TreeModule } from '@circlon/angular-tree-component';
import { NgxImageCompressService } from 'ngx-image-compress';
import { HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';
import { Deploy } from 'cordova-plugin-ionic/dist/ngx';
import { NgxActioncableService } from '@csventures/ngx-actioncable';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsSelectSnapshotModule } from '@ngxs-labs/select-snapshot';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { HealthAndSafetyModule } from '../services/health-and-safety/health-and-safety.module';
import { AppComponent } from './app.component';
import { EAServicesModule } from '../services/services.module';
import { LiftModule } from '../services/lift/lift.module';
import { AppRoutingModule } from './app-routing.module';
import { EAComponentsModule } from '../components/components.module';
import { SentryErrorHandler } from './sentry-error-handler';
import { StoreModule } from './store/store.module';
import { SharedModule } from './components/shared/shared.module';
import { HeaderInterceptor } from '@ea-interceptors-v4';
import { CachedImageModule } from './components/cached-image/cached-image.module';
import { environment } from '../environments/environment';

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides: _.Dictionary<object> = {
    pinch: { enable: false },
    rotate: { enable: false }
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    NgIdleModule.forRoot(),
    HttpClientModule,
    EAServicesModule.forRoot(),
    EAComponentsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    // AngularFireModule.initializeApp(
    //   environment.environment === 'production' ? FIREBASE_PRODUCTION_SETTINGS : FIREBASE_STAGING_SETTINGS
    // ),
    // AngularFireDatabaseModule,
    MatNativeDateModule,
    TreeModule,
    LiftModule,
    HealthAndSafetyModule,
    NgxsLoggerPluginModule.forRoot({
      disabled: environment.isDeployedBuild
    }),
    NgxsSelectSnapshotModule.forRoot(),
    StoreModule,
    SharedModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    CachedImageModule
  ],
  entryComponents: [AppComponent],
  providers: [
    FileTransfer,
    NgxFile,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    },
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler
    },
    NgxImageCompressService,
    Deploy,
    NgxActioncableService,
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
