import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Network } from '@capacitor/network';
import { Device } from '@capacitor/device';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar } from '@capacitor/status-bar';
import { Platform } from '@ionic/angular';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { AppState } from './store/app/app.state';
import { Store } from '@ngxs/store';

import { DevicePlatform } from '@ea-models-v4/common';
import { NetworkStatusChange, SetDeviceInfo } from './store/app/app.action';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { DASHBOARD_MODULES } from '@edgeauditor/app/constants';
import { Observable, defer, of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { NgSelectConfig } from '@ng-select/ng-select';
import { Capacitor } from '@capacitor/core';

export const IMAGE_FOLDER = 'IMAGES';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewSelectSnapshot(AppState.getIsMobilePlatform) readonly isMobilePlatform: boolean;
  @ViewSelectSnapshot(AppState.getDevicePlatform) readonly devicePlatform: DevicePlatform;

  currentUser: any;
  isOnBrowser: boolean;
  isOnAndroid: boolean;
  isIpad: boolean;

  readonly preloadIcons: Readonly<string[]> = [
    'arrow-back-outline',
    'pulse',
    'share',
    'cloud-upload',
    'close',
    'menu-sharp',
    'cloud-offline-outline',
    'calendar',
    'school',
    'people'
  ];
  readonly preloadImages: Readonly<string[]> = [...DASHBOARD_MODULES.map((i) => i.image), 'assets/image/logo-02.svg'];

  constructor(private platform: Platform, private store: Store, private readonly config: NgSelectConfig) {
    if (Capacitor.getPlatform() !== 'ios') {
      this.config.appendTo = 'body';
    }
  }

  async ngOnInit(): Promise<void> {
    this.platform.ready().then(async () => {
      if (SplashScreen) {
        setTimeout(() => {
          SplashScreen.hide();
        }, 1000);
      }
      // Redirect to homepage if there's no current user set
      this.isOnBrowser = !this.isMobilePlatform;
      this.isOnAndroid = this.devicePlatform === 'android';
      this.isIpad = this.devicePlatform === 'ios';

      this.platform.resume.subscribe(() => {
        if (this.isMobilePlatform) {
          StatusBar.show();
        }
      });

      //Initial network check
      const networkStatus = await Network.getStatus();
      this.store.dispatch(new NetworkStatusChange(networkStatus));

      const deviceInfo = await Device.getInfo();
      this.store.dispatch(new SetDeviceInfo(deviceInfo));

      //Ongoing network check
      Network.addListener('networkStatusChange', (status) => {
        this.store.dispatch(new NetworkStatusChange(status));
      });

      if (this.isOnBrowser) {
        this.createCacheFolderOnBrowser();
      }
    });
  }

  ngAfterViewInit(): void {
    const element = document.getElementById('ion-preload-components');
    element.remove();
  }

  private async createCacheFolderOnBrowser() {
    const hasCreatedFolder: boolean = !!(await Filesystem.readdir({
      directory: Directory.Cache,
      path: IMAGE_FOLDER
    }));

    if (!hasCreatedFolder) {
      await this.createCacheFolder();
    }
  }

  private async createCacheFolder() {
    const createCacheFolder$: Observable<void> = defer(() =>
      Filesystem.mkdir({
        directory: Directory.Cache,
        path: IMAGE_FOLDER
      })
    ).pipe(
      take(1),
      catchError((_e) => of(null))
    );

    try {
      const permissions = await Filesystem.checkPermissions();
      const { publicStorage } = permissions;
      if (publicStorage === 'granted') {
        createCacheFolder$.subscribe();
      } else if (publicStorage === 'denied') {
        alert('Invalid Permission');
      } else {
        Filesystem.requestPermissions()
          .then((result) => {
            if (result.publicStorage === 'granted') {
              createCacheFolder$.subscribe();
            }
          })
          .catch(() => alert('Invalid Permission'));
      }
    } catch (exception) {
      console.log('Filesystem.checkPermissions error: ', exception);
    }
  }
}
