import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { PendingAndRejectedTotals } from '@ea-models-v4/location';
import { AuthState } from '@edgeauditor/app/store/auth/auth.state';
import { ConfigService } from '../../services/config.service';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  constructor(private http: HttpClient, private configService: ConfigService, private store: Store) {}

  getPendingAndRejectedTotals(): Observable<PendingAndRejectedTotals> {
    //TODO: Move to interceptor when fully moved to API v4
    const locationId = this.store.selectSnapshot(AuthState.getLocationId);

    return this.http.get<PendingAndRejectedTotals>(
      this.configService.getBaseUrl(4) + `locations/${locationId}/pending_and_rejected`
    );
  }
}
