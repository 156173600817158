import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ConfigService } from '../config.service';
import { GlobalService } from '@ea-services';
import {
  Incident,
  InProgressIncidentReport,
  NemsisReportDetail,
  OtherReport,
  OtherReportAnswer,
  RepReport
} from '@ea-models-v4';
import { DeclinedAccidentReport, DeclinedReport } from '@ea-models-v4/rejected-report.model';
import { IDynamicComponentModel, IReport } from '@ea-models';

interface SearchIncidentResponse {
  accidents: InProgressIncidentReport[];
  accidents_found: number;
  success: boolean;
}

export interface GetReportResponse {
  declined: DeclinedReport[];
  declined_accident_reports: DeclinedAccidentReport[];
  in_progress_accident_reports: number;
  in_progress_reports: number;
  reports: IReport[];
  rejected_nemsis_reports: NemsisReportDetail[];
}

export interface SubmitReportResponse {
  submission_id: number;
  success: boolean;
}

type GetDeclinedReportResponse = Omit<GetReportResponse, 'reports'>;

export interface RtpCustomer {
  UniquePersonId?: string;
  uniquePersonId?: string;
  PrimaryPassId: string;
  FirstName: string;
  LastName: string;
  Email: string;
  ContactNumber: string;
  TypeOfPerson: number;
  PersonDOB: string;
  Medication: string;
  FoodAllergy: string;
  DrugAllergy: string;
  SpecialCondition: string;
  PrimaryEmergencyContact: string;
  PrimaryEmergencyPhone: string;
  AlternateEmergencyContact1: string;
  AlternateEmergencyPhone1: string;
  AlternateEmergencyContact2: string;
  AlternateEmergencyPhone2: string;
  EmergencyContactInfoSource: string;
  address: string;
  city: string;
  state_or_province: string;
  country: string;
  zip_or_postal_code: string;
}

interface GetRtpCustomerLookupResponse {
  responseData: RtpCustomer[];
  statusCode: number;
  statusMessage: string;
}

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  reports: IReport[];
  declinedReports: DeclinedReport[];
  declinedIncidents: DeclinedAccidentReport[];
  reportDiagrams: _.Dictionary<any> = {};
  currReportSections = [];
  currAdditionalReportAttributes = null;
  currReportModel: IDynamicComponentModel[][] = [];
  currLinkedIncident;
  ableToUpdateReport = false;
  closedReport = false;
  backURL: string;

  constructor(
    private httpClient: HttpClient,
    public configService: ConfigService,
    private globalService: GlobalService
  ) {}

  flattenDeep = (arr, d = 1) =>
    d > 0
      ? [...arr].reduce((acc, val) => acc.concat(Array.isArray(val) ? this.flattenDeep(val, d - 1) : val), [])
      : [...arr];

  getReports(token: string): Observable<GetReportResponse> {
    return this.httpClient.get<GetReportResponse>(`${this.configService.apiURL()}reports`, { params: { token } });
  }

  getDeclinedReports(token: string): Observable<GetDeclinedReportResponse> {
    return this.httpClient.get<GetDeclinedReportResponse>(`${this.configService.apiURL()}reports`, {
      params: { declined: true, token }
    });
  }

  getCustomerByBarCode(barcode: string, token: string): Observable<GetRtpCustomerLookupResponse> {
    return this.httpClient.get<GetRtpCustomerLookupResponse>(
      `${this.configService.apiURLv3()}rtp_customer_lookup?token=${token}&barcode=${barcode}`
    );
  }

  public getDiagrams(token: string): Observable<any> {
    if (this.reportDiagrams[token]) {
      return of(this.reportDiagrams[token]);
    }
    return this.httpClient.get<any>(`${this.configService.apiURL()}diagrams?token=${token}`);
  }

  public submitReport(token: string, report: RepReport): Observable<SubmitReportResponse> {
    return this.httpClient.post<SubmitReportResponse>(
      `${this.configService.apiURL()}report_submission?token=${token}`,
      report
    );
  }

  public getSubmissionsList(token: string, reportId: number, filter_date?: string): Observable<any> {
    return this.httpClient.get<any>(
      `${this.configService.apiURL()}get_submissions_list?token=${token}&id=${reportId}&filter_date=${
        filter_date || ''
      }`
    );
  }

  public getSubmissionData(token: string, submissionId: number): Observable<any> {
    return this.httpClient.get<any>(
      `${this.configService.apiURL()}get_submissions_data?token=${token}&id=${submissionId}`
    );
  }

  public submitAnswer(
    token: string,
    answer: { answer: IDynamicComponentModel }
  ): Observable<{ answer_id: number; success: boolean }> {
    return this.httpClient.post<{ answer_id: number; success: boolean }>(
      `${this.configService.apiURL()}report_answers?token=${token}`,
      answer
    );
  }

  public searchIncident(searchParams: {
    accident_number?: number;
    last_name?: string;
  }): Observable<SearchIncidentResponse> {
    const token = this.globalService._currentUser.token;
    return this.httpClient.post<SearchIncidentResponse>(`${this.configService.apiURL()}accident_search`, searchParams, {
      params: { token }
    });
  }

  public submitIncident(token: string, report: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.configService.apiURLv3()}incidents/submit_incident?token=${token}`,
      report
    );
  }

  public updateIncident(token: string, report: any): Observable<any> {
    return this.httpClient.put<any>(
      `${this.configService.apiURLv3()}incidents/${report.accident_report.id}?token=${token}`,
      report
    );
  }

  public getPendingIncidentReports(token: string): Observable<any> {
    return this.httpClient.get<any>(`${this.configService.apiURL()}pending_accidents?token=${token}`);
  }

  public getInProgressReports(token: string): Observable<any> {
    return this.httpClient.get<any>(`${this.configService.apiURL()}submissions/available?token=${token}`);
  }

  public getIncidentReport(token: string, reportId: number): Observable<Incident> {
    return this.httpClient.get<Incident>(`${this.configService.apiURLv3()}incidents/${reportId}?token=${token}`);
  }

  public getNewIncidentNumber(token: string): Observable<{ report_number: number }> {
    return this.httpClient.get<{ report_number: number }>(
      `${this.configService.apiURL()}incident_count.json?token=${token}`
    );
  }

  public cleanUpTempData() {
    this.reports = null;
    this.declinedReports = null;
    this.declinedIncidents = null;
    this.reportDiagrams = {};
    this.currReportSections = [];
  }

  public resetDataForContext() {
    this.currReportSections = [];
    this.currReportModel = [];
    this.currAdditionalReportAttributes = null;
    this.ableToUpdateReport = false;
    this.closedReport = false;
    this.backURL = null;
    this.currLinkedIncident = null;
  }
}
