<ea-header [hasCloseButton]="true" [title]="assignment?.title + (assignment?.title && assignment?.area ? ' - ' : '') + assignment?.area"></ea-header>

<ion-content class="groomer-assignment-container" [class.full]="tabId === 1">
  <div class="section-component__body">
    <div class="tab">
      <div class="tab-item" [class.active]="tabId === 2" (click)="tabId = 2">
        Log Progress
      </div>
      <div class="tab-item" [class.active]="tabId === 1" (click)="tabId = 1">
        Details
      </div>
    </div>
    <!-- detail -->
    <ion-grid class="tab-content" [hidden]="tabId !== 1">
      <ion-row>
        <div class="priority">
          {{
            assignment.priority
              ? 'Priority ' + assignment.priority
              : 'No Priority'
          }}
        </div>
        <div class="assigned">{{ params?.activity_type }}</div>
      </ion-row>
      <ion-row>
        <div class="priority">Notes</div>
        <div class="assigned">{{ assignment.note }}</div>
      </ion-row>
      <ion-row class="no-border">
        <div class="priority">Operator logs</div>
        <div class="log-history">
          <div class="log-header">
            <div class="col">name</div>
            <div class="col">% completed</div>
            <div class="col">Time Spent</div>
            <div class="col col-3">notes</div>
          </div>
          <div class="log-item" *ngFor="let log of params?.logs">
            <div class="col">{{ log.user_name }}</div>
            <div class="col">
              <div class="completed">
                <ng-container
                  *ngIf="!isSetProgressCase(assignment.status, log)"
                >
                  <div class="line">
                    <div
                      class="in-line"
                      [style.width]="(log.amount_completed || 0) + '%'"
                    ></div>
                  </div>
                  <div>
                    {{ (log.overridden ? 100 : 0) + log.amount_completed }}%
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="col">
              <ng-container
                *ngIf="
                  !isSetProgressCase(assignment.status, log) &&
                  !isSetCompletedCase(assignment.status, log)
                "
              >
                {{ log.time_in_hours || 0 }}h {{ log.time_in_minutes || 0 }}m
              </ng-container>
            </div>
            <div class="col col-3">
              {{ log.comments }}
            </div>
          </div>
        </div>
      </ion-row>
    </ion-grid>

    <!-- log progress -->
    <ion-grid class="tab-content" [hidden]="tabId !== 2">
      <ion-row>
        <div class="title">% Completed</div>
        <div
          class="slide-container end"
          [class.end]="!!dragEvent?.end"
          [attr.data-complete]="amountComplete"
        >
          <div class="slide-control" #sliderControl>
            <div class="line">
              <div
                class="in-line"
                [style.width]="(dragEvent?.x || 0) + 'px'"
              ></div>
            </div>
            <div
              class="circle trans"
              [ngStyle]="{ left: (dragEvent?.x || 0) + 'px' }"
              #sliderElement
              [draggable]="true"
              (drag)="logDrag($event)"
              (dragend)="logDrag($event, true)"
            ></div>
            <div
              class="circle"
              [ngStyle]="{ left: (dragEvent?.x || 0) + 'px' }"
            ></div>
          </div>
          <div
            class="slide-limit"
            *ngIf="shouldLimitComplete"
            [ngStyle]="limitWidth"
          >
            <div class="slide-control">
              <div class="line"><div class="in-line"></div></div>
              <div
                class="circle"
                *ngIf="!dragEvent?.end && currentAmountComplete > limitComplete"
              ></div>
            </div>
            <div class="percentage-container"></div>
          </div>
          <div class="percentage-container">
            <span
              class="percentage"
              (click)="setCompleted(item)"
              *ngFor="let item of percentageCompletes"
              >{{ item }}</span
            >
          </div>
        </div>
        <div class="overridden" *ngIf="setOverridden || alreadySetOverridden">
          <div class="content">
            <div class="small-title">% complete overridden</div>
            <div class="msg">
              This area has already been groomed to 100%. Additional progress
              will be added to the total.
            </div>
          </div>
        </div>
        <div
          class="set-overridden"
          *ngIf="!setOverridden && !alreadySetOverridden"
        >
          <button
            *ngIf="
              !setOverridden &&
              (totalFinalComplete >= 100 ||
                assignment?.log?.amount_completed >= 100)
            "
            class="btn-set-overridden no-ripple"
            (click)="onSetOverridden(true)"
          >
            Override percentage
          </button>
          <div
            *ngIf="
              totalFinalComplete >= 100 ||
              model.amount_completed >= 100 ||
              assignment?.log?.amount_completed >= 100
            "
          >
            This area can be groomed in excess of 100%.
          </div>
        </div>
      </ion-row>
      <!-- hide until there is new update -->
      <!-- <ion-row>
        <div class="title">Time Completed</div>
        <div class="time-controls">
          <div class="time-control">
            <div class="up" [class.disabled]="time.h_complete==23" (click)="onChangeTime(time.h_complete+1, 'h_complete', 24)"></div>
            <div class="time">
              <ion-input [ngModel]="time.h_complete | number:'2.00'" min="0" max="23" (ionChange)="onChangeTime($event.target.value, 'h_complete', 23)">
              </ion-input>
            </div>
            <div class="down" [class.disabled]="time.h_complete<=1" (click)="onChangeTime(time.h_complete-1, 'h_complete', 23, 1)"></div>
          </div>
          <div class="sep">:</div>
          <div class="time-control">
            <div class="up" [class.disabled]="time.m_complete==59" (click)="onChangeTime(time.m_complete+1, 'm_complete', 59)"></div>
            <div class="time">
              <ion-input [ngModel]="time.m_complete | number:'2.00'" min="0" max="59" (ionChange)="onChangeTime($event.target.value, 'm_complete', 59)">
              </ion-input>
            </div>
            <div class="down" [class.disabled]="time.m_complete<=1" (click)="onChangeTime(time.m_complete-1, 'm_complete', 59, 1)"></div>
          </div>
          <div class="sep">
            <div class="sync-to-now" (click)="setNowTime()">Set time to now</div>
          </div>
        </div>
      </ion-row> -->
      <ion-row>
        <div class="title">Time Spent on Activity</div>
        <div class="control">
          <div class="time-controls">
            <div class="time-control">
              <div
                class="up"
                (click)="onChangeTime(null, +time.h_spent + 1, 'h_spent')"
              ></div>
              <div class="time">
                <ion-input
                  [ngModel]="time.h_spent | number: '2.00'"
                  min="0"
                  step="1"
                  type="{{ isMobilePlatform ? 'number' : 'text' }}"
                  (ionChange)="
                  onChangeTimeEvent($event, $event, 'h_spent')
                  "
                >
                </ion-input>
              </div>
              <div
                class="down"
                [class.disabled]="time.h_spent <= 0"
                (click)="
                  onChangeTime(null, +time.h_spent - 1, 'h_spent', null, 0)
                "
              ></div>
            </div>
            <div class="sep m-r-20">Hours</div>
            <div class="time-control">
              <div
                class="up"
                [class.disabled]="time.m_spent == 59"
                (click)="onChangeTime(null, +time.m_spent + 1, 'm_spent', 59)"
              ></div>
              <div class="time">
                <ion-input
                  [ngModel]="time.m_spent | number: '2.00'"
                  min="0"
                  max="59"
                  step="1"
                  type="{{isMobilePlatform ? 'number' : 'text'}}"
                  (ionChange)="
                    onChangeTimeEvent($event, $event, 'm_spent', 59)
                  "
                >
                </ion-input>
              </div>
              <div
                class="down"
                [class.disabled]="time.m_spent <= 0"
                (click)="
                  onChangeTime(null, +time.m_spent - 1, 'm_spent', null, 0)
                "
              ></div>
            </div>
            <div class="sep">Mins</div>
          </div>
          <div class="add-controls">
            <div (click)="addTimeSpent(5)">+ 5 mins</div>
            <div (click)="addTimeSpent(10)">+ 10 mins</div>
            <div (click)="addTimeSpent(30)">+ 30 mins</div>
          </div>
        </div>
      </ion-row>
      <ion-row class="no-border">
        <div class="title">Status</div>
        <div class="control cbx" *ngFor="let item of status">
          <div>{{ item.value }}</div>
          <ion-checkbox
            [disabled]="item.selected"
            [checked]="item.selected"
            (click)="changeStatus(item)"
          ></ion-checkbox>
        </div>
      </ion-row>
      <ion-row class="no-border">
        <div class="title">Comments</div>
        <div class="control">
          <ion-textarea debounce="400" rows="5" [(ngModel)]="model.comments">
          </ion-textarea>
        </div>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>
<div class="btn-groomer-assignment-container" [hidden]="tabId === 1">
  <button
    class="btn-success no-ripple"
    [disabled]="!allowSave"
    (click)="save()"
  >
    Save Progress
  </button>
</div>
<div class="successful-result-container" *ngIf="isShowWarning">
  <div class="over-lay"></div>
  <div class="successful-result-content">
    <!-- result -->
    <div class="successful-result" *ngIf="isSuccessful">
      <img
        src="/assets/image/black-close.svg"
        width="20px"
        (click)="close(true)"
      />
      <div class="result-container">
        <div class="result">
          <div>
            <img src="/assets/image/alert-circle-outline.svg" width="52px" />
          </div>
          <div class="large-text">Success</div>
          <div>Log Progress Submitted Successfully.</div>
          <div class="btn">
            <button class="btn-close no-ripple" (click)="close(true)">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- warning -->
    <div class="successful-result" *ngIf="!isSuccessful && askBeforeLeaving">
      <div class="result-container">
        <div class="result">
          <div>
            <img src="/assets/image/warning-circle-outline.svg" width="52px" />
          </div>
          <div class="large-text">Heads Up! You have unsaved changes.</div>
          <div>
            If you exit any changes made since the last save will be lost.
          </div>
          <div class="btn">
            <button
              class="btn-never-mind no-ripple"
              (click)="isShowWarning = false"
            >
              Never mind, stay on this page
            </button>
            <button class="btn-exit no-ripple" (click)="close(null, true)">
              Exit and Don’t Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
