import { Injectable } from '@angular/core';
import {
  FileTransfer,
  FileUploadOptions,
  FileTransferObject,
  FileUploadResult
} from '@awesome-cordova-plugins/file-transfer/ngx';
import { File as NgxFile } from '@awesome-cordova-plugins/file/ngx';
import { from, Observable, forkJoin } from 'rxjs';
import { map, delay } from 'rxjs/operators';
import { GlobalService } from './global.service';
import _ from 'lodash';
import { SafetyMeetingImageAttribute, SafetyMeetingPostData } from '@ea-models';
class UtilityService {
  fileKey = '';

  private appendFormData(formData, data: Blob | NgxFile | any[] | Object, rootName: string) {
    const root = rootName || '';
    if (data instanceof Blob) {
      formData[root] = data;
    } else if (data instanceof NgxFile) {
      formData[root] = data;
    } else if (Array.isArray(data)) {
      for (let i = 0; i < data.length; i++) {
        this.appendFormData(formData, data[i], root + '[' + i + ']');
      }
    } else if (typeof data === 'object' && data) {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          if (root === '') {
            this.appendFormData(formData, data[key], key);
          } else {
            const newRoot = root + '[' + key + ']';
            if (key == 'file') {
              this.fileKey = newRoot;
            }
            this.appendFormData(formData, data[key], newRoot);
          }
        }
      }
    } else {
      if (data !== null && typeof data !== 'undefined') {
        formData[root] = data;
      }
    }
  }

  getFormDataFromObj(data) {
    const formData = {};
    this.appendFormData(formData, data, '');
    return { objPathMap: formData, fileKey: this.fileKey };
  }
}

@Injectable({
  providedIn: 'root'
})
export class FileUploader {
  constructor(private fileTransfer: FileTransfer, private gbService: GlobalService) {}

  submitAllImages(
    endPointUrl: string,
    _dataObj: SafetyMeetingImageAttribute | SafetyMeetingPostData,
    _endPointObject: string,
    filesAttributeKey: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const observables: Observable<FileUploadResult>[] = [];
      const image_list: any[] = _.cloneDeep(_dataObj[filesAttributeKey]);
      const token = this.gbService._currentUser.token;
      return from(image_list)
        .pipe(
          delay(1000),
          map((attachment) => {
            const tempDataObj = _.cloneDeep(_dataObj);
            const filePath = attachment.filePath;
            const fileName = attachment.fileName;
            const ob = new UtilityService();
            tempDataObj[filesAttributeKey] = [attachment];
            tempDataObj[_endPointObject] = _.cloneDeep(tempDataObj);
            const { objPathMap, fileKey } = ob.getFormDataFromObj(tempDataObj);
            observables.push(this.uploadImage(endPointUrl, token, objPathMap, fileKey, fileName, filePath));
            return observables;
          })
        )
        .toPromise()
        .then((_res) => {
          forkJoin(observables).subscribe((responses) => {
            const errorFileNames: Array<string> = [];
            let validResponse = false;
            let error: any = {};
            let apiResponse: _.Dictionary<any> = {};
            responses.forEach((response, index: number) => {
              apiResponse = response.response ? JSON.parse(response.response) : {};
              if (response.responseCode == 200 && apiResponse.id > 0) {
                validResponse = true;
              } else {
                error = response;
                errorFileNames.push(image_list[index].fileName);
              }
            });
            if (validResponse) {
              resolve(apiResponse);
            } else {
              error['errorMsg'] = `File upload failed for image(s) "${errorFileNames.join(',')}"`;
              console.log('file uploader error', error['errorMsg']);
              reject(error);
            }
          });
        });
    });
  }

  private uploadImage(
    endPointUrl: string,
    token: string,
    params: _.Dictionary<any>,
    fileKey: string,
    fileName: string,
    filePath: string
  ): Observable<FileUploadResult> {
    const options: FileUploadOptions = {
      fileKey,
      fileName,
      mimeType: 'image/jpeg',
      params
    };
    return this.imageUpload(`${endPointUrl}?token=${token}`, filePath, options);
  }

  private imageUpload(url: string, fileURL: string, options: FileUploadOptions): Observable<FileUploadResult> {
    return this.upload(url, fileURL, options);
  }

  private upload(url: string, fileUrl: string, options: FileUploadOptions): Observable<FileUploadResult> {
    const fileTransfer: FileTransferObject = this.fileTransfer.create();
    return from(fileTransfer.upload(fileUrl, url, options));
  }
}
