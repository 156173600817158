<ion-menu side="end"
          menuId="user"
          type="overlay"
          contentId="main"
          swipeGesture="false">
  <ion-header>
    <ion-toolbar mode="md"
                 color="main">
      <ion-title>Settings</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <ion-list>
      <ion-item button
                class="menu-list-btn"
                *ngIf="allowPasswordChange"
                (click)="changePassword()">
        <ion-label>Change Password</ion-label>
        <ion-icon name="key-outline"></ion-icon>
      </ion-item>
      <ion-item button
                class="menu-list-btn"
                (click)="logout()">
        <ion-label>Log Out</ion-label>
        <ion-icon name="exit-outline"></ion-icon>
      </ion-item>
    </ion-list>
  </ion-content>
  <ion-footer>
    <ion-toolbar mode="md"
                 color="main">
      <ion-title class="version">Version: {{ version }}</ion-title>
    </ion-toolbar>
  </ion-footer>
</ion-menu>
