<ion-grid [hidden]="hidePreview" *ngIf="attachments?.length">
  <ion-row class="imgs-preview">
    <ion-col *ngFor="let attachment of attachments; let idx = index">
      <!--<ion-icon class="close-button" name="close" (click)="removeImage(idx)"></ion-icon>-->
      <img class="image-gallery-img"
           [src]="attachment?.object_attachment.file"
           (click)="preview(attachment, idx)"
           alt="" />
    </ion-col>
  </ion-row>
</ion-grid>