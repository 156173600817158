import { NgModule } from '@angular/core';
import { environment } from '@edgeauditor/environments/environment';
import { NgxsModule } from '@ngxs/store';
import { AppState } from './app';
import { AuthState } from './auth';
import { DispatchState } from './dispatch';
import { ErrorState } from './error';
import { IncidentReportState } from './incident-report';
import { NemsisState } from './nemsis/nemsis.state';
import { OtherReportState } from './other-report';
import { RejectedReportState } from './rejected-report';
import { ReportsState } from './reports';

const STORE_STATES = [
  AppState,
  AuthState,
  DispatchState,
  ErrorState,
  NemsisState,
  IncidentReportState,
  OtherReportState,
  RejectedReportState,
  ReportsState
];

@NgModule({
  imports: [
    NgxsModule.forRoot(STORE_STATES, {
      developmentMode: !environment.isDeployedBuild
    })
  ]
})
export class StoreModule {}
