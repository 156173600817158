import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { SignaturePadModal } from './signature-pad';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { EAComponentsModule } from '@edgeauditor/components/components.module';
import { SharedModule } from '@edgeauditor/app/components/shared/shared.module';

@NgModule({
  declarations: [SignaturePadModal],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    MatDatepickerModule,
    EAComponentsModule,
    SharedModule
  ],
  entryComponents: [SignaturePadModal]
})
export class SignaturePadModalModule {}
