<ea-header title="Log Fuel" [hasCloseButton]="true"></ea-header>

<ion-content class="groomer-fuel-log-container">
  <ion-grid class="section-component__body" style="width: 100%">
    <ion-row>
      <div class="title cbx">Fuel Up Location</div>
      <div class="control cbx" *ngFor="let item of fuelLocations">
        <div>{{ item.name }}</div>
        <ion-checkbox
                      [disabled]="item.selected"
                      [checked]="item.selected"
                      (click)="onChange(item, 'fuel_location_id', fuelLocations)"></ion-checkbox>
      </div>
    </ion-row>
    <ion-row>
      <div class="title cbx">Department</div>
      <div class="control cbx" *ngFor="let item of departments">
        <div>{{ item.name }}</div>
        <ion-checkbox
                      [disabled]="item.selected"
                      [checked]="item.selected"
                      (click)="onChange(item, 'department_id', departments)"></ion-checkbox>
      </div>
    </ion-row>
    <ion-row>
      <div class="title">Fuel Used</div>
      <div class="control">
        <input type="number"
               inputmode="numeric"
               class="input number"
               [(ngModel)]="model.fuel_used"
               (keydown)="utility.NumbersOnly($event, 'fuel_used', model)" />
        <!-- <ion-input [(ngModel)]="model.fuel_used" class="input" type="{{globalService.isMobile ? 'number' : 'text'}}"
                    (ionChange)="onTextChange($event.target.value, 'fuel_used')">
        </ion-input> -->
      </div>
    </ion-row>
    <ion-row>
      <div class="title">Hour Meter on Machine</div>
      <div class="control">
        <input
               type="number"
               inputmode="numeric"
               class="input number"
               [(ngModel)]="model.hour_meter"
               (keydown)="utility.NumbersOnly($event, 'hour_meter', model)" />
        <!-- <ion-input [(ngModel)]="model.hour_meter" class="input" type="{{globalService.isMobile ? 'number' : 'text'}}"
                    (ionChange)="onTextChange($event.target.value, 'hour_meter')">
        </ion-input> -->
      </div>
    </ion-row>
    <!-- <ion-row>
      <div class="title">Comments</div>
      <div class="control">
        <ion-textarea debounce="400" rows="5" [(ngModel)]="model.notes">
        </ion-textarea>
      </div>
    </ion-row> -->
  </ion-grid>
</ion-content>
<div class="btn-groomer-fuel-log-container">
  <button class="btn-cancel no-ripple" (click)="reset()">Clear Fuel Log</button>
  <button
          class="btn-success no-ripple"
          [disabled]="!allowSave"
          (click)="save()">
    Save Fuel Log
  </button>
</div>