import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
  ChangeDetectorRef,
  OnDestroy
} from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad';
import { Observable, of } from 'rxjs';
import { take, tap, delay } from 'rxjs/operators';
import { DevicePlatform } from '@ea-models-v4/common';
import { AppState } from '@edgeauditor/app/store/app/app.state';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss']
})
export class SignatureComponent implements AfterViewInit {
  @ViewChild('_signaturePad') set _signaturePad(sign: any) {
    if (sign && sign.signaturePad) {
      this.signaturePad = sign.signaturePad;
      if (window.document.body.clientWidth) {
        if (this.fullWidth) {
          this.resizeCanvasInput(window.document.body.clientWidth);
        } else {
          this.resizeCanvas(window.document.body.clientWidth);
        }
      }
      this.restoreSignatureAfterResize();
    }
  }
  @Output() signatureChanged = new EventEmitter<string>();
  @Output() clear = new EventEmitter<boolean>();
  @Input() signatureEvent: Observable<string>;
  @Input() parentChangeDetectorRef: ChangeDetectorRef;

  @Input() showClear: boolean = true;

  @Input() fullWidth: boolean = false;

  @Input() canvasWidth = 350;
  @Input() canvasHeight = 300;
  @Input() isRequired = false;

  private readonly imgStyle = {
    height: '300px',
    width: '100%'
  };
  signaturePadOptions: Object = {
    minWidth: 1,
    maxWidth: 4,
    canvasWidth: this.canvasWidth,
    canvasHeight: this.canvasHeight
  };
  signature = '';
  s3signature = null;
  signaturePad: SignaturePad;
  isRefreshSignaturePad: boolean;
  get s3FromServer() {
    return this.s3signature?.indexOf('http') >= 0;
  }

  @ViewSelectSnapshot(AppState.getDevicePlatform) devicePlatform: DevicePlatform;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngAfterViewInit() {
    if (this.signatureEvent) {
      this.signatureEvent
        .pipe(
          take(1),
          tap((signature) => {
            this.s3signature = signature;
            this.restoreSignatureAfterResize();
            this.changeDetectorRef.detectChanges();
          })
        )
        .subscribe();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize($event) {
    if (this.fullWidth) {
      this.resizeCanvasInput(window.document.body.clientWidth);
    } else {
      this.resizeCanvas($event.target.innerWidth);
    }
    if (!this.s3signature && this.signature && this.signature !== '') {
      this.signaturePad.fromDataURL(this.signature, {
        width: this.canvasWidth,
        height: this.canvasHeight
      });
    } else {
      this.restoreSignatureAfterResize();
    }
  }

  private resizeCanvas(clientWidth) {
    try {
      // min sm screen size
      const sm = 576;
      // extra padding
      const extra = clientWidth >= sm ? 25 : 41;
      // calculate total width
      this.canvasWidth = (clientWidth >= sm ? clientWidth / 2 : clientWidth) - extra;
      this.signaturePad['_canvas'].width = this.canvasWidth;
      this.imgStyle.width = this.canvasWidth + 'px';
      this.imgStyle.height = this.canvasHeight + 'px';
    } catch {}
  }

  private resizeCanvasInput(clientWidth) {
    try {
      // min sm screen size
      //const sm = 576;
      // extra padding
      //const extra = clientWidth >= sm ? 25 : 41;
      // calculate total width
      this.canvasWidth = clientWidth - 34; //32 padding 2 for border
      this.signaturePad['_canvas'].width = this.canvasWidth;
      this.imgStyle.width = this.canvasWidth + 'px';
      this.imgStyle.height = this.canvasHeight + 'px';
    } catch {}
  }

  clearSignature() {
    if (this.s3FromServer) {
      this.clearSignatureDataFromURL();
    } else if (this.signaturePad) {
      this.signaturePad.clear();
    }
    this.signature = '';
    this.signatureChanged.emit('');
    this.clear.emit(true);
    this.s3signature = null;
    if (window.document.body.clientWidth) {
      if (this.fullWidth) {
        this.resizeCanvasInput(window.document.body.clientWidth);
      } else {
        this.resizeCanvas(window.document.body.clientWidth);
      }
    }
  }

  drawStart() {
    if (this.parentChangeDetectorRef && this.devicePlatform === 'android') this.parentChangeDetectorRef.detach();
  }

  drawComplete() {
    try {
      this.signature = this.signaturePad.toDataURL();
      this.signatureChanged.emit(this.signature);
      this.s3signature = this.signature;
    } catch {}
    if (this.parentChangeDetectorRef && this.devicePlatform === 'android') this.parentChangeDetectorRef.reattach();
  }

  private restoreSignatureAfterResize() {
    const signature = this.s3signature || this.signature;
    if (!signature || signature === '' || !this.signaturePad) return;
    this.signaturePad.fromDataURL(signature, {
      width: this.canvasWidth,
      height: this.canvasHeight
    });
  }

  // due to issue of signature pad with url image, return empty value with "toDataURL"
  // should render pad again for new signature
  private clearSignatureDataFromURL() {
    this.isRefreshSignaturePad = true;
    of(null)
      .pipe(
        delay(100),
        take(1),
        tap(() => {
          this.isRefreshSignaturePad = false;
          if (this.changeDetectorRef) {
            this.changeDetectorRef.detectChanges();
            console.log('clearSignatureDataFromURL detectChanges');
          }
        })
      )
      .subscribe();
    of(null)
      .pipe(
        delay(300),
        take(1),
        tap(() => {
          if (this.signaturePad) this.signaturePad['_canvas'].width = this.canvasWidth;
        })
      )
      .subscribe();
  }
}
