<ion-content>
  <div class="link-container">
    <div class="header-container">
      <ion-input
        class="standard-input"
        debounce="1000"
        [(ngModel)]="model.searchStr"
        placeholder="Last name OR Incident #"
        (ionChange)="search()"
      ></ion-input>
      <ion-icon
        class="link-report-modal__search-btn"
        name="search"
        (click)="search(true)"
      ></ion-icon>
    </div>

    <div class="list-container">
      <ion-list>
        <ion-list-header color="lightgrey" class="ion-no-padding">
          <ion-grid>
            <ion-row>
              <ion-col size-xs="4">
                <div class="transparent-item">
                  <span class="bold">Report Number</span>
                </div>
              </ion-col>
              <ion-col size-xs="4">
                <div class="transparent-item">
                  <span class="bold">Patient Name</span>
                </div>
              </ion-col>
              <ion-col size-xs="4">
                <div class="transparent-item">
                  <span class="bold">Date & Time</span>
                </div>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-list-header>
        <!-- Incidents  -->
        <ion-item
          color="light"
          class="ion-no-padding"
          *ngFor="let incident of incidents"
        >
          <ion-grid>
            <ion-row
              class="link-report-modal__clickable-row"
              (click)="selectIncident(incident)"
            >
              <ion-col size-xs="4">
                <div>{{ incident.report_number }}</div>
              </ion-col>
              <ion-col size-xs="4">
                <div>{{ incident.last_name }}, {{ incident.first_name }}</div>
              </ion-col>
              <ion-col size-xs="4">
                <div>{{ incident.date | date: 'yyyy-MM-dd H:mm' }}</div>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>
      </ion-list>
    </div>

    <div class="footer-close" [hidden]="true" (click)="dismiss()">
      <ion-icon name="close-circle-outline"></ion-icon> Close
    </div>
  </div>
</ion-content>
