export type ModuleStorageKeyType = 'emergency_operation_plans' | 'health_and_safety' | 'RecentUsers';
export const ModuleStorageKey: { [StorageKey: string]: ModuleStorageKeyType } = {
  RecentUsers: 'RecentUsers'
};
export type PendingReportType =
  | 'pendingSafetyMeetings'
  | 'pendingFormalTrainings'
  | 'pendingIncidentReports'
  | 'pendingReports'
  | 'pendingParkBuilds'
  | 'pendingObjects';
export const PendingReportKey: {
  [PendingReportKey: string]: { name: string; value: PendingReportType };
} = {
  safetyMeetings: { name: 'Safety Meeting', value: 'pendingSafetyMeetings' },
  formalTrainings: { name: 'Formal Training', value: 'pendingFormalTrainings' },
  incidentReports: { name: 'Incident Report', value: 'pendingIncidentReports' },
  reports: { name: 'Report', value: 'pendingReports' },
  parkBuilds: { name: 'Park Builds', value: 'pendingParkBuilds' },
  objects: { name: 'Objects', value: 'pendingObjects' }
};
