import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_BASE_URL, API_BASE_URL_V3 } from './constants';
import { UploadAttachedPhoto } from '@ea-models';

@Injectable({
  providedIn: 'root'
})
export class ParkFeaturesService {
  readonly API_BASE_URL_V3_BUILDS = `${API_BASE_URL_V3}/builds`;

  constructor(private httpClient: HttpClient) {}

  public submitFeature(token: string, feature: any): Observable<any> {
    // const endpoint = feature.park_feature && feature.park_feature.id ? 'feature_update' : 'feature_submission';
    return this.httpClient.post<any>(`${this.API_BASE_URL_V3_BUILDS}/new_feature?token=${token}`, feature);
  }

  public uploadAttachedPhoto(token: string, uploadData: UploadAttachedPhoto): Observable<any> {
    return this.httpClient.post<any>(`${API_BASE_URL_V3}/object_attachments?token=${token}`, uploadData);
  }

  public getRecentBuildFeatures(token: string, id: number, type: string): Observable<any> {
    return this.httpClient.get<any>(`${this.API_BASE_URL_V3_BUILDS}/recent_build?token=${token}&id=${id}&type=${type}`);
  }

  public getAllFeatures(token: string): Observable<any> {
    return this.httpClient.get<any>(`${API_BASE_URL}/get_features?token=${token}`);
  }
}
