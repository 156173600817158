import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Observable, Subscription } from 'rxjs';
import { Store } from '@ngxs/store';
import { AlertController, NavController } from '@ionic/angular';

import { User } from '@ea-models';
import { PopUpService } from './popup.service';
import { NETWORK_ERROR, NETWORK_ERROR_ACCESS_FEATURE } from '@ea-pages/pages.constants';
import { AppState } from '@edgeauditor/app/store/app/app.state';
import { RouterConstant } from '@edgeauditor/app/constants/router.constant';

@Injectable()
export class GlobalService implements OnDestroy {
  private subscription: Subscription;

  constructor(private popUpService: PopUpService, private store: Store, private navController: NavController) {
    this.subscription = new Subscription();
    this.subscription.add(this.getCurrentUser().subscribe((value) => (this._currentUser = value)));
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }

  private localData: any = {};

  private currentUser$: Subject<User> = new Subject();
  public _currentUser: User;
  // End DEV Session Data

  private isOpenMenuSettings = false;
  showReponseErrorPopup = true;
  public logout$: Subject<string> = new Subject();

  public setCurrentUser(newValue: User): void {
    this.currentUser$.next(newValue);
  }

  checkNetworkErrorAndBackToDashboard(alertController: AlertController, isRedirect = true) {
    const networkStatus = this.store.selectSnapshot(AppState.getNetworkStatus);
    if (!networkStatus?.connected) {
      const handler = () => {
        if (isRedirect) this.navController.navigateRoot(`/${RouterConstant.DASHBOARD_PAGE}`);
      };
      this.popUpService.popupAlert(NETWORK_ERROR, NETWORK_ERROR_ACCESS_FEATURE, alertController, handler, !isRedirect);
    }
  }

  private getCurrentUser(): Observable<User> {
    return this.currentUser$.asObservable();
  }

  popupAlert = this.popUpService.popupAlert;
  popupCustomConfirm = this.popUpService.popupCustomConfirm;
}
