import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { LogService } from './log.service';
import { Subject, Observable } from 'rxjs';
import { User } from '@ea-models';
import _ from 'lodash';
import { RECENT_USERS_KEY } from './services.constants';

export const USER_LIMIT = 2;
export interface canAddUserResult {
  success: boolean;
  reason?: 'already_exists' | 'users_with_local_data';
}

@Injectable()
export class RecentUsers {
  private users$: Subject<User[]> = new Subject();
  users: User[] = [];

  constructor(public Storage: StorageService, public log: LogService) {}

  public all(): Observable<User[]> {
    return this.users$.asObservable();
  }

  canAddUser(userToAdd: User): canAddUserResult {
    let result: canAddUserResult = {
      success: true
    };

    const userExists = this.users.find((user) => user.org === userToAdd.org && user.username === userToAdd.username);

    if (userExists) {
      result = {
        success: false,
        reason: 'already_exists'
      };
      return result;
    }

    if (this.users.length >= USER_LIMIT) {
      const nonDeleteUsers: User[] = [];

      this.users.forEach((user) => {
        if (
          (user.pendingParkBuilds && user.pendingParkBuilds.length) ||
          (user.pendingFeatures && user.pendingFeatures.length) ||
          (user.pendingFormalTrainings && user.pendingFormalTrainings.length) ||
          (user.pendingSafetyMeetings && user.pendingSafetyMeetings.length) ||
          (user.pendingIncidentReports && user.pendingIncidentReports.length) ||
          (user.pendingReports && user.pendingReports.length) ||
          (user.pendingObjects && user.pendingObjects.length)
        ) {
          nonDeleteUsers.push(user);
        }
      });

      if (nonDeleteUsers.length >= USER_LIMIT) {
        result = {
          success: false,
          reason: 'users_with_local_data'
        };

        return result;
      }
    }

    return result;
  }

  public add(user): any {
    const existedUser: User = this.users.find((obj) => obj.org === user.org && obj.username === user.username);
    let result = null;
    if (this.users.length >= 2) {
      const newUsers = [];
      this.users.forEach((_tempUser: User) => {
        const isNoPendingData =
          (!_tempUser.pendingParkBuilds || !_tempUser.pendingParkBuilds.length) &&
          (!_tempUser.pendingFeatures || !_tempUser.pendingFeatures.length) &&
          (!_tempUser.pendingFormalTrainings || !_tempUser.pendingFormalTrainings.length) &&
          (!_tempUser.pendingSafetyMeetings || !_tempUser.pendingSafetyMeetings.length) &&
          (!_tempUser.pendingIncidentReports || !_tempUser.pendingIncidentReports.length) &&
          (!_tempUser.pendingReports || !_tempUser.pendingReports.length) &&
          (!_tempUser.pendingObjects || !_tempUser.pendingObjects.length);

        if (!isNoPendingData && !(_tempUser.org === user.org && _tempUser.username === user.username)) {
          newUsers.push(_tempUser);
        }
      });

      if (newUsers.length) {
        result = {
          alertCannotRemoveCachedData: true,
          users: _.cloneDeep(newUsers)
        };
      }
      if (newUsers.length <= 1) {
        this.users = newUsers;
      }
    }
    if (!existedUser && (!result || result.users.length <= 1)) {
      this.users.push(user);
      this.save();
      this.users$.next(this.users);
    }
    return result;
  }

  public update(user: User): void {
    const index = this.users.findIndex((obj) => obj.org === user.org && obj.username === user.username);
    if (index >= 0) {
      this.users[index] = user;
      this.save();
      this.users$.next(this.users);
    }
  }

  get(org, username) {
    return this.users.find((obj) => obj.org === org && obj.username === username);
  }

  getAll() {
    return this.Storage.getObject(RECENT_USERS_KEY);
  }

  save() {
    // LocalStorage save
    this.Storage.setObject(RECENT_USERS_KEY, this.users);
  }

  retrieveUsers() {
    // LocalStorage retrieve
    this.Storage.getObject(RECENT_USERS_KEY).then((data) => {
      if (data) {
        this.users = data;
        this.users$.next(this.users);
      }
    });
  }

  clear() {
    this.users = [];
    this.Storage.clearObject(RECENT_USERS_KEY);
    this.Storage.clearAll();
    this.users$.next(this.users);
  }
}
