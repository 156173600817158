import { NgModule, ModuleWithProviders } from '@angular/core';
import { ApiService } from './api.service';
import { AuthenticationService } from '../app/services/authentication.service';
import { ConfigService } from './config.service';
import { GlobalService } from './global.service';
import { LogService } from './log.service';
import { RecentUsers } from './recent-users.service';
import { SpinnerService } from './spinner.service';
import { StorageService } from './storage.service';
import { PopUpService } from './popup.service';
import { LocalParkBuildService } from './local-park-build.service';
import { LocalIncidentReportService } from './local-incident-report.service';
import { IncidentReportService } from './incident-report.service';
import { OfflineDataUtility } from './emergency-operation-plans/offline-data-utility';
import { LiftStop } from './lift/liftStop';
import { LiftService } from './lift/lift.service';
import { ObjectsProviderService } from './provider/objects-provider.service';

const providers = [
  ApiService,
  AuthenticationService,
  ConfigService,
  GlobalService,
  LogService,
  RecentUsers,
  SpinnerService,
  StorageService,
  PopUpService,
  LocalIncidentReportService,
  IncidentReportService,
  ObjectsProviderService,
  OfflineDataUtility,
  LiftStop,
  LiftService,
  LocalParkBuildService
];

@NgModule({})
export class EAServicesModule {
  static forRoot(): ModuleWithProviders<EAServicesModule> {
    return {
      ngModule: EAServicesModule,
      providers
    };
  }
}
