<ion-grid class="diagram-drawing" fixed>
  <ion-row class="top-header">
    <ion-col size-xs="4" class="diagram-drawing__sel-col">
      <ion-label>Tool</ion-label>
      <div class="ion-padding-top">
        <ng-select
          [items]="toolOptions"
          [selectOnTab]="true"
          placeholder=""
          bindLabel="name"
          bindValue="value"
          [searchable]="false"
          class="standard-select"
          (change)="onToolChange($event)"
          [(ngModel)]="model.tool"
          [clearable]="false"
        >
        </ng-select>
      </div>
    </ion-col>
    <ion-col size-xs="4" class="diagram-drawing__sel-col">
      <ion-label>Size</ion-label>
      <div class="ion-padding-top">
        <ng-select
          [items]="sizeOptions"
          [selectOnTab]="true"
          placeholder=""
          bindLabel="name"
          bindValue="value"
          [searchable]="false"
          class="standard-select"
          (change)="onSizeChange($event)"
          [(ngModel)]="model.size"
          [clearable]="false"
        >
        </ng-select>
      </div>
    </ion-col>
    <ion-col size-xs="4" class="diagram-drawing__sel-col">
      <ion-icon
        class="close-button"
        name="close"
        (click)="confirmClose()"
      ></ion-icon>
      <ion-label>Color</ion-label>
      <div class="ion-padding-top">
        <ng-select
          [items]="colorOptions"
          [selectOnTab]="true"
          placeholder=""
          bindLabel="name"
          bindValue="value"
          [searchable]="false"
          class="standard-select"
          (change)="onColorChange($event)"
          [(ngModel)]="model.color"
          [clearable]="false"
        >
        </ng-select>
      </div>
    </ion-col>
  </ion-row>
  <ion-row class="diagram-drawing__central-row">
    <ion-col size-xs="12" class="diagram-drawing__canvas-col">
      <signature-pad
        [ngStyle]="canvasStyle"
        [options]="options"
        (onEndEvent)="drawComplete()"
        #_signaturePad
      >
      </signature-pad>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size-xs="6">
      <ion-button expand="full" color="danger" (click)="clear()"
        >Clear</ion-button
      >
    </ion-col>
    <ion-col size-xs="6">
      <ion-button expand="full" color="success" (click)="save()"
        >Save</ion-button
      >
    </ion-col>
  </ion-row>
</ion-grid>
