import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { IReport } from '@ea-models';
import { GetReports, GetReportsSuccess } from './reports.action';
import { GlobalService, ReportService, SubmitReportResponse } from '@ea-services';
import { tap } from 'rxjs/operators';
import { DeclinedAccidentReport, DeclinedReport } from '@ea-models-v4/rejected-report.model';
import { IncidentReportState, NemsisState, OtherReportState } from '@ea-store-v4';
import { NemsisReportDetail } from '@ea-models-v4';

interface StateModel {
  reports: IReport[];
  declinedReports: DeclinedReport[];
  declinedAccidentReports: DeclinedAccidentReport[];
  submitReportResponse?: SubmitReportResponse;
  rejected_nemsis_reports?: NemsisReportDetail[];
  // nemsis: NemsisStateModel;
  // otherReport: OtherReportStateModel;
  // incidentReport: IncidentReportStateModel;
}

const defaults: StateModel = {
  reports: [],
  declinedReports: [],
  declinedAccidentReports: [],
  rejected_nemsis_reports: []
  // nemsis: NEMSIS_DEFAULT_STATE,
  // otherReport: otherReportStateDefaults,
  // incidentReport: incidentReportStateDefaults,
};

@State<StateModel>({
  name: 'reports',
  defaults,
  children: [NemsisState, OtherReportState, IncidentReportState]
})
@Injectable({
  providedIn: 'root'
})
export class ReportsState {
  constructor(private reportService: ReportService, private globalService: GlobalService) {}

  @Selector()
  static getReports(s: StateModel) {
    return s.reports;
  }

  @Selector()
  static getAllReports({ reports, declinedReports, declinedAccidentReports, rejected_nemsis_reports }: StateModel) {
    return { reports, declinedReports, declinedAccidentReports, rejected_nemsis_reports };
  }

  @Selector()
  static getSubmitReportResponse(s: StateModel) {
    return s.submitReportResponse;
  }

  @Action(GetReports)
  getReports(ctx: StateContext<StateModel>) {
    const token = this.globalService._currentUser.token;
    return this.reportService.getReports(token).pipe(tap((res) => ctx.dispatch(new GetReportsSuccess(res))));
  }

  @Action(GetReportsSuccess)
  getReportsSuccess(ctx: StateContext<StateModel>, payload: GetReportsSuccess) {
    ctx.patchState({
      reports: payload.res.reports || [],
      declinedReports: payload.res.declined || [],
      declinedAccidentReports: payload.res.declined_accident_reports || [],
      rejected_nemsis_reports: payload.res.rejected_nemsis_reports || []
    });
  }
}
