export class RouterConstant {
  static readonly LOGIN_PAGE = 'login';
  static readonly RESET_PASSWORD_PAGE = 'reset-password';
  static readonly DASHBOARD_PAGE = 'dashboard';
  static readonly DISPATCH_CONSOLE_PAGE = 'dispatch-console';

  //REPORTS
  static readonly REPORTS_PAGE = 'reports';

  //New Report
  static readonly NEW_REPORT_PAGE = RouterConstant.REPORTS_PAGE + '/new';
  static readonly NEW_INCIDENT_REPORT = RouterConstant.NEW_REPORT_PAGE + '/incident-report';
  static readonly NEW_OTHER_REPORT_PAGE = RouterConstant.NEW_REPORT_PAGE + '/other';

  //In Progress Reports
  static readonly PENDING_REPORTS = RouterConstant.REPORTS_PAGE + '/in-progress';
  static readonly PENDING_LOCAL_REPORTS_PAGE = RouterConstant.PENDING_REPORTS + '/local';
  static readonly PENDING_OTHER_REPORTS_PAGE = RouterConstant.PENDING_REPORTS + '/other';

  //Rejected Reports
  static readonly REJECTED_REPORTS = RouterConstant.REPORTS_PAGE + '/rejected';

  //Feature Builds
  static readonly FEATURE_BUILD_PAGE = 'feature-builds';

  //Add Features
  static readonly ADD_FEATURE_PAGE = 'add-feature';

  //Health and Safety
  static readonly HEALTH_AND_SAFETY_PAGE = 'health-and-safety';
  static readonly HEALTH_AND_SAFETY_MEETING_PAGE = RouterConstant.HEALTH_AND_SAFETY_PAGE + '/meeting';
  static readonly FORMAL_TRAINING_PAGE = RouterConstant.HEALTH_AND_SAFETY_PAGE + '/formal-training';
  static readonly STAFF_SUMMARY_PAGE = RouterConstant.HEALTH_AND_SAFETY_PAGE + '/staff-summary';

  //Emergency Operation Plans
  static readonly EMERGENCY_OPERATION_PLANS_PAGE = 'emergency-operation-plans';

  //Lift Stop
  static readonly LIFT_STOP_PAGE = 'lift-stop';

  //Equipment
  static readonly EQUIPMENT_PAGE = 'equipment';

  //Equipment Assignment
  static readonly EQUIPMENT_ASSIGNMENT_PAGE = RouterConstant.EQUIPMENT_PAGE + '/assignment';
  static readonly EQUIPMENT_ASSIGNMENT_RENTAL_PAGE = RouterConstant.EQUIPMENT_ASSIGNMENT_PAGE + '/rental';

  //Equipment Maintenance
  static readonly EQUIPMENT_MAINTENANCE_PAGE = RouterConstant.EQUIPMENT_PAGE + '/maintenance';
  /*static readonly EQUIPMENT_MAINTENANCE_PENDING_PAGE = this.EQUIPMENT_MAINTENANCE_PAGE + 'pending';
  static readonly EQUIPMENT_MAINTENANCE_ALL_PAGE = this.EQUIPMENT_MAINTENANCE_PAGE + 'all';
  static readonly EQUIPMENT_MAINTENANCE_LOG_WORK_PAGE = this.EQUIPMENT_MAINTENANCE_PAGE + 'log-work';*/

  //Special Events
  static readonly SPECIAL_EVENTS_PAGE = 'special-events';

  //Objects
  static readonly OBJECTS_PAGE = 'objects';

  //Groomer Tracking
  static readonly GROOMER_TRACKING_PAGE = 'groomer-tracking';

  //Waiver Lookup
  static readonly WAIVER_LOOKUP_PAGE = 'waiver-lookup';

  //Tasks
  static readonly TASKS_PAGE = 'tasks';

  //NEMSIS
  static readonly NEMSIS_PAGE = RouterConstant.REPORTS_PAGE + '/nemsis';
}
