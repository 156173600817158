import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';

import { HeaderComponent } from './header/header.component';
import { UserMenuComponent } from './user-menu/user-menu.component';

const COMPONENTS = [HeaderComponent, UserMenuComponent];

const PIPES = [];

const DIRECTIVES = [];

const MODALS = [];

@NgModule({
  declarations: [...COMPONENTS, ...MODALS, ...PIPES, ...DIRECTIVES, UserMenuComponent],
  imports: [CommonModule, IonicModule, RouterModule, FormsModule],
  providers: [],
  exports: [...COMPONENTS, ...PIPES, ...DIRECTIVES]
})
export class SharedModule {}
