export const templates: string[] = ['nsaa', 'cwsaa', 'copper'];

export const nsaa_sections = {
  patients: 'Injured Person Details & History',
  description: 'Description of Incident',
  equipment: 'Equipment',
  complaints: 'Probable Injury/Illness',
  first_aid: 'First Aid Rendered',
  transport: 'Transport & Patrollers',
  conditions: 'Site Conditions',
  witnesses: 'Witness',
  additional_questions: 'Additional Questions'
};

export const sections = {
  nsaa: nsaa_sections,
  nsaa_mammoth: {
    ...nsaa_sections,
    patients: 'Person Details & History'
  },
  nsaa_june: {
    ...nsaa_sections,
    patients: 'Person Details & History'
  },
  cwsaa: {
    patients: 'Patient',
    complaints: 'Complaint',
    first_aid: 'First Aid',
    witnesses: 'Witness',
    location: 'Location & Conditions',
    transport: 'Transport',
    additional_questions: 'Additional Questions'
  },
  copper: {
    patients: 'Injured Person Details & History',
    first_aid: 'First Aid & Conditions'
  }
};
