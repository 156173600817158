<ion-header>
  <ion-toolbar mode="md" [color]="color">
    <ion-buttons slot="start">
      <ion-button *ngIf="hasBackButton" (click)="exit()" mode="md">
        <ion-icon name="arrow-back-outline"></ion-icon>
        Back
      </ion-button>
      <ng-content select="[start-buttons]"></ng-content>
    </ion-buttons>
    <ion-title class="ion-text-center">{{ title }}<ng-container *ngIf="subtitle"> - {{ subtitle
        }}</ng-container></ion-title>
    <ion-buttons slot="end">
      <ion-button *ngIf="hasNetworkIndicator" class="network-indicator">
        <span>{{ ((networkStatus$ | async)?.connected) ? 'Online' : 'Offline' }}</span>
        <ion-icon [name]="(networkStatus$ | async)?.connected ? 'wifi-outline' : 'cloud-offline-outline'"></ion-icon>
      </ion-button>
      <ng-content select="[end-buttons]"></ng-content>
      <ion-button *ngIf="hasCloseButton" (click)="exit()" mode="ios">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ng-content select="[extra-content]"></ng-content>
</ion-header>