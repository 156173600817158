import { DispatchConsoleEvent } from '@ea-models';
import { dispatchEventArraySchema, NormalizedEntities } from '@ea-models-v4';
import { normalize } from 'normalizr';

export class NormalizrHelper {
  static normalizeDispatchEvents(events: DispatchConsoleEvent[]) {
    const normalizedData = normalize<DispatchConsoleEvent, Pick<NormalizedEntities, 'events'>, number[]>(
      events,
      dispatchEventArraySchema
    );
    return normalizedData;
  }
}
