import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import _ from 'lodash';

import { InProgressOtherReport } from '@ea-models-v4/other-report';
import { GlobalService } from '@ea-services';
import { OtherReportService } from '@ea-services-v4';
import { ClearReports, GeneralError } from '../app/app.action';
import { GetInProgressOtherReports } from './other-report.action';

export interface OtherReportStateModel {
  inProgressReports: InProgressOtherReport[];
}

export const otherReportStateDefaults: OtherReportStateModel = { inProgressReports: [] };

@State<OtherReportStateModel>({
  name: 'otherReport',
  defaults: otherReportStateDefaults
})
@Injectable({
  providedIn: 'root'
})
export class OtherReportState {
  constructor(private otherReportService: OtherReportService, private globalService: GlobalService) {}

  @Selector()
  static getInProgressReports(state: OtherReportStateModel) {
    return state.inProgressReports || [];
  }

  @Action(GetInProgressOtherReports)
  getInProgressReports(ctx: StateContext<OtherReportStateModel>, { start_index, limit }: GetInProgressOtherReports) {
    return this.otherReportService.getInProgressOtherReports(start_index, limit).pipe(
      catchError((e: HttpErrorResponse) => {
        ctx.dispatch(
          new GeneralError(
            'There was a problem while fetching In Progress Reports. Please try again or contact Support.'
          )
        );
        return throwError(() => e);
      }),
      tap((reports) => {
        const sortedReportsByDateDesc = _.concat(ctx.getState().inProgressReports || [], reports).sort((a, b) =>
          a.datetime < b.datetime ? 1 : a.datetime > b.datetime ? -1 : 0
        );

        //TODO: Remove global service and handle it through state
        this.globalService._currentUser.dispatchedReports = sortedReportsByDateDesc;
        ctx.patchState({ inProgressReports: _.cloneDeep(sortedReportsByDateDesc) });
      })
    );
  }

  @Action(ClearReports)
  clearReports(ctx: StateContext<OtherReportStateModel>) {
    ctx.patchState({ inProgressReports: [] });
  }
}
