import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { API_BASE_URL } from './constants';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GroomersService {
  public areasData: any;

  constructor(private httpClient: HttpClient) {}

  public getShifts(token: string): Observable<any> {
    return this.httpClient.get<any>(`${API_BASE_URL}/groomer_shifts?token=${token}`);
  }

  public getVehicles(token: string): Observable<any> {
    return this.httpClient.get<any>(`${API_BASE_URL}/vehicles?token=${token}`);
  }

  public getActivityTypes(token: string): Observable<any> {
    return this.httpClient.get<any>(`${API_BASE_URL}/groomer_activity_types?token=${token}`);
  }

  public getAreas(token: string): Observable<any> {
    if (this.areasData) {
      return of(this.areasData);
    }
    return this.httpClient
      .get<any>(`${API_BASE_URL}/areas?token=${token}`)
      .pipe(tap((res) => (this.areasData = res ? res.areas : null)));
  }

  public getGroomerAssignments(shiftId: number, token: string): Observable<any> {
    return this.httpClient.get<any>(`${API_BASE_URL}/groomer_assignments?groomer_shift_id=${shiftId}&token=${token}`);
  }

  public getGroomerAssignmentsWithPaging(shiftId: number, token: string, pageIndex: number): Observable<any> {
    return this.httpClient.get<any>(
      `${API_BASE_URL}/groomer_assignments?groomer_shift_id=${shiftId}&token=${token}&page=${pageIndex}&support_paging=true&per_page=100`
    );
  }

  public getGroomerAssignment(assignmentId: number, token: string): Observable<any> {
    return this.httpClient.get<any>(`${API_BASE_URL}/groomer_acitivity_assignments/${assignmentId}?token=${token}`);
  }

  public saveVehicleLocation(data: any, token: string): Observable<any> {
    //data: { locations: [{lat: number, lng: number, vehicle_id: number, groomer_shift_id: number}] }
    return this.httpClient.post<any>(`${API_BASE_URL}/vehicle_locations?token=${token}`, data);
  }

  public updateGroomerStatus(id: Number, status: string, token: string): Observable<any> {
    return this.httpClient.put<any>(
      `${API_BASE_URL}/update_groomer_assignment?id=${id}&token=${token}&status=${status}`,
      null
    );
  }

  public logActivity(token: string, data: any): Observable<any> {
    return this.httpClient.post<any>(`${API_BASE_URL}/log_groomer_activity?token=${token}`, data);
  }

  public createFuelLog(token: string, data: any): Observable<any> {
    return this.httpClient.post<any>(`${API_BASE_URL}/create_fuel_log?token=${token}`, data);
  }

  public finishShift(token: string, data: any): Observable<any> {
    return this.httpClient.post<any>(`${API_BASE_URL}/create_utilization_log?token=${token}`, data);
  }
}
