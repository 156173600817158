import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DispatchConsoleEvent } from '@ea-models';
import { DispatchCategory, GetDispatchCategoriesResponse } from '@ea-models-v4';
import { GlobalService } from '@ea-services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigService } from '../../services/config.service';

@Injectable({
  providedIn: 'root'
})
export class DispatchConsoleService {
  constructor(private http: HttpClient, private configService: ConfigService, private globalService: GlobalService) {}

  getEvents(): Observable<DispatchConsoleEvent[]> {
    const token = this.globalService._currentUser.token;
    return this.http
      .get<DispatchConsoleEvent[]>(this.configService.dispatchURL() + 'dispatch_events', {
        headers: {
          Authorization: token
        }
      })
      .pipe(map((response) => response));
  }

  getCategories(): Observable<DispatchCategory[]> {
    const token = this.globalService._currentUser.token;
    return this.http
      .get<GetDispatchCategoriesResponse>(this.configService.dispatchURL() + 'dispatch_categories', {
        headers: {
          Authorization: token
        }
      })
      .pipe(map((response) => response.categories));
  }

  createEvent(data): Observable<DispatchConsoleEvent> {
    const token = this.globalService._currentUser.token;
    return this.http
      .post<DispatchConsoleEvent>(this.configService.dispatchURL() + 'dispatch_events', data, {
        headers: {
          Authorization: token
        }
      })
      .pipe(map((response) => response));
  }

  lockEvent(id: number): Observable<{ success: boolean }> {
    const token = this.globalService._currentUser.token;
    return this.http
      .post<{ success: boolean }>(this.configService.dispatchURL() + `dispatch_events/${id}/lock`, null, {
        headers: {
          Authorization: token
        }
      })
      .pipe(map((response) => response));
  }

  unlockEvent(id: number): Observable<{ success: boolean }> {
    const token = this.globalService._currentUser.token;
    return this.http
      .post<{ success: boolean }>(this.configService.dispatchURL() + `dispatch_events/${id}/unlock`, null, {
        headers: {
          Authorization: token
        }
      })
      .pipe(map((response) => response));
  }
}
