import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { take, tap } from 'rxjs/operators';

import { API_BASE_URL, API_BASE_URL_V3 } from './constants';
import { GlobalService } from '@ea-services/global.service';
import { RejectedParkBuild } from '@ea-models-v4/rejected-report.model';

interface GetRejectedBuildsResponse {
  success: boolean;
  data: {
    park: RejectedParkBuild[];
  };
}

interface CommonObject {
  id: number;
  name: string;
}

export interface Feature extends CommonObject {
  is_now: boolean;
}

interface GetFeatureResponse {
  features: Feature[];
  objects: CommonObject[];
  success: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ParkBuildsService {
  buildsData = {};
  declinedBuilds: RejectedParkBuild[] = [];
  readonly API_BASE_URL_V3_BUILDS = `${API_BASE_URL_V3}/builds`;
  rejectedBuildsCounts: number;

  constructor(private httpClient: HttpClient, private globalService: GlobalService) {}

  public getBuildsAndFeaturesByType(token: string, type: string): Observable<GetFeatureResponse> {
    return this.httpClient
      .get<GetFeatureResponse>(`${API_BASE_URL_V3}/features`, { params: { token, type } })
      .pipe(take(1));
  }

  //TODO
  public getRejectedBuilds(token: string): Observable<GetRejectedBuildsResponse> {
    return this.httpClient
      .get<GetRejectedBuildsResponse>(`${this.API_BASE_URL_V3_BUILDS}/check_for_declined`, {
        params: { token, r: new Date().getTime() }
      })
      .pipe(
        take(1),
        tap((res) => {
          this.declinedBuilds = [];
          if (!res || !res.data) return res;
          let rejectedBuildsCounts = 0;
          const currentUser = this.globalService._currentUser;
          Object.keys(res.data).forEach((key) => {
            (res.data[key] || []).forEach((item) => {
              this.declinedBuilds.push(item);
              if (currentUser?.permissions[`${item.type}_module`] > 1) {
                rejectedBuildsCounts++;
              }
            });
          });
          this.rejectedBuildsCounts = rejectedBuildsCounts;
        })
      );
  }

  public submitParkBuild(token: string, build: any): Observable<any> {
    return this.httpClient.post<any>(`${this.API_BASE_URL_V3_BUILDS}?token=${token}`, build);
  }

  public updateParkBuildFeature(token: string, fature: any): Observable<any> {
    return this.httpClient.post<any>(`${API_BASE_URL}/parkbuild_feature_update?token=${token}`, fature);
  }

  public resetData() {
    this.buildsData = {};
    this.declinedBuilds = [];
    this.rejectedBuildsCounts = 0;
  }
}
