<div [class.invalid]="isRequired && !model.diagram_image">
  <app-image-picker (imageSelected)="onBgImageSelected($event)"
                    [hidePreview]="true"
                    [isSupportResizePhoto]="isSupportResizePhoto"
                    [showWarningResizePhoto]="showWarningResizePhoto"
                    [askWhenPickup]="!!model.diagram_image"
                    (onPickup)="showAlertForExistingDiagram($event)"></app-image-picker>
  <ion-button expand="full"
              color="primary"
              (click)="presentActionSheet(!!model.diagram_image)">Pre-Built Diagram</ion-button>
  <ion-button expand="full"
              color="warning"
              (click)="editDiagram()"
              [hidden]="!model.diagram_image"
              #btnExistingDiagram>Edit Existing Diagram</ion-button>
</div>