import { DEF_COL_12, DEF_COL_4, DEF_COL_6, DEF_COL_3, DEF_KEY, DEF_KEY_VAL, DEF_KEY_VAL_POS_TOP } from './constants';
import { ITemplate } from './types';

export const COPPER_PATIENTS_TEMPLATE: ITemplate = [
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_6,
        label: 'First Name',
        field: 'first_name',
        type: 'input',
        required: true
      },
      {
        ...DEF_COL_6,
        label: 'Last Name',
        field: 'last_name',
        type: 'input',
        required: true
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Address',
        field: 'address',
        type: 'custom',
        custom: 'address',
        defaultCountry: 'US'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Occupation',
        field: 'occupation',
        type: 'input'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Date of Birth',
        field: 'birthday',
        type: 'custom',
        custom: 'birthdate'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_4,
        label: 'Gender',
        field: 'gender',
        type: 'select',
        ...DEF_KEY_VAL
      },
      { ...DEF_COL_4, label: 'Height', field: 'height', type: 'input' },
      { ...DEF_COL_4, label: 'Weight', field: 'weight', type: 'input' }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      { ...DEF_COL_6, label: 'Phone', field: 'phone', type: 'input' },
      { ...DEF_COL_6, label: 'Email', field: 'email', type: 'input' }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Skiing History',
        field: '',
        type: 'label',
        icon: 'snow'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Ability',
        field: 'ability',
        type: 'select',
        ...DEF_KEY_VAL_POS_TOP
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_6,
        label: 'Were you in a lesson when this happened?',
        field: 'was_in_lesson',
        type: 'checkbox'
      },
      // eslint-disable-next-line max-len
      {
        ...DEF_COL_6,
        label: 'Lesson Type',
        field: 'lesson',
        type: 'select',
        ...DEF_KEY_VAL,
        _: 'copper_',
        if: { key: 'was_in_lesson', value: true }
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_6,
        label: 'Days This Season',
        field: 'days_all_areas',
        type: 'input'
      },
      {
        ...DEF_COL_6,
        label: 'Days This Season at Copper',
        field: 'days_this_area',
        type: 'input'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Patient History',
        field: '',
        type: 'label',
        icon: 'pulse'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_6,
        label: 'Injury Is a Re-occurrence of a Previous One',
        field: 'is_reccurence',
        type: 'checkbox'
      },
      {
        ...DEF_COL_6,
        label: 'Year Injured',
        field: 'previous_year_injured',
        type: 'input',
        if: { key: 'is_reccurence', value: true }
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Equipment and Activity',
        field: '',
        type: 'label',
        icon: 'cog'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_3,
        label: 'Activity',
        field: 'activity',
        type: 'select',
        ...DEF_KEY_VAL,
        _: 'copper_'
      },
      // eslint-disable-next-line max-len
      {
        ...DEF_COL_3,
        label: 'Other Activity',
        field: 'activity_other',
        type: 'input',
        ...DEF_KEY_VAL,
        if: { key: 'activity', value: 'Other' }
      },
      {
        ...DEF_COL_3,
        label: 'Equipment',
        field: 'equipment',
        type: 'select',
        ...DEF_KEY_VAL,
        _: 'copper_'
      },
      {
        ...DEF_COL_3,
        label: 'Helmet',
        field: 'helmet',
        type: 'select',
        ...DEF_KEY_VAL,
        _: 'copper_'
      }
    ]
  },
  {
    if: { key: 'activity', value: 'Ski' },
    cols: [
      {
        ...DEF_COL_3,
        label: 'Left Din Toe',
        field: 'equipment_left_din_toe',
        type: 'input'
      },
      {
        ...DEF_COL_3,
        label: 'Left Din Heel',
        field: 'equipment_left_din_heel',
        type: 'input'
      },
      {
        ...DEF_COL_3,
        label: 'Right Din Toe',
        field: 'equipment_right_din_toe',
        type: 'input'
      },
      {
        ...DEF_COL_3,
        label: 'Right Din Heel',
        field: 'equipment_right_din_heel',
        type: 'input'
      }
    ]
  },
  {
    if: { key: 'activity', value: 'Ski' },
    cols: [
      {
        ...DEF_COL_6,
        label: 'Binding Make/Model',
        field: 'equipment_make_model',
        type: 'input'
      },
      {
        ...DEF_COL_6,
        label: 'Binding Release',
        field: 'binding_release',
        type: 'select',
        ...DEF_KEY_VAL,
        _: 'copper_'
      }
    ]
  },
  {
    if: { key: 'equipment', value: 'Copper Rented' },
    cols: [
      {
        ...DEF_COL_12,
        label: 'Rental Ski/Snowboard #',
        field: 'rental_ski',
        type: 'input'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Description of Incident',
        field: '',
        type: 'label',
        icon: 'document'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'How Did The Incident Occur?',
        field: 'description',
        type: 'textarea'
      },
      {
        ...DEF_COL_12,
        label: 'How Could This Incident Have Been Prevented?',
        field: 'prevention_description',
        type: 'textarea'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'The above statement is accurate and true',
        field: '',
        type: 'label'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_6,
        label: 'Signature Type',
        field: 'signature_type',
        type: 'select',
        ...DEF_KEY_VAL
      },
      // eslint-disable-next-line max-len
      {
        ...DEF_COL_6,
        label: 'Name of Person Signing',
        field: 'other_signature_name',
        type: 'input',
        if: {
          key: 'signature_type',
          value: 'Parent',
          or: ['Guardian', 'Spouse']
        }
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Signature',
        field: 'signature',
        type: 'custom',
        custom: 'signature',
        signatureOnly: true
      }
    ]
  }
];

export const COPPER_FIRST_AID_TEMPLATE: ITemplate = [
  {
    ...DEF_KEY,
    cols: [{ ...DEF_COL_12, label: ' ', field: '', type: 'label' }]
  },
  {
    ...DEF_KEY,
    cols: [{ ...DEF_COL_12, label: ' ', field: '', type: 'label' }]
  },
  {
    ...DEF_KEY,
    cols: [{ ...DEF_COL_12, label: ' ', field: '', type: 'label' }]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: '',
        labels: { part: 'Part', probable: 'Probable Injury' },
        field: 'complaint',
        type: 'custom',
        custom: 'complaint'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'First Aid',
        field: 'first_aid_scene',
        type: 'textarea'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Medication Taken',
        field: 'medications',
        type: 'input'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Transport and Patrollers',
        field: '',
        type: 'label',
        icon: 'train'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Transport Method To CMC',
        field: 'transport_to_aid',
        type: 'select',
        ...DEF_KEY_VAL,
        _: 'copper_'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_6,
        label: 'Destination',
        field: 'destination',
        type: 'select',
        ...DEF_KEY_VAL,
        _: 'copper_'
      },
      // eslint-disable-next-line max-len
      {
        ...DEF_COL_6,
        label: 'Handoff Time (CMC/Ambulance/Helicopter)',
        field: 'transport_to_aid_time',
        type: 'datetime',
        displayFormat: 'H:mm'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Transport & Patrollers',
        field: 'patrollers',
        type: 'custom',
        custom: 'patrollers'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Patrollers Involved (Name & ID)',
        field: 'patrol',
        type: 'textarea'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Site Conditions',
        field: '',
        type: 'label',
        icon: 'rainy'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_12,
        label: 'Visibility',
        field: 'light',
        type: 'select',
        ...DEF_KEY_VAL_POS_TOP,
        _: 'copper_'
      }
    ]
  },
  {
    ...DEF_KEY,
    cols: [
      {
        ...DEF_COL_6,
        label: 'Weather',
        field: 'weather',
        type: 'select',
        ...DEF_KEY_VAL_POS_TOP,
        _: 'copper_'
      },
      {
        ...DEF_COL_6,
        label: 'Surface',
        field: 'surface',
        type: 'select',
        ...DEF_KEY_VAL_POS_TOP,
        _: 'copper_'
      }
    ]
  }
];
