import { Injectable } from '@angular/core';
import { Subscription, fromEvent, Subject, interval, BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';
import { SpinnerService } from '../spinner.service';
import { take } from 'rxjs/operators';
import { BarcodeScanner } from '@capacitor-mlkit/barcode-scanning';

import { LogRocketProvider } from './logrocket';
import { Platform } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { AppState } from '@edgeauditor/app/store/app/app.state';

@Injectable({
  providedIn: 'root'
})
export class EquipmentProviderService {
  barcode: string = '';
  keyboardSubscription: Subscription;
  barcode$: Subject<{ data?: string; error?: boolean }> = new Subject();
  subscription: Subscription;
  isTorchAvailable = false;
  private readonly cameraScanningOptions = {
    showTorchButton: true, // iOS and Android
    showFlipCameraButton: true, // iOS and Android
    resultDisplayDuration: 500, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
    disableAnimations: true, // iOS
    disableSuccessBeep: false // iOS and Android
  };
  private timeoutAutoFireBarCode = null;
  readonly specialKeyCodes = {
    8: true, //backspace
    9: true, //tab
    13: true, //enter
    16: true, //shift
    17: true, //ctrl
    18: true, //alt
    20: true, //caps lock
    27: true, //escape
    45: true, //insert
    46: true //delete
  };
  readonly noiseKeys = {
    undefined: true,
    unidentified: true
  };

  private _scanActive = new BehaviorSubject<boolean>(false);
  scanActive$ = this._scanActive.asObservable();
  torchEnabled = false;

  constructor(
    private spinnerService: SpinnerService,
    private logRocket: LogRocketProvider,
    private platform: Platform,
    private store: Store
  ) {}

  startKeyboardEvent() {
    this.unsubscribe();
    this.keyboardSubscription = fromEvent(document, 'keydown').subscribe((event) => {
      this.keyEvent(event);
    });
  }

  keyEvent(event: any) {
    console.log('keyboardEvent: ', event);
    //not detect on input/textarea
    const notDetectFor = { input: true, textarea: true };
    if (event && event.target && notDetectFor[event.target.nodeName.toLowerCase()]) {
      this.barcode = '';
      return;
    }
    try {
      this.clearInterval();
      if (!this.specialKeyCodes[event.keyCode] && !this.noiseKeys[(event.key || '').toLowerCase()]) {
        this.barcode += event.keyCode === 32 ? ' ' : this.parseValidKey(event.key);
        this.clearTimeout();
        this.timeoutAutoFireBarCode = setTimeout(() => {
          this.barcode$.next({ data: this.barcode });
          this.barcode = '';
          this.timeoutAutoFireBarCode = null;
        }, 300);
        const _interval = interval(5000).pipe(take(1)); // 5000ms = 5s
        this.subscription.add(
          _interval.subscribe((x) => {
            this.barcode = '';
          })
        );
      } else if (event.keyCode == 13 && this.barcode) {
        this.spinnerService.show('scanning...', 200);
        this.barcode$.next({ data: this.barcode });
        this.barcode = '';
      }
    } catch {
      this.barcode = '';
    }
  }

  async openCameraScanning() {
    // this plugin no work on browser
    const isMobilePlatform = this.store.selectSnapshot(AppState.getIsMobilePlatform);

    if (!isMobilePlatform) {
      return;
    }

    const checkPermissions = await BarcodeScanner.checkPermissions();

    if (checkPermissions.camera !== 'granted') {
      const permission = await BarcodeScanner.requestPermissions();

      if (permission.camera !== 'granted') {
        return;
      }
    }

    const _subscription = this.platform.backButton.subscribeWithPriority(9999, () => {
      document.addEventListener('backbutton', () => this.stopScanner(), false);
    });

    this.scanActive = true;

    document.querySelector('body').classList.add('scanner-active');

    // Add the `barcodeScanned` listener
    const listener = await BarcodeScanner.addListener('barcodeScanned', async (result) => {
      setTimeout(() => {
        document.removeEventListener('backbutton', () => {});
        if (_subscription) _subscription.unsubscribe();
      }, 500);
      if (!result.barcode) return;
      this.barcode$.next({ data: result.barcode.rawValue });

      listener.remove();
    });

    // Start the barcode scanner
    try {
      await BarcodeScanner.startScan();
      this.isTorchAvailable = (await BarcodeScanner.isTorchAvailable()).available;
    } catch (err) {
      document.removeEventListener('backbutton', () => {});
      if (_subscription) _subscription.unsubscribe();
      this.logRocket.captureException(err, 'equipment - camera scanning');
    }
  }

  async stopScanner() {
    this.scanActive = false;
    this.torchEnabled = false;
    //TODO: Check if this is needed
    // BarcodeScanner.showBackground();
    document.querySelector('body').classList.remove('scanner-active');
    await BarcodeScanner.stopScan();
  }

  async toggleFlash() {
    if (!this.isTorchAvailable) {
      return;
    }

    try {
      await BarcodeScanner.toggleTorch();
      this.torchEnabled = !this.torchEnabled;
    } catch (err) {}
  }

  unsubscribe() {
    try {
      if (this.keyboardSubscription) {
        this.keyboardSubscription.unsubscribe();
        this.keyboardSubscription = null;
      }
      this.clearInterval();
      this.clearTimeout();
    } catch {}
  }

  clearInterval() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = new Subscription();
  }

  private parseValidKey(key: string) {
    return _.isNil(key) || key.toLowerCase() == 'unidentified' ? '' : key;
  }

  clearTimeout() {
    if (this.timeoutAutoFireBarCode) {
      clearTimeout(this.timeoutAutoFireBarCode);
      this.timeoutAutoFireBarCode = null;
    }
  }

  private get scanActive(): boolean {
    return this._scanActive.value;
  }

  private set scanActive(value: boolean) {
    this._scanActive.next(value);
  }
}
