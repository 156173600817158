import {
  EventEmitter,
  Input,
  Output,
  ChangeDetectorRef,
  ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver,
  ViewChildren,
  Directive
} from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { take, tap, delay } from 'rxjs/operators';
import moment from 'moment';
import _ from 'lodash';

import {
  IDynamicComponentModelWrapper,
  IReportSection,
  IReportSectionObject,
  IDynamicComponentModel,
  ReportQuestionType,
  IReportSectionQuestion
} from '@ea-models/reports';
import { User, IAttachment } from '@ea-models';
import { DynamicComponentComponent } from './dynamic-component/dynamic-component.component';
import { ReportService } from '@ea-services';

@Directive()
export class ReportItemBase {
  @Input() section: IReportSection;
  @Input() object: IReportSectionObject;
  @Input() objectIndex: number;
  @Input() sectionIndex: number;
  @Input() userEvent: Observable<User>;
  @Input() restoreSectionEvent: Observable<any>;
  @Input() parentChangeDetectorRef: ChangeDetectorRef;
  @Input() isSupportResizePhoto: boolean;
  @Input() showWarningResizePhoto: boolean;
  @Input() parentRef: ChangeDetectorRef;
  @Output() questionChanged = new EventEmitter<IDynamicComponentModelWrapper>();
  @ViewChild('viewContainerRef', { read: ViewContainerRef, static: true })
  viewContainerRef: ViewContainerRef;
  @ViewChild('onePixel') onePixel: any;
  @ViewChildren('target', { read: ViewContainerRef }) set targets(_targets: any) {
    this._targets = _targets;
  }
  protected renderQuestionName: boolean;
  private _targets: any;
  readonly ReportQuestionType = ReportQuestionType;
  readonly SelectTypes = {
    [ReportQuestionType.patroller]: true,
    [ReportQuestionType.lift_operator]: true,
    [ReportQuestionType.lift_maintenance_staff]: true,
    [ReportQuestionType.park_staff]: true,
    [ReportQuestionType.ski_instructor]: true
  };
  readonly DateTimeTypes = [ReportQuestionType.date, ReportQuestionType.time];
  readonly selectsMapping: _.Dictionary<string> = {
    [ReportQuestionType.patroller]: 'patrollers',
    [ReportQuestionType.lift_operator]: 'lift_operators',
    [ReportQuestionType.lift_maintenance_staff]: 'lift_maintenance_staff',
    [ReportQuestionType.park_staff]: 'park_staff',
    [ReportQuestionType.ski_instructor]: 'ski_instructors'
  };

  constructor(
    protected readonly factoryResolver: ComponentFactoryResolver,
    protected readonly reportService?: ReportService
  ) {}

  init(isCallInit?: boolean) {
    if (this.section && this.section.objects && this.section.questions && this.viewContainerRef) {
      this.section.questions.forEach((q, index) => {
        this.addDynamicComponent(q, index, isCallInit);
      });
    }
  }

  private addDynamicComponent(
    question: IReportSectionQuestion,
    index: number,
    isCallInit?: boolean,
    target?: ViewContainerRef
  ) {
    const viewContainerRef = target || this.viewContainerRef;
    if (!viewContainerRef) return;
    viewContainerRef.detach();
    const factory = this.factoryResolver.resolveComponentFactory(DynamicComponentComponent);
    const component = factory.create(viewContainerRef.parentInjector);
    component.instance.question = question;
    component.instance.renderQuestionName = this.renderQuestionName;
    component.instance.parentChangeDetectorRef = this.parentChangeDetectorRef;
    component.instance.restoreSectionEvent = this.restoreSectionEvent;
    component.instance.userEvent = this.userEvent;
    component.instance.isSupportResizePhoto = this.isSupportResizePhoto;
    component.instance.showWarningResizePhoto = this.showWarningResizePhoto;
    component.instance.questionIndex = index;
    component.instance.objectId = this.object.object_id;
    component.instance.objectIndex = this.objectIndex;
    component.instance.sectionIndex = this.section['index'];
    component.instance.elementRef.nativeElement.className = `${question.type === 'checkbox' ? 'checkbox' : ''} ${
      question.type === 'boolean' ? 'boolean' : ''
    }`;
    if (isCallInit) {
      component.instance.init();
    }
    of(null)
      .pipe(
        delay(0.1),
        take(1),
        tap(() => viewContainerRef.insert(component.hostView))
      )
      .subscribe();
  }

  public initModel() {
    const _sections = this.reportService.currReportSections;
    const object = this.object;
    this.section.questions.forEach((q, qIndex) => {
      let model = {
        value: null,
        date: null,
        time: null
      } as IDynamicComponentModel;
      const findFn = (item) => {
        return (
          item &&
          !item.isRestored &&
          item.question_id === q.question_id &&
          (item.feature_id || item.object_id) === object.object_id
        );
      };
      const compData = _sections && _sections.find && _sections.find(findFn);
      let objectQuestion = {};
      if (compData && compData) {
        compData.isRestored = true;
        model = { ...compData };
      }
      object['models'][qIndex] = objectQuestion;
      objectQuestion['model'] = model;
      objectQuestion['signSubject'] = new ReplaySubject<string>(1);
      objectQuestion['diagramSubject'] = new ReplaySubject<any>(1);
      objectQuestion['imagesSubject'] = new ReplaySubject<any>(1);
      if (model.temp_file && model.temp_file !== '') {
        objectQuestion['signSubject'].next(model.temp_file);
      }
      if (q.type === ReportQuestionType.diagram) {
        objectQuestion['diagramSubject'].next({
          diagram_image: model.diagram_image,
          diagram_id: model.diagram_id,
          object_attachments_attributes: model.object_attachments_attributes
        });
      } else if (q.type === ReportQuestionType.image) {
        if (model.object_attachments_attributes) {
          objectQuestion['imagesSubject'].next(
            model.object_attachments_attributes.map((img) => ({
              object_attachment: img
            }))
          );
        }
      } else if (q.type === ReportQuestionType.checkbox || q.type === ReportQuestionType.boolean) {
        let value = model.value || false;
        if (value && typeof value === 'string' && q.type === ReportQuestionType.boolean && q.options) {
          const index = q.options.findIndex((o) => o.text === value);
          value = index === 0 ? true : false;
          model.toggler = value;
        } else if (typeof value === 'string' && q.type === ReportQuestionType.checkbox) {
          value = value === '0' ? false : value;
        }
        model.value = value;
      }
      if (this.userEvent) {
        this.userEvent
          .pipe(
            take(1),
            tap((user) => (objectQuestion['currentUser'] = user)),
            tap(() => {
              const key = this.selectsMapping[q.type];
              if (key) {
                objectQuestion['currentUser'][key].forEach(
                  (item: any) => (item.name = `${item.first_name} ${item.last_name}`)
                );
              }
              if (this.parentRef) {
                this.parentRef.detectChanges();
              }
            })
          )
          .subscribe();
      }
      this.emitChangeEvent(model, q, qIndex, object.object_id);
    });
  }

  protected renderControls() {
    if (!this._targets || !this._targets._results.length) return;
    const object = this.object;
    this.section.questions.forEach((q, qIndex) => {
      const dataCI = `${this.sectionIndex}${this.objectIndex}${qIndex}`;
      const model = object['models'][qIndex].model;
      const _target = this._targets._results[qIndex];
      const columnEle: Element = _target.element.nativeElement.parentElement;
      _target.detach();
      switch (q.type) {
        case ReportQuestionType.checkbox:
          {
            const newEle = document.createElement('ion-checkbox');
            newEle.checked = !!model.value;
            newEle.setAttribute('data-ci', dataCI);
            if (q.required && !model.value) newEle.classList.add('invalid');
            newEle.onclick = (event) => {
              const prevChecked: boolean = event.target['checked'];
              const newValue = !prevChecked;
              this.onChange(newValue, ReportQuestionType.checkbox, q, model, qIndex, this.object.object_id);
              if (q.required) {
                if (prevChecked) newEle.classList.add('invalid');
                else newEle.classList.remove('invalid');
              }
            };
            columnEle.appendChild(newEle);
          }
          break;
        case ReportQuestionType.boolean:
          {
            const itemEle = document.createElement('div');
            // itemEle.lines = "none";
            itemEle.className = 'toggle';
            const newEle = document.createElement('ion-toggle');
            newEle.mode = 'ios';
            const model = this.object['models'][qIndex].model;
            newEle.checked = !!model.toggler;
            newEle.setAttribute('data-ci', dataCI);
            newEle.onclick = (event: any) => {
              of(null)
                .pipe(
                  delay(100),
                  take(1),
                  tap(() => {
                    this.onChange(
                      event.target.checked,
                      ReportQuestionType.boolean,
                      q,
                      model,
                      qIndex,
                      this.object.object_id
                    );
                  })
                )
                .subscribe();
            };
            if (q.options && q.options.length > 1) {
              const span = document.createElement('span');
              span.textContent = q.options[1].text;
              itemEle.appendChild(span);
            }
            itemEle.appendChild(newEle);
            if (q.options && q.options.length) {
              const span = document.createElement('span');
              span.textContent = q.options[0].text;
              itemEle.appendChild(span);
            }
            columnEle.appendChild(itemEle);
          }
          break;
        case ReportQuestionType.number:
        case ReportQuestionType.string:
        case ReportQuestionType.text:
          {
            const type = q.type !== ReportQuestionType.text ? 'ion-input' : 'ion-textarea';
            const newEle = document.createElement(type);
            if (q.type === ReportQuestionType.number) newEle.inputMode = 'tel';
            if (q.type === ReportQuestionType.text) newEle.setAttribute('rows', '5');
            newEle.autocapitalize = 'sentences';
            newEle.value = model.value as string;
            newEle.placeholder = `Enter ${q.question}`;
            newEle.setAttribute('data-ci', dataCI);
            if (q.required && !model.value) newEle.classList.add('invalid');
            newEle.addEventListener('ionChange', (event: any) => {
              model.value = event.detail.value;
              if (q.required) {
                if (_.isEmpty(model.value)) newEle.classList.add('invalid');
                else newEle.classList.remove('invalid');
              }
              this.onChange(model.value, null, q, model, qIndex, this.object.object_id);
            });
            columnEle.appendChild(newEle);
          }
          break;
      }
    });
    this.parentRef.detectChanges();
  }

  onChange(
    newValue: string | number | boolean | Date | number[],
    custom: ReportQuestionType = null,
    question: IReportSectionQuestion,
    model: IDynamicComponentModel,
    questionIndex: number,
    objectId: number
  ) {
    if (question.type === ReportQuestionType.multi_select) {
      model.question_id = question.question_id;
      model.multi_values = newValue instanceof Array ? newValue : [];
      const obj: IDynamicComponentModelWrapper = {
        model: { ...model },
        index: questionIndex,
        feature_id: objectId
      };
      this.onQuestionChanged(obj, questionIndex);
    } else {
      const dtVal = newValue instanceof Date ? moment(newValue).format() : '';
      const customValMapping = {
        checkbox: newValue ? 1 : 0,
        boolean: question.options ? (question.options[newValue ? 0 : 1] || {}).text || null : null,
        date: dtVal,
        time: dtVal
      };
      const key = ['date', 'time'].includes(custom) ? custom : 'value';
      model.question_id = question.question_id;
      if (custom) model[key] = customValMapping[custom];
      const obj: IDynamicComponentModelWrapper = {
        model: { ...model },
        index: questionIndex,
        feature_id: objectId
      };
      this.onQuestionChanged(obj, questionIndex);
    }

    // this.modelChanged.emit({...this.model});
  }

  onSelectChange(
    newVal: _.Dictionary<string | number> | { id: number; text: string }[],
    key: string,
    question: IReportSectionQuestion,
    model: IDynamicComponentModel,
    questionIndex: number,
    objectId: number,
    isMultiple?: boolean
  ) {
    if (isMultiple && newVal instanceof Array) {
      const typedNewValue = newVal.map((i) => i.id);
      this.onChange(typedNewValue, null, question, model, questionIndex, objectId);
    } else {
      const typedNewValue = newVal as _.Dictionary<string | number>;
      const _value = !_.isNil(typedNewValue) && !_.isNil(typedNewValue[key]) ? typedNewValue[key] : typedNewValue;
      // TODO: Strong typing in the future.
      const finalValue: any = !_.isNil(_value) ? _value : '';
      this.onChange(finalValue, null, question, model, questionIndex, objectId);
    }
  }

  onTextChange(
    value,
    question: IReportSectionQuestion,
    model: IDynamicComponentModel,
    questionIndex: number,
    objectId: number
  ) {
    model.value = value;
    this.onChange(value, null, question, model, questionIndex, objectId);
  }

  onDiagramChange(
    newVal: IDynamicComponentModel,
    question: IReportSectionQuestion,
    model: IDynamicComponentModel,
    questionIndex: number,
    objectId: number
  ) {
    model.diagram_id = newVal.diagram_id;
    model.diagram_image = newVal.diagram_image;
    model.object_attachments_attributes = newVal.object_attachments_attributes || [];
    this.onChange(newVal.diagram_image, null, question, model, questionIndex, objectId);
  }

  onSignatureChange(
    signatureData: string,
    question: IReportSectionQuestion,
    model: IDynamicComponentModel,
    questionIndex: number,
    objectId: number,
    isClear?: boolean
  ) {
    model.temp_file = signatureData;
    if (isClear) model.remove_file = isClear;
    else delete model.remove_file;
    console.log('onSignatureChange isClear', isClear);
    this.onChange(signatureData, null, question, model, questionIndex, objectId);
  }

  onImageChange(
    imagesData: { currentImage: IAttachment; images: IAttachment[] },
    question: IReportSectionQuestion,
    model: IDynamicComponentModel,
    questionIndex: number,
    objectId: number
  ) {
    const attachments = imagesData.images.map((img) => img.object_attachment) || [];
    if (model.object_attachments_attributes && model.object_attachments_attributes.length) {
      const deletedAttachments = [];
      model.object_attachments_attributes.forEach((img) => {
        if (img.id && !attachments.some((i) => i['id'] === img.id)) {
          img._destroy = true;
          deletedAttachments.push(img);
        }
      });
      model.object_attachments_attributes = [...attachments, ...deletedAttachments];
    } else {
      model.object_attachments_attributes = attachments;
    }

    this.onChange('', null, question, model, questionIndex, objectId);
  }

  private emitChangeEvent(
    model: IDynamicComponentModel,
    question: IReportSectionQuestion,
    questionIndex: number,
    objectId: number
  ) {
    const customTypes = [
      ReportQuestionType.date,
      ReportQuestionType.time,
      ReportQuestionType.boolean,
      ReportQuestionType.checkbox
    ];
    let value: string | number | true | number[];
    if (question.type === ReportQuestionType.multi_select) value = model.multi_values;
    else value = model.date || model.time || model.value || '';
    let custom: ReportQuestionType = null;
    if (customTypes.includes(question.type)) custom = question.type;
    this.onChange(value, custom, question, model, questionIndex, objectId);
  }

  onQuestionChanged($event: IDynamicComponentModelWrapper, questionIndex: number) {
    const item = { ...$event.model };
    item.feature_id = $event.feature_id;
    item.answer_save = true;
    this.addUpdateQuestion(item, questionIndex);
  }

  addUpdateQuestion(item, questionIndex: number) {
    this.reportService.currReportModel[this.sectionIndex] = this.reportService.currReportModel[this.sectionIndex] || [];
    // const idx = this.reportService.currReportModel[sectionIndex].findIndex((obj) => obj.feature_id === item.feature_id && obj.question_id === item.question_id);
    const index = +`${this.objectIndex + 1}${questionIndex}`;
    this.reportService.currReportModel[this.sectionIndex][index] = item;
  }
}
