import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ChangeDetectorRef,
  ViewChild
} from '@angular/core';
import { ActionSheetController, ModalController, AlertController } from '@ionic/angular';
import { DiagramDrawingComponent } from './diagram-drawing.component';
import { from, Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { IDynamicComponentModel } from '@ea-models/reports';
import { ReportService, GlobalService, PopUpService } from '@ea-services';
import { User, IAttachment } from '@ea-models';
import _ from 'lodash';
import { WARNING_HEADER } from '../../../pages/pages.constants';

@Component({
  selector: 'app-diagram',
  templateUrl: './diagram.component.html',
  styleUrls: ['./diagram.component.scss']
})
export class DiagramComponent implements OnInit, AfterViewInit {
  @Output() modelChanged = new EventEmitter<IDynamicComponentModel>();
  @Input() diagramEvent: Observable<any>;
  @Input() userEvent: Observable<User>;
  @Input() parentChangeDetectorRef: ChangeDetectorRef;
  @Input() isSupportResizePhoto: boolean;
  @Input() showWarningResizePhoto: boolean;
  @Input() isRequired = false;

  @ViewChild('btnExistingDiagram') btnExistingDiagram: any;
  private updateDataFromInit = false;
  model: IDynamicComponentModel = {};
  currentUser: User;
  diagramTypes: { id: number; type: string; url: string }[] = [];
  customImage: string;

  constructor(
    private modalController: ModalController,
    private actionSheetController: ActionSheetController,
    private popUpService: PopUpService,
    private alertController: AlertController,
    private reportService: ReportService,
    public globalService: GlobalService
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    if (this.diagramEvent) {
      this.diagramEvent
        .pipe(
          take(1),
          tap((diagram) => {
            [this.model.diagram_image, this.model.diagram_id, this.model.object_attachments_attributes] = [
              diagram.diagram_image,
              diagram.diagram_id,
              diagram.object_attachments_attributes
            ];
            if (this.model.diagram_image && this.btnExistingDiagram) {
              this.updateDataFromInit = true;
              this.btnExistingDiagram.el.click();
              this.parentChangeDetectorRef.detectChanges();
            }
          }),
          tap(() => {
            if (this.model.object_attachments_attributes) {
              this.customImage = (this.model.object_attachments_attributes.find((i) => !i._destroy) || {}).file;
            }
          })
        )
        .subscribe();
    }
    if (this.userEvent) {
      this.userEvent
        .pipe(
          take(1),
          tap((user) => (this.currentUser = user)),
          tap(() => this.getDiagrams())
        )
        .subscribe();
    }
  }

  async showAlertForExistingDiagram(onYesCallback: any) {
    const msg =
      'You already have an existing diagram saved. If you continue, your selection will delete the current diagram. Do you still want to continue?';
    const onYesHandler = () => {
      this.model.diagram_image = null;
      this.model.object_attachments_attributes = [];
      this.modelChanged.emit({ ...this.model });
      if (onYesCallback) onYesCallback();
    };
    this.popUpService.popupConfirm(WARNING_HEADER, msg, this.alertController, onYesHandler);
  }

  private getDiagrams() {
    this.reportService
      .getDiagrams(this.currentUser.token)
      .pipe(
        take(1),
        tap((res) => {
          this.diagramTypes = res.diagrams.sort((a, b) => a.type.localeCompare(b.type));
          this.reportService.reportDiagrams[this.currentUser.token] = {
            diagrams: [...this.diagramTypes]
          };
        })
      )
      .subscribe();
  }

  async presentModal(diagram = { id: null, url: null }) {
    const url = diagram.url || this.customImage;
    const props = {
      diagram_id: diagram.id,
      diagram_image: this.model.diagram_image,
      url,
      parentChangeDetectorRef: this.parentChangeDetectorRef
    };
    const modal = await this.modalController.create({
      component: DiagramDrawingComponent,
      cssClass: 'diagram-modal',
      componentProps: props
    });
    from(modal.onDidDismiss())
      .pipe(
        take(1),
        tap((data) => {
          if (data.data && data.data.success) {
            this.model.diagram_id = data.data.diagram_id;
            this.model.diagram_image = data.data.diagram;
            if (this.model.diagram_id) this.customImage = null;
            if (!this.model.diagram_image && this.model.object_attachments_attributes) {
              this.clearAttachments();
            } else {
              this.updateAttachments();
            }
            this.modelChanged.emit({ ...this.model });
          }
        })
      )
      .subscribe();
    return await modal.present();
  }

  async presentActionSheet(isCheckExistingDiagram?: boolean) {
    if (isCheckExistingDiagram && this.model.diagram_image) {
      this.showAlertForExistingDiagram(() => this.presentActionSheet());
      return;
    }
    const buttons = [];
    this.diagramTypes.forEach((t) =>
      buttons.push({
        text: t.type,
        icon: 'analytics',
        handler: () => this.presentModal(t)
      })
    );
    buttons.push({
      text: 'Cancel',
      icon: 'close',
      role: 'cancel',
      handler: () => {
        console.log('Cancel clicked');
      }
    });
    const actionSheet = await this.actionSheetController.create({
      header: 'Pre-Built Diagram',
      buttons
    });
    await actionSheet.present();
  }

  editDiagram() {
    if (this.updateDataFromInit) {
      this.updateDataFromInit = false;
      return;
    }
    let diagram = this.diagramTypes.find((d) => d.id === this.model.diagram_id) || ({} as any);
    this.presentModal(diagram);
  }

  onBgImageSelected(imagesData: { currentImage: IAttachment; images: IAttachment[] }) {
    this.customImage = (imagesData && imagesData.currentImage && imagesData.currentImage.object_attachment.file) || '';
    this.presentModal();
  }

  private clearAttachments() {
    const attachments = _.cloneDeep(this.model.object_attachments_attributes);
    this.model.object_attachments_attributes = [];
    attachments.forEach((i) => {
      if (i.id) {
        this.model.object_attachments_attributes.push(i);
        i._destroy = true;
      }
    });
  }

  private updateAttachments() {
    if (this.model.object_attachments_attributes) {
      const attachments = _.cloneDeep(this.model.object_attachments_attributes);
      this.model.object_attachments_attributes = [];
      let hasNewAttachment = true;
      attachments.forEach((i) => {
        if (i.id) {
          this.model.object_attachments_attributes.push(i);
          if (i.file !== this.customImage) {
            i._destroy = true;
          } else {
            hasNewAttachment = false;
          }
        } else if (i.file == this.customImage) {
          hasNewAttachment = false;
          this.model.object_attachments_attributes.push(i);
        }
      });
      if (hasNewAttachment && this.customImage) {
        this.model.object_attachments_attributes = [{ file: this.customImage }];
      }
    } else if (this.customImage) this.model.object_attachments_attributes = [{ file: this.customImage }];
  }
}
