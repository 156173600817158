import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { ConfigService, ApiService, SpinnerService, GlobalService, GroomersService } from '@ea-services';
import { of } from 'rxjs';
import { tap, take, delay } from 'rxjs/operators';
import { filter_types } from '../../../constants/groomer.constants';
import _ from 'lodash';

@Component({
  selector: 'modal-filter-groomer',
  templateUrl: 'filter-groomer.component.html',
  styleUrls: ['filter-groomer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FilterGroomerModalComponent implements OnInit {
  @Input() orgAssignments: any;
  @Input() assignments: any;
  @Input() activityTypes: any;
  @Input() selectedOptions: {
    area: string;
    sort: number;
    priority: any;
    completed: number;
    completedPercent: any;
    activity_type: number;
    type: number;
  };

  public areas = [];
  public types = [];
  public sorts = [
    { id: 1, name: 'Location Alphabetically', value: 0, selected: false },
    { id: 2, name: 'Priority', value: 1 }
  ];
  public completedPercents = [
    {
      id: 1,
      name: 'Less Than 50%',
      func: (list) => {
        return list.filter(
          (i) => (!i.log || !i.log.overridden) && (i.totalFinalComplete || i.amount_completed || 0) < 50
        );
      },
      selected: false
    },
    {
      id: 2,
      name: '50% or Greater',
      func: (list) => {
        return list.filter(
          (i) => (i.log && i.log.overridden) || (i.totalFinalComplete || i.amount_completed || 0) >= 50
        );
      },
      selected: false
    }
  ];
  public completeds = [
    { id: 1, name: 'Yes', value: 1, selected: true },
    { id: 2, name: 'No', value: 0 }
  ];
  public priorities = [
    { id: 1, name: 'All', value: 0, selected: true },
    { id: 2, name: '1', value: 1 },
    { id: 3, name: '2', value: 2 },
    { id: 3, name: '3', value: 3 },
    { id: 4, name: '4', value: 4 }
  ];
  private areaAll = { id: 1, name: 'all areas', selected: true };

  constructor(
    public configService: ConfigService,
    public apiService: ApiService,
    public globalService: GlobalService,
    public groomersService: GroomersService,
    public spinnerService: SpinnerService,
    public modalController: ModalController,
    public alertController: AlertController
  ) {}

  ngOnInit() {
    this.types = _.cloneDeep(filter_types);
  }

  ionViewWillEnter() {
    this.groomersService
      .getAreas(this.globalService._currentUser.token)
      .pipe(
        take(1),
        tap(() => {
          if (this.groomersService.areasData) {
            let areas = _.cloneDeep(this.groomersService.areasData);
            let selectedAreas = _.split(this.selectedOptions.area || '', '|') || [];
            selectedAreas = selectedAreas[0].length ? selectedAreas : [];
            this.areas = areas.map((i, index) => {
              return {
                id: index + 2,
                name: i.name,
                selected: selectedAreas.includes(i.name)
              };
            });
            this.areas = this.areas.sort((a, b) => a.name.localeCompare(b.name));
            this.areaAll.selected = !selectedAreas.length || selectedAreas.includes(this.areaAll.name);
            this.areas = [this.areaAll, ...this.areas];
          }
        })
      )
      .subscribe();
  }

  ionViewDidEnter() {
    if (this.activityTypes) {
      this.activityTypes = this.activityTypes.sort((a, b) => a.name.localeCompare(b.name));
      this.activityTypes = [{ id: 0, name: 'all types', selected: true }, ...this.activityTypes];
      this.activityTypes.forEach((i) => {
        i.selected = this.selectedOptions.activity_type === i.id;
      });
    }
    if (this.areas) {
      const filter = this.areas.find((a) => a.name === this.selectedOptions.area);
      if (filter) filter.selected = true;
    }
    this.sorts.forEach((i) => {
      i.selected = i.value === this.selectedOptions.sort;
    });
    this.completeds.forEach((i) => {
      i.selected = i.value === this.selectedOptions.completed;
    });
    if (this.selectedOptions.completedPercent) {
      this.completedPercents.forEach((i) => {
        i.selected = i.id === this.selectedOptions.completedPercent.id;
      });
    }

    this.priorities.forEach((i) => {
      i.selected = this.selectedOptions.priority[i.value];
    });
    this.types.forEach((i) => {
      i.selected = i.value === this.selectedOptions.type;
    });
  }

  reset() {
    this.sorts.forEach((i, index) => {
      i.selected = false;
    });
    this.areas.forEach((i, index) => {
      i.selected = index === 0;
    });
    this.completeds.forEach((i, index) => {
      i.selected = index === 0;
    });
    this.priorities.forEach((i, index) => {
      i.selected = index === 0;
    });
    this.activityTypes.forEach((i, index) => {
      i.selected = index === 0;
    });

    this.types.forEach((i, index) => {
      i.selected = false;
    });
    this.completedPercents.forEach((i, index) => {
      i.selected = false;
    });
    this.applyFilter();
  }

  async applyFilter() {
    const model = this.selectedOptions;
    const areas = this.areas
      .filter((a) => a.selected)
      .map((a) => a.name)
      .join('|');
    model.area = areas === this.areaAll.name ? null : areas;
    const activity = this.activityTypes.find((a) => a.selected);
    model.activity_type = activity.id;
    const sort = this.sorts.find((a) => a.selected);
    model.sort = sort ? sort.value : null;
    const completed = this.completeds.find((a) => a.selected);
    model.completed = completed.value;

    const completedPercent = this.completedPercents.find((a) => a.selected);
    model.completedPercent = completedPercent;

    model.priority = {};
    this.priorities.forEach((a) => (model.priority[a.value] = a.selected));
    if (_.isEmpty(model.priority)) model.priority = { 0: true };

    const _type = this.types.find((a) => a.selected);
    model.type = _type ? _type.value : 0;
    await this.modalController.dismiss(model);
  }

  onChange(item, list, allowEmpty?: boolean) {
    if (item.selected && !allowEmpty) {
      return;
    }
    of(null)
      .pipe(
        delay(100),
        take(1),
        tap(() => {
          const _selected = item.selected;
          list.forEach((element) => {
            element.selected = false;
          });
          item.selected = allowEmpty ? !_selected : true;
        })
      )
      .subscribe();
  }

  onChangeArea(item) {
    const _selected = item.selected;
    of(null)
      .pipe(
        delay(100),
        take(1),
        tap(() => {
          item.selected = !_selected;
          if (item.selected && item.id === 1) {
            this.areas.forEach((element) => {
              element.selected = false;
            });
            item.selected = true;
            return;
          }
          const hasSelect = this.areas.some((i) => i.selected && i.id !== 1);
          this.areas.find((i) => i.id === 1).selected = !hasSelect;
        })
      )
      .subscribe();
  }

  onChangePriority(item, list) {
    const _selected = item.selected;
    const optionAll = list.find((i) => i.id === 1);
    if (item.id === 1) {
      list.forEach((element) => {
        element.selected = false;
      });
    } else {
      optionAll.selected = false;
    }
    item.selected = !_selected;
    const hasSelected = list.some((i) => i.selected);
    if (!hasSelected) optionAll.selected = true;
  }
}
