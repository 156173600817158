import { GetReportResponse } from '@ea-services';

const STATE_KEY = '[Reports State]';
const TYPE = (text: string) => STATE_KEY + ' ' + text;

export class GetReports {
  static readonly type = TYPE('Get Reports');
}

export class GetReportsSuccess {
  static readonly type = TYPE('Get Reports Success');
  constructor(public readonly res: GetReportResponse) {}
}
